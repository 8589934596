import { contextMatcher, isFullyLoaded } from './helpers';

const getters = {
  GET_CONTEXTUAL_STATE: 'GET_CONTEXTUAL_STATE',
  GET_ITEM: 'GET_ITEM',
  GET_PAGE: 'GET_PAGE',
  IS_FULLY_LOADED: 'IS_FULLY_LOADED',
};

export default getters;

export const provideImplementations = ({ idColumn, excludeGlobalContext }) => ({
  [getters.GET_CONTEXTUAL_STATE]: (state, otherGetters, rootState) => (providedContext = null) => {
    const context = providedContext || state.context || {};

    const fullContext = excludeGlobalContext
      ? context
      : {
        ...rootState.globalContext,
        ...context,
      };

    return state.byContext.find(contextMatcher(fullContext));
  },
  /**
   * Get an item (or the index of an item) by a column (default column is "id")
   *
   * @returns {function(String id): Object}
   */
  [getters.GET_ITEM]: (state, otherGetters) => (id, returnIndex = false, column = null) => {
    const contextualState = otherGetters[getters.GET_CONTEXTUAL_STATE]();

    let items = ((contextualState && contextualState.items) || []);
    if (!returnIndex) {
      // Add in items that are fetched outside of pagination
      items = items.concat(state.additionalItems || []);
    }
    // Filter items so that we only match objects, and not `undefined` that's in the array
    return items.filter((item) => typeof item === 'object')[
      // (After filtering) switch whether to use `find` or `findIndex` depending on param
      returnIndex ? 'findIndex' : 'find'
    ]((candidate) => candidate[column || idColumn] === id);
  },
  /**
   * Get a full page of results, without switching the page in state
   *
   * @returns {function(Number page, Object context): (Array)}
   */
  [getters.GET_PAGE]: ({ pageSize }, otherGetters) => (page, context = {}) => {
    const contextualState = otherGetters[getters.GET_CONTEXTUAL_STATE](context);
    if (!contextualState) {
      return [];
    }
    const startIndex = pageSize * (page - 1);

    return contextualState.items.slice(startIndex, startIndex + pageSize);
  },
  /**
   * Whether the number of records in state matches the total number of records
   *
   * @returns {function(Object context): boolean}
   */
  [getters.IS_FULLY_LOADED]: (state, otherGetters) => (context = null) => (
    isFullyLoaded(otherGetters[getters.GET_CONTEXTUAL_STATE](context))
  ),
});
