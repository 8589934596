var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{ref:"header",staticClass:"navigation-bar"},[_c('ChecLogo',{staticClass:"navigation-bar__logo"}),_c('ChecButton',{attrs:{"icon":_vm.navOpen ? 'close' : 'hamburger',"variant":"small"},on:{"click":function($event){_vm.navOpen = !_vm.navOpen}}})],1),_c('nav',{ref:"navigation",staticClass:"navigation",class:{ 'navigation--open': _vm.navOpen }},[_c('a',{staticClass:"navigation__skip",attrs:{"href":"#main"}},[_vm._v(" "+_vm._s(_vm.$t('navigation.skip'))+" ")]),_c('div',{staticClass:"navigation__logo"},[_c('ChecLogo')],1),_c('MerchantSelector'),_c('div',{staticClass:"navigation__items"},[_vm._l((_vm.navMenuItems),function(ref){
var divider = ref.divider;
var key = ref.key;
var icon = ref.icon;
var label = ref.label;
var link = ref.link;
var subMenu = ref.subMenu;
return [(divider)?_c('div',{key:key,staticClass:"navigation__divider"}):_c('NavItem',{key:key,attrs:{"icon":icon || key,"label":_vm.$t(label),"link":link,"has-sub-menu":Boolean(subMenu),"sub-menu-open":subMenu && _vm.openMenus.includes(key),"ping":_vm.pings[key],"active":key === _vm.activeTopLevel},on:{"toggle-sub-nav":function () { return _vm.toggleSubNav(key); }}}),(subMenu && _vm.openMenus.includes(key))?_c('SubMenu',{key:(key + "-menu"),attrs:{"menu":subMenu}}):_vm._e()]})],2),_c('CurrentUser')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }