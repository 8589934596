import { apiConfig, ApiRequest, makeApiRequest } from '@/lib/api';
import globalMutations from '@/store/mutations';

const { SET_LOADING } = globalMutations;

const actions = {
  LOAD_PREFERENCE: 'LOAD_PREFERENCE',
  SET_PREFERENCE: 'SET_PREFERENCE',
};

const mutations = {
  ADD_PREFERENCE: 'ADD_PREFERENCE',
};

export { actions, mutations };

export default {
  namespaced: true,
  state: {
    preferences: [],
  },
  actions: {
    [actions.LOAD_PREFERENCE]({ commit }, preferenceKey) {
      commit(SET_LOADING, true, { root: true });
      const request = new ApiRequest('GET', `/v1/users/preferences/${preferenceKey}`);

      // This is a user preference and the merchant ID isn't relevant so we can disable it
      request.disableExtraParams();

      request.expectError(404);

      return request.send()
        .then((result) => {
          const preference = { key: preferenceKey, preference: result.data };
          commit(mutations.ADD_PREFERENCE, preference);
          return preference;
        })
        .catch(() => {
          // noop
        })
        .finally(() => {
          commit(SET_LOADING, false, { root: true });
        });
    },
    [actions.SET_PREFERENCE]({ commit }, preferenceObject) {
      commit(mutations.ADD_PREFERENCE, preferenceObject);

      return makeApiRequest(
        'POST',
        `/v1/users/preferences/${preferenceObject.key}`,
        preferenceObject.preference,
        {},
        {},
        apiConfig.url,
        true,
      )
        .then((result) => {
          const newPreference = {
            ...preferenceObject,
            preference: result.data,
          };
          commit(mutations.ADD_PREFERENCE, newPreference);
          return newPreference;
        });
    },
  },
  mutations: {
    [mutations.ADD_PREFERENCE](state, { key, preference }) {
      state.preferences = [
        ...state.preferences.filter((candidate) => candidate.key !== key),
        { key, preference },
      ];
    },
  },
};
