export default {
  SET_ERROR: 'SET_ERROR',
  SET_LOADING: 'SET_LOADING',
  SET_INITIATED: 'SET_INITIATED',
  SET_DETAILS: 'SET_DETAILS',
  SET_PLANS: 'SET_PLANS',
  SET_CURRENT_PLAN: 'SET_CURRENT_PLAN',
  SET_INVOICE_LIST: 'SET_INVOICE_LIST',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_TRANSACTION_FEES: 'SET_TRANSACTION_FEES',
  SET_WEBHOOKS: 'SET_WEBHOOKS',
  SET_WEBHOOKS_HISTORY: 'SET_WEBHOOK_HISTORY',
  SET_WEBHOOK_SIGNING_KEY: 'SET_WEBHOOK_SIGNING_KEY',
  SET_WEBHOOK_TYPES: 'SET_WEBHOOK_TYPES',
  SET_WEBHOOK_MODAL_OPEN: 'SET_WEBHOOK_MODAL_OPEN',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_CONFIRM_DIALOG: 'SET_CONFIRM_DIALOG',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_MERCHANT: 'SET_MERCHANT',
  SET_NEW_ORDER: 'SET_NEW_ORDER',
  SET_DISCOUNTS: 'SET_DISCOUNTS',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_PANEL_COUNT: 'SET_PANEL_COUNT',
  SET_SUBDIVISIONS: 'SET_SUBDIVISIONS',
  PUSH_ERROR: 'PUSH_ERROR',
  SET_ERRORS: 'SET_ERRORS',
  SET_TAX: 'SET_TAX',
  SET_EU_VAT_MOSS: 'SET_EU_VAT_MOSS',
  SET_USER: 'SET_USER',
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  SET_GLOBAL_CONTEXT: 'SET_GLOBAL_CONTEXT',
};
