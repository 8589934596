import { applyPagination } from '@/lib/pagination';
import addresses from './addresses';
import notes from './notes';
import orders from './orders';

export default applyPagination('/v1/customers?sortBy=created_at&sortDirection=desc', {
  individualItemEndpoint: '/v1/customers',
  searchProperties: ['firstname', 'lastname', 'email'],
})({
  namespaced: true,
  modules: {
    addresses,
    notes,
    orders,
  },
});
