import integrations from './integrations';
import templates from './templates';

export default {
  namespaced: true,
  modules: {
    integrations,
    templates,
  },
};
