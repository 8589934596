<template>
  <div class="template-card">
    <div class="template-card__container">
      <div class="template-card__logo-container">
        <img
          v-if="template.icon_url"
          :src="template.icon_url"
          :alt="template.name"
          class="template-card__logo"
        >
        <h4 class="template-card__name">
          {{ template.name }}
        </h4>
      </div>
      <TemplateTags
        v-if="template.tags && template.tags.length"
        :tags="template.tags"
        buttons
        @click-tag="handleFilterTemplates"
      />
      <p class="template-card__description">
        {{ template.short_description }}
      </p>
    </div>
    <div class="template-card__actions">
      <ChecButton
        text-only
        variant="small"
        color="primary"
        @click="showInfoPanel = true"
      >
        {{ $t('general.learnMoreButton') }}
      </ChecButton>
      <EnableTemplateButton
        :template="template"
        variant="small"
      />
    </div>
    <TemplateInfo
      v-if="showInfoPanel"
      :template="template"
      show-enable-button
      @close="handleClose"
    />
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import TemplateInfo from './TemplateInfoPanel.vue';
import TemplateTags from './TemplateTags.vue';
import EnableTemplateButton from './EnableTemplateButton.vue';

export default {
  name: 'TemplateCard',
  components: {
    EnableTemplateButton,
    ChecButton,
    TemplateInfo,
    TemplateTags,
  },
  props: {
    template: {
      type: Object,
    },
  },
  data() {
    return {
      showInfoPanel: false,
    };
  },
  methods: {
    handleClose() {
      this.showInfoPanel = false;
    },
    handleFilterTemplates(tag) {
      this.$emit('change-filters', tag);
    },
  },
};
</script>

<style lang="scss">
.template-card {
  @apply relative shadow-sm rounded-md overflow-hidden flex flex-col h-full;

  &__container {
    @apply p-4 bg-white flex flex-col flex-grow;
  }

  &__logo-container {
    @apply flex flex-row items-center;
  }

  &__logo {
    @apply w-12 mr-2 rounded;
  }

  &__name {
    @apply text-xl font-bold text-gray-600;
  }

  &__description {
    @apply text-gray-600 pt-4;
  }

  &__actions {
    @apply flex justify-between bg-gray-200 p-4;
  }
}
</style>
