<template>
  <div class="intro-splash bg-confetti">
    <ChecCard compact class="intro-splash__card">
      <h3 class="intro-splash__title">
        {{ title }}
      </h3>
      <p class="intro-splash__description">
        <!-- @slot Description content of the intro card -->
        <slot />
      </p>
      <div
        v-if="graphic"
        class="intro-splash__graphic"
      >
        <!-- @slot Graphic image or logo reel in the intro card -->
        <slot name="graphic" />
      </div>
      <div v-if="alert">
        <!-- @slot Alert card in the intro card -->
        <slot name="alert" />
      </div>
      <div v-if="button" class="intro-splash__action">
        <ChecButton
          variant="round"
          color="primary"
          icon="plus"
          @click="handleClick"
        >
          {{ $t('general.add') }}
        </ChecButton>
      </div>
    </ChecCard>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'IntroSplash',
  components: {
    ChecButton,
    ChecCard,
  },
  props: {
    /**
     * Title of the intro card
     */
    title: String,
    /**
     * Determine whether there is an action button
     */
    button: Boolean,
    /**
     * Determine whether to include graphic
     */
    graphic: Boolean,
    /**
     * Determine whether to include an alert card
     */
    alert: Boolean,
  },
  methods: {
    /**
     * Triggered on a click event on the button
     * @type {Event}
     */
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
.intro-splash {
  @apply pt-24 pb-40 mt-4 whitespace-pre-line bg-cover;

  &__card {
    @apply mx-auto max-w-xl;
  }

  &__title {
    @apply mb-4 font-bold;
  }

  &__description {
    span {
      a {
        @apply font-bold underline text-gray-500;

        &:hover {
          @apply text-gray-600;
        }
      }
    }
  }

  &__graphic {
    @apply mx-auto w-3/4 pt-6;
  }

  &__action {
    @apply flex justify-end mt-8;
  }
}
</style>
