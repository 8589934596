<template>
  <ChecSlideoutPanel
    class="webhook-details"
    :title="$t('webhooks.deliveryDetails')"
    close-on-overlay-click
    @close="close"
  >
    <template>
      <ChecTable
        class="webhook-details__table"
      >
        <tbody>
          <tr v-if="showId">
            <td>
              {{ $t('webhooks.webhook') }}
            </td>
            <td>
              <code>
                {{ webhook.webhook_id }}
              </code>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('webhooks.eventName') }}
            </td>
            <td>
              <code>
                {{ webhook.event }}
              </code>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('general.status') }}
            </td>
            <td>
              <ChecDataPill :color="statusColor(webhook.response_code)">
                {{ webhook.response_code }}
              </ChecDataPill>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('general.url') }}
            </td>
            <td>
              <CopyInline
                class="api-key-row__inline"
                :copy-content="webhook.url"
              >
                {{ webhook.url }}
              </CopyInline>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('general.dateTime') }}
            </td>
            <td>
              <Timestamp :timestamp="webhook.created" />
            </td>
          </tr>
        </tbody>
      </ChecTable>
      <ChecHeader
        header-tag="h3"
        :title="$t('webhooks.responseBody')"
      />
      <!-- eslint-disable -->
      <CodeBlock
        :title="$t('webhooks.responseBody')"
        language="none"
      >{{ webhook.response_body
        ? webhook.response_body
        : $t('general.empty') }}</CodeBlock>
      <!-- eslint-enable -->
    </template>
    <template #toolbar>
      <div class="webhook-details__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.close' ) }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  CodeBlock,
  ChecDataPill,
  ChecHeader,
  ChecSlideoutPanel,
  ChecTable,
} from '@chec/ui-library';
import CopyInline from '@/components/CopyInline.vue';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'WebhookLogDetails',
  components: {
    ChecButton,
    CodeBlock,
    ChecDataPill,
    ChecHeader,
    ChecSlideoutPanel,
    ChecTable,
    CopyInline,
    Timestamp,
  },
  props: {
    webhook: Object,
    showId: Boolean,
  },
  methods: {
    statusColor(responseCode) {
      if (responseCode >= 200 && responseCode < 300) {
        return 'green';
      }
      return 'red';
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.webhook-details {
  .slideout-panel__content {
    @apply h-full;
  }

  .chec-header {
    @apply mb-4;
  }

  &__table {
    @apply w-full mb-8;

    table {
      @apply w-full h-px;
    }

    tbody {
      @apply h-full;
    }

    td:first-of-type {
      @apply caps-xxs;
    }

    td:last-of-type {
      @apply text-right;
    }
  }

  &__toolbar {
    @apply w-full flex justify-end items-center;
  }

  code {
    @apply py-1 px-2 bg-gray-200 rounded-sm break-words
      text-xs font-mono tracking-wide text-gray-600;
  }
}
</style>
