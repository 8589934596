import actions from '../store/actions';

export default {
  methods: {
    /**
     * Send a toast notification message to the dashboard.
     *
     * @param {string} message E.g. "Hello world"
     * @param {string} type One of success, error, info
     */
    addNotification(message, type = 'success') {
      this.$store.dispatch(`notifications/${actions.ADD_NOTIFICATION}`, {
        type,
        text: message,
      });
    },
  },
};
