<template>
  <ChecButton
    v-bind="props"
    v-on="eventHandler"
  >
    {{ schema.label }}
  </ChecButton>
</template>

<script>
import { ChecButton } from '@chec/ui-library';

export default {
  name: 'DynamicButton',
  components: {
    ChecButton,
  },
  props: {
    schema: Object,
  },
  computed: {
    props() {
      const props = {};

      props.color = 'primary';
      props.disabled = this.schema.disabled;

      if (this.schema.type === 'link') {
        props.tagType = 'link';
        props.href = this.schema.href;
        props.target = '_blank';
        props.rel = 'nofollow';
      }
      if (this.schema.type === 'button') {
        props.tagType = 'button';
      }

      return props;
    },
    eventHandler() {
      if (this.schema.type === 'link') {
        return {};
      }

      return {
        click: () => {
          this.$emit('click');
        },
      };
    },
  },
};
</script>

<style lang="scss">

</style>
