<template>
  <router-view />
</template>

<script>
export default {
  name: 'IntegrationsIndex',
};
</script>

<style lang="scss">
// Centralized styles for integration/template HTML description and content
.integration-description-content {
  @apply text-gray-600;

  // Apply general styles for HTML formatting, which in future might be markdown based
  p {
    @apply leading-normal mb-4;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-bold mb-4;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  ul {
    @apply mb-4 ml-2;

    li {
      @apply ml-4 list-disc;
    }
  }

  a {
    @apply text-blue-600 font-bold;

    &:hover,
    &:focus {
      @apply underline;
    }
  }

  img {
    @apply my-8 shadow mx-auto rounded;
    max-width: 90%;
  }

  code {
    @apply py-1 px-2 bg-gray-200 rounded-sm break-words
      text-sm font-mono tracking-wide text-gray-600;
  }
}
</style>
