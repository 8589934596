import { createNamespacedHelpers } from 'vuex';

/**
 * Generic error wrapper for emitting and catching pagination specific errors
 */
export class PaginationError extends Error {

}

/**
 * A predicate that finds a relevant entry in the array by matching _all keys_ in the given context
 *
 * @param context
 * @returns {function(*): boolean}
 */
export const contextMatcher = (context) => (candidate) => {
  if (Object.keys(context).length === 0) {
    return Object.keys(candidate.context).length === 0;
  }

  return Object.entries(context).every(([key, value]) => candidate.context[key] === value);
};

/**
 * Helper take state keys (eg. `'billing/invoices'`) and turn them into an object (eg.
 * `{ invoices: 'billing/invoices' }`) if not already provided in that format
 *
 * @param stateKeys {Object|Array|String}
 * @returns {Object}
 */
const parseStateKeys = (stateKeys) => {
  const stateToLocal = (key) => {
    const parts = key.split('/');
    return parts[parts.length - 1];
  };

  if (typeof stateKeys === 'string') {
    return { [stateToLocal(stateKeys)]: stateKeys };
  }
  if (typeof stateKeys !== 'object') {
    throw new PaginationError('Keys given to mapPagination must be a string, object, or array');
  }
  if (Array.isArray(stateKeys)) {
    return stateKeys.reduce((acc, key) => ({
      ...acc,
      [stateToLocal(key)]: key,
    }), {});
  }
  return stateKeys;
};

/**
 * Maps given state keys to computed props on a component containing the current page of items
 *
 * @param stateKeys {Object|Array|String}
 * @returns {Object}
 */
export const mapPagination = (stateKeys) => Object.entries(parseStateKeys(stateKeys))
  .reduce((acc, [localKey, stateKey]) => {
    const { mapState } = createNamespacedHelpers(stateKey);
    const { items, isLoading, isSearching } = mapState(['items', 'isLoading', 'isSearching']);

    return {
      ...acc,
      [localKey]: items,
      isLoading,
      isSearching,
    };
  }, {});

/**
 * Creates an assertion function that ensures that relevant context is set for the configured
 * endpoint. The assertion function also takes the action or mutation name as a second argument for
 * helpful error messaging
 *
 * @param endpoint {String} The configured endpoint
 * @param type {String} The "type" of key that will be provided, usually "mutation" or "action"
 * @returns {function(Object state, String key): void}
 */
export const makeContextChecker = (endpoint, type) => ({ context }, key) => {
  const keyedBy = Array.from(endpoint.matchAll(/:([^/]+)/g)).map((match) => match[1]);
  if (keyedBy.length > 0 && keyedBy.some((k) => !Object.hasOwnProperty.call(context, k))) {
    throw new PaginationError(`"${key}" ${type} cannot be run without context set`);
  }
};

/**
 * Assert that the given page is loaded within the given state
 *
 * @param state {{ byContext: Array, context: Object, pageSize: Number }}
 * @param page {Number}
 * @returns {boolean}
 */
export const pageIsLoaded = ({ byContext, context, pageSize }, page) => {
  const lowerOffset = pageSize * (page - 1);
  const upperOffset = lowerOffset + pageSize;

  // Check if we have the items already in our big list
  const existingContextState = byContext.find(contextMatcher(context));
  const existingItems = existingContextState
    ? existingContextState.items.slice(lowerOffset, upperOffset)
    : [];
  const existingCount = existingContextState && existingContextState.count;

  const hasFullPage = Boolean(
    // We have a full page of items
    existingItems.length === pageSize
    // Or, we are looking at the last page, and there's the expected number of items
    || (existingCount !== null && existingItems.length === existingCount - lowerOffset),
  );

  return hasFullPage
    // Also check that the page of items doesn't have any empties
    && existingItems.filter((candidate) => Boolean(candidate)).length === existingItems.length;
};

/**
 * Checks if the given context is the current context (set in state)
 *
 * @param state {Object}
 * @param context {Object}
 * @returns {boolean}
 */
export const isCurrentContext = (state, context) => (
  [{ context: state.context }].findIndex(contextMatcher(context)) === 0
);

export const calculateRemainingPage = (totalItems, currentItemCount, maxPageSize = 200) => {
  if (totalItems === null) {
    return {
      page: 1,
      // Max page size
      pageSize: maxPageSize,
    };
  }

  const remainingItems = totalItems - currentItemCount;

  // If there are more than "too many" remaining items, we can just try and fetch as many as
  // possible
  if (remainingItems > maxPageSize) {
    return {
      page: 1,
      pageSize: maxPageSize,
    };
  }

  // If there are more than half the items remaining, we can only get it in one request if we get
  // all the items
  if (remainingItems > totalItems / 2) {
    return { page: 1, pageSize: totalItems };
  }

  // Otherwise we'll find the smallest number that will contain all the items
  let pageSize = remainingItems;
  do {
    // Calculate how many items on the next page are going to be irrelevant (previously fetched)
    const irrelevantItemsOnNextPage = currentItemCount % pageSize;
    const numberOfPagesAlready = Math.floor(currentItemCount / pageSize);

    // If the new page size is larger than the remaining items and the irrelevant items on the next
    // page, then that'll work.
    if (pageSize >= (remainingItems + irrelevantItemsOnNextPage)) {
      return {
        page: numberOfPagesAlready + 1,
        pageSize,
      };
    }

    // We can use the number of irrelevant records to figure out how many more items we should be
    // fetching in the page. We find how many extra records we're fetching on purpose (page size
    // minus remaining items), and then take that from the number of irrelevant items that are on
    // the current page, then we divide that by the total number of pages (we always assume we have
    // all but the last page).
    pageSize += (Math.floor(
      (irrelevantItemsOnNextPage - (pageSize - remainingItems)) / (numberOfPagesAlready + 1),
    ) || 1);
    // We keep looping while we are looking at page sizes that are a subset of the current item
    // count
  } while (pageSize <= currentItemCount);

  // If we're here we couldn't figure out a suitable page size, so fall back to just fetching
  // everything
  return { page: 1, pageSize: totalItems };
};

export const getPageForNextItems = (pageSize, currentOffset) => {
  // Get the next highest page-size offset from the current offset
  const nextPage = Math.ceil(currentOffset / pageSize);

  // If the current offset matches the current page-size then this is a redundant call, just
  // increment the page
  if (currentOffset % pageSize === 0) {
    return {
      page: nextPage + 1,
      pageSize,
    };
  }

  // Use the existing "remaining items" function with the next offset as a maximum
  return calculateRemainingPage(nextPage * pageSize, currentOffset);
};

/**
 * Given state pull from the "byContext" attribute, will return whether the resource is "fully
 * loaded" by checking the known total count of records against the number actually in state.
 *
 * @param {{items: Array, count: Number}} contextualState
 * @returns {boolean}
 */
export const isFullyLoaded = (contextualState) => {
  // If the given state is falsy, or the count is falsy (and hasn't been determined yet)
  if (!contextualState || (!contextualState.count && contextualState.count !== 0)) {
    return false;
  }

  // Find the first undefined option in our set, and ensure there is none, or it's beyond the index
  // that exists (ie. we can safely ignore an undefined 12th item if there are 11 items).
  const firstUndefined = contextualState.items.findIndex((candidate) => candidate === undefined);
  return contextualState.items.length >= contextualState.count
    && (firstUndefined === -1 || firstUndefined > contextualState.count);
};
