import { CancelToken, Cancel } from 'axios';
import has from 'lodash.has';
import get from 'lodash.get';
import escapeRegex from 'escape-string-regexp';
import { apiConfig, ApiRequest, makeApiRequest } from '../api';
import {
  calculateRemainingPage,
  getPageForNextItems,
  isCurrentContext,
  isFullyLoaded,
  makeContextChecker,
  pageIsLoaded,
  PaginationError,
} from './helpers';
import {
  buildQueryString,
  matchers,
} from './filters';
import mutations from './mutations';
import getterTypes from './getters';

// See documentation on each individual action implementation below.
const actions = {
  ADD_NEW_ITEM: 'ADD_NEW_ITEM',
  APPLY_FILTERS: 'APPLY_FILTERS',
  DELETE_ITEM: 'DELETE_ITEM',
  LOAD_ITEM: 'LOAD_ITEM',
  LOAD_ADJACENT_ITEMS: 'LOAD_ADJACENT_ITEMS',
  LOAD_REMAINING_ITEMS: 'LOAD_REMAINING_ITEMS',
  RESET: 'RESET',
  SEARCH_ITEMS: 'SEARCH_ITEMS',
  SET_PAGE: 'SET_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SWITCH_CONTEXT: 'SWITCH_CONTEXT',
  UPDATE_ITEM: 'UPDATE_ITEM',
};

export default actions;

export const provideImplementations = ({
  addMethod,
  clientSideHandleLimit,
  endpoint,
  hooks,
  idColumn,
  individualItemEndpoint,
  itemResolver,
  searchProperties,
  totalResolver,
  updateMethod,
  prepItemForPost,
  excludeGlobalContext,
}) => {
  // Axios provides the facility to cancel running requests by providing a callback. We define
  // variables for these callbacks in this scope so any action may issue a cancellation.
  let cancelPriorRequest = null;
  let cancelSearchRequest = null;

  // Helper function to configured hooks.
  const callHook = (commit, hookName, payload) => {
    if (payload instanceof Cancel) {
      return;
    }

    const hook = hooks[hookName];

    if (!hook) {
      return;
    }

    if (typeof hook === 'string') {
      commit(hook, payload, { root: true });
      return;
    }

    if (typeof hook === 'function') {
      hook(commit, payload);
      return;
    }

    throw new PaginationError(
      `Provided hooks must be a string or function (received "${typeof hook}")`,
    );
  };

  // Helper function to merge in global context, provided the module hasn't opted out of it
  const withGlobalContext = (context, rootState) => (
    excludeGlobalContext
      ? context
      : { ...rootState.globalContext, ...context }
  );

  const queryPrefix = endpoint.includes('?') ? '&' : '?';

  // Replace params in the configured endpoint with set context
  const resolveEndpoint = (context, candidate) => (candidate || endpoint)
    .replace(/:([^/?]+)/g, (_, key) => context[key]);

  // Get the endpoint for an individual item by combining a given ID and state, and information
  // provided with configuration
  const getIndividualEndpoint = (id, context) => {
    // The module can define a function to generate this for us
    if (typeof individualItemEndpoint === 'function') {
      return individualItemEndpoint(id, idColumn, context);
    }

    // Otherwise we use a specified endpoint for individual items if provided, falling back to the
    // endpoint provided for the whole module.
    const baseEndpoint = typeof individualItemEndpoint === 'string'
      ? resolveEndpoint(context, individualItemEndpoint)
      : resolveEndpoint(context);

    if (!id) {
      return baseEndpoint;
    }

    // Find any query params and ensure the ID is appended before the query params start.
    // Note that the `limit` parameter on String.split just omits any further splits, so in the rare
    // (and broken) scenario there are two question marks, we'll take all the remaining segments and
    // join them together
    const [paramlessEndpoint, ...params] = baseEndpoint.split('?');

    if (params && params.length > 0) {
      return `${paramlessEndpoint}/${id}?${params.join('?')}`;
    }

    return `${baseEndpoint}/${id}`;
  };

  // This will be a function that throws an error if the provided context does not fulfill the
  // requirements of the endpoint. Eg. `/v1/products/:productId/assets` must have `productId` within
  // context.
  const assertContext = makeContextChecker(endpoint, 'action');

  // An assertion function to ensure the given item has the configured "ID" column
  const assertIdColumn = (item) => {
    if (!Object.hasOwnProperty.call(item, idColumn)) {
      throw new PaginationError(
        `Pagination attempted to update an item in the list identified by the "${
          idColumn
        }" column, but that column does not exist on the item. (endpoint: ${endpoint}).`,
      );
    }
  };

  // Create a rejected promise with a generic user-displayable message
  const createGenericRejection = (action) => (Promise.reject(
    new PaginationError(`Sorry, we're having trouble ${action} this item`),
  ));

  /**
   * Search a list of objects for a given term
   *
   * @param {Array<Object>} list
   * @param {String} term
   * @param {Array} filters
   * @param {Number} page
   * @param {Number} pageSize A maximum number of records to return from the given list
   * @param {Boolean} sortByRelevance Whether to do a crude sort by relevance
   * @returns {*|{total: *, items: *}}
   */
  const applySearchAndFilters = (list, term, filters, page, pageSize, sortByRelevance = true) => {
    // Exit early if there is nothing to search in
    if (!list.length) {
      return {
        items: [],
        total: 0,
      };
    }
    const hasSearchTerm = typeof term === 'string' && term.length > 0;

    // Take the first item and match it against the configured "searchProperties" to find the
    // properties that can actually be matched
    const searchableProps = searchProperties.filter(
      (prop) => {
        if (Array.isArray(prop)) {
          return prop.every((candidate) => has(list[0], candidate));
        }
        return has(list[0], prop);
      },
    );

    // Help the developer debug search issues by indicating when no search is possible
    if (hasSearchTerm && searchableProps.length === 0) {
      throw new PaginationError(
        `Failed to perform search as no valid searchable properties were defined. The configured
        props are: ${searchProperties.join(', ')}.`,
      );
    }

    let filtered = list;

    // Apply filters
    if (Array.isArray(filters)) {
      filters.forEach((filter) => {
        // The different filter functions are abstracted away into the filterFunctions exported by
        // the helpers file. The filter should provide what type of filter should be used (eg.
        // "exact"), which column the filter applies to (eg. "payment_status"), and what the
        // parameter is for the filter (eg. "paid")
        filtered = matchers[filter.type](filtered, filter.column, filter.param);
      });
    }

    // Apply search term
    if (hasSearchTerm) {
      // We're using a reducer rather than `.filter` here as we need to keep track of the number of
      // attributes that were matched when sorting by relevancy.
      filtered = filtered.reduce((acc, candidate) => {
        // Find the number of matches of the term within the properties of the "model" that are
        // considered "searchable". Finding the number of matches (and using this reducer) gives us
        // a crude "weight" to the results in order to display relevant results.
        const matchCount = searchableProps.reduce((count, prop) => {
          let matchableValue;
          // Support arrays of props for a concatenated text search
          if (Array.isArray(prop)) {
            matchableValue = prop.map((part) => get(candidate, part))
              .filter((part) => typeof part === 'string' && part.length > 0)
              .join(' ');
          } else {
            matchableValue = get(candidate, prop);
          }

          // Avoid attempting to match non-string values
          if (typeof matchableValue !== 'string' || matchableValue.length === 0) {
            return count;
          }

          // We can avoid regex matching for multiple occurrences if we don't care about relevancy
          if (!sortByRelevance && matchableValue.includes(term)) {
            return 1;
          }

          const matches = matchableValue.match(new RegExp(escapeRegex(term), 'ig'));
          if (!matches) {
            return count;
          }

          return count + matches.length;
        }, 0);

        if (matchCount === 0) {
          return acc;
        }

        if (!sortByRelevance) {
          return [...acc, candidate];
        }

        // Return an object that contains the match count (relevancy) and the match if we're sorting
        return [...acc, {
          matchCount,
          candidate,
        }];
      }, []);
    }

    return {
      items: !sortByRelevance || !hasSearchTerm
        ? filtered.slice((page - 1) * pageSize, pageSize)
        : filtered
          .sort((a, b) => b.matchCount - a.matchCount)
          // Map back the actual result (and remove the "match count").
          .map(({ candidate }) => candidate)
          .slice((page - 1) * pageSize, pageSize),
      total: filtered.length,
    };
  };

  // Dedupe logic around loading a page and adding it to state. The function will wait for a "lock"
  // that may exist, preventing mutations from happening to state while other actions are pending.
  const loadPage = ({ state, commit }, page, pageSize, context) => state.lock.then(
    () => makeApiRequest(
      'GET',
      `${resolveEndpoint(context)}${queryPrefix}page=${page}&limit=${pageSize}`,
      {},
      {},
      // Use the cancelToken API provided by axios to make this request cancellable
      { cancelToken: new CancelToken((cancel) => { cancelPriorRequest = cancel; }) },
      apiConfig.url,
      excludeGlobalContext,
    )
      .then(({ data: json }) => {
        commit(mutations.ADD_ITEM_PAGE, {
          items: itemResolver(json, true),
          page,
          context,
          pageSize,
        });
        const total = totalResolver(json);
        if (typeof total === 'number') {
          commit(mutations.SET_TOTAL_COUNT, { count: total, context });
        }
      })
      .catch((error) => {
        if (error instanceof Cancel) {
          return;
        }
        callHook(commit, 'error', error);
        throw error;
      })
      .finally(() => {
        callHook(commit, 'loading', false);
      }),
  );

  return {
    /**
     * Set the page, triggering a request to get the page if not already loaded
     *
     * @param context {Object} Vuex action context
     * @param payload {Number|Object} The payload, either a page number or an object as documented
     * @param payload.page {Number} The page number to go to
     * @param payload.context {Object} Optionally set a new context as well as a new page
     * @param payload.isLoading {boolean} Whether loading state is already set for this call, and it
     *        should only be removed, not added. Default: false
     */
    [actions.SET_PAGE]({
      commit,
      dispatch,
      getters,
      state,
      rootState,
    }, payload) {
      if (typeof cancelPriorRequest === 'function') {
        cancelPriorRequest();
      }

      // We support the payload just being a page number, or getting a page number and new context
      // to set at the same time (where the payload is a keyed object)
      let page = payload;
      let context = { ...state.context };
      let isLoading = false;
      if (typeof payload === 'object') {
        ({ page, isLoading } = payload);
        if (payload.context) {
          context = { ...payload.context };
        }
      }

      // Set the context for the page. This mutation will do nothing if the context is the same as
      // the current context
      context = withGlobalContext(context, rootState);
      commit(mutations.SET_CONTEXT, context);

      const hasSearch = state.currentSearchTerm.trim().length > 0;

      if (hasSearch) {
        // Set the new page, and just keep the existing page items for now...
        commit(mutations.SET_PAGE, { pageNumber: page, pageItems: state.items });
        // Redispatch the current search which should take care of changing to the new page
        return dispatch(actions.SEARCH_ITEMS, state.currentSearchTerm).then(() => {
          if (isLoading) {
            callHook(commit, 'loading', false);
          }
        });
      }

      const { pageSize } = state;

      // Dedupe set page logic in this action, as we can either do it immediately when the relevant
      // resources are already in state, or after we finish loading those resources
      const setPage = () => {
        const itemsStartIndex = pageSize * (page - 1);
        const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE](context);

        commit(mutations.SET_PAGE, {
          pageNumber: page,
          pageItems: contextualState
            ? contextualState.items.slice(itemsStartIndex, itemsStartIndex + pageSize)
            : [],
        });

        // Ensure the total is updated if we have it
        if (
          contextualState && typeof contextualState.count === 'number'
          && state.totalCount !== contextualState.count
        ) {
          commit(mutations.SET_TOTAL_COUNT, {
            count: contextualState.count,
            // Ensure global context
            context: withGlobalContext(context, rootState),
          });
        }
      };

      if (pageIsLoaded(state, page)) {
        setPage();

        if (isLoading) {
          callHook(commit, 'loading', false);
        }

        return Promise.resolve();
      }

      if (!isLoading) {
        callHook(commit, 'loading', true);
      }

      return loadPage({ state, commit }, page, pageSize, withGlobalContext(context, rootState))
        .then(setPage);
    },
    /**
     * Set the page size, and the current page to show as well. Dispatches the SET_PAGE action
     *
     * @param context {Object} Vuex action context
     * @param payload {Object} The action payload
     * @param payload.size {Number} The new page size
     * @param payload.page {Number} The new page to change to
     */
    [actions.SET_PAGE_SIZE]({ commit, dispatch }, { size, page }) {
      commit(mutations.SET_PAGE_SIZE, size);
      return dispatch(actions.SET_PAGE, page);
    },
    /**
     * Load the items before and after the given item.
     *
     * @param context {Object} Vuex action context
     * @param payload {Object} The action payload
     * @param payload.id {String} The item ID
     */
    async [actions.LOAD_ADJACENT_ITEMS]({
      commit,
      dispatch,
      getters,
      state,
    }, { id, preventRecursion }) {
      const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE]();
      const existingItemIndex = contextualState && getters[getterTypes.GET_ITEM](id, true);
      const totalCount = contextualState ? contextualState.count : null;
      const itemCount = contextualState ? contextualState.items.findIndex((item) => !item) : 0;

      // If we can't find the existing item, or we don't have any state for this context yet, we
      // need to load it
      if (existingItemIndex < 0 || !contextualState) {
        // If we don't know how many records there are, then we haven't done any API call for this
        // resource yet. We'll just assume/hope it's on the first page, and then re-call this method
        // to load adjacent items again.
        if (totalCount === null) {
          await dispatch(actions.SET_PAGE, 1);

          if (preventRecursion) {
            return Promise.reject();
          }
          return dispatch(actions.LOAD_ADJACENT_ITEMS, { id });
        }

        // If there is less records in total that we feel safe to load in one call, then just get
        // the rest of the items. All adjacent items will be loaded when all items are loaded.
        if (totalCount <= clientSideHandleLimit) {
          return dispatch(actions.LOAD_REMAINING_ITEMS);
        }

        // We can't load adjacent items if we can't find the current item in the first 200 records.
        // We don't have any way of knowing what page the current item is on.
        if (itemCount >= clientSideHandleLimit || preventRecursion) {
          return Promise.reject();
        }

        // Otherwise we'll just load as many items as possible, and try again for adjacent items
        await dispatch(actions.LOAD_REMAINING_ITEMS);
        return dispatch(actions.LOAD_ADJACENT_ITEMS, { id, preventRecursion: true });
      }

      // Checking if the given item index is not less than 0 ie the first item.
      const loadPreviousItem = existingItemIndex > 0
        && !contextualState.items[existingItemIndex - 1];

      // Checking if the given item index is not greater than the total count and the
      // next item is defined.
      const loadNextItem = existingItemIndex < contextualState.count - 1
        && !contextualState.items[existingItemIndex + 1];

      const promises = [];

      if (loadPreviousItem) {
        promises.push(dispatch(actions.SET_PAGE, state.page - 1));
      }
      if (loadNextItem) {
        const nextPageSpec = getPageForNextItems(state.pageSize, existingItemIndex + 1);
        promises.push(loadPage(
          { state, commit },
          nextPageSpec.page,
          nextPageSpec.pageSize,
          state.context,
        ));
      }

      return Promise.all(promises);
    },
    /**
     * Ensure an individual item is loaded, triggering a request if not.
     *
     * @param context {Object} Vuex action context
     * @param idOrObject {String|Array<String>|Object} The object ID (or IDs) to load from the API.
     *        This may also be an object with an `ids` key and a `force` key indicating it must be
     *        re-fetched
     * @param prepend {Boolean} Whether to prepend the new item to the list. By default we will
     *        track the new item as an "additional item" since we can't assume where it belongs
     *        in the paginated list. Providing `prepend: true` assumes it can be prepended.
     * @param showLoading {Boolean} Whether to update the loading state as a new item is loaded
     */
    [actions.LOAD_ITEM]({
      commit,
      getters,
      state,
      rootState,
    }, {
      id,
      ids: givenIds,
      force = false,
      prepend = false,
      showLoading = true,
    }) {
      const ids = givenIds || (Array.isArray(id) ? id : [id]);

      // Idempotence check - the items are already in state.
      const itemsExist = ids
        .filter((candidateId) => getters[getterTypes.GET_ITEM](candidateId) === undefined)
        .length === 0;
      const context = { ...state.context };

      // Ensure context is set
      commit(mutations.SET_CONTEXT, withGlobalContext(context, rootState));

      if (!force && itemsExist) {
        return Promise.resolve();
      }

      if (showLoading) {
        callHook(commit, 'loading', true);
      }

      const fetchMultiple = ids.length > 1;

      const resolvedEndpoint = fetchMultiple
        ? `${resolveEndpoint(state.context)}${queryPrefix}query=${ids.join(',')}&limit=200`
        : getIndividualEndpoint(ids[0], state.context);

      // Dedupe logic to slot our item into the right spot in state. We will either call this for
      // one item, or loop it on returned items when loading multiple
      const addOrReplace = (item) => {
        if (getters[getterTypes.GET_ITEM](item[idColumn]) === undefined) {
          // We can prepend it if we know that's what we want to do
          if (prepend) {
            return commit(mutations.ADD_ITEM, { item, prepend, context });
          }
          // Don't assume it should be in a particular position in the list, so track it as an
          // additional item.
          return commit(mutations.ADD_ADDITIONAL_ITEM, item);
        }
        // Item already exists in state, replace it with this one
        return commit(mutations.REPLACE_ITEM, { item, context });
      };

      const request = new ApiRequest('GET', resolvedEndpoint);
      request.disableExtraParams(excludeGlobalContext);

      if (!fetchMultiple) {
        // "Expect" 404s, meaning we won't log errors for them
        request.expectError(404);
      }

      return request.send()
        .then(({ data: json }) => {
          const response = itemResolver(json, fetchMultiple);
          if (!fetchMultiple) {
            addOrReplace(response);
            return response;
          }
          response.forEach((item) => addOrReplace(item));
          return response;
        })
        .catch((error) => {
          callHook(commit, 'error', error);
          throw error;
        })
        .finally(() => {
          if (showLoading) {
            callHook(commit, 'loading', false);
          }
        });
    },
    /**
     * Update an existing item with the API and update it in state with new attributes. Either a new
     * item, or a mutation function (an `updater` key) needs to be provided
     *
     * @param context {Object} Vuex action context
     * @param payload {Object} The action payload
     * @param payload.id {Number} The ID for the object to update (Required)
     * @param payload.item {Object} An object with new properties to set in the object.
     * @param payload.updater {Function} A mutation function updates the (given) existing object.
     * @param payload.expectedErrors {Array} See expected errors on ApiRequest
     * @param merge {Boolean} Whether the `item` provided should
     * @param optimistic {Boolean} Whether the state is changed immediately and action resolves
     *        before the API returns. Default: true
     */
    [actions.UPDATE_ITEM]({ commit, getters, state }, {
      id,
      item,
      merge = false,
      optimistic = true,
      updater,
      expectedErrors = [],
    }) {
      try {
        assertContext(state, actions.UPDATE_ITEM);
      } catch (error) {
        if (error instanceof PaginationError) {
          console.error(error.message);
          return createGenericRejection('saving');
        }
      }

      // We need the existing item to merge or run an "updater" function on, if provided
      const existingItem = getters[getterTypes.GET_ITEM](id);
      const useUpdater = typeof updater === 'function';

      if (!existingItem && (useUpdater || merge)) {
        console.error(
          'Attempted to update an item (with merge/updater method) that doesn\'t exist in state',
        );
        throw new PaginationError('Sorry, we\'re having trouble saving this item');
      }

      // Build the "new item" to send to the API based on the provided parameters...
      let newItem;

      // The user provided a function that will update an item from state
      if (useUpdater) {
        newItem = updater(existingItem);
      } else {
        // The user provided part of an item, and indicated that it should merge with an existing
        // item (with `merge`), or we should just use the given item.
        newItem = merge ? {
          ...existingItem,
          ...item,
        } : item;
      }

      const { context } = state;

      try {
        assertIdColumn(newItem);
      } catch (error) {
        if (error instanceof PaginationError) {
          console.error(
            'When replacing an item in state with a new one, the new item must have an ID. Did you '
            + 'mean to use the `merge` option?',
          );
          return createGenericRejection('saving');
        }
        throw error;
      }

      if (optimistic) {
        commit(mutations.REPLACE_ITEM, { item: newItem, context });
      } else {
        callHook(commit, 'loading', true);
      }

      newItem = prepItemForPost(newItem);

      const request = new ApiRequest(updateMethod, getIndividualEndpoint(id, context));

      request.disableExtraParams(excludeGlobalContext);
      expectedErrors.forEach(([status, error]) => {
        request.expectError(status, error);
      });

      return request.send(newItem)
        .then(({ data: json }) => {
          commit(mutations.REPLACE_ITEM, { item: itemResolver(json), context });
          return json;
        })
        .catch((error) => {
          callHook(commit, 'error', error);
          if (optimistic) {
            commit(mutations.REPLACE_ITEM, { item: existingItem, context });
          }
          throw error;
        })
        .finally(() => {
          if (!optimistic) {
            callHook(commit, 'loading', false);
          }
        });
    },
    /**
     * Add a new item with the API and add it to the existing page-state. Note that will
     * optimistically insert into the state used for pagination and will show on pages.
     *
     * @param context {Object} Vuex action context
     * @param payload {Object} The action payload
     * @param payload.item {Object} The new (validated) object to add to the store
     * @param prepend {Boolean} Whether the new item should be prepended tp the first page.
     *        Default: false
     * @param optimistic {Boolean} Whether the state is changed immediately and action resolves
     *        before the API returns. Default: true
     */
    [actions.ADD_NEW_ITEM]({
      commit,
      dispatch,
      getters,
      state,
    }, {
      item,
      prepend = false,
      optimistic = true,
      expectedErrors = [],
    }) {
      const { context, pageSize } = state;

      try {
        assertContext(state, actions.ADD_NEW_ITEM);
      } catch (error) {
        if (error instanceof PaginationError) {
          console.error(error.message);
          return createGenericRejection('saving');
        }
      }

      const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE](context);
      const totalItems = contextualState ? contextualState.count : 0;

      const targetPage = prepend ? 1 : Math.ceil((totalItems + 1) / pageSize);
      const useOptimism = optimistic && pageIsLoaded(state, targetPage);

      if (useOptimism) {
        commit(mutations.ADD_ITEM, { item, prepend, context });
        commit(mutations.SET_TOTAL_COUNT, { count: totalItems + 1, context });
      } else {
        callHook(commit, 'loading', true);
      }
      const atIndex = prepend ? 0 : totalItems;

      const request = new ApiRequest(addMethod, getIndividualEndpoint(null, context));

      request.disableExtraParams(excludeGlobalContext);
      expectedErrors.forEach(([status, error]) => {
        request.expectError(status, error);
      });

      return request.send(prepItemForPost(item))
        .then(({ data: json }) => {
          const newItem = itemResolver(json);
          if (useOptimism) {
            commit(mutations.REPLACE_ITEM, { atIndex, item: newItem, context });
            return Promise.resolve(newItem);
          }

          // If we were only pessimistic because the page wasn't loaded yet, we can just load the
          // right page now
          if (optimistic) {
            commit(mutations.ADD_ADDITIONAL_ITEM, newItem);
            callHook(commit, 'loading', false);
            return Promise.resolve(newItem);
          }

          // Otherwise pessimistic additions are pretty nuclear - they just nuke the whole list and
          // start again
          return dispatch(actions.RESET, true).then(() => newItem);
        })
        .catch((error) => {
          callHook(commit, 'error', error);
          if (useOptimism) {
            commit(mutations.REMOVE_ITEM, { atIndex, item, context });
            commit(mutations.SET_TOTAL_COUNT, { count: totalItems, context });
          } else {
            callHook(commit, 'loading', false);
          }
          throw error;
        });
    },
    /**
     * Deletes an item with the API and removes it from the store.
     *
     * @param context {Object} Vuex action context
     * @param payload {Object} The action payload
     * @param item {Object} The item to delete (that contains the ID to delete)
     * @param id {String} The ID of the item to delete
     * @param optimistic {Boolean} Whether the state is changed immediately and action resolves
     *        before the API returns. Default: true
     */
    [actions.DELETE_ITEM]({
      commit,
      dispatch,
      getters,
      state,
    }, { item, id, optimistic = true }) {
      try {
        assertContext(state, actions.DELETE_ITEM);
        if (item) {
          assertIdColumn(item);
        }
      } catch (error) {
        console.error(error);
        return createGenericRejection('deleting');
      }

      // Copy context as we can't trust that state context will persist during async requests
      const context = { ...state.context };
      const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE](context);
      const targetId = item ? item[idColumn] : id;

      if (!targetId) {
        console.error('No ID was provided to delete');
        return createGenericRejection('deleting');
      }

      // Handle us not items loaded at all
      if (!contextualState) {
        callHook(commit, 'loading', true);

        const deleteCall = makeApiRequest(
          'DELETE',
          getIndividualEndpoint(item ? item[idColumn] : id, context),
          {},
          {},
          {},
          apiConfig.url,
          excludeGlobalContext,
        )
          .catch((error) => {
            callHook(commit, 'error', error);
            throw error;
          })
          .finally(() => {
            callHook(commit, 'loading', false);
          });

        commit(mutations.CREATE_LOCK, deleteCall);

        return deleteCall;
      }

      // Find the index of the item currently
      const existingIndex = contextualState.items.findIndex(
        (candidate) => candidate && candidate[idColumn] === targetId,
      );
      const existingItem = contextualState.items[existingIndex];
      const existingCount = contextualState.count;

      if (existingIndex >= 0 && optimistic) {
        commit(mutations.REMOVE_ITEM, { atIndex: existingIndex, context });
        commit(mutations.SET_TOTAL_COUNT, { count: existingCount - 1, context });
      } else {
        callHook(commit, 'loading', true);
      }

      return makeApiRequest(
        'DELETE',
        getIndividualEndpoint(item ? item[idColumn] : id, context),
        {},
        {},
        {},
        apiConfig.url,
        excludeGlobalContext,
      )
        .then(() => {
          if (!optimistic) {
            commit(mutations.REMOVE_ITEM, { atIndex: existingIndex, context });
            commit(mutations.SET_TOTAL_COUNT, { count: existingCount - 1, context });
          }
          // Set the page so that we're left with a full page
          return dispatch(actions.SET_PAGE, { page: state.page, context });
        })
        .catch((error) => {
          callHook(commit, 'error', error);
          if (optimistic) {
            // Restore the item as the delete failed
            commit(mutations.ADD_ITEM, { item: existingItem, context, atIndex: existingIndex });
            commit(mutations.SET_TOTAL_COUNT, { count: existingCount, context });
          }
          throw error;
        })
        .finally(() => {
          if (!optimistic) {
            callHook(commit, 'loading', false);
          }
        });
    },
    /**
     * Clear all items for the current context and reload the current page from the API.
     *
     * @param vuexContext {Object} Vuex action context
     * @param isLoading {boolean} Whether loading state is already set for this call, and it
     *        should only be removed, not added. Default: false
     */
    [actions.RESET]({
      commit,
      dispatch,
      state: { context, page },
      rootState,
    }, isLoading = false) {
      commit(mutations.EMPTY_CONTEXT, withGlobalContext(context, rootState));
      return dispatch(actions.SET_PAGE, { page: page || 1, context, isLoading });
    },
    /**
     * Loads all remaining items, or as many up to the "clientSideHandleLimit" set in the module
     * configuration. This will do a calculation to load the most efficient "page". Eg. if you have
     * loaded two pages of 75 records (150 total), and you have 173 items in total, this will load
     * page 7 with a page size of 25 to get records between 150 and 175.
     *
     * @param {Object} context VueX context
     * @param {Object} providedContext Pagination context if different from that in state
     * @returns {Promise<unknown>|Promise<void>}
     */
    [actions.LOAD_REMAINING_ITEMS]({
      commit,
      getters,
      state,
      rootState,
    }, providedContext) {
      const context = withGlobalContext(providedContext, rootState) || { ...state.context };
      const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE](context);
      const totalCount = contextualState ? contextualState.count : null;
      // Find the first index where there's no item, and use that as the count of items
      let itemsFetched = contextualState ? contextualState.items.findIndex((item) => !item) : 0;
      if (itemsFetched < 0) {
        itemsFetched = contextualState.items.length;
      }

      // Avoid trying to load remaining items when not required.
      if (totalCount && itemsFetched === totalCount) {
        return Promise.resolve();
      }

      const { page, pageSize } = calculateRemainingPage(
        totalCount,
        itemsFetched,
        clientSideHandleLimit,
      );

      return loadPage({ commit, state }, page, pageSize, context);
    },
    /**
     * Filters pages of items into a filtered set using a string search term, and an array of filter
     * objects. Filter objects have the following form:
     *
     * {
     *   type: 'exact',
     *   column: 'id',
     *   param: 'prod_1234',
     * }
     *
     * The "column" refers to the attribute in the object that should be matched by the filter,
     * "param" refers to the actual value of the filter, and "type" refers to the type of filter
     * that should be used. Supported "type"s are defined in the `filters.js` file.
     *
     * @param {Object} context VueX context
     * @param {string} term The search term to use
     * @param {array} filters An array of "filter" objects to filter by
     * @param {boolean} recurse Whether this function is recursing, and should not be recursed again
     * @returns {Promise<void>}
     */
    async [actions.SEARCH_ITEMS]({
      commit,
      dispatch,
      getters,
      state,
    }, { term, filters, recurse }) {
      // Find the relevant data from state for the search
      const contextualState = getters[getterTypes.GET_CONTEXTUAL_STATE]();
      const totalCount = contextualState ? contextualState.count : null;

      // Fall back to any term/filters that weren't provided with this call
      const resolvedTerm = term === undefined ? state.currentSearchTerm : term;
      const resolvedFilters = filters === undefined ? state.currentFilters : filters;

      // Check if we're actually performing a search we haven't already completed
      const hasNewTerm = state.currentSearchTerm !== resolvedTerm;
      const hasNewFilters = filters !== undefined
        && !(resolvedFilters.length === 0 && state.currentFilters.length === 0)
        && (
          resolvedFilters.length !== state.currentFilters.length
          || !state.currentFilters.every((filter) => resolvedFilters.some((candidate) => (
            filter.type === candidate.type
            && filter.column === candidate.column
            && filter.param === candidate.param
          )))
        );

      if (!hasNewTerm && !hasNewFilters && !recurse) {
        return;
      }

      // Update the state with the current search/filter parameters
      commit(mutations.SEARCH, { term, filters });

      // Reset back to standard page viewing if there are no filters or search term
      if (resolvedTerm.trim().length === 0 && resolvedFilters.length === 0) {
        await dispatch(actions.SET_PAGE, state.page);
        return;
      }

      // Stop any existing running search
      if (typeof cancelSearchRequest === 'function') {
        cancelSearchRequest();
      }

      const { page, pageSize } = state;

      // If we're fully loaded, we can just perform the search on the client side for speed
      if (isFullyLoaded(contextualState)) {
        commit(mutations.COMPLETE_SEARCH, applySearchAndFilters(
          contextualState.items,
          resolvedTerm,
          resolvedFilters,
          page,
          pageSize,
        ));
        return;
      }

      // If we don't have all the records, but there's not many records in total, then we can just
      // fetch whatever items are left and be fully loaded...
      if (totalCount !== null && totalCount <= clientSideHandleLimit && recurse !== true) {
        await dispatch(actions.LOAD_REMAINING_ITEMS);
        // ... and redispatch the action - with a recursion guard
        await dispatch(actions.SEARCH_ITEMS, { recurse: true });
        return;
      }

      // At this point we'll just let the server figure it out

      // Create an array of query string components that will be joined together for a full query
      // string.
      const queryString = `&${buildQueryString(resolvedFilters, resolvedTerm)}`;

      const apiResult = await (state.lock.then(() => makeApiRequest(
        'GET',
        `${resolveEndpoint(state.context)}${queryPrefix}page=${page}&limit=${pageSize}${queryString}`,
        {},
        {},
        { cancelToken: new CancelToken((cancel) => { cancelSearchRequest = cancel; }) },
        apiConfig.url,
        excludeGlobalContext,
      )
        // Convert the response back to an object with the items and the matched number of results
        .then(({ data: json }) => ({
          items: itemResolver(json, true),
          total: totalResolver(json),
        }))
        .catch((error) => {
          callHook(commit, 'error', error);
          throw error;
        })));

      // "Complete" the search with a mutation.
      commit(mutations.COMPLETE_SEARCH, apiResult);
    },
    /**
     * @param {Object} context VueX context
     * @param context
     * @param page
     * @param clear
     */
    [actions.SWITCH_CONTEXT]({
      commit,
      dispatch,
      state,
      rootState,
    }, { context }) {
      const fullContext = withGlobalContext(context, rootState);

      if (!isCurrentContext(state, fullContext)) {
        commit(mutations.SET_CONTEXT, fullContext);

        if (pageIsLoaded(state, 1)) {
          dispatch(actions.SET_PAGE, 1);
        } else {
          commit(mutations.CLEAR_CURRENT_PAGE);
        }
      }
    },
  };
};
