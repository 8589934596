<template>
  <ChecTable
    :class="['dashboard-table', `dashboard-table--${alignRightAfter}-left`]"
    :inner-class="tableClasses"
    :snug="snug"
  >
    <template v-if="showSearch" #prepend>
      <form @submit.prevent="doSearch">
        <ChecFilterBar
          v-model="searchTerm"
          :active-filters="activeFilters"
          :active-search="currentSearchTerm"
          :filters="filters"
          @change-filters="changeFilters"
          @clear-search="clearSearch"
          @search="doSearch"
        >
          <template #filters>
            <slot name="filters" />
          </template>
        </ChecFilterBar>
      </form>
    </template>
    <thead>
      <tr>
        <th
          v-for="{ title, class: columnClass } in parsedColumns"
          :key="title"
          :class="columnClass"
        >
          {{ title }}
        </th>
      </tr>
    </thead>
    <tbody v-if="showPlaceholderBody">
      <tr>
        <td
          :colspan="columns.length"
          class="dashboard-table__loading-row"
          :class="{ 'dashboard-table__loading-row--loading': isLoading || isSearching }"
        >
          <ChecLoading
            v-if="isLoading || isSearching"
            without-background
          />
          <span v-else-if="activeFilters.length === 0" class="dashboard-table__empty-text">
            {{ $t('general.empty') }}
          </span>
          <span v-else class="dashboard-table__empty-text">
            {{ $t('general.noResults') }}
          </span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <slot />
    </tbody>
  </ChecTable>
</template>

<script>
import {
  ChecFilterBar,
  ChecLoading,
  ChecTable,
} from '@chec/ui-library';
import withPropDefinedState from '@/mixins/withPropDefinedState';
import { actions, getters } from '@/lib/pagination';
import debouncedSearch from '@/mixins/debouncedSearch';

export default {
  name: 'DashboardTable',
  components: {
    ChecFilterBar,
    ChecLoading,
    ChecTable,
  },
  mixins: [
    withPropDefinedState({
      state: ['isLoading', 'items', 'isSearching', 'currentSearchTerm', 'currentFilters'],
      functionalGetters: {
        isFullyLoaded: getters.IS_FULLY_LOADED,
      },
      actions: {
        search: debouncedSearch,
        dispatchSearch: actions.SEARCH_ITEMS,
      },
    }, 'stateKey', false),
  ],
  props: {
    /**
     * How many columns will align left before columns align their content on the right
     */
    alignRightAfter: Number,
    /**
     * An array of strings for the columns of the table, or objects with a "title" and "class"
     */
    columns: Array,
    /**
     * Filters to show in a filter section next to the search bar (when shown)
     * See the ChecFilterBar component for more details
     */
    filters: {
      type: Array,
      default: () => ([]),
    },
    layoutFixed: Boolean,
    /**
     * Whether to automatically show a functional search bar
     */
    showSearch: Boolean,
    /**
     * @see ChecTable
     */
    snug: Boolean,
  },
  data() {
    return {
      searchTerm: '',
      // Note, the following prop is used in the "debouncedSearch" action
      // eslint-disable-next-line vue/no-unused-properties
      debouncedSearchTimeout: null,
    };
  },
  computed: {
    activeFilters() {
      // Convert pagination filter back into filters for the filter bar
      return this.currentFilters.map(({ filterInUse }) => filterInUse);
    },
    /**
     * Force the provided array of columns to be an array of objects
     * @returns {Object[]}
     */
    parsedColumns() {
      return this.columns.map((candidate) => {
        if (typeof candidate === 'string') {
          return {
            title: candidate,
            class: null,
          };
        }

        return candidate;
      });
    },
    showPlaceholderBody() {
      if (!this.stateKey) {
        return false;
      }

      return this.isLoading || this.isSearching || this.items.length === 0;
    },
    tableClasses() {
      const base = 'dashboard-table__table';

      if (this.layoutFixed) {
        return `${base} ${base}--fixed`;
      }

      return base;
    },
  },
  methods: {
    changeFilters(filters) {
      this.$emit('change-filters', filters);
    },
    clearSearch() {
      return this.dispatchSearch({ term: '' });
    },
    doSearch() {
      this.search(this.searchTerm);
      this.searchTerm = '';
    },
  },
};
</script>

<style lang="scss">
.dashboard-table {
  // TODO remove when this is fixed in the UI lib
  &__table {
    @apply w-full overflow-x-auto whitespace-no-wrap block;

    &--fixed {
      @apply table-fixed;
    }

    @screen sm {
      @apply whitespace-normal table;
    }
  }

  &__loading-row {
    @apply relative;

    &--loading {
      @apply py-16;
    }

    // Add specificity for "snug" tables
    .chec-table--snug & {
      @apply p-8;
    }
  }

  &__empty-text {
    @apply text-gray-400;
  }

  &__actions-button {
    @apply flex justify-end;
  }

  // Styles for different numbers of columns being left aligned
  @for $num from 1 through 5 {
    &--#{$num}-left {
      tr > *:nth-child(n+#{$num + 1}) {
        @apply text-right;
      }
    }
  }
}
</style>
