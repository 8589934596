import mutations from '@/store/mutations';

const storageKey = 'chec_dashboard_language';

const {
  SET_LANGUAGE,
} = mutations;

export default {
  namespaced: true,
  state: {
    language: window.localStorage.getItem(storageKey) || 'en', // Default
  },
  mutations: {
    [SET_LANGUAGE](state, payload) {
      window.localStorage.setItem(storageKey, payload);
      // todo stop using the window
      window.i18n.locale = payload;
      state.language = payload;
    },
  },
  getters: {
    /**
     * Return the user's language, either from localstorage or from state
     *
     * @param {object} state
     * @returns {string}
     */
    getLanguage: (state) => window.localStorage.getItem(storageKey) || state.language,
  },
};
