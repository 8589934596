// Import metadata generated by localazy
import meta from './meta';

// Do some webpack require magic to resolve files that are provided by localazy
const requireModule = require.context('./', false, /\.js$/); //
const langs = {};

meta.languages.forEach(({ language }) => {
  langs[language] = {
    ...requireModule(`./${language}.js`).default,
  };
});

export default langs;
