<template>
  <tr
    class="dashboard-table-row"
    :class="{ ['dashboard-table-row--non-editable']: nonEditable }"
    @click="open"
  >
    <slot />
  </tr>
</template>

<script>
import router from 'vue-router';

const { isNavigationFailure, NavigationFailureType } = router;

export default {
  name: 'TableRow',
  props: {
    id: String,
    nonEditable: Boolean,
    paramName: {
      type: String,
      default: () => 'id',
    },
    routeName: String,
  },
  methods: {
    open() {
      this.$emit('click');

      if (!this.routeName) {
        return;
      }

      this.$router.push({
        name: this.routeName,
        params: { [this.paramName]: this.id },
      }).catch((error) => {
        // Avoid errors for duplicated clicks on the account button
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }

        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.dashboard-table-row {
  &:not(.dashboard-table-row--non-editable) {
    @apply cursor-pointer;
  }
}
</style>
