export default class ApiError extends Error {
  constructor(axiosError, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.axiosError = axiosError;
  }
}
