<template>
  <div v-if="backRoute" class="page-navigator">
    <div class="page-navigator__return">
      <router-link :to="backRoute" class="page-navigator__link">
        <ChecIcon class="page-navigator__icon" icon="left-arrow" />
        {{ backLinkText }}
      </router-link>
    </div>
    <PortalTarget name="details-pagination" />
  </div>
</template>

<script>
import { ChecIcon } from '@chec/ui-library';
import { PortalTarget } from 'portal-vue';
import { mapState } from 'vuex';

export default {
  name: 'DetailsPageNavigator',
  components: {
    ChecIcon,
    PortalTarget,
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    /**
     * Gets the route information if back button is enabled.
     *
     * @returns {Object|null}
     */
    backRoute() {
      const showsBack = this.$route.matched.findIndex((route) => route.meta.showBack);
      if (showsBack >= 0) {
        return this.$route.matched[showsBack - 1];
      }

      // Catch routes that might have no nav (as they're merchantless)
      if (this.$route.meta.merchantless && !this.$route.meta.hideNav && !this.merchant?.id) {
        return { name: 'merchant.list', meta: { title: this.$t('merchant.selectMerchant') } };
      }

      return null;
    },
    /**
     * Returns either the section title or generic back title
     *
     * @returns {String|null}
     */
    backLinkText() {
      if (!this.backRoute) {
        return null;
      }

      const { title } = this.backRoute.meta;
      const page = title && typeof title === 'function' ? title(this.$i18n) : '';

      if (page) {
        return page;
      }

      return this.$t('general.backToPreviousPage');
    },
  },
};
</script>

<style lang="scss">
.page-navigator {
  @apply text-gray-500 flex flex-row justify-between;

  &__return {
    @apply font-bold text-sm;
  }

  &__link {
    @apply flex items-center mb-12;
  }

  &__icon {
    @apply w-4 mr-1;
    // Fix for icon height.
    margin-top: 2px;
  }
}
</style>
