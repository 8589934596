export default {
  account: {
    account: "Conta",
    currentPassword: "Senha atual",
    dashboardLanguage: "Idioma do Painel Administrativo",
    deleteAccount: "Deletar conta",
    deleteCallout: "Você está prestes a deletar sua conta permanentemente.",
    deleteFailed: "Falha ao deletar sua conta! Tente novamente mais tarde.",
    deleteMessage: "Deletar sua conta é permanente e não pode ser revertido. Por favor, certifique-se de ter exportado todos os seus pedidos.",
    deleteMyAccount: "Deletar minha conta",
    details: "Detalhes da conta",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "Informação da conta",
    language: "Idioma",
    logout: "Sair",
    missingPasswords: "Insira sua senha atual e a nova senha desejada.",
    newPassword: "Nova senha",
    passwordUpdateFailed: "Falha ao atualizar sua senha. Por favor, tente novamente.",
    passwordUpdated: "Senha atualizada com sucesso",
    updateDetails: "Atualizar detalhes",
    updateFailed: "Falha ao salvar as informações da sua conta. Por favor, tente novamente.",
    updateLanguage: "Atualizar idioma",
    updatePassword: "Atualizar senha",
    updatedDetailsSuccessfully: "Details updated successfully.",
    updatedLanguageSuccessfully: "Language updated successfully."
  },
  address: {
    city: "Cidade",
    country: "País",
    line1: "Endereço",
    line2: "Endereço continuação",
    name: "Nome",
    state: "Estado",
    zipCode: "Código postal"
  },
  attribute: {
    attributes: "Atributos"
  },
  attributes: {
    add: "Add attribute",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Atributos",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Valor predefinido",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "Field type is required",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Something went wrong. Please try logging in again.",
    unableToAuth: "Unable to authorize your account. Please try again."
  },
  billing: {
    accruedFees: "Taxas acumuladas",
    addCard: "Adicionar Cartão",
    addPaymentMethod: "Adicionar forma de pagamento",
    billing: "Cobrança",
    changePlan: "Mudar plano",
    checFee: "Chec cota ({currency})",
    choosePlan: "Escolher plano",
    confirmChangePlan: "Se o plano para o qual você está mudando tiver restrições aos recursos da plataforma (como Commerce Rays), seus recursos serão automaticamente atualizados para se adequar aos limites do seu novo plano.",
    currentPlan: "Plano atual",
    customPricing: "Preço customizado",
    emailAddress: "Endereço eletrônico de cobrança",
    invoiceAmount: "Valor",
    invoiceDate: "Data",
    invoiceDescription: "Descrição",
    invoiceDownload: "Baixar",
    invoiceStatus: "Status",
    invoices: "Faturas",
    monthlyCeiling: "{ceiling} máximo por mês",
    noPaymentMethod: "Sem método de pagamento",
    orderValue: "Valor do pedido ({currency})",
    overageFee: "{fee} excedente",
    paid: "Pago",
    paymentGateway: "Gateway de pagamento",
    paymentMethod: "Método de pagamento",
    paymentMethodRequired: "Por favor, adicione um método de pagamento antes de escolher novos planos",
    perMonth: "por mês",
    perTransaction: "Por transação",
    planDetails: "Detalhes do plano",
    planMonthlyCeiling: "{ceiling} máximo por mês",
    planName: "{name} plano",
    planOverage: "{overage} excesso",
    planSummary: "Você vai ser cobrado {perMonth} por mês e {perTransaction} por transação",
    planTransactionFee: "{fee} taxa por transação",
    transactionFeeOrder: "Pedido",
    transactionFeesTitle: "Taxas de transação faturadas em {date}",
    unpaid: "Não pago",
    updateCard: "Editar",
    updateEmail: "Editar endereço eletrônico de cobrança",
    viewInvoice: "Ver fatura",
    voided: "Anulado"
  },
  category: {
    add: "Adicionar categoria",
    addSubCategory: "Add sub-category",
    added: "Categoria adicionada.",
    addedSubCategory: "Added sub-category.",
    categories: "Categorias",
    chooseParentCategory: "Choose parent category",
    confirmDelete: "Você tem certeza que deseja deletar essa categoria?",
    confirmDeleteTitle: "Você tem certeza?",
    create: "Criar categoria",
    createError: "Desculpa, estamos com problemas em criar essa categoria.",
    deleteFailed: "Failed to delete your category! Please try again.",
    deleted: "Categoria deletada",
    edit: "Editar categoria",
    editMultiple: "Editar categorias",
    images: {
      title: "Galeria de imagens",
      types: "| {types} aceitos | {types} & {lastType} aceito"
    },
    introSplash: {
      description: "Create infinitely nestable product categories with category images and descriptions. {more}.",
      more: "Saiba mais",
      title: "Add categories and sub-categories."
    },
    noAvailableCategories: "Please add another category first",
    noSubCategories: "This category has no sub-categories.",
    permalink: "Permalink",
    pleaseEnterName: "Por favor, insira um nome",
    pleaseEnterPermalink: "Please enter a permalink",
    save: "Salvar categoria",
    selectPlaceholder: "Escolha uma categoria",
    subCategories: "Subcategorias",
    update: "Atualizar categoria",
    updateError: "Desculpa, estamos com problemas em salvar essa categoria.",
    updateSubCategoryError: "Sorry, we're having problems saving this sub-category.",
    updated: "Categoria atualizada."
  },
  customer: {
    add: "Adicionar cliente",
    added: "Cliente adicionado.",
    confirmDelete: "Tem certeza de que deseja deletar este cliente?",
    create: "Criar cliente",
    createError: "Desculpe, estamos com problemas para criar este cliente.",
    customers: "Clientes",
    deleteFailed: "Failed to delete your customer! Please try again.",
    deleted: "Cliente deletado.",
    edit: "Editar cliente",
    emailTaken: "Esse endereço de email já está em uso",
    externalId: "ID externo",
    introSplash: {
      description: "Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.",
      title: "Your customers will be displayed here"
    },
    notesTitle: "Anotações",
    save: "Salvar cliente",
    update: "Atualizar cliente",
    updateError: "Desculpe, estamos com problemas para salvar este cliente.",
    updated: "Cliente atualizado."
  },
  developer: {
    apiKeys: "Chaves de API",
    apiKeysCors: "API keys & CORS",
    checCli: "Chec CLI",
    cliNotice: "Use o {cli} para acompanhar os logs em tempo real: {cliCommand}",
    copyCodeFailure: "Falha ao copiar o código para a área de transferência. Atualize a página e tente novamente.",
    copyCodeSuccess: "O código foi copiado para a área de transferência.",
    corsDomainsHelp: "You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the \"Access-Control-Allow-Origin\" header. If you specify multiple, API calls will compare against the request's \"Origin\" header. If you leave this blank, all origins will be allowed (default).",
    crossOriginResourceSharing: "Cross-Origin Resource Sharing (CORS) origins",
    dateTime: "Data/hora",
    ipAddress: "Endereço IP",
    keyUsed: {
      label: "Chave usada",
      "public": "Público",
      secret: "Secreta"
    },
    method: "Método",
    request: "Requisição",
    requestMethod: {
      "delete": "DELETE",
      get: "GET",
      label: "Método de requisição",
      post: "POST",
      put: "PUT"
    },
    response: "Resposta",
    responseDate: {
      label: "Data da resposta"
    },
    responseStatus: {
      badRequest: "Requisição incorreta",
      label: "Status da resposta",
      notFound: "Não encontrado",
      ok: "OK",
      other: "Outros ou erros do servidor",
      requestFailed: "Requisição falhou",
      unauthorized: "Não autorizado",
      validation: "Validação"
    },
    summary: "Resumo",
    url: "URL"
  },
  discount: {
    add: "Adicionar desconto",
    added: "Desconto adicionado.",
    all: "Todos",
    code: "Código",
    codeRequired: "Código do desconto é mandatório",
    confirmDelete: "Você tem certeza que deseja deletar esse desconto?",
    create: "Criar desconto",
    deleteFailed: "Failed to delete your discount! Please try again.",
    deleted: "Desconto deletado.",
    discountCode: "Código do desconto",
    discountOffProduct: "{discount} para {product}",
    discounts: "Descontos",
    edit: "Editar desconto",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "Saiba mais",
      title: "Add a discount code and run a promotion."
    },
    neverExpires: "Sem Expiração",
    productTooltip: "If left empty, this discount will work for all products",
    productsLabel: "Limit to these products",
    saveError: "Desculpa, estamos com problemas em salvar esse desconto.",
    updated: "Desconto atualizado.",
    valueRequired: "Por favor, insira um valor para o desconto."
  },
  error: {
    notFoundSuggestion: "Volte para a página anterior e tente novamente.",
    notFoundTitle: "Página não encontrada...",
    permissionErrorSuggestion: "Tente sair e entrar novamente. Se o problema persistir entre em contato com o support.",
    permissionErrorTitle: "Permissão negada!",
    serverErrorSuggestion: "Pegue um cafézinho e tente novamente em alguns minutos. Nós fomos notificados desse erro.",
    serverErrorTitle: "Aconteceu algo de errado...",
    somethingWentWrong: "Oops. Algo deu errado!"
  },
  extraFields: {
    add: "Add extra field",
    addOptions: "Hit enter or comma to add an option",
    collectsBilling: "Exigir endereço de cobrança",
    collectsFullName: "Exigir nome completo",
    collectsShipping: "Exigir endereço de entrega",
    confirmDeleteExtraField: "Are you sure you want to delete this extra field? Storefronts that rely on this extra field may be affected.",
    deleteFailed: "Failed to delete your extra field! Please try again.",
    deleted: "Extra field deleted successfully.",
    description: "Capturar informações personalizadas de seu cliente",
    edit: "Edit extra field",
    extraFields: "Campos extras",
    fieldType: "Tipo de campo",
    fieldTypeDescription: "Select the field type for your extra field. This is for frontend use.",
    introDescription: "Extra fields are custom merchant defined data fields that are globally available, usually in a checkout. An example might be a custom extra field for collecting customers' phone numbers in a checkout form.",
    introTitle: "Collect custom data from customers with global extra fields.",
    name: "Nome do campo extra",
    nameRequired: "Nome é obrigatório",
    saveFailure: "Sorry, we're having a problem saving your extra field.",
    saveSuccess: "Your extra field has been saved successfully.",
    typeRequired: "Field type is required",
    updateSuccess: "Your extra field has been updated successfully."
  },
  gateways: {
    add: "Add payment gateway",
    braintree: "Braintree",
    braintreeDocumentation: "See the documentation",
    braintreeHelp: "Enter your Braintree merchant ID, public key, private key, and your tokenization key. {documentation} for more information on tokenization keys.",
    chooseGateway: "Please choose a gateway",
    confirmDelete: "Are you sure you want to delete this payment gateway? Any stores using it will stop accepting payments immediately from this gateway.",
    deleteFailed: "Something went wrong while trying to delete this payment gateway. Please try again.",
    deleted: "Payment gateway deleted.",
    edit: "Edit payment gateway: {gateway}",
    email: "Endereço eletrônico",
    gateway: "Gateway",
    introDescription: "A payment gateway authorizes credit card or direct payments processing for online businesses. The test gateway has been enabled for making test orders.",
    introTitle: "Start collecting payments by enabling your payment gateway(s).",
    keyId: "Key ID",
    keySecret: "Key secret",
    manual: "Manual",
    manualConfig: {
      detailsHelp: "Details about this payment method - shown before placing an order.",
      name: "Name of payment method",
      paymentInstructions: "Payment instructions",
      paymentInstructionsHelp: "Details on how to pay - shown to customers after placing an order."
    },
    manualHelp: "You will manually have to record payments and fulfill orders placed using this method.",
    manualName: "Manual: {name}",
    merchantId: "ID do comerciantea",
    omise: "Omise",
    omiseDocumentation: "Omise documentation",
    omiseHelp: "Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.",
    paymentMethodHelper: "To add a live payment gateway, please add a payment method in the billing settings page.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "Private key",
    publicKey: "Chave pública:",
    publishableKey: "Publishable key",
    razorpay: "Razorpay",
    redirectingToSquare: "Redirecting to Square",
    sandboxNotAvailable: "Sandbox mode is not available for this gateway.",
    saveFailed: "Failed to save your payment gateway, please check you have filled out all required fields.",
    saved: "Your payment gateway was successfully saved.",
    secretKey: "Chave Secreta:",
    square: "Square",
    squareHelp: "When you click 'Save changes' below, you will be redirected to Square to login to your payment account.",
    stripe: "Stripe",
    stripeDocumentation: "Stripe documentation.",
    stripeHelp: "Enter your publishable key and your secret key, both available from the Stripe Dashboard. Learn more in the {documentation}",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "Test gateway",
    tokenizationKey: "Tokenization key",
    transactionVolume: "Transaction volume",
    weSupport: "Gateways we support"
  },
  general: {
    about: "Sobre",
    actions: "Ações",
    active: "Ativado",
    add: "Adicionar",
    adding: "A adicionar…",
    address: {
      city: "Cidade",
      country: "País",
      postalZip: "Código Postal / CEP",
      provinceState: "Province/state",
      street: "Rua"
    },
    advanced: "Avançado",
    all: "Todos",
    amount: "Valor",
    amountValue: "Quantidade: {value}",
    andMore: " & {n} mais",
    areYouSure: "Você tem certeza?",
    assets: "Ativos",
    auto: "Automático",
    backTo: "Voltar para {page}",
    backToPreviousPage: "Voltar à página anterior",
    byAuthor: "por {author}",
    calculation: "O Cálculo",
    cancel: "Cancelar",
    cancelled: "Cancelado",
    checkbox: "Caixa de verificação",
    checked: "Selecionado",
    clearSearch: "Limpar pesquisa",
    clickToCopy: "Clique para copiar",
    close: "Fechar",
    contactUs: "Entre em contato",
    copied: "Copiado!",
    copy: "Copiar",
    country: "País",
    creating: "Criando...",
    currency: "Moeda",
    date: "Data",
    dateTime: "Data/hora",
    "default": "Padrão",
    "delete": "Deletar",
    description: "Descrição",
    descriptionOptional: "Descrição (opcional)",
    details: "Detalhes",
    digital: "Digital",
    disable: "Desativar",
    disabled: "Desativado",
    discount: "Desconto",
    draft: "Rascunho",
    duplicate: "Duplicar",
    edit: "Editar",
    emailAddress: "Endereço eletrônico",
    empty: "Nenhum log encontrado",
    enable: "Ativar",
    enabled: "Ativado",
    environment: "meio ambiente",
    error: "Sorry, there has been an error. Please try again.",
    expired: "Expirado",
    expires: "Data de Expiração",
    "false": "Falso",
    firstName: "Primeiro nome",
    "for": "Para",
    fulfill: "Concluir",
    fulfilled: "Concluído",
    generate: "Gerar",
    getStarted: "Começar",
    goBack: "Voltar",
    health: "Status",
    hidden: "Oculto",
    home: "Início",
    hostedExperiences: "Hosted experiences",
    id: "ID",
    images: "Imagens",
    inactive: "Desativado",
    item: "item",
    label: "Etiqueta",
    lastName: "Sobrenome",
    learnMore: "Saiba mais",
    learnMoreButton: "Saiba mais",
    live: "Em direto",
    loading: "Carregando...",
    loggedInAs: "Logado como {name}",
    logo: "Logotipo",
    longText: "Long text",
    merchant: "Comerciante",
    month: {
      april: "Abril",
      august: "Agosto",
      december: "Dezembro",
      february: "Fevereiro",
      january: "Janeiro",
      july: "Julho",
      june: "Junho",
      march: "Março",
      may: "Maio",
      november: "Novembro",
      october: "Outubro",
      september: "Setembro"
    },
    name: "Nome",
    never: "Nunca",
    "new": "Novo",
    next: "Próximo",
    nextName: "Seguinte: {name}",
    no: "Não",
    noResults: "Nenhum resultado corresponde à sua pesquisa",
    notAvailable: "N/A",
    notFulfilled: "Não concluído",
    notPaid: "Não pago",
    notRequired: "Não é necessário",
    notes: "Anotações",
    number: "número",
    open: "Abrir",
    option: "Opção",
    options: "Opções",
    override: "Substituir",
    paid: "Pago",
    parent: "Pai",
    partiallyFulfilled: "Parcialmente concluído",
    paymentGateway: "Gateway de pagamento",
    permalinkSlug: "Link Permanente/slug",
    phoneNumber: "Número de telefone",
    placed: "Colocado",
    previous: "Anterior",
    previousName: "Anterior: {name}",
    price: "Preço",
    "private": "Privado",
    province: "Província",
    published: "Publicado",
    quantity: "Quantidade",
    radio: "Rádio",
    rate: "Tarifa",
    rates: "Tarifas",
    reference: "Referência",
    refund: "Reembolsar",
    refunded: "Reembolsado",
    refunding: "Reembolsando...",
    regenerate: "Regenerar",
    region: "Região",
    regions: "Regiões",
    remaining: "Estoque",
    required: "Exigido",
    sales: "Vendas",
    sandbox: "Sandbox",
    sandboxMode: "Modo de teste",
    save: "Salvar",
    saveAndClose: "Salvar e Fechar",
    saveChanges: "Salvar mudanças",
    saved: "Mudanças salvas",
    saving: "Salvando...",
    search: "Pesquisar",
    security: "Segurança",
    selectOption: "Selecione a opção",
    sending: "Enviando…",
    shareEmbed: "Compartilhar/incorporar",
    shipping: "Frete",
    shippingMethod: "Shipping ({method})",
    shortText: "Texto curto",
    slug: "Slug",
    standard: "Padrão",
    startDate: "Data de início",
    state: "Estado",
    status: "Status",
    subtotal: "Subtotal",
    tax: "Imposto",
    template: "Modelo",
    text: "Texto",
    time: "Tempo",
    timestamp: "Data e horário",
    today: "Hoje",
    total: "Total",
    totalPaid: "Total pago",
    "true": "Verdadeiro",
    type: "Tipo",
    unchecked: "Desmarcado",
    unfulfilled: "Não concluído",
    unlimited: "Unlimited",
    update: "Atualizar",
    upgradePlan: "Melhorar seu plano",
    url: "URL",
    value: "Valor",
    view: "Ver",
    viewDetails: "Ver detalhes",
    visibility: "Visibilidade",
    visible: "Visível",
    whatIsThis: "O que é isto?",
    xOfY: "{x} de {y}",
    yes: "Sim",
    yesterday: "Ontem"
  },
  home: {
    addProducts: "Adicione seus produtos",
    configureShipping: "Configure o seu frete",
    configureTax: "Configure seu imposto",
    createCommerceRay: "Crie seu primeiro Ray Commerce",
    enableGateway: "Habilite um gateway de pagamento",
    gettingStarted: "Introdução",
    gettingStartedRays: "Crie seu Commerce Ray",
    latestAnnouncements: "Últimos anúncios",
    latestChanges: "Últimas alterações",
    messageUs: "Envie-nos uma mensagem",
    speakToAnExpert: "Fale com um especialista",
    speakToCopy: "Nossos especialistas em eCommerce podem garantir que Commerce.js seja o ideal para o seu negócio e ajudar a arquitetar uma solução.",
    title: "Bem-vindo",
    viewChangelog: "Ver log de mudanças",
    welcomeSubtitle: "Bem-vindo! Estamos aqui para ajudá-lo a fazer as coisas andarem."
  },
  integration: {
    about: "Sobre: {integration}",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration added",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Configure integration",
    confirmDelete: "Are you sure you want to delete this integration?",
    createError: "Sorry, we're having problems activating this integration.",
    creating: "Creating integration...",
    deleteFailed: "Failed to delete your integration! Try again later.",
    deleted: "Integration deleted.",
    edit: "Edit integration",
    editNamed: "Edit integration - {name}",
    filter: {
      checkout: "Finalizar",
      content: "Conteúdo",
      dataMesh: "Data mesh",
      email: "E-mail",
      frontend: "Frontend",
      fulfillment: "Fulfillment",
      orders: "Pedidos",
      payments: "Pagamentos",
      personalization: "Personalização",
      tax: "Imposto"
    },
    filterLabel: "Filtro",
    filterTagLabel: "Filtro: {tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "Integração",
    integrations: "Integrações",
    introSplash: {
      description: "Connect with other services and compose your desired business stack.",
      title: "Configure integrations with 3rd party providers."
    },
    lastRun: "Last run",
    noConfigNeeded: "This template requires no additional configuration",
    noLogo: "No logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "São",
      notApplicable: "N/A",
      unhealthy: "Insalubre",
      unstable: "Unstable"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API request logs with details will be listed here for ease of debugging.",
      title: "Your request logs will be shown here"
    },
    logs: "Logs"
  },
  merchant: {
    createFull: "Let's set up your merchant",
    createLeadIn: "Your information can be changed or updated at any time.",
    createMerchant: "Create merchant",
    seeAll: "View all merchants",
    selectMerchant: "Select a merchant"
  },
  notes: {
    add: "Adicionar uma anotação",
    addANote: "Adicionar uma anotação",
    confirmDelete: "Tem certeza de que deseja excluir esta anotação?",
    note: "Anotação"
  },
  order: {
    capturedOn: "Capturada:",
    cardEndingIn: "Cartão terminando em {number}",
    changeCustomer: "Alterar informações do cliente",
    confirmRefund: "Tem certeza de que deseja reembolsar este pedido?",
    customerDetails: "Detalhes do cliente",
    customerUpdateFailed: "Desculpe, estamos com problemas para atualizar as informações do cliente.",
    customerUpdated: "Informações do cliente atualizadas no pedido # {reference}",
    date: "Data do pedido",
    dateTime: "Data/hora",
    deleteTest: "Delete test orders",
    deleteTestConfirmation: "Are you sure you want to delete all of your sandbox orders?",
    edit: "Editar pedido",
    "export": "Exportar pedidos",
    exportConfirmation: "Enviaremos uma cópia dos seus pedidos (com os filtros atuais) no formato CSV para o seu e-mail quando estiver pronto.",
    fulfillItems: "Concluir itens",
    fulfillment: {
      confirmCopy: "Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?",
      confirmHeader: "Please confirm your fulfillment changes",
      dateShipped: "Data de envio: {date}",
      reference: "Referência: {reference}",
      shipmentNumber: "Frete #{number}",
      shippingCarrier: "Transportadora",
      trackingNotProvided: "Rastreamento não fornecido",
      trackingNumber: "Rastreamento #{number}",
      trackingNumberOptional: "Número de rastreamento (opcional)"
    },
    fulfillmentStatus: {
      fulfilled: "Concluído",
      label: "Status da entrega",
      not_fulfilled: "Não concluído",
      partially_fulfilled: "Parcialmente concluído",
      returned: "Retornado"
    },
    gatewayTransactionId: "ID da transação do gateway: {id}",
    itemFulfilled: "Items fulfilled successfully.",
    newNotification: "Nova pedido {reference} de {value}!",
    nextOrder: "Próximo pedido",
    noOrders: {
      copy: "Em seguida, mostraremos pedidos e vendas exibidos ao longo do tempo.",
      title: "Faça sua primeira venda!"
    },
    orderNotes: "Anotações do pedido",
    orderNumber: "Pedido #",
    orderStatus: "Status do pedido",
    orders: "Pedidos",
    ordersAllTime: "Pedidos - Todos",
    ordersOnMonth: "Pedidos - {month}",
    ordersOnMonthHelptip: "Number of orders since the start of this month, compared with the same period in the previous month",
    overview: "Visão geral",
    paymentMethod: "Método de pagamento",
    paymentSaveFailed: "Desculpe, estamos tendo problemas para salvar esse pagamento.",
    paymentSaved: "Pagamento salvo com sucesso.",
    paymentStatus: {
      label: "Status do pagamento",
      not_paid: "Não pago",
      paid: "Pago",
      partially_paid: "Parcialmente pago",
      pending: "Pagamento pendente",
      refunded: "Reembolsado"
    },
    previousOrder: "Pedido anterior",
    receiptSendFailed: "Não foi possível reenviar o recibo, tente novamente.",
    receiptSent: "Recibo enviado.",
    recordManualPayment: "Registre o pagamento manual",
    reference: "Referência: {reference}",
    refundFailed: "Não foi possível reembolsar o pedido, tente novamente.",
    refundSuccess: "Pedido reembolsado com sucesso.",
    resendReceipt: "Reenviar o recibo",
    salesAllTime: "Vendas - Todas",
    salesOnMonth: "Vendas - {month}",
    salesOnMonthHelptip: "Sales performance since the start of this month, compared with the same period in the previous month",
    sandbox: "Pedido teste",
    shipments: "Envios",
    status: "Status",
    testOrdersDeleted: "Test orders successfully deleted.",
    transactionReferenceId: "ID de transação/referência",
    updateAddressError: "Something went wrong while saving the address, please try again.",
    updatedBilling: "Updated billing address.",
    updatedShipping: "Updated shipping address.",
    viewMultiple: "Ver pedidos",
    viewOne: "Ver pedido"
  },
  product: {
    add: "Adicionar produto",
    added: "Produto adicionado",
    additionalActions: "Additional product actions",
    basePrice: "Preço base",
    chooseRelatedProducts: "Escolha produtos relacionados",
    confirmDelete: "Tem certeza de que deseja deletar este produto?",
    confirmDeleteTitle: "Você tem certeza?",
    copyPaste: "Copie e cole este código no seu site.",
    count: "Sem produtos | 1 produto | {n} produtos",
    customPermalink: "Permalink personalizado",
    "delete": "Deletar produto",
    deleteFailed: "Falha ao deletar seu produto! Tente novamente mais tarde.",
    deleted: "Produto deletado.",
    description: "Descrição",
    digitalFulfillment: {
      doesNotExpire: "Links não expiram",
      downloadLimit: "Limite de download",
      duration: "Duração",
      durationPeriod: "Período de duração",
      expiryMessage: "Após {duration} {period} da compra",
      linkExpiration: "Validade do link",
      period: {
        days: "dias | dia | dias",
        months: "meses | mês | meses",
        weeks: "semanas | semana | semanas"
      },
      title: "Entrega digital"
    },
    duplicateProduct: "Duplicar produto",
    duplicated: "Produto duplicado com sucesso e agora você está editando a cópia.",
    edit: "Editar produto",
    embed: "Embutir",
    embedCheckout: "Incorporar checkout",
    embedCheckoutDescription: "Incorpore seu checkout diretamente no seu site ou blog.",
    embedShareSell: "Incorpore, compartilhe, venda!",
    images: {
      alert: "A galeria de imagens contém todas as imagens associadas a este produto, incluindo suas variantes.",
      title: "Galeria de imagens",
      types: "| {types} aceitos | {types} & {lastType} aceito"
    },
    includesNCountries: "Includes 1 country | Includes {number} countries",
    introSplash: {
      api: "Create product API",
      description: "Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.",
      more: "Saiba mais",
      title: "Add your first physical or digital product"
    },
    inventory: {
      available: "Em estoque",
      managed: "Administrado",
      tooltip: "Quando o inventário é administrado, você pode definir o número de itens disponíveis, caso contrário, ele será ilimitado"
    },
    lastSavedAt: "Última atualização",
    linkCustomers: "Conecte os clientes diretamente ao checkout usando essa URL.",
    minimumPrice: "Preço mínimo",
    misc: "Extra",
    name: "Nome",
    noImage: "Sem imagem",
    noRelatedAvailable: "You have no other products",
    payWhatYouWant: "Pague o que você quiser",
    price: "Preço",
    product: "Produto",
    products: "Produtos",
    pwywEnabled: "'Pague o que quiser' habilitado",
    relatedProducts: "Produtos relactionados",
    saveFailed: "Falha ao salvar o produto! Por favor, tente novamente.",
    saved: "Mudanças salvadas",
    searchEngineDescription: "Descrição do motor de busca",
    searchEngineTitle: "Título do mecanismo de busca",
    seo: "SEO",
    shippingCard: {
      baseRateLabel: "Tarifa base",
      baseRateTooltip: "O preço base para esta taxa. Outras tarifas serão adicionadas em cima disso.",
      nameLabel: "Nome",
      onOwnLabel: "Por conta própria",
      onOwnTooltip: "O preço dessa taxa quando este item é encomendado por conta própria. Isso será adicionado à Taxa base, por exemplo, Taxa base + Taxa por conta própria = Taxa total de frete cobrada",
      title: "Opções de frete",
      withOthersLabel: "Com outros",
      withOthersTooltip: "O preço dessa taxa quando encomendado com outros itens será adicionado com a taxa de envio de outros itens e a taxa base, por exemplo, Taxa base + Taxa com outros (taxa deste item) + Taxa com outros (taxa de outros itens) = Taxa total de envio cobrada"
    },
    sku: "SKU",
    sortOrder: "Ordem de classificação",
    sortOrderTooltip: "Lower values make your product show up in lists earlier, whereas higher values make it show up later.",
    thankYouPage: "Página de agradecimento",
    variants: {
      addGroup: "Adicionar grupo de variantes",
      addOption: "Adicionar opção",
      addVariant: "Adicionar variante",
      amount: "Valor",
      andMore: "E {count} variantes adicionais",
      bulkAdd: "Adicionar em massa",
      bulkFailure: "Desculpe, estamos tendo problemas para atualizar suas variantes.",
      bulkSuccess: "As variantes foram atualizadas com sucesso",
      chooseGroupsAndOptions: "Escolha grupos e opções",
      create: "Criar variante | Criar variantes",
      createBulk: "Criar variantes em massa",
      createSingle: "Criar uma única variante",
      defaultPrice: "Preço padrão",
      defaultPriceTooltip: "O preço padrão para a opção de variantes.",
      defaultVariantTitle: "Nova variante",
      edit: "Editar variantes",
      editSingle: "Editar variante",
      editSingleNamed: "Editar variante: {name}",
      generateVariants: "Gerar variantes",
      groups: "Grupos | Grupo | Grupos",
      hasInvalidVariants: "Este produto tem variantes inválidas. Essas variantes não podem ser adicionadas a um carrinho ou checkout e não podem ser vendidas. Para reativar variantes inválidas, edite-as e resolva quaisquer problemas.",
      help: "Diferentes tipos deste produto (por exemplo tamanho, cor)",
      image: "Imagem",
      invalidReason: {
        duplicate_option_config: "Esta variante inválida compartilha opções com outra variante. Apenas uma variante pode existir para um conjunto específico de opções.",
        incomplete_option_config: "Esta variante inválida está faltando uma opção de um dos grupos de variantes configurados para este produto. As variantes devem ter uma opção definida para cada grupo."
      },
      invalidVariantsOnSave: "Assim que salvar, suas variantes serão marcadas como “inválidas” e você não poderá vender variantes inválidas até que você corrija os problemas.",
      mustSave: "Você deve salvar antes de adicionar novas variantes",
      noGroups: "Variantes ajudam você a vender produtos com pequenas diferenças, mas ainda são o mesmo produto. Por exemplo, você pode vender uma camiseta em cores diferentes, ou um vaso de plantas em tamanhos diferentes. Você pode configurar variantes para ter seu próprio preço, SKU e inventário de estoque.\n\nPara começar, crie grupos e opções para suas variantes. Os grupos definem o tipo de variante (por exemplo, cor). As opções são uma escolha que seu cliente pode fazer dentro desse grupo (por exemplo, azul).",
      noGroupsTitle: "Configurar grupos de variantes para vender variações do mesmo produto",
      noOptions: "Por favor insira pelo menos uma opção",
      noVariants: "Use os botões acima para criar variantes. Variantes são combinações de grupos de variantes e opções de variantes. Você será capaz de definir o preço e o inventário para cada variante (se ativado)",
      noVariantsTitle: "Nenhuma variante foi criada",
      noVariantsToGenerate: "Todas as variantes já existem para as opções selecionadas.",
      optionDefaultImage: "Imagem padrão",
      options: "Opções | Opção | Opções",
      optionsInGroupsRequired: "Você deve selecionar pelo menos uma opção em cada grupo para gerar variantes em massa.",
      optionsUpdateFailure: "Desculpe, estamos com problemas para atualizar suas opções de variantes.",
      optionsUpdateSuccess: "As opções de variantes foram atualizadas com sucesso",
      override: "Substituir",
      preview: "Pré-visualização da variante",
      previewHelp: "Pré-visualização das variantes que serão criadas com base nas opções selecionadas acima.",
      quantity: "Quantidade",
      requiresGroupsSave: "Usar esse recurso requer que você salve os grupos primeiro.",
      requiresSave: "Usar esse recurso requer que você salve o produto primeiro.",
      saveAndGenerate: "Salvar e gerar variantes",
      selectOptions: "Selecione as opções para a sua variante",
      singleCreateSuccess: "Variante foi criada com sucesso",
      singleUpdateFailure: "Desculpe, estamos com um problema em salvar sua variante.",
      singleUpdateSuccess: "Variante foi atualizada com sucesso",
      title: "Variantes",
      variantDetails: "Detalhes da variante",
      variantExists: "Parece que esta variante já existe.",
      variantGroup: "Grupo de variante",
      variantGroupName: "Nome do grupo de variantes",
      variantGroupTitle: "Grupo de variante: {group}",
      variantName: "Nome da variante",
      willInvalidateVariants: "A adição e/ou remoção de grupos de variantes invalidará suas variantes existentes, pois cada variante deve ter uma opção para cada grupo e não pode ser duplicada."
    },
    viewProducts: "Ver produtos"
  },
  rays: {
    add: "Criar novo Ray",
    commerceRays: "Commerce Rays",
    confirmDelete: "Tem certeza de que deseja deletar este Ray?",
    confirmDeleteTitle: "Você tem certeza?",
    create: "Criar Ray",
    "delete": "Deletar Ray",
    deleteFailed: "Falha ao deletar seu Ray! Tente novamente mais tarde.",
    deleted: "Ray deletado.",
    deploying: "Publicando...",
    desktop: "Área de trabalho",
    edit: "Editar Ray",
    embed: "Embutir",
    embedModal: "Você também pode incorporar seu checkout em um pop-up modal, acionado ao clicar em um botão “Comprar agora”.",
    exit: "Sair",
    images: {
      title: "Imagens",
      types: "| {types} aceitos | {types} & {lastType} aceito"
    },
    introduction: {
      description: "Um construtor de página sem código com checkout incluso.",
      "for": {
        campaigns: "Campanhas",
        digitalProducts: "Produtos digitais",
        influencers: "Parcerias de influenciadores",
        mediaCheckouts: "Checkouts de mídia",
        ppc: "Campanhas de PPC",
        productDrops: "Lançamento de produto",
        title: "Rays é para",
        videoCommerce: "Video Commerce"
      },
      line1: "Qualquer pessoa pode criar belas páginas de produtos e conteúdo que converte.",
      line2: "Escolha entre nossa bonita seleção de temas editáveis e venda a história da sua marca.",
      title: "✨ Bem-vindo ao Commerce Rays"
    },
    lastDeployment: "Última publicação:",
    mobile: "Celular",
    nameHelper: "Para identificação interna.",
    noProducts: "Você deve ter pelo menos um produto. Para criar um Ray, comece adicionando um produto.",
    noRays: "Nenhum Ray encontrado.",
    noThemes: "Nenhum tema encontrado",
    pickTheme: "Escolha um tema",
    planWarning: {
      limitReached: "Você atingiu o limite do seu plano. Por favor {upgrade} para publicar mais.",
      notice: "Seu plano atual não suporta o uso de Rays. Por favor {upgrade} para publicar.",
      upgrade: "Melhorar seu plano"
    },
    ray: "Ray",
    rayName: "Nome do Ray",
    rays: "Rays",
    sandboxHelper: "Ativar o modo sandbox permitirá que você use o Stripe no modo de teste. Nenhum pedido real pode ser criado no modo de teste.",
    save: "Salvar",
    saveDeploy: "Salvar e publicar",
    saveFailed: "Falha ao salvar seu Ray! Por favor, tente novamente.",
    saved: "Mudanças salvas",
    savedAndDeploying: "Alterações salvas e publicando seu Ray...",
    saving: "Salvando...",
    select: "Selecionar tema",
    selectProducts: "Selecionar produto(s)",
    validationFailed: "Há um problema com as configurações escolhidas. Verifique se há erros na barra lateral.",
    viewPublishedRay: "Ver Ray publicado",
    viewRay: "Ver Ray",
    viewportWarning: "Esta parte do nosso painel é melhor visualizada em uma tela maior. Por favor, faça login no seu computador para ver Raios."
  },
  settings: {
    analytics: "Analytics",
    data: "Dados",
    developer: "Desenvolvedor",
    developerSettings: {
      addKey: "Adicionar chave de API",
      apiKey: "Chave API",
      apiKeys: "Chaves de API",
      apiVersion: "Versão de API",
      changeKey: "Alterar chave",
      changelog: "See changelogs",
      confirmDeleteKey: "Are you sure you want to delete this API key? Any stores using this key will stop functioning immediately.",
      createKey: "Criar chave de API",
      createSuccess: "API key has been created successfully",
      deleteFailed: "Failed to delete your API key! Try again later.",
      deleted: "API key deleted.",
      editKey: "Edit API key",
      generateKey: "Are you sure you want to regenerate the key?",
      keyChangFailed: "Failed to update your API key! Try again later.",
      keyChanged: "Key has been successfully updated",
      label: "API key label",
      live: "EM DIRETO",
      "public": "Público",
      resetKeyButtonTooltip: "Gerar nova chave",
      resetKeyConfirmationHeader: "Gerar nova chave?",
      sandbox: "Sandbox",
      secret: "Segredo",
      type: "Tipo",
      updateFailure: "Failed to update your API key. Please try again.",
      updateSuccess: "API key successfully updated"
    },
    merchant: {
      analyticsCardTitle: "Google Analytics",
      analyticsGaTrackingId: "Tracking ID",
      analyticsGaTrackingIdTooltip: "Your unique Google Analytics tracking ID",
      analyticsLinkedDomain: "Add linked domain",
      analyticsLinkedDomainTooltip: "You can add multiple domains separated by a comma.",
      coverImage: "Imagem de capa",
      coverImageTooltip: "This is the cover image that will be on your storefront.",
      descriptionTooltip: "A brief description about yourself/your company",
      emailTooltip: "This email is visible to your customers. Customer support emails will go here.",
      enableHostedCheckouts: "Enable hosted checkout",
      enableHostedCheckoutsTooltip: "The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Enable hosted storefront",
      isPublic: "Make visible to public API",
      merchantAddress: "Endereço",
      merchantId: "ID do comerciante",
      merchantIdInfo: "This is your unique merchant ID. We may ask for it when you contact us.",
      name: "Nome da empresa",
      nameTooltip: "Em qual nome você está vendendo?",
      save: "Guardar definições",
      saveFailed: "Failed to save the merchant settings! Please try again.",
      saved: "Mudanças salvas",
      timezoneTooltip: "All dates and times in Chec will be relative to this timezone",
      title: "Detalhes do comerciante"
    },
    notifications: {
      customerLoginToken: "Cliente: Token de login",
      customerOrderReceipt: "Cliente: recibo do pedido",
      customerOrderShipped: "Cliente: pedido enviado",
      dashboard: "Notificações do Painel Administrativo",
      dashboardSummary: "Controle o comportamento das notificações no Painel Administrativo Chec.",
      emailNotificationSummary: "Ativar/desativar notificações padrões de email que são enviadas por Chec.",
      emailNotifications: "Notificações de email",
      emails: "Emails",
      merchantOrderReceipt: "Comerciante: novo pedido recebido",
      navMenuHighlight: "Destacar no menu",
      news: "Novidades e anúncios de funcionalidades",
      none: "Nenhuma",
      notificationPopup: "Popup de notificação",
      title: "Notificações"
    },
    paymentGateways: "Gateways de pagamento",
    settings: "Configurações",
    shipping: "Frete",
    tax: {
      addState: "Add state",
      addTax: "Enable tax management",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Add tax zone",
      addedToRate: "Added to country rate",
      address: "Endereço",
      addressAlert: "This is used to calculate tax and is displayed on invoices.",
      calculateAutomatically: "Calculate taxes automatically",
      configureTaxZone: "Configure tax zone",
      confirmDeleteZone: "Are you sure you want to delete this tax zone?",
      confirmDeleteZoneTitle: "Você tem certeza?",
      countrySelectInfo: "The tax rate configured here will be applied to all orders in this tax zone.",
      countryTax: "Country tax",
      deleted: "Tax configuration deleted",
      digitalRate: "Digital rate",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Edit tax zone",
      enableTax: "Enable tax",
      enableVat: "Enable EU digital goods VAT",
      enableVatInfo: "Collect EU digital goods VAT (VAT MOSS) tax on digital goods. Tax rates are automatically calculated and applied to EU customers.",
      euVatMoss: "European Union (VAT MOSS)",
      introCopy: "Commerce.js allows you to easily define your region's tax rates to help you easily collect the appropriate tax during checkout. You can also choose to integrate any tax provider and implement custom business rules with your own serverless functions.",
      introTitle: "Let Commerce.js manage your tax!",
      nexusHelp: "Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.",
      nexusStates: "Nexus states",
      overrideRate: "Instead of country rate",
      pricesIncludeTax: "Do your prices include tax?",
      ratesAutoUpdated: "Rates are automatically updated & set.",
      saveFailed: "Failed to save the tax configuration! Please try again.",
      standardRate: "Tarifa padrão",
      taxRateZones: "Tax rate zones",
      taxRates: "Tax rates",
      title: "Imposto",
      zipAlreadyExists: "The provided ZIP code has already been added",
      zipInvalid: "The provided ZIP code does not appear to be a valid US postal ZIP code"
    },
    updateFailed: "Falha ao salvar suas preferências de notificação. Por favor, tente novamente.",
    updatedSuccessfully: "Preferências de notificação atualizadas com sucesso.",
    viewStorefront: "Ver loja"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "Adicionar uma zona",
    confirmDeleteZone: "Are you sure you want to delete this zone?",
    countriesRegions: "Countries & regions",
    countriesRegionsHelp: "Please note that changes to this panel will not be saved until the shipping zone is saved.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "Editar zona: {name}",
    enableOnProductsInfo: "If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.",
    enableOnProductsTitle: "Enable on all products",
    introSplash: {
      description: "A shipping zone is added by default as an example. Once you've added additional shipping zones, save and enable for the zones to be available for your products. Shipping zones can also be selected in the {productPage}.",
      title: "Add shipping zones for your products"
    },
    noCountriesRegions: "No countries or regions have been added to your zone. Start adding countries or regions to your zone before saving.",
    noCountriesRegionsTitle: "No countries or regions have been added",
    numCountries: "No. of countries",
    numRegions: "No. of regions",
    productPage: "product edit page",
    rateNameRequired: "The rate name is required",
    ratePriceRequired: "The rate price is required. For free rates use 0.",
    ratesLeadIn: "Increases to these rates can also be added in the {productPage}.",
    saveFailure: "Sorry, we're having a problem saving your shipping zone.",
    saveSuccess: "Your shipping zone has been saved successfully.",
    selectRegion: "Configure regions",
    shippingZones: "Shipping zones",
    zone: "Zona",
    zoneDeleteFailed: "Something went wrong while trying to delete this zone. Please try again.",
    zoneDeleted: "Zone deleted.",
    zoneName: "Nome da zona",
    zoneNameDescription: "The zone name is for internal use only and customers will not see this.",
    zoneNameRequired: "The zone name is required"
  },
  signup: {
    error: "Algo deu errado ao criar sua conta. Verifique seus dados e tente novamente.",
    form: {
      experience: "Assim, podemos fornecer-lhe a melhor experiência possível.",
      merchantDetails: "Detalhes do comerciante",
      merchantEmail: "Email do comerciante",
      merchantEmailDescription: "Este email é visível para seus clientes. Você também receberá notificações de pedidos aqui.",
      merchantName: "Nome do comerciante",
      merchantNameDescription: "Em qual nome você está vendendo?",
      next: "Próximo",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "Salvando..."
    },
    intro: {
      text: "Crie sua conta em alguns passos e comece a usar o Commerce.js.",
      title: "Bem-vindo, vamos configurar sua conta"
    },
    page: {
      title: "Configuração da conta"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Algo mais",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Marketing",
      other: "Outro",
      owner: "Dono do negócio",
      solution_architect: "Solution Architect",
      student: "O Aluno/A Aluna"
    }
  },
  slack: {
    alreadyJoined: "Já faz parte?",
    description: "Nossa comunidade de desenvolvedores e designers de eCommerce.",
    error: {
      alreadyInTeam: "Este endereço de email já está na nossa comunidade!",
      alreadyInvited: "Este endereço de email tem um convite pendente. Verifique o seu email!",
      invalidEmail: "Você deve fornecer um endereço de email válido",
      missingEmail: "Você deve fornecer um endereço de email!"
    },
    getMyInvite: "Receba meu convite",
    inviteSent: "Convite enviado! Verifique o seu email.",
    join: "Junte-se à comunidade do Slack",
    joinCommerceJs: "Junte-se ao Commerce.js no Slack",
    joinUs: "Junte-se a nós no Slack",
    openSlack: "Abrir o Slack",
    registeredCount: "{count} usuários registrados até agora.",
    slack: "Slack"
  },
  spaces: {
    storefront: "Vitrine"
  },
  status: {
    maintenance: "Serviço sob manutenção",
    major: "Interrupção do sistema parcial",
    minor: "Pequena interrupção de serviço",
    none: "Todos os sistemas operacionais"
  },
  validation: {
    enterEmail: "Por favor preencha seu email",
    enterName: "Por favor, insira um nome",
    enterOption: "Por favor, insira o nome da opção",
    enterPrice: "Por favor insira um preço",
    enterQuantity: "Por favor insira a quantidade",
    enterUrl: "Por favor, insira a URL",
    enterValue: "Please enter an attribute value",
    generic: "Insira um valor para {field}",
    number: "Introduza um número",
    positiveNumber: "Insira um número positivo",
    typeRequired: "Please enter a type",
    validBusinessName: "Please enter a business name",
    validCity: "Insira uma cidade",
    validCountry: "Por favor, selecione o país",
    validEmail: "Por favor insira um endereço de email válido",
    validNumber: "Insira um número válido",
    validPostalZipCode: "Please enter a postal/zip code",
    validPrice: "Por favor, insira um preço válido",
    validRegion: "Please select a province/state",
    validStreet: "Please enter a street name & number",
    validSupportEmail: "Por favor introduza o e-mail",
    validUrl: "Por favor, insira uma URL válida"
  },
  versions: {
    current: "{version} - Actual"
  },
  webhooks: {
    add: "Adicionar webhook",
    added: "Webhook adicionado",
    confirmDelete: "Você tem certeza que deseja deletar esse webhook?",
    confirmRegenerateKey: "Você tem certeza que deseja recriar a chave de assinatura? Isso vai afetar todos os webhooks.",
    copiedKey: "Copiado!",
    copyKey: "Copiar chave",
    created: "Criado em",
    "delete": "Deletar webhook",
    deleteFailed: "Falha ao deletar webhook!",
    deleted: "Webhook deletado.",
    deliveryDetails: "Detalhes da entrega",
    deliveryHistory: "Histórico de entrega",
    edit: "Editar webhook",
    event: "Evento",
    eventName: "Nome do evento",
    events: "Eventos",
    generateKey: "Gerar nova chave",
    introSplash: {
      description: "Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.",
      more: "Saiba mais",
      title: "Add a webhook to extend the possibilities of your business."
    },
    regenerateKeyFailed: "Não foi possível gerar uma nova chave de assinatura!",
    responseBody: "Response body",
    responseCode: "Código de resposta HTTP: {code}",
    save: "Salvar webhook",
    saveFailed: "Falha ao salvar o webhook! Por favor, tente novamente.",
    saved: "Mudanças salvadas.",
    sendTestRequest: "Enviar requisição de teste",
    sendingTestRequest: "Enviando requisição de teste...",
    signingKey: "Chave de assinatura",
    testRequestCallbackFailed: "Requisição enviada mas a resposta falhou.",
    testRequestFailed: "Falha ao enviar requisição de teste!",
    testRequestSuccess: "Requisição de teste enviada com sucesso..",
    view: "Ver webhook",
    webhook: "Webhook",
    webhooks: "Webhooks"
  }
};