export default {
  account: {
    account: "Konto",
    currentPassword: "Aktuelles Passwort",
    dashboardLanguage: "Dashboard-Sprache",
    deleteAccount: "Konto löschen",
    deleteCallout: "Du bist dabei, dein Konto endgültig zu löschen.",
    deleteFailed: "Dein Konto konnte nicht gelöscht werden! Versuche es später noch einmal.",
    deleteMessage: "Das Löschen deines Kontos ist dauerhaft und kann nicht rückgängig gemacht werden. Bitte stelle sicher, dass du alle Bestellungen exportiert hast.",
    deleteMyAccount: "Meinen Account löschen",
    details: "Kontodetails",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "Kontoinformationen",
    language: "Sprache",
    logout: "Abmelden",
    missingPasswords: "Bitte gib deine aktuellen und gewünschten neuen Passwörter ein.",
    newPassword: "Neues Passwort",
    passwordUpdateFailed: "Dein Passwort konnte nicht aktualisiert werden. Bitte versuche es noch einmal.",
    passwordUpdated: "Passwort erfolgreich aktualisiert",
    updateDetails: "Angaben aktualisieren",
    updateFailed: "Deine Kontoinformationen konnten nicht gespeichert werden. Bitte versuche es noch einmal.",
    updateLanguage: "Sprache aktualisieren",
    updatePassword: "Passwort aktualisieren",
    updatedDetailsSuccessfully: "Details wurden erfolgreich aktualisiert.",
    updatedLanguageSuccessfully: "Die Sprache wurde erfolgreich aktualisiert."
  },
  address: {
    city: "Stadt",
    country: "Land",
    line1: "Adresszeile 1",
    line2: "Adresszeile 2",
    name: "Name",
    state: "Bundesland",
    zipCode: "Postleitzahl"
  },
  attribute: {
    attributes: "Attribute"
  },
  attributes: {
    add: "Attribut hinzufügen",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Attribute",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Standartwert",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "Art des Feldes ist erforderlich",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Etwas ist schief gelaufen. Bitte melde dich erneut an.",
    unableToAuth: "Dein Konto kann nicht autorisiert werden. Bitte versuche es noch einmal."
  },
  billing: {
    accruedFees: "Angefallene Transaktionsgebühren",
    addCard: "Karte hinzufügen",
    addPaymentMethod: "Zahlungsmethode hinzufügen",
    billing: "Rechnung",
    changePlan: "Tarif ändern",
    checFee: "Chec Gebühr ({currency})",
    choosePlan: "Tarif auswählen",
    confirmChangePlan: "Wenn der Tarif, zu dem du wechselst, Beschränkungen für Plattformfunktionen (wie Commerce Rays) aufweist, werden deine Ressourcen automatisch an den Rahmen deines neuen Tarifs angepasst.",
    currentPlan: "Aktueller Tarif",
    customPricing: "Individuelle Preisgestaltung",
    emailAddress: "Rechnungs-E-Mail-Adresse",
    invoiceAmount: "Betrag",
    invoiceDate: "Datum",
    invoiceDescription: "Beschreibung",
    invoiceDownload: "Herunterladen",
    invoiceStatus: "Status",
    invoices: "Rechnungen",
    monthlyCeiling: "{ceiling} Monatliche Höchstgrenze",
    noPaymentMethod: "Kein Zahlungsmittel",
    orderValue: "Bestellwert ({currency})",
    overageFee: "{fee} Überschreitungsgebühr",
    paid: "Bezahlt",
    paymentGateway: "Zahlungsportal",
    paymentMethod: "Zahlungsmethode",
    paymentMethodRequired: "Bitte füge eine Zahlungsmethode hinzu, bevor du neue Tarife auswählst",
    perMonth: "pro Monat",
    perTransaction: "pro Transaktion",
    planDetails: "Tarifdetails",
    planMonthlyCeiling: "{ceiling} Monatliches Limit",
    planName: "{name} Tarif",
    planOverage: "{overage} Überschreitungsgebühr",
    planSummary: "Ihnen werden {perMonth} pro Monat und {perTransaction} pro Transaktion in Rechnung gestellt",
    planTransactionFee: "{fee} Transaktionsgebühr",
    transactionFeeOrder: "Reihenfolge",
    transactionFeesTitle: "Transaktionsgebühren werden am {date} in Rechnung gestellt",
    unpaid: "Unbezahlt",
    updateCard: "Aktualisieren",
    updateEmail: "Rechnungs-E-Mail-Adresse aktualisieren",
    viewInvoice: "Rechnung anzeigen",
    voided: "Ungültig"
  },
  category: {
    add: "Kategorie hinzufügen",
    addSubCategory: "Unterkategorie hinzufügen",
    added: "Kategorie hinzugefügt.",
    addedSubCategory: "Unterkategorie hinzugefügt.",
    categories: "Kategorien",
    chooseParentCategory: "Übergeordnete Kategorie wählen",
    confirmDelete: "Möchtest du diese Kategorie wirklich löschen?",
    confirmDeleteTitle: "Bist du sicher?",
    create: "Kategorie erstellen",
    createError: "Es tut uns leid, aber wir haben Probleme beim Erstellen dieser Kategorie.",
    deleteFailed: "Deine Kategorie konnte nicht gelöscht werden! Bitte versuche es noch einmal.",
    deleted: "Kategorie gelöscht",
    edit: "Kategorie bearbeiten",
    editMultiple: "Kategorien bearb.",
    images: {
      title: "Bildergalerie",
      types: "| {types} akzeptiert | {types} & {lastType} akzeptiert"
    },
    introSplash: {
      description: "Erstelle unendlich verschachtelbare Produktkategorien mit Kategoriebildern und Beschreibungen. Erfahre mehr. {more}.",
      more: "Weitere Informationen",
      title: "Füge Kategorien und Unterkategorien hinzu."
    },
    noAvailableCategories: "Bitte füge zuerst eine weitere Kategorie hinzu",
    noSubCategories: "Diese Kategorie hat keine Unterkategorien.",
    permalink: "Permalink",
    pleaseEnterName: "Bitte gib einen Namen ein",
    pleaseEnterPermalink: "Bitte gib einen Permalink ein",
    save: "Kategorie speichern",
    selectPlaceholder: "Kategorie wählen",
    subCategories: "Unterkategorien",
    update: "Kategorie aktualisieren",
    updateError: "Entschuldigung, wir haben Probleme beim Speichern dieser Kategorie.",
    updateSubCategoryError: "Leider haben wir Probleme beim Speichern dieser Unterkategorie.",
    updated: "Die Kategorie wurde aktualisiert."
  },
  customer: {
    add: "Kunden hinzufügen",
    added: "Kunde wurde hinzugefügt.",
    confirmDelete: "Möchtest du diesen Kunden wirklich löschen?",
    create: "Kunde erstellen",
    createError: "Es tut uns leid, aber wir haben Probleme bei der Erstellung dieses Kunden.",
    customers: "Kunden",
    deleteFailed: "Deine Kundschaft konnte nicht gelöscht werden! Bitte versuche es noch einmal.",
    deleted: "Kunde wurde gelöscht.",
    edit: "Kunden bearbeiten",
    emailTaken: "Diese E-Mail-Adresse wird bereits verwendet",
    externalId: "Externe ID",
    introSplash: {
      description: "Füge manuell Kundschaft hinzu oder warte auf einen Verkauf. Füge eine externe ID hinzu, wenn du Kundschaft über ein CRM verwaltest.",
      title: "Deine Kundschaft wird hier angezeigt"
    },
    notesTitle: "Notizen",
    save: "Kunde speichern",
    update: "Kunde aktualisieren",
    updateError: "Es tut uns leid, aber wir haben Probleme, diesen Kunden zu speichern.",
    updated: "Kunde wurde aktualisiert."
  },
  developer: {
    apiKeys: "API Schlüssel",
    apiKeysCors: "API-Schlüssel & CORS",
    checCli: "Chec CLI",
    cliNotice: "Verwende die {cli}, um Logs in Echtzeit zu streamen: {cliCommand}",
    copyCodeFailure: "Der Code konnte nicht in die Zwischenablage kopiert werden. Bitte aktualisiere die Seite und versuche es erneut.",
    copyCodeSuccess: "Der Code wurde in deine Zwischenablage kopiert.",
    corsDomainsHelp: "Du kannst eine Liste von vertrauten Domains (inklusive Schema und Port) für CORS Header konfigurieren, wenn öffentliche API-Schlüssel benutzt werden. Wenn du eine Domain angibst, werden alle API-Antworten diese Domain im \"Access-Control-Allow-Origin\"-Header nutzen. Wenn du mehrere angibst, werden diese bei API-Aufrufen mit dem \"Origin\"-Header der Anfrage verglichen. Lässt du das Feld frei, sind standardmäßig alle Urspünge erlaubt.",
    crossOriginResourceSharing: "Ursprungsübergreifende Ressourcenfreigabe (CORS)",
    dateTime: "Datum / Zeit",
    ipAddress: "IP-Adresse",
    keyUsed: {
      label: "Verwendeter Schlüssel",
      "public": "Öffentlich",
      secret: "Geheimnis"
    },
    method: "Methode",
    request: "Request",
    requestMethod: {
      "delete": "DELETE",
      get: "GET",
      label: "Request-Methode",
      post: "POST",
      put: "PUT"
    },
    response: "Antwort",
    responseDate: {
      label: "Antwortdatum"
    },
    responseStatus: {
      badRequest: "Request ungültig",
      label: "Antwortstatus",
      notFound: "Nicht gefunden",
      ok: "Ok",
      other: "Sonstige/Serverfehler",
      requestFailed: "Request fehlgeschlagen",
      unauthorized: "Unautorisiert",
      validation: "Validierung"
    },
    summary: "Zusammenfassung",
    url: "URL:"
  },
  discount: {
    add: "Rabatt hinzufügen",
    added: "Rabatt hinzugefügt.",
    all: "Alle",
    code: "Code:",
    codeRequired: "Rabattcode ist erforderlich",
    confirmDelete: "Möchtest du diesen Rabatt wirklich löschen?",
    create: "Rabatt erstellen",
    deleteFailed: "Dein Rabatt konnte nicht gelöscht werden! Bitte versuche es noch einmal.",
    deleted: "Der Rabatt wurde gelöscht.",
    discountCode: "Aktionscode",
    discountOffProduct: "{discount} auf {product}",
    discounts: "Rabatte",
    edit: "Rabatt bearbeiten",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "Weitere Informationen",
      title: "Füge einen Rabattcode hinzu und lass eine Promo laufen."
    },
    neverExpires: "Nie",
    productTooltip: "Wenn es leer gelassen wird, funktioniert dieser Rabatt für alle Produkte",
    productsLabel: "Auf diese Produkte beschränken",
    saveError: "Es tut uns leid, aber wir haben Probleme, diesen Rabatt zu speichern.",
    updated: "Der Rabatt wurde aktualisiert.",
    valueRequired: "Bitte trage den Rabattwert ein"
  },
  error: {
    notFoundSuggestion: "Navigiere zurück zu der Seite, von der du gekommen bist, und versuche es erneut.",
    notFoundTitle: "Nicht gefunden",
    permissionErrorSuggestion: "Versuche dich auszuloggen und melde dich wieder an. Wenn das Problem weiterhin besteht, wende dich bitte an den Support.",
    permissionErrorTitle: "Erlaubnis verweigert",
    serverErrorSuggestion: "Schnapp‘ dir einen Kaffee und versuche es in ein paar Minuten erneut. Wir wurden auch über diesen Fehler informiert.",
    serverErrorTitle: "Etwas ist schiefgelaufen",
    somethingWentWrong: "Ups. Etwas ist schief gelaufen!"
  },
  extraFields: {
    add: "Extra-Feld hinzufügen",
    addOptions: "Drücke Enter oder gib einen Beistrich ein, um eine Option hinzuzufügen",
    collectsBilling: "Rechnungsadresse verlangen",
    collectsFullName: "Vollständigen Namen verlangen",
    collectsShipping: "Lieferadresse verlangen",
    confirmDeleteExtraField: "Bist ud sicher, dass du dieses Extra-Feld löschen möchtest? Schaufenster, die dieses Extra-Feld benötigen, können betroffen sein.",
    deleteFailed: "Extra-Feld konnte nicht gelöscht werden! Bitte versuche es noch einmal.",
    deleted: "Extra-Feld wurde erfolgreich gelöscht.",
    description: "Sammle benutzerdefinierte Informationen von deinen Kunden.",
    edit: "Extra-Feld bearbeiten",
    extraFields: "Zusätzliche Felder",
    fieldType: "Art des Felds",
    fieldTypeDescription: "Wähle die Art des Feldes für dein Extra-Feld aus. Dies ist für den Frontend-Einsatz.",
    introDescription: "Extra-Felder sind Shop-spezifische Datenfelder, die global verfügbar sind, normalerweise in einem Checkout. Ein Beispiel könnte ein spezifisches Extra-Feld sein, um die Telefonnummern deiner Kundschaft in einem Checkout-Formular zu sammeln.",
    introTitle: "Sammle spezifische Daten von deiner Kundschaft mit globalen Extra-Feldern.",
    name: "Extrafeld Name",
    nameRequired: "Name erforderlich",
    saveFailure: "Leider haben wir ein Problem beim Speichern deines Extra-Feldes.",
    saveSuccess: "Dein Extra-Feld wurde erfolgreich gespeichert.",
    typeRequired: "Art des Feldes ist erforderlich",
    updateSuccess: "Dein Extra-Feld wurde erfolgreich aktualisiert."
  },
  gateways: {
    add: "Zahlungsportal hinzufügen",
    braintree: "Braintree",
    braintreeDocumentation: "Dokumentation ansehen",
    braintreeHelp: "Gib deine Braintree-Händler-ID, öffentlichen und privaten Schlüssel, und deinen Token-Schlüssel ein. {documentation} für weitere Informationen zu Token-Schlüsseln.",
    chooseGateway: "Bitte wähle ein Portal",
    confirmDelete: "Bist du sicher, dass du dieses Zahlungsportal löschen willst? Alle Shops, die es verwenden, werden Zahlungen von diesem Portal nicht mehr akzeptieren.",
    deleteFailed: "Beim Versuch, dieses Zahlungsportal zu löschen, ist etwas schief gelaufen. Bitte versuche es noch einmal.",
    deleted: "Zahlungsportal wurde gelöscht.",
    edit: "Zahlungsportal bearbeiten: {gateway}",
    email: "E-Mail-Adresse",
    gateway: "Portal",
    introDescription: "Ein Zahlungsportal autorisiert die Abwicklung von Kreditkarten- oder Direktzahlungen für Online-Unternehmen. Das Test-Portal wurde für Testaufträge aktiviert.",
    introTitle: "Fange an Zahlungen zu erhalten, indem du Zahlungsportale aktivierst.",
    keyId: "Schlüssel-ID",
    keySecret: "Geheimer Schlüssel",
    manual: "Manuell",
    manualConfig: {
      detailsHelp: "Details zu dieser Zahlungsmethode (wird vor der Bestellung angezeigt).",
      name: "Name der Zahlungsmethode",
      paymentInstructions: "Anweisungen zur Zahlung",
      paymentInstructionsHelp: "Details zur Zahlung (wird deiner Kundschaft nach der Bestellung angezeigt)."
    },
    manualHelp: "Du musst Zahlungen und Bestellungen, die mit dieser Methode aufgegeben wurden, händisch abwickeln.",
    manualName: "Manuell: {name}",
    merchantId: "Händler-ID",
    omise: "Omise",
    omiseDocumentation: "Omise-Dokumentation",
    omiseHelp: "Gib deinen öffentlichen und geheimen Schlüssel ein, beide sind im Omise-Dashboard verfügbar. Erfahre mehr in der {documentation}.",
    paymentMethodHelper: "Um ein aktives Zahlungsportal hinzuzufügen, füge bitte eine Zahlungsmethode auf der Rechnungseinstellungsseite hinzu.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "Privater Schlüssel",
    publicKey: "Öffentlicher Schlüssel",
    publishableKey: "Publizierbarer Schlüssel",
    razorpay: "Razorpay",
    redirectingToSquare: "Weiterleitung zu Square",
    sandboxNotAvailable: "Der Sandbox-Modus ist für dieses Portal nicht verfügbar.",
    saveFailed: "Dein Zahlungsportal konnte nicht gespeichert werden. Überprüfe bitte, ob du alle erforderlichen Felder ausgefüllt hast.",
    saved: "Dein Zahlungsportal wurde erfolgreich gespeichert.",
    secretKey: "Geheimschlüssel",
    square: "Square",
    squareHelp: "Wenn du unten auf „Änderungen speichern“ klickst, wirst du zu Square weitergeleitet, um dich bei deinem Zahlungskonto anzumelden.",
    stripe: "Stripe",
    stripeDocumentation: "Stripe-Dokumentation.",
    stripeHelp: "Gib deinen publizierbaren Schlüssel und deinen geheimen Schlüssel ein, die beide im Stripe-Dashboard verfügbar sind. Erfahre mehr in der {documentation}",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "Test-Portal",
    tokenizationKey: "Token-Schlüssel",
    transactionVolume: "Zahlungsvolumen",
    weSupport: "Portale, die wir unterstützen"
  },
  general: {
    about: "Über",
    actions: "Aktionen",
    active: "Aktiv",
    add: "Hinzufügen",
    adding: "Wird hinzugefügt…",
    address: {
      city: "Stadt",
      country: "Land",
      postalZip: "Postleitzahl",
      provinceState: "Bundesland/Kanton",
      street: "Straße"
    },
    advanced: "Erweitert",
    all: "Alle",
    amount: "Betrag",
    amountValue: "Betrag – {value}",
    andMore: "& {n} Mehr",
    areYouSure: "Bist du sicher?",
    assets: "Aktiva",
    auto: "Auto",
    backTo: "Zurück zu {page}",
    backToPreviousPage: "Zurück zur vorherigen Seite",
    byAuthor: "von {author}",
    calculation: "Berechnung",
    cancel: "Abbrechen",
    cancelled: "Abgebrochen",
    checkbox: "Kontrollkästchen",
    checked: "Aktiviert",
    clearSearch: "Suche zurücksetzen",
    clickToCopy: "Zum Kopieren anklicken",
    close: "Schließen",
    contactUs: "Kontaktiere uns",
    copied: "Kopiert",
    copy: "Kopieren",
    country: "Land",
    creating: "Wird erstellt…",
    currency: "Währung",
    date: "Datum",
    dateTime: "Datum / Zeit",
    "default": "Standard",
    "delete": "Löschen",
    description: "Beschreibung",
    descriptionOptional: "Beschreibung (optional)",
    details: "Details",
    digital: "Digital",
    disable: "Deaktivieren",
    disabled: "Deaktiviert",
    discount: "Rabatt",
    draft: "Entwurf",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    emailAddress: "E-Mail-Adresse",
    empty: "Leer",
    enable: "Aktivieren",
    enabled: "Aktiviert",
    environment: "Umgebung",
    error: "Sorry, es ist ein Fehler aufgetreten. Bitte versuche es noch einmal.",
    expired: "Abgelaufen",
    expires: "Läuft ab",
    "false": "Falsch",
    firstName: "Vorname",
    "for": "für",
    fulfill: "Ausführen",
    fulfilled: "Ausgeführt",
    generate: "Generieren",
    getStarted: "Loslegen",
    goBack: "Zurück",
    health: "Gesundheit",
    hidden: "Verborgen",
    home: "Startseite",
    hostedExperiences: "Hosted experiences",
    id: "ID",
    images: "Bilder",
    inactive: "Inaktiv",
    item: "Artikel",
    label: "Etikett",
    lastName: "Nachname",
    learnMore: "Mehr erfahren",
    learnMoreButton: "Weitere Informationen",
    live: "live",
    loading: "Wird geladen…",
    loggedInAs: "Angemeldet als: {name}",
    logo: "Logo",
    longText: "Langer Text",
    merchant: "Händler",
    month: {
      april: "April",
      august: "August",
      december: "Dezember",
      february: "Februar",
      january: "Januar",
      july: "Juli",
      june: "Juni",
      march: "März",
      may: "Mai",
      november: "November",
      october: "Oktober",
      september: "September"
    },
    name: "Name",
    never: "Nie",
    "new": "Neu",
    next: "Weiter",
    nextName: "Weiter: {name}",
    no: "Nein",
    noResults: "Keine Ergebnisse entsprechen Ihrer Suche",
    notAvailable: "n. a.",
    notFulfilled: "Nicht ausgeführt",
    notPaid: "Nicht bezahlt",
    notRequired: "Nicht erforderlich",
    notes: "Notizen",
    number: "Zahl",
    open: "Öffnen",
    option: "Option",
    options: "Optionen",
    override: "Überschreiben",
    paid: "Bezahlt",
    parent: "Übergeordneter Artikel",
    partiallyFulfilled: "Teilweise ausgeführt",
    paymentGateway: "Zahlungsportal",
    permalinkSlug: "Permalink/SLUG",
    phoneNumber: "Telefonnummer",
    placed: "Aufgegeben",
    previous: "Zurück",
    previousName: "Zurück: {name}",
    price: "Preis",
    "private": "Privat",
    province: "Kanton",
    published: "Veröffentlicht",
    quantity: "Menge",
    radio: "Radio",
    rate: "Tarif",
    rates: "Tarife",
    reference: "Referenz",
    refund: "Rückerstattung",
    refunded: "Erstattet",
    refunding: "Wird erstattet",
    regenerate: "Regenerieren",
    region: "Region",
    regions: "Regionen",
    remaining: "Verbleibend",
    required: "Benötigt",
    sales: "Verkäufe",
    sandbox: "Sandbox",
    sandboxMode: "Sandbox-Modus",
    save: "Speichern",
    saveAndClose: "Speichern und schließen",
    saveChanges: "Änderungen speichern",
    saved: "Änderungen gespeichert",
    saving: "Speichern…",
    search: "Suchen…",
    security: "Sicherheit",
    selectOption: "Wählen Sie eine Option aus...",
    sending: "Wird gesendet…",
    shareEmbed: "Teilen/einbetten",
    shipping: "Versand",
    shippingMethod: "Shipping ({method})",
    shortText: "Kurzer Text",
    slug: "Slug",
    standard: "Standard",
    startDate: "Startdatum",
    state: "Bundesland",
    status: "Status",
    subtotal: "Zwischensumme",
    tax: "Steuer",
    template: "Vorlage",
    text: "Text",
    time: "Zeit",
    timestamp: "Zeitstempel",
    today: "Heute",
    total: "Gesamt",
    totalPaid: "Insgesamt bezahlt",
    "true": "WAHR",
    type: "Typ",
    unchecked: "Deaktiviert",
    unfulfilled: "Unausgeführt",
    unlimited: "Unlimited",
    update: "Aktualisieren",
    upgradePlan: "Verbessere deinen Tarif",
    url: "URL:",
    value: "Wert",
    view: "Anzeigen",
    viewDetails: "Details anzeigen",
    visibility: "Sichtbarkeit",
    visible: "Sichtbar",
    whatIsThis: "Was ist das?",
    xOfY: "{x} von {y}",
    yes: "Ja",
    yesterday: "Gestern"
  },
  home: {
    addProducts: "Füge deine Produkte hinzu",
    configureShipping: "Stell deinen Versand ein",
    configureTax: "Stell deinen Steuersatz ein",
    createCommerceRay: "Erstelle deinen ersten Commerce Ray",
    enableGateway: "Aktiviere ein Zahlungsportal",
    gettingStarted: "Erste Schritte",
    gettingStartedRays: "Erstelle deinen Commerce Ray",
    latestAnnouncements: "Aktuelle Ankündigungen",
    latestChanges: "Aktuelle Änderungen",
    messageUs: "Nachricht an uns",
    speakToAnExpert: "Sprich mit einem Experten",
    speakToCopy: "Unsere E-Commerce-Fachleute können sicherstellen, dass Commerce.js für dein Unternehmen geeignet ist und dir helfen, eine Lösung zu entwickeln.",
    title: "Willkommen",
    viewChangelog: "Changelog anzeigen",
    welcomeSubtitle: "Willkommen! Wir sind hier, um dir Starthilfe zu geben."
  },
  integration: {
    about: "Über",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration hinzugefügt",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Konfigurieren der Integration",
    confirmDelete: "Bist du sicher, dass du diese Integration löschen möchtest?",
    createError: "Leider sind Probleme beim Aktivieren dieser Integration aufgetreten.",
    creating: "Creating integration...",
    deleteFailed: "Deine Integration konnte nicht gelöscht werden! Versuch es später noch einmal.",
    deleted: "Integration wurde gelöscht.",
    edit: "Edit integration",
    editNamed: "Edit integration - {name}",
    filter: {
      checkout: "Zur Kasse",
      content: "Inhalt",
      dataMesh: "Data mesh",
      email: "E-Mail",
      frontend: "Frontend",
      fulfillment: "Ausführung",
      orders: "Bestellungen",
      payments: "Zahlungen",
      personalization: "Personalisierung",
      tax: "Steuer"
    },
    filterLabel: "Filtern",
    filterTagLabel: "Filtern {tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "Integration",
    integrations: "Integrationen",
    introSplash: {
      description: "Verbinde andere Dienste und erstelle deinen gewünschten Business-Stack.",
      title: "Konfiguriere Integrationen mit Drittanbietern."
    },
    lastRun: "Letzter Lauf",
    noConfigNeeded: "Diese Vorlage braucht keine zusätzliche Konfiguration",
    noLogo: "Kein Logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "Gesund",
      notApplicable: "n. a.",
      unhealthy: "Ungesund",
      unstable: "Instabil"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API-Request-Logs mit Details werden hier aufgelistet, um das Debuggen zu vereinfachen.",
      title: "Deine Request-Logs werden hier angezeigt"
    },
    logs: "Logs"
  },
  merchant: {
    createFull: "Lass uns deinen Shop einrichten",
    createLeadIn: "Deine Daten können jederzeit geändert oder aktualisiert werden.",
    createMerchant: "Shop erstellen",
    seeAll: "Alle Händler ansehen",
    selectMerchant: "Wähle einen Shop aus"
  },
  notes: {
    add: "Notiz hinzufügen",
    addANote: "Notiz hinzufügen",
    confirmDelete: "Seid ihr sicher, dass ihr diese Notiz löschen wollt?",
    note: "Hinweis"
  },
  order: {
    capturedOn: "Erfasst:",
    cardEndingIn: "Kartennummer endet auf {number}",
    changeCustomer: "Kundeninformationen ändern",
    confirmRefund: "Möchtest du diese Bestellung wirklich rückerstatten?",
    customerDetails: "Angaben zum Kunden",
    customerUpdateFailed: "Es tut uns leid, aber wir haben Probleme beim Aktualisieren der Kundeninformationen.",
    customerUpdated: "Kundeninformationen zur Bestellung # {reference} aktualisiert",
    date: "Bestellungsdatum",
    dateTime: "Datum / Zeit",
    deleteTest: "Testbestellungen löschen",
    deleteTestConfirmation: "Bist du sicher, dass du alle Testbestellungen löschen möchtest?",
    edit: "Bestellung bearbeiten",
    "export": "Bestellungen exportieren",
    exportConfirmation: "Wir senden eine Kopie deiner Bestellungen (mit den aktuellen Filtern) im CSV-Format an deine E-Mail, wenn sie fertig ist.",
    fulfillItems: "Posten ausführen",
    fulfillment: {
      confirmCopy: "Beim Bestätigen wird eine E-Mail mit der Aktualisierung des Bestellstatus inkl. Sendungsverfolgungsnummer (falls enthalten) an deine Kundschaft gesendet. Diese Änderungen können nicht rückgängig gemacht werden. Ist das okay?",
      confirmHeader: "Bitte bestätige deine Änderungen an der Bestellungsausführung",
      dateShipped: "Versanddatum: {date}",
      reference: "Referenz: {reference}",
      shipmentNumber: "Sendungsnr. {number}",
      shippingCarrier: "Versandunternehmen",
      trackingNotProvided: "Tracking nicht vorgesehen",
      trackingNumber: "Sendungsverfolgungsnr. {number}",
      trackingNumberOptional: "Tracking-Nummer (optional)"
    },
    fulfillmentStatus: {
      fulfilled: "Ausgeführt",
      label: "Ausführungsstatus",
      not_fulfilled: "Nicht ausgeführt",
      partially_fulfilled: "Teilweise ausgeführt",
      returned: "Retourniert"
    },
    gatewayTransactionId: "Portal-Transaktionsnummer: {id}",
    itemFulfilled: "Artikel erfolgreich ausgeführt.",
    newNotification: "Neue Bestellung {reference} für {value}!",
    nextOrder: "Nächste Bestellung",
    noOrders: {
      copy: "Wir zeigen dir dann Bestellungen und Verkäufe, die im Laufe der Zeit angezeigt werden.",
      title: "Mache deinen ersten Verkauf!"
    },
    orderNotes: "Anmerkungen zur Bestellung",
    orderNumber: "Bestellnr.",
    orderStatus: "Bestellstatus",
    orders: "Bestellungen",
    ordersAllTime: "Bestellungen - Alle",
    ordersOnMonth: "Bestellungen - {month}",
    ordersOnMonthHelptip: "Anzahl der Bestellungen seit Beginn dieses Monats im Vergleich zum gleichen Zeitraum des Vormonats",
    overview: "Übersicht",
    paymentMethod: "Zahlungsmethode",
    paymentSaveFailed: "Sorry, wir haben Probleme, diese Zahlung zu speichern.",
    paymentSaved: "Die Zahlung wurde erfolgreich gespeichert.",
    paymentStatus: {
      label: "Zahlungsstatus",
      not_paid: "Nicht bezahlt",
      paid: "Bezahlt",
      partially_paid: "Teilweise bezahlt",
      pending: "Zahlung ausstehend",
      refunded: "Erstattet"
    },
    previousOrder: "Vorherige Bestellung",
    receiptSendFailed: "Die Quittung kann nicht erneut gesendet werden, bitte versuche es nochmal.",
    receiptSent: "Quittung gesendet.",
    recordManualPayment: "Zahlung händisch eintragen",
    reference: "Referenz: {reference}",
    refundFailed: "Die Bestellung kann nicht erstattet werden, bitte versuche es erneut.",
    refundSuccess: "Die Bestellung wurde erfolgreich erstattet.",
    resendReceipt: "Beleg erneut senden",
    salesAllTime: "Verkäufe - Alle",
    salesOnMonth: "Verkäufe - {month}",
    salesOnMonthHelptip: "Umsatzentwicklung seit Beginn dieses Monats im Vergleich zum gleichen Zeitraum des Vormonats",
    sandbox: "Testbestellung",
    shipments: "Sendungen",
    status: "Status",
    testOrdersDeleted: "Testbestellungen wurden erfolgreich gelöscht.",
    transactionReferenceId: "Transaktions-/Referenz-ID",
    updateAddressError: "Beim Speichern der Adresse ist etwas schief gelaufen, bitte versuche es erneut.",
    updatedBilling: "Rechnungsadresse wurde aktualisiert.",
    updatedShipping: "Lieferadresse wurde aktualisiert.",
    viewMultiple: "Bestellungen anzeigen",
    viewOne: "Sortieransicht"
  },
  product: {
    add: "Produkt hinzufügen",
    added: "Produkt wurde hinzugefügt",
    additionalActions: "Zusätzliche Bearbeitungsmöglichkeiten für das Produkt",
    basePrice: "Grundpreis",
    chooseRelatedProducts: "Wähle ähnliche Produkte",
    confirmDelete: "Möchtest du dieses Produkt wirklich löschen?",
    confirmDeleteTitle: "Bist du sicher?",
    copyPaste: "Kopiere diesen Code und füge ihn in deine Webseite ein.",
    count: "Keine Produkte | 1 Produkt | {n} Produkte",
    customPermalink: "Benutzerdefinierter Permalink",
    "delete": "Produkt löschen...",
    deleteFailed: "Das Produkt konnte nicht gelöscht werden! Bitte versuche es noch einmal.",
    deleted: "Das Produkt wurde gelöscht.",
    description: "Beschreibung",
    digitalFulfillment: {
      doesNotExpire: "Links laufen nicht ab",
      downloadLimit: "Download-Limit",
      duration: "Dauer",
      durationPeriod: "Dauer",
      expiryMessage: "Nach {duration} {period} des Kaufs",
      linkExpiration: "Ablaufzeit des Links",
      period: {
        days: "Tage | Tag | Tage",
        months: "Monate | Monat | Monate",
        weeks: "Wochen | Woche | Wochen"
      },
      title: "Digitale Lieferung"
    },
    duplicateProduct: "Produkt duplizieren",
    duplicated: "Das Produkt wurde erfolgreich dupliziert und du bearbeitest jetzt das Duplikat.",
    edit: "Produkt bearbeiten",
    embed: "Einbetten",
    embedCheckout: "Checkout einbetten",
    embedCheckoutDescription: "Bette deinen Checkout direkt in deine Website oder deinen Blog ein.",
    embedShareSell: "Einbetten, teilen, verkaufen!",
    images: {
      alert: "Die Galerie enthält alle Bilder, die zu diesem Produkt gehören, einschließlich seiner hinzugefügten Varianten.",
      title: "Galerie",
      types: "| {types} akzeptiert | {types} & {lastType} akzeptiert"
    },
    includesNCountries: "Beinhaltet ein Land | Beinhaltet {number} Länder",
    introSplash: {
      api: "Erstelle eine Produkt-API",
      description: "Füge dein Produkt und seine Varianten manuell über das Dashboard oder programmatisch über unsere {api} hinzu. Produkte müssen mindestens einen Namen und einen Preis haben. {more}.",
      more: "Weitere Informationen",
      title: "Füge dein erstes Produkt hinzu, digital oder materiell"
    },
    inventory: {
      available: "Verfügbares Inventar",
      managed: "Verwaltet",
      tooltip: "Wenn das Inventar verwaltet wird, kannst du die Anzahl der verfügbaren Artikel festlegen. Andernfalls ist es unbegrenzt."
    },
    lastSavedAt: "Zuletzt gespeichert",
    linkCustomers: "Benutze diese URL um deine Kundschaft direkt mit dem Checkout zu verbinden.",
    minimumPrice: "Mindestpreis",
    misc: "Sonstiges",
    name: "Name",
    noImage: "Kein Bild",
    noRelatedAvailable: "Du hast keine anderen Produkte",
    payWhatYouWant: "Bezahle was du willst",
    price: "Preis",
    product: "Produkt",
    products: "Produkte",
    pwywEnabled: "„Bezahle was du willst“ aktiviert",
    relatedProducts: "Ähnliche Produkte",
    saveFailed: "Das Produkt konnte nicht gespeichert werden! Bitte versuche es noch einmal.",
    saved: "Änderungen gespeichert",
    searchEngineDescription: "Beschreibung für Suchmaschinen",
    searchEngineTitle: "Titel für Suchmaschinen",
    seo: "SEO",
    shippingCard: {
      baseRateLabel: "Grundgebühr",
      baseRateTooltip: "Der Grundpreis für diesen Tarif. Darüber hinaus werden weitere Tarife hinzukommen.",
      nameLabel: "Name",
      onOwnLabel: "Alleinig",
      onOwnTooltip: "Der Preis für diesen Tarif, wenn nur dieser Artikel bestellt wird. Dieser wird so zum Basistarif addiert: Basistarif + Artikeltarif = Gesamtsumme der Versandkosten",
      title: "Optionen für den Versand",
      withOthersLabel: "Mit anderen",
      withOthersTooltip: "Der Preis für diesen Tarif bei Bestellungen mit anderen Artikeln. Wird mit anderen Artikeln und dem Basistarif hinzugefügt: Basistarif + Rate mit anderen (Preis für diesen Artikel) + Rate mit anderen (Preis eines anderen Artikels) = Gesamtsumme der Versandkosten"
    },
    sku: "Artikelnummer",
    sortOrder: "Sortierreihenfolge",
    sortOrderTooltip: "Niedrigere Werte lassen dein Produkt früher in Listen erscheinen, während höhere Werte es später auftauchen lassen.",
    thankYouPage: "Danke-Seite",
    variants: {
      addGroup: "Variantengruppe hinzufügen",
      addOption: "Auswahl (Bsp.: Rot, Grün, Blau, etc.)",
      addVariant: "Variante hinzufügen",
      amount: "Betrag",
      andMore: "Und {count} zusätzliche Varianten",
      bulkAdd: "Mehrere hinzufügen",
      bulkFailure: "Es tut uns leid, aber wir haben Probleme beim Aktualisieren deiner Varianten.",
      bulkSuccess: "Varianten wurden erfolgreich aktualisiert",
      chooseGroupsAndOptions: "Wähle Gruppen & Optionen",
      create: "Variante erstellen | Varianten erstellen",
      createBulk: "Erstellen von Massenvarianten",
      createSingle: "Einzelne Variante erstellen",
      defaultPrice: "Standardpreis",
      defaultPriceTooltip: "Der Standardpreis für die Variantenoption.",
      defaultVariantTitle: "Neue Variante",
      edit: "Varianten bearbeiten",
      editSingle: "Variante bearbeiten",
      editSingleNamed: "Variante bearbeiten: {name}",
      generateVariants: "Varianten generieren",
      groups: "Gruppen | Gruppe | Gruppen",
      hasInvalidVariants: "Dieses Produkt hat ungültige Varianten. Diese Varianten können nicht zu einem Einkaufswagen hinzugefügt und auch nicht verkauft werden. Um ungültige Varianten wieder zu aktivieren, bearbeite diese bitte und behebe alle Probleme.",
      help: "Verschiedene Arten dieses Produkts (z.B. Größe, Farbe)",
      image: "Bild",
      invalidReason: {
        duplicate_option_config: "Diese ungültige Variante teilt Optionen mit einer anderen Variante. Für eine bestimmte Reihe von Optionen kann es nur eine Variante geben.",
        incomplete_option_config: "Dieser ungültigen Variante fehlt eine Option aus einer der für dieses Produkt konfigurierten Variantengruppen. Varianten müssen für jede Gruppe einen Optionssatz haben."
      },
      invalidVariantsOnSave: "Sobald du gespeichert hast, werden deine Varianten als „ungültig“ markiert. Du kannst diese Varianten erst verkaufen, wenn du die Probleme behoben hast.",
      mustSave: "Du musst speichern, bevor du neue Varianten hinzufügst",
      noGroups: "Varianten helfen dir, ein Produkt in unterschiedlichen Ausführungen zu verkaufen. Zum Beispiel könntest du ein T-Shirt in verschiedenen Farben oder einen Blumentopf in verschiedenen Größen verkaufen. Du kannst Varianten so konfigurieren, dass sie ihren eigenen Preis, Artikelnummer und Lagerbestand haben.\n\nUm loszulegen, erstelle Gruppen und Optionen für deine Varianten. Gruppen definieren den Typ der Variante (z. B. Farbe). Optionen sind eine Auswahl, die deine Kundschaft innerhalb dieser Gruppe treffen kann (z. B. blau).",
      noGroupsTitle: "Richte Variantengruppen ein, um verschiedene Ausführungen desselben Produkts zu verkaufen",
      noOptions: "Bitte gib mindestens eine Option ein",
      noVariants: "Verwende die Buttons oben, um Varianten zu erstellen. Varianten sind Kombinationen aus Variantengruppen und Variantenoptionen. Du kannst den Preis und das Inventar für jede Variante individuell festlegen (falls aktiviert).",
      noVariantsTitle: "Es wurden keine Varianten erstellt",
      noVariantsToGenerate: "Für die ausgewählten Optionen gibt es bereits alle Varianten.",
      optionDefaultImage: "Standard-Bild",
      options: "Optionen | Option | Optionen",
      optionsInGroupsRequired: "Du musst mindestens eine Option in jeder Gruppe auswählen, um Varianten in großen Mengen zu generieren.",
      optionsUpdateFailure: "Sorry, wir haben Probleme beim Aktualisieren deiner Variantenoptionen.",
      optionsUpdateSuccess: "Variantenoptionen wurden erfolgreich aktualisiert",
      override: "Überschreiben",
      preview: "Vorschau der Variante",
      previewHelp: "Vorschau der Varianten, die basierend auf den oben ausgewählten Optionen erstellt werden.",
      quantity: "Menge",
      requiresGroupsSave: "Um diese Funktion verwenden, musst du zuerst die Gruppen speichern.",
      requiresSave: "Um diese Funktion verwenden, musst du das Produkt zuerst speichern.",
      saveAndGenerate: "Varianten speichern und generieren",
      selectOptions: "Wähle Optionen für deine Variante",
      singleCreateSuccess: "Variante wurde erfolgreich erstellt",
      singleUpdateFailure: "Sorry, wir haben ein Problem beim Speichern deiner Variante.",
      singleUpdateSuccess: "Variante wurde erfolgreich aktualisiert",
      title: "Varianten",
      variantDetails: "Details zur Variante",
      variantExists: "Es sieht so aus, als ob es diese Variante bereits gibt.",
      variantGroup: "Varianten-Gruppe",
      variantGroupName: "Gruppenname (zB Größe, Farbe, Material, etc.)",
      variantGroupTitle: "Variantengruppe: {group}",
      variantName: "Varianten-Bezeichnung",
      willInvalidateVariants: "Das Hinzufügen und/oder Entfernen von Variantengruppen macht deine vorhandenen Varianten ungültig, da jede Variante eine Option für jede Gruppe haben muss und nicht dupliziert werden kann."
    },
    viewProducts: "PRODUKTE ANSEHEN"
  },
  rays: {
    add: "Erstelle neues Ray",
    commerceRays: "Commerce Rays",
    confirmDelete: "Bist du sicher, dass du dieses Ray löschen möchtest?",
    confirmDeleteTitle: "Bist du sicher?",
    create: "Ray erstellen",
    "delete": "Ray löschen",
    deleteFailed: "Dein Ray konnte nicht gelöscht werden! Versuche es später noch einmal.",
    deleted: "Ray wurde gelöscht.",
    deploying: "Wird veröffentlicht ...",
    desktop: "Desktop",
    edit: "Ray bearbeiten",
    embed: "Einbetten",
    embedModal: "Du kannst deinen Checkout auch in ein modales Popup einbetten, das durch Klicken auf die Schaltfläche „Jetzt kaufen“ ausgelöst wird.",
    exit: "Beenden",
    images: {
      title: "Bilder",
      types: "| {types} akzeptiert | {types} & {lastType} akzeptiert"
    },
    introduction: {
      description: "Ein Seitengenerator ohne Code mit integrierten Checkouts.",
      "for": {
        campaigns: "Kampagnen",
        digitalProducts: "Digitale Produkte",
        influencers: "Influencer-Partnerschaften",
        mediaCheckouts: "Medien-Checkouts",
        ppc: "PPC-Kampagnen",
        productDrops: "Launch neuer Produkte",
        title: "Rays ist für",
        videoCommerce: "Video Commerce"
      },
      line1: "Alle können ansprechende Produkt- und Inhaltsseiten erstellen, die überzeugen.",
      line2: "Wähle aus unserer Auswahl an schönen, editierbaren Themes und verkaufe deine Brandstory.",
      title: "✨ Willkommen bei Commerce Rays"
    },
    lastDeployment: "Letzte Veröffentlichung:",
    mobile: "Mobil",
    nameHelper: "Zur internen Identifikation.",
    noProducts: "Du musst mindestens ein Produkt haben.",
    noRays: "Keine Rays gefunden.",
    noThemes: "Keine Themes gefunden",
    pickTheme: "Wähle eine Vorlage",
    planWarning: {
      limitReached: "Du hast das Limit deines Tarifs erreicht. Bitte {upgrade} um weitere zu veröffentlichen.",
      notice: "Dein aktueller Tarif unterstützt nicht die Verwendung von Rays. Bitte {upgrade} um zu veröffentlichen.",
      upgrade: "Upgrade deines Tarifs"
    },
    ray: "Ray",
    rayName: "Ray Bezeichnung",
    rays: "Rays",
    sandboxHelper: "Wenn du den Sandbox-Modus aktivierst, kannst du Stripe im Testmodus verwenden. Im Sandbox-Modus können keine echten Aufträge erstellt werden.",
    save: "Speichern",
    saveDeploy: "Speichern & veröffentlichen",
    saveFailed: "Dein Ray konnte nicht gespeichert werden! Bitte versuche es noch einmal.",
    saved: "Änderungen gespeichert",
    savedAndDeploying: "Änderungen gespeichert. Dein Ray wird veröffentlicht...",
    saving: "Speichern…",
    select: "Theme auswählen",
    selectProducts: "Produkte auswählen",
    validationFailed: "Es gibt ein Problem mit deinen gewählten Einstellungen. Bitte überprüfe die Seitenleiste auf Fehler.",
    viewPublishedRay: "Veröffentlichten Ray ansehen",
    viewRay: "Ray ansehen",
    viewportWarning: "Dieser Teil unseres Dashboards lässt sich am besten auf einem größeren Bildschirm erleben. Bitte melde dich auf deinem Computer an, um Rays zu sehen."
  },
  settings: {
    analytics: "Statistik",
    data: "Daten",
    developer: "Entwicklung",
    developerSettings: {
      addKey: "API-Schlüssel hinzufügen",
      apiKey: "API-Schlüssel",
      apiKeys: "API Schlüssel",
      apiVersion: "API-Version",
      changeKey: "Schlüssel ändern",
      changelog: "Changelogs ansehen",
      confirmDeleteKey: "Bist du sicher, dass du diesen API-Schlüssel löschen möchtest? Alle Shops, die diesen Schlüssel verwenden, funktionieren dann nicht mehr.",
      createKey: "API-Schlüssel erstellen",
      createSuccess: "API-Schlüssel wurde erfolgreich erstellt",
      deleteFailed: "API-Schlüssel konnte nicht gelöscht werden! Versuche es später noch einmal.",
      deleted: "API-Schlüssel wurde gelöscht.",
      editKey: "API-Schlüssel bearbeiten",
      generateKey: "Bist du sicher, dass du den Schlüssel neu generieren willst?",
      keyChangFailed: "Der API-Schlüssel konnte nicht aktualisiert werden! Versuche es später noch einmal.",
      keyChanged: "Key wurde erfolgreich aktualisiert",
      label: "API-Schlüssel-Bezeichnung",
      live: "live",
      "public": "Öffentlich",
      resetKeyButtonTooltip: "Neuen Schlüssel generieren",
      resetKeyConfirmationHeader: "Neuen Schlüssel generieren",
      sandbox: "Sandbox",
      secret: "Geheimnis",
      type: "Typ",
      updateFailure: "Der API-Schlüssel konnte nicht aktualisiert werden. Bitte versuche es noch einmal.",
      updateSuccess: "API-Schlüssel wurde erfolgreich aktualisiert"
    },
    merchant: {
      analyticsCardTitle: "Google Analytics",
      analyticsGaTrackingId: "Tracking-ID",
      analyticsGaTrackingIdTooltip: "Deine einzigartige Google Analytics-Tracking-ID",
      analyticsLinkedDomain: "Verlinkte Domain hinzufügen",
      analyticsLinkedDomainTooltip: "Du kannst mehrere Domänen hinzufügen, die durch ein Komma getrennt sind.",
      coverImage: "Cover-Bild",
      coverImageTooltip: "Dies ist das Titelbild, das sich in deinem Schaufenster befindet.",
      descriptionTooltip: "Eine kurze Beschreibung über dich selbst oder dein Unternehmen",
      emailTooltip: "Diese E-Mail ist für deine Kundschaft sichtbar. Support-E-Mails werden hierher gesendet.",
      enableHostedCheckouts: "Gehosteten Checkout aktivieren",
      enableHostedCheckoutsTooltip: "Der standardmäßig gehostete und integrierbare Checkout, der für alle deine Produkte verfügbar ist, zB https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Gehostetes Schaufenster aktivieren",
      isPublic: "Für die öffentliche API sichtbar machen",
      merchantAddress: "Adresse",
      merchantId: "Händler-ID",
      merchantIdInfo: "Dies ist deine eindeutige Händler-ID. Wir können danach fragen, wenn du dich mit uns in Verbindung setzt.",
      name: "Name des Geschäfts",
      nameTooltip: "Unter welchem Namen verkaufst du?",
      save: "Einstellungen speichern",
      saveFailed: "Die Händlereinstellungen konnten nicht gespeichert werden! Bitte versuche es noch einmal.",
      saved: "Änderungen gespeichert",
      timezoneTooltip: "Datum und Uhrzeit in Chec werden relativ zu dieser Zeitzone sein",
      title: "Angaben zum Händler"
    },
    notifications: {
      customerLoginToken: "Kunde: Login-Token",
      customerOrderReceipt: "Kunde: Auftragsbestätigung",
      customerOrderShipped: "Kunde: Bestellung versandt",
      dashboard: "Dashboard-Benachrichtigungen",
      dashboardSummary: "Kontrolliere das Verhalten von Benachrichtigungen im Chec Dashboard.",
      emailNotificationSummary: "Aktiviere/Deaktiviere Standard-E-Mail-Benachrichtigungen, die von Chec gesendet werden.",
      emailNotifications: "E-Mail-Benachrichtigungen",
      emails: "E-Mails",
      merchantOrderReceipt: "Händler: Neue Bestellung erhalten",
      navMenuHighlight: "Navigationsmenü-Highlight",
      news: "Neuigkeiten & Feature-Ankündigungen",
      none: "Keine",
      notificationPopup: "Benachrichtigungs-Popup",
      title: "Benachrichtigungen"
    },
    paymentGateways: "Zahlungsportale",
    settings: "Einstellungen",
    shipping: "Versand",
    tax: {
      addState: "Bundesstaat hinzufügen",
      addTax: "Steuerverwaltung aktivieren",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Steuerzone hinzufügen",
      addedToRate: "Zur Steuerrate des Landes hinzugefügt",
      address: "Adresse",
      addressAlert: "Dies wird zur Berechnung der Steuern verwendet und wird auf Rechnungen angezeigt.",
      calculateAutomatically: "Steuern automatisch berechnen",
      configureTaxZone: "Steuerzone konfigurieren",
      confirmDeleteZone: "Bist du sicher, dass du diese Steuerzone löschen möchtest?",
      confirmDeleteZoneTitle: "Bist du sicher?",
      countrySelectInfo: "Der hier eingestellte Steuersatz wird auf alle Bestellungen in dieser Steuerzone angewendet.",
      countryTax: "Landesspezifische Steuer",
      deleted: "Steuereinstellung wurde gelöscht",
      digitalRate: "Steuer auf digitale Güter",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Steuerzone bearbeiten",
      enableTax: "Steuer aktivieren",
      enableVat: "EU-Mehrwertsteuer für digitale Waren aktivieren",
      enableVatInfo: "Kassiere die EU-Mehrwertsteuer auf digitale Güter für deine digitale Waren. Die Steuersätze werden automatisch berechnet und auf EU-Kunden angewendet.",
      euVatMoss: "EU Mehrwertsteuer (KEA System)",
      introCopy: "Mit Commerce.js kannst du die Steuersätze deines Landes einfach definieren, damit du beim Checkout einfach die entsprechende Steuer erheben kannst. Du kannst auch einen Steueranbieter integrieren und spezifische Regeln mit deinen eigenen serverlosen Funktionen implementieren.",
      introTitle: "Lass Commerce.js deine Steuern verwalten!",
      nexusHelp: "Chec berechnet automatisch die Umsatzsteuer einschließlich Bezirks-, Kommunal- und sonstigen Steuern. Du musst die Umsatzsteuer in Bundesstaaten erheben, in denen du Nexus hast.",
      nexusStates: "Nexus-Staaten",
      overrideRate: "Anstelle des Steuersatzes des Landes",
      pricesIncludeTax: "Enthalten deine Preise Steuern?",
      ratesAutoUpdated: "Die Steuersätze werden automatisch aktualisiert und festgelegt.",
      saveFailed: "Die Steuerkonfiguration konnte nicht gespeichert werden! Bitte versuche es noch einmal.",
      standardRate: "Standardtarif",
      taxRateZones: "Steuer-Zonen",
      taxRates: "Steuersätze",
      title: "Steuer",
      zipAlreadyExists: "Die angegebene Postleitzahl wurde bereits hinzugefügt",
      zipInvalid: "Die angegebene Postleitzahl scheint keine gültige US-Postleitzahl zu sein"
    },
    updateFailed: "Deine Benachrichtigungseinstellungen konnten nicht gespeichert werden. Bitte versuche es noch einmal.",
    updatedSuccessfully: "Die Benachrichtigungseinstellungen wurden erfolgreich aktualisiert.",
    viewStorefront: "Onlineshop anzeigen"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "Zone hinzufügen",
    confirmDeleteZone: "Bist du sicher, dass du diese Zone löschen möchtest?",
    countriesRegions: "Länder & Regionen",
    countriesRegionsHelp: "Bitte beachte, dass Änderungen an diesem Panel erst gespeichert werden, wenn die Versandzone gespeichert wurde.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "Zone bearbeiten: {name}",
    enableOnProductsInfo: "Wenn diese Option aktiviert ist, übernehmen alle Produkte diese Versandzone standardmäßig. Deaktiviere sie, damit nur ausgewählte Produkte diese Versandzone übernehmen sollen. Alle Produkte werden ohne Preisdifferenz aktiviert.",
    enableOnProductsTitle: "Für alle Produkte aktivieren",
    introSplash: {
      description: "Eine Versandzone wird standardmäßig als Beispiel hinzugefügt. Nachdem du zusätzliche Versandzonen hinzugefügt hast, speichere und aktiviere sie, damit die Zonen für deine Produkte verfügbar sind. Versandzonen können auch in der {productPage} ausgewählt werden.",
      title: "Füge Versandzonen für deine Produkte hinzu"
    },
    noCountriesRegions: "Deiner Zone wurden keine Länder oder Regionen hinzugefügt. Füge deiner Zone Länder oder Regionen hinzu, bevor du speicherst.",
    noCountriesRegionsTitle: "Es wurden keine Länder oder Regionen hinzugefügt",
    numCountries: "Anzahl der Länder",
    numRegions: "Anzahl der Regionen",
    productPage: "Produktbearbeitungsseite",
    rateNameRequired: "Der Tarifname ist erforderlich",
    ratePriceRequired: "Der Preis des Tarifs ist erforderlich. Verwende für kostenlose Tarife 0.",
    ratesLeadIn: "Erhöhungen dieser Tarife kannst du auch in der {productPage} hinzufügen.",
    saveFailure: "Leider haben wir ein Problem beim Speichern deiner Versandzone.",
    saveSuccess: "Deine Versandzone wurde erfolgreich gespeichert.",
    selectRegion: "Configure regions",
    shippingZones: "Versandzonen",
    zone: "Zone",
    zoneDeleteFailed: "Beim Versuch, diese Zone zu löschen, ist etwas schief gelaufen. Bitte versuche es noch einmal.",
    zoneDeleted: "Zone wurde gelöscht.",
    zoneName: "Zonenname",
    zoneNameDescription: "Der Zonenname ist nur für den internen Gebrauch bestimmt und für deine Kundschaft nicht sichtbar.",
    zoneNameRequired: "Der Zonenname ist erforderlich"
  },
  signup: {
    error: "Bei der Erstellung deines Kontos ist etwas schief gelaufen. Bitte überprüfe deine Angaben und versuche es erneut.",
    form: {
      experience: "So können wir dir das bestmögliche Erlebnis bieten.",
      merchantDetails: "Angaben zum Händler",
      merchantEmail: "E-Mail des Händlers",
      merchantEmailDescription: "Diese E-Mail ist für deine Kunden sichtbar. Du erhältst hier auch Bestellbenachrichtigungen.",
      merchantName: "Name des Händlers",
      merchantNameDescription: "Unter welchem Namen verkaufst du?",
      next: "Weiter",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "Speichern…"
    },
    intro: {
      text: "Erstelle dein Konto in wenigen Schritten und beginne mit der Verwendung von Commerce.js.",
      title: "Willkommen, lass uns dein Konto einrichten"
    },
    page: {
      title: "Konto einrichten"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Etwas anderes",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Marketing",
      other: "Anderes",
      owner: "Geschäftsinhaber",
      solution_architect: "Solution Architect",
      student: "Schüler"
    }
  },
  slack: {
    alreadyJoined: "Schon dabei?",
    description: "Unsere eCommerce Entwicklungs- und Design-Community.",
    error: {
      alreadyInTeam: "Diese e-mail-adresse ist bereits in unserer Community!",
      alreadyInvited: "Diese E-Mail-Adresse hat eine ausstehende Einladung. Überprüfe deine E-Mails!",
      invalidEmail: "Du musst eine gültige e-mail-adresse angeben",
      missingEmail: "Du musst eine a-mail-adresse angeben."
    },
    getMyInvite: "Einladung bekommen",
    inviteSent: "Einladung verschickt! Überprüfe deine e-mails.",
    join: "Tritt der Slack Community bei",
    joinCommerceJs: "Tritt Commerce.js auf Slack bei",
    joinUs: "Sei auf Slack mit dabei",
    openSlack: "Slack öffnen",
    registeredCount: "{count} Benutzer sind bisher registriert.",
    slack: "Slack"
  },
  spaces: {
    storefront: "Schaufenster"
  },
  status: {
    maintenance: "Wartungsarbeiten im Gange",
    major: "Teilweiser Systemausfall",
    minor: "Geringfügiger Serviceausfall",
    none: "Alle systeme betriebsbereit"
  },
  validation: {
    enterEmail: "Bitte geben Sie eine E-Mail-Adresse an",
    enterName: "Bitte gib einen Namen ein.",
    enterOption: "Bitte gib einen Optionsnamen an",
    enterPrice: "Bitte Preis eingeben",
    enterQuantity: "Bitte Menge eingeben",
    enterUrl: "Bitte URL eingeben",
    enterValue: "Please enter an attribute value",
    generic: "Bitte gib einen Wert für {field} ein",
    number: "Bitte geben Sie eine Zahl ein",
    positiveNumber: "Bitte gib eine positive Zahl ein",
    typeRequired: "Please enter a type",
    validBusinessName: "Bitte gib einen Firmennamen ein",
    validCity: "Bitte gib eine Stadt ein.",
    validCountry: "Bitte wähle ein Land",
    validEmail: "Bitte gib eine gültige E-Mail-Adresse ein",
    validNumber: "Bitte gib eine gültige Nummer ein",
    validPostalZipCode: "Bitte gib eine Postleitzahl ein",
    validPrice: "Bitte gib einen gültigen Preis ein",
    validRegion: "Bitte wähle ein Bundesland/Kanton",
    validStreet: "Bitte gib Straßenname und Hausnummer an",
    validSupportEmail: "Bitte geben Sie eine E-Mail ein.",
    validUrl: "Gib bitte eine gültige URL ein"
  },
  versions: {
    current: "Aktuell: {version}"
  },
  webhooks: {
    add: "Webhook hinzufügen",
    added: "Webhook wurde hinzugefügt.",
    confirmDelete: "Bist du sicher, dass du diesen Webhook löschen möchtest?",
    confirmRegenerateKey: "Möchtest du den Signaturschlüssel wirklich regenerieren? Dies wirkt sich auf alle Webhooks aus.",
    copiedKey: "Kopiert",
    copyKey: "Kopiert den Schlüssel",
    created: "Erstellt",
    "delete": "Webhook löschen",
    deleteFailed: "Fehler beim Löschen des Webhooks!",
    deleted: "Webhook wurde gelöscht.",
    deliveryDetails: "Verspätete Lieferungen",
    deliveryHistory: "Sendungsverlauf",
    edit: "Webhook bearbeiten",
    event: "Ereignis",
    eventName: "Name der Veranstaltung",
    events: "Ereignisse",
    generateKey: "Neuen Schlüssel generieren",
    introSplash: {
      description: "Webhooks konfigurieren Callback-URLs und abonnieren sie für Events in deinem Konto. Wir benachrichtigen deine Callback-URLs, wenn diese Events eintreten, einschließlich der API-Antwort des Aufrufs, der das Event ausgelöst hat. {more}.",
      more: "Weitere Informationen",
      title: "Füge einen Webhook hinzu, um die Möglichkeiten deines Unternehmens zu erweitern."
    },
    regenerateKeyFailed: "Der Signaturschlüssel konnte nicht neu generiert werden!",
    responseBody: "Response body",
    responseCode: "HTTP-Antwortcode: {code}",
    save: "Webhook speichern",
    saveFailed: "Der Webhook konnte nicht gespeichert werden! Bitte versuche es noch einmal.",
    saved: "Änderungen gespeichert",
    sendTestRequest: "Test-Request senden",
    sendingTestRequest: "Sende Testanfrage...",
    signingKey: "Signier-Schlüssel",
    testRequestCallbackFailed: "Die Testanfrage wurde gesendet, aber die Callback-Antwort ist fehlgeschlagen.",
    testRequestFailed: "Testanfrage konnte nicht gesendet werden!",
    testRequestSuccess: "Die Testanfrage wurde erfolgreich gesendet.",
    view: "Webhook ansehen",
    webhook: "Webhook",
    webhooks: "Webhooks"
  }
};