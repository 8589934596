<template>
  <span
    v-if="indicator"
    v-tooltip="statusDescription"
    class="status-indicator"
    :class="{
      [`status-indicator--${indicator}`]: indicator && indicator !== null,
    }"
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'StatusIndicator',
  data() {
    return {
      /**
       * Possible values: "none", "minor", "major", "maintenance"
       */
      indicator: null,
    };
  },
  computed: {
    /**
     * @returns {string}
     */
    statusDescription() {
      if (!this.indicator) {
        return '';
      }
      return this.$t(`status.${this.indicator}`);
    },
  },
  mounted() {
    this.fetchStatus();
  },
  methods: {
    /**
     * Get the Chec application status from StatusPage. Includes all components.
     */
    fetchStatus() {
      axios.get('https://status.chec.io/api/v2/status.json')
        .then(({ data: { status } }) => {
          this.indicator = status.indicator;
        });
    },
  },
};
</script>

<style lang="scss">
.status-indicator {
  // Default state: "none"
  @apply block absolute w-3 h-3 bg-green-400 rounded-full mt-px -ml-5;
}

.status-indicator--minor {
  @apply bg-orange-400;
}

.status-indicator--major {
  @apply bg-red-500;
}

.status-indicator--maintenance {
  @apply bg-blue-400;
}
</style>
