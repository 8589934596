import actions from '@/store/actions';
import mutations from '@/store/mutations';
import { makeApiRequest } from '@/lib/api';
import invoices from './invoices';
import transactionFees from './transactionFees';

const {
  FETCH_BILLING,
  FETCH_MERCHANT,
  FETCH_PLANS,
  SET_PLAN,
  SET_BILLING_EMAIL,
} = actions;
const {
  SET_DETAILS,
  SET_ERROR,
  SET_PLANS,
  SET_CURRENT_PLAN,
} = mutations;

export default {
  namespaced: true,
  modules: {
    invoices,
    transactionFees,
  },
  state: {
    error: null,
    details: null,
    plans: [],
  },
  mutations: {
    [SET_DETAILS](state, payload) {
      state.details = payload;
    },
    [SET_ERROR](state, error) {
      state.error = error;
    },
    [SET_ERROR](state, error) {
      state.error = error;
    },
    [SET_PLANS](state, payload) {
      state.plans = payload;
    },
    [SET_CURRENT_PLAN](state, payload) {
      state.details.plan = payload;
    },
  },
  actions: {
    /**
     * request GET /billing
     * sets general info about merchants billing - current plan, accrued fees, billing email...
     */
    [FETCH_BILLING]({ commit }, update = false) {
      return makeApiRequest(
        update ? 'POST' : 'GET',
        update ? '/v1/billing/refresh-payment-details' : '/v1/billing',
      )
        .then(({ data }) => commit(SET_DETAILS, data))
        .catch((error) => commit(SET_ERROR, error));
    },
    /**
     * request GET  billing/plans
     * sets list of plans
     */
    [FETCH_PLANS]({ commit }) {
      return makeApiRequest('GET', '/v1/billing/plans')
        .then(({ data }) => commit(SET_PLANS, data))
        .catch((error) => commit(SET_ERROR, error));
    },
    /**
     * Request POST billing/plans/change
     *
     * Attempts to change current plan, setting current plan if successful. It will then
     * re-fetch the merchant information, since that contains plan related info.
     */
    [SET_PLAN]({ commit, dispatch }, plan) {
      return makeApiRequest('POST', '/v1/billing/plans/change', { plan })
        .then(({ data }) => commit(SET_DETAILS, data))
        .then(() => dispatch(`merchant/${FETCH_MERCHANT}`, {
          showLoading: false,
          force: true,
        }, { root: true }))
        .catch((error) => commit(SET_ERROR, error));
    },
    /**
     * request PUT billing
     * Updates billing details
     */
    [SET_BILLING_EMAIL]({ commit, state: { details: existingDetails } }, email) {
      // Optimistic update
      commit(SET_DETAILS, {
        ...existingDetails,
        billing_email: email,
      });

      makeApiRequest('PUT', '/v1/billing', { email })
        .then(({ data }) => commit(SET_DETAILS, data))
        .catch((error) => {
          commit(SET_ERROR, error);
          commit(SET_DETAILS, existingDetails);
        });
    },
  },
};
