export default [
  {
    path: 'webhooks',
    component: () => import(/* webpackChunkName: "webhooks" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('webhooks.webhooks'),
    },
    children: [
      {
        path: '/',
        name: 'settings.webhooks.home',
        component: () => import(/* webpackChunkName: "webhooks" */ './views/home.vue'),
      },
      {
        path: 'add',
        name: 'settings.webhooks.add',
        component: () => import(/* webpackChunkName: "webhooks" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('webhooks.add'),
        },
      },
      {
        path: ':id(wbhk_[^/]+)',
        name: 'settings.webhooks.edit',
        component: () => import(/* webpackChunkName: "webhooks" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('webhooks.edit'),
        },
      },
    ],
  },
];
