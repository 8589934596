import ClientOAuth2 from 'client-oauth2';
import resolveDomain from '../resolveDomain';

/**
 * Returns the client-oauth2 Client instance, which can be used to call authorization and
 * token endpints.
 */
export default new ClientOAuth2({
  clientId: 2, // See DB migration script in the "authorize" service repo
  clientSecret: null,
  authorizationUri: `${resolveDomain('authorize')}/oauth/authorize`,
  accessTokenUri: `${resolveDomain('authorize')}/oauth/token`,
  redirectUri: `${resolveDomain('dashboard')}/auth/callback`,
});
