import { applyPagination } from '@/lib/pagination';
import i18n from '@/lang';

const actions = {
  INTEGRATION_READY_NOTIFICATION: 'INTEGRATION_READY_NOTIFICATION',
  AWAITING_THIRD_PARTY: 'AWAITING_THIRD_PARTY',
};

export { actions };

export default applyPagination('/v1/integrations?include=template')({
  namespaced: true,
  actions: {
    /**
     * Pushes a notification for integrations that have become ready.
     */
    [actions.INTEGRATION_READY_NOTIFICATION]({ dispatch }, { id, type }) {
      // We don't care about the merchant's dashboard notification settings for this, we
      // will always just show it in a toast message
      return dispatch('notifications/ADD_NOTIFICATION', {
        type: 'success',
        hideTime: 10000, // milliseconds
        text: i18n.t('integration.readyNotification', {
          type,
        }),
        buttonText: i18n.t('integration.view'),
        buttonRoute: { name: 'integrations.edit', params: { id } },
      }, { root: true });
    },
    /**
     * Pushes a notification for integrations that are awaiting third party approval/authorization
     */
    [actions.AWAITING_THIRD_PARTY]({ dispatch }, { type }) {
      return dispatch('notifications/ADD_NOTIFICATION', {
        type: 'info',
        hideTime: 10000, // milliseconds
        text: i18n.t('integration.awaitingThirdPartyNotification', {
          type,
        }),
      }, { root: true });
    },
  },
});
