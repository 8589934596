<template>
  <form class="integration-edit" @submit.prevent>
    <DashboardHeader :title="pageTitle" />
    <ChecLoading v-if="loading" without-background />
    <div v-else class="integration-edit__content space-y-4 lg:space-y-0">
      <div class="integration-edit__main space-y-4">
        <ChecCard tailwind="p-4">
          <ChecLoading v-if="loading" />
          <DashboardHeader
            variant="card"
            margin="small"
            :title="$t('general.options')"
          />
          <Configuration
            v-if="integrationTemplate"
            v-model="integration.config"
            :template="integrationTemplate"
            :errors="errors"
            editing
            @save="handleSaveConfiguration"
            @set-external-id="setExternalId"
          />
        </ChecCard>
        <!-- Show the logs if the integration is ready, otherwise show a loading message -->
        <LogsCard
          v-if="!initializing && !awaitingThirdParty && history && history.length"
          :webhook-history="history"
          :loading="loading"
        />
        <div
          v-if="initializing"
          class="integration-edit__initializing"
        >
          <ChecLoading
            :message="$t('integration.creating')"
            without-background
          />
        </div>
        <div
          v-else-if="awaitingThirdParty"
          class="integration-edit__initializing"
        >
          <ChecLoading
            :message="$t('integration.awaitingThirdParty')"
            without-background
          />
        </div>
      </div>
      <div class="integration-edit__aside space-y-4">
        <ActionsCard
          :integration="integration"
          class="integration-edit__actions-card"
          :disable-save="isShortcutIntegration"
          @delete-integration="handleDeleteIntegration(integration.id)"
          @save-integration="handleSaveConfiguration"
        />
        <InfoCard
          :integration="integration"
          :shortcut-template="integrationTemplate && integrationTemplate.source_repo === null"
        />
        <DescriptionCard
          v-if="integrationTemplate"
          :template="integrationTemplate"
          :loading="loading"
        />
      </div>
      <ActionsCard
        :integration="integration"
        class="integration-edit__actions-card--mobile"
        @delete-integration="handleDeleteIntegration(integration.id)"
        @save-integration="handleSaveConfiguration"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { ChecCard, ChecLoading } from '@chec/ui-library';
import paginationMutations from '@/lib/pagination/mutations';
import actions from '@/store/actions';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ActionsCard from '../components/ActionsCard.vue';
import DescriptionCard from '../components/DescriptionCard.vue';
import LogsCard from '../components/LogsCard.vue';
import InfoCard from '../components/InfoCard.vue';
import Configuration from '../components/Configuration.vue';

const { FETCH_WEBHOOKS_HISTORY } = actions;

export default {
  name: 'EditIntegration',
  components: {
    Configuration,
    ChecCard,
    ChecLoading,
    DashboardHeader,
    InfoCard,
    ActionsCard,
    DescriptionCard,
    LogsCard,
  },
  mixins: [
    crud('integrations/integrations', true),
    crud('integrations/templates', false, null, true),
    addNotification,
    confirm,
  ],
  data() {
    return {
      errors: {},
      externalId: undefined,
      integration: {},
      integrationTemplate: null,
      saving: false,
    };
  },
  computed: {
    ...mapGetters('webhooks', ['webhookHistory']),
    loading() {
      return this.isLoading || this.isLoadingTemplates;
    },
    integrationId() {
      return this.$route.params.id;
    },
    initializing() {
      // We treat initializing and installing as the same thing for the user's sake. Installing
      // would mean that a (long-running) background task is running to synchronise data.
      return this.integration && ['initializing', 'installing'].includes(this.integration.status);
    },
    awaitingThirdParty() {
      return this.integration && this.integration.status === 'awaiting_third_party';
    },
    isShortcutIntegration() {
      return this.integration && !this.integration?.template?.source_repo;
    },
    /**
     * Page title with integration name
     *
     * @returns {String}
     */
    pageTitle() {
      if (!this.integrationTemplate || this.loading) {
        return this.$t('integration.edit');
      }
      return this.$t('integration.editNamed', {
        name: this.integrationTemplate.name,
      });
    },
    /**
     * Integration webhooks array
     *
     * @returns {Array|null}
     */
    integrationWebhooks() {
      if (this.integration?.webhooks?.length === 0) {
        return null;
      }
      return this.integration.webhooks;
    },
    /**
     * Webhook history array
     *
     * @returns {Array|null}
     */
    history() {
      const webhookId = this.integrationWebhooks ? this.integrationWebhooks[0].id : null;
      if (!webhookId) {
        return [];
      }
      return this.webhookHistory(webhookId);
    },
  },
  async mounted() {
    if (!this.integrationId) {
      return;
    }

    try {
      // Load the first namespaced integrations from crud
      await this.load(this.integrationId);
    } catch (e) {
      this.$router.push({ name: 'notFound' });
    }

    // Store the integration in local state
    this.integration = this.get(this.integrationId);

    // Subscribe to updates in the state
    this.$store.subscribe((mutation) => {
      if (
        mutation.type !== `integrations/integrations/${paginationMutations.REPLACE_ITEM}`
        || mutation.payload?.item?.id !== this.integrationId
      ) {
        return;
      }

      this.integration = mutation.payload.item;
    });

    // Load the template
    await this.loadTemplates(this.integration.template_id);

    // Store the integration template in local state
    this.integrationTemplate = this.getTemplates(this.integration.template_id);

    // Fetch webhooks
    const webhookId = this.integration.webhooks.length
      ? this.integration.webhooks[0].id
      : null;

    if (!webhookId) {
      return;
    }

    // Fetch a specific records history (more records are provided than the global list)
    this.$store.dispatch(`webhooks/${FETCH_WEBHOOKS_HISTORY}`, webhookId);
  },
  methods: {
    /**
     * Handle saving the integration
     */
    async handleSaveConfiguration() {
      this.saving = true;
      try {
        await this.update(this.integrationId, {
          config: this.integration.config,
          external_id: this.externalId,
        }, true);
        this.addNotification(this.$t('integration.saved'));
      } catch (error) {
        console.error(error);
        this.addNotification(this.$t('integration.savingFailure'), 'error');
      } finally {
        this.saving = false;
      }
    },
    /**
     * Deletes integration
     */
    async handleDeleteIntegration(integrationId) {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('integration.confirmDelete'),
      )) {
        return;
      }

      // Use delete CRUD
      this.delete(integrationId)
        .then(() => this.addNotification(this.$t('integration.deleted')))
        .catch(() => this.addNotification(this.$t('integration.deleteFailed'), 'error'));

      this.$router.push({ name: 'integrations.home' });
    },
    setExternalId(id) {
      this.externalId = id;
    },
  },
};
</script>

<style lang="scss">
.integration-edit {
  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__aside {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  &__actions-card {
    @apply hidden;

    @screen lg {
      @apply block;
    }

    &--mobile {
      @apply mt-4;

      @screen lg {
        @apply hidden;
      }
    }
  }

  &__initializing {
    @apply relative p-32;
  }

  .card {
    @apply mb-8;
  }
}
</style>
