import Vue from 'vue';
import store from '@/store';
import { mapGetters } from 'vuex';

// Global mixin to check if panel is open and disable Intercom
export default Vue.mixin({
  name: 'IntercomPanelCheck',
  computed: {
    ...mapGetters(['panelCount']),
  },
  mounted() {
    if (this.$options.name !== 'ChecSlideoutPanel') {
      return;
    }

    store.commit('SET_PANEL_COUNT', this.panelCount + 1);

    // Hide the Intercom button
    if (this.$intercom) {
      this.$intercom.update({ hide_default_launcher: true });
    }
  },
  beforeDestroy() {
    if (this.$options.name !== 'ChecSlideoutPanel') {
      return;
    }

    store.commit('SET_PANEL_COUNT', this.panelCount - 1);

    // Show the Intercom button
    if (this.$intercom && this.panelCount === 0) {
      this.$intercom.update({ hide_default_launcher: false });
    }
  },
});
