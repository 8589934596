<template>
  <component :is="layout()">
    <router-view v-if="publicRoute || isAppInitiated" />
    <div v-if="isLoading" class="loading-container loading-container--full-screen">
      <ChecLoading />
    </div>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Cancel } from 'axios';
import { ChecLoading } from '@chec/ui-library';
import actions from '@/store/actions';

export default {
  name: 'App',
  components: { ChecLoading },
  computed: {
    ...mapGetters(['isLoading', 'isAppInitiated']),
    publicRoute() {
      return this.$route?.meta.public;
    },
  },
  errorCaptured(error) {
    if (error instanceof Cancel) {
      return;
    }

    this[actions.LOG_ERROR](error);
  },
  methods: {
    ...mapActions([actions.LOG_ERROR]),
    layout() {
      // Show loading while authentication is happening or the route is being resolved. This should
      // only actually take a noticable amount of time if the user is going through the auth flow,
      // or we have dispatched actions to fetch the required data to initialise the app.
      if (this.$route.matched?.length === 0) {
        return 'loading-layout';
      }
      return this.$route.meta.layout || 'default-layout';
    },
  },
};
</script>
