<template>
  <ChecSlideoutPanel
    :title="panelTitle"
    close-on-overlay-click
    @close="handleClose"
  >
    <ChecCard
      inner-class="template-info__card"
      tailwind="p-4"
    >
      <div class="template-info__logo-container">
        <img
          v-if="template.icon_url"
          :src="template.icon_url"
          :alt="template.name"
          class="template-info__logo"
        >
        <h4 class="template-info__name">
          {{ template.name }}
        </h4>
      </div>
      <EnableTemplateButton
        v-if="showEnableButton"
        variant="round"
        :template="template"
      />
    </ChecCard>
    <TemplateTags
      v-if="template.tags && template.tags.length"
      :tags="template.tags"
      class="template-info__tags"
    />

    <ChecHeader
      header-tag="h3"
      class="template-info__about-header"
      :title="$t('integration.aboutThisIntegration')"
    />
    <div class="integration-description-content" v-html="template.description" />
    <template #toolbar>
      <div class="template-info__toolbar">
        <ChecButton
          text-only
          color="primary"
          class="template-info__go-back"
          @click="handleClose"
        >
          {{ $t('general.goBack' ) }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import EnableTemplateButton from './EnableTemplateButton.vue';
import TemplateTags from './TemplateTags.vue';

export default {
  name: 'TemplateInfoPanel',
  components: {
    EnableTemplateButton,
    ChecButton,
    ChecCard,
    ChecHeader,
    ChecSlideoutPanel,
    TemplateTags,
  },
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    showEnableButton: Boolean,
  },
  computed: {
    /**
     * Create the info panel title with the template name
     *
     * @returns {string}
     */
    panelTitle() {
      if (!this.template.name) {
        return this.$t('general.template');
      }
      return this.$t('integration.about', { integration: this.template.name });
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.template-info {
  &__about-header {
    @apply mb-6;
  }

  &__card {
    @apply flex justify-between items-center flex-row mt-6;
  }

  &__logo-container {
    @apply flex flex-row items-center;
  }

  &__logo {
    @apply w-12 mr-2;
  }

  &__name {
    @apply text-xl font-bold text-gray-600;
  }

  &__tags {
    @apply mb-4;
  }

  &__toolbar {
    @apply flex justify-end w-full;
  }
}
</style>
