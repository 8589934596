export default {
  INIT_APP: 'INIT_APP',
  INIT_MERCHANT: 'INIT_MERCHANT',
  BOOT_INTERCOM: 'BOOT_INTERCOM',
  BOOT_PUSHER: 'BOOT_PUSHER',
  FETCH_BILLING: 'FETCH_BILLING',
  FETCH_PLANS: 'FETCH_PLANS',
  SET_PLAN: 'SET_PLAN',
  SET_BILLING_EMAIL: 'SET_BILLING_EMAIL',
  FETCH_TRANSACTION_FEES: 'FETCH_TRANSACTION_FEES',
  FETCH_WEBHOOKS: 'FETCH_WEBHOOKS',
  APPEND_WEBHOOK: 'APPEND_WEBHOOK',
  UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',
  FETCH_WEBHOOKS_HISTORY: 'FETCH_WEBHOOKS_HISTORY',
  APPEND_WEBHOOK_HISTORY: 'APPEND_WEBHOOK_HISTORY',
  FETCH_WEBHOOK_SIGNING_KEY: 'FETCH_WEBHOOK_SIGNING_KEY',
  FETCH_WEBHOOK_EVENTS: 'FETCH_WEBHOOK_EVENTS',
  UPDATE_WEBHOOK_MODAL_OPEN: 'UPDATE_WEBHOOK_MODAL_OPEN',
  REMOVE_WEBHOOK: 'REMOVE_WEBHOOK',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  OPEN_CONFIRM_DIALOG: 'OPEN_CONFIRM_DIALOG',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  APPEND_PRODUCT: 'APPEND_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  FETCH_MERCHANT: 'FETCH_MERCHANT',
  FETCH_ORDERS: 'FETCH_ORDERS',
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_SUBDIVISIONS: 'FETCH_SUBDIVISIONS',
  ADD_TAX_ZONE: 'ADD_TAX_ZONE',
  FETCH_TAX_ZONES: 'FETCH_TAX_ZONES',
  FETCH_TAX: 'FETCH_TAX',
  FETCH_EU_VAT_MOSS: 'FETCH_EU_VAT_MOSS',
  UPDATE_TAX: 'UPDATE_TAX',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  LOG_ERROR: 'LOG_ERROR',
  FETCH_USER: 'FETCH_USER',
  UPDATE_USER: 'UPDATE_USER',
  NEW_ORDER: 'NEW_ORDER',
  FETCH_NOTIFICATION_SETTINGS: 'FETCH_NOTIFICATION_SETTINGS',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
};
