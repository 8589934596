<template>
  <div class="merchant-tag" :class="{ 'merchant-tag--inverted': invert }">
    <div class="merchant-tag__logo">
      <ChecAvatar :image="merchantLogo" />
    </div>
    <div class="merchant-tag__details">
      <div class="merchant-tag__name" :title="merchant.name">
        {{ merchant.name }}
      </div>
      <div class="merchant-tag__id">
        #{{ merchant.id }}
      </div>
    </div>
  </div>
</template>

<script>
import { ChecAvatar } from '@chec/ui-library';

export default {
  name: 'MerchantTag',
  components: {
    ChecAvatar,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    invert: Boolean,
  },
  computed: {
    merchantLogo() {
      return this.merchant.images?.logo?.url;
    },
  },
};
</script>

<style lang="scss">
.merchant-tag {
  @apply flex flex-grow text-gray-500 rounded items-center min-w-0;

  &__details {
    @apply overflow-hidden;
  }

  &__logo {
    @apply w-8 h-8 mr-2 mt-px flex-shrink-0;

    .avatar {
      @apply bg-gray-400;
    }
  }

  &__name {
    @apply caps-xs truncate;
  }

  &__id {
    @apply caps-xxs text-gray-400;
  }

  &--inverted {
    @apply text-white;

    .merchant-tag__logo .avatar {
      @apply bg-gray-300;
    }

    .merchant-tag__id {
      @apply text-gray-300;
    }
  }
}
</style>
