const localazyMetadata = {
  projectUrl: "https://localazy.com/p/chec-dashboard",
  baseLocale: "en",
  languages: [
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "es",
      region: "",
      script: "",
      isRtl: false,
      name: "Spanish",
      localizedName: "Español",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "it",
      region: "",
      script: "",
      isRtl: false,
      name: "Italian",
      localizedName: "Italiano",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "ja",
      region: "",
      script: "",
      isRtl: false,
      name: "Japanese",
      localizedName: "日本語",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "pt",
      region: "",
      script: "",
      isRtl: false,
      name: "Portuguese",
      localizedName: "Português",
      pluralType: (n) => { return (n>=0 && n<=1) ? "one" : "other"; }
    }
  ]
};

export default localazyMetadata;