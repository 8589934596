import Vue from 'vue';
import Vuex from 'vuex';
import Pusher from 'pusher-js';
import * as Sentry from '@sentry/browser';
import { apiConfig, ApiRequest, makeApiRequest } from '@/lib/api';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import paginationActions from '@/lib/pagination/actions';
import billing from '@/modules/billing/store';
import categories from '@/modules/categories/store';
import customers from '@/modules/customers/store';
import developerLogs from '@/modules/developer/store';
import discounts from '@/modules/discounts/store';
import fulfillment from '@/modules/fulfillment/store';
import integrations from '@/modules/integrations/store';
import { actions as integrationsActions } from '@/modules/integrations/store/integrations';
import users, { actions as userActions } from '@/modules/users/store';
import account from '@/modules/account/store';
import merchant from '@/modules/merchant/store';
import notifications from '@/modules/notifications/store';
import orders from '@/modules/orders/store';
import orderActions from '@/modules/orders/store/actions';
import products from '@/modules/products/store';
import rays from '@/modules/rays/store';
import settings from '@/modules/settings/store';
import webhooks from '@/modules/webhooks/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    billing,
    categories,
    customers,
    developerLogs,
    discounts,
    fulfillment,
    integrations,
    merchant,
    notifications,
    products,
    webhooks,
    orders,
    rays,
    settings,
    users,
  },
  state: {
    user: {},
    loading: [],
    initiated: false,
    errors: [],
    countries: null,
    subdivisions: {},
    // Holds "context" for the crud/pagination helper that should be applied to every sub-context
    globalContext: {},
    // Count of slide panels loaded into the application;
    panelCount: 0,
  },
  mutations: {
    /**
     * Set the loading state of the app. This is implemented as a "counter", so subsequent loading
     * calls will increment the counter, and an equal number of "false" calls will be needed to
     * clear loading
     *
     * Supports a boolean, or an object with keys "loading" and "context", which allows you to
     * ensure loading state of your specific context is tracked separately
     *
     * @param {Object} state
     * @param {boolean|Object} payload
     */
    [mutations.SET_LOADING](state, payload = true) {
      let context;
      let loading = payload;
      if (typeof payload === 'object') {
        ({ context, loading } = payload);
      }

      if (!Array.isArray(context)) {
        context = [context];
      }

      if (loading) {
        context.forEach((key) => state.loading.push(key));
        return;
      }

      context.forEach((key) => {
        const index = state.loading.findIndex((candidate) => candidate === key);
        if (index < 0) {
          return;
        }
        state.loading.splice(index, 1);
      });
    },
    /**
     * Sets the loaded panel count.
     *
     * @param {Object} state
     * @param {Number} payload
     */
    [mutations.SET_PANEL_COUNT](state, payload) {
      state.panelCount = payload;
    },
    /**
     * Sets a flag to say that INIT_APP has finished.
     *
     * @param {Object} state
     */
    [mutations.SET_INITIATED](state) {
      state.initiated = true;
    },
    /**
     * Set the list of available countries
     *
     * @param {Object} state
     * @param {Object[]} payload
     */
    [mutations.SET_COUNTRIES](state, payload) {
      state.countries = payload;
    },
    /**
     * Set the list of available subdivisions for the provided country code
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {Object[]} subdivisions
     */
    [mutations.SET_SUBDIVISIONS](state, { countryCode, subdivisions }) {
      state.subdivisions = {
        ...state.subdivisions,
        [countryCode]: subdivisions,
      };
    },
    /**
     * Push a new error to the application's global error stack
     *
     * @param {Object} state
     * @param {Object} error
     */
    [mutations.PUSH_ERROR](state, error) {
      // Handle errors that have no response (probably a JS error)
      if (!error || !error.response) {
        const detail = typeof error === 'object' ? error : {};

        if (!detail.id) {
          // Hope that Sentry gives a relevant error ID here
          detail.id = Sentry.lastEventId();
        }

        state.errors = [
          ...state.errors,
          detail,
        ];
        return;
      }

      // We ignore anything that isn't a GET request
      if (error.response.config.method.toLowerCase() !== 'get') {
        return;
      }

      state.errors = [
        ...state.errors,
        {
          code: error.response.status,
          // Try to extract a Sentry error ID from the response
          id: (error.response.data && error.response.data.id) || null,
        },
      ];
    },
    /**
     * Set the errors for the application's global error stack, replacing existing
     *
     * @param {Object} state
     * @param {Array} errors
     */
    [mutations.SET_ERRORS](state, errors = []) {
      state.errors = errors;
    },
    /**
     * Set the authenticated user's data
     *
     * @param {Object} state
     * @param {Object} payload
     */
    [mutations.SET_USER](state, payload) {
      state.user = payload;
    },
    [mutations.SET_GLOBAL_CONTEXT](state, context) {
      state.globalContext = context;
    },
  },
  actions: {
    /**
     * The "init app" action is dispatched either when you first load the dashboard, or
     * when you have logged in to the dashboard.
     *
     * Put generic actions here for pre-fetching data that various parts of the dashboard
     * would need to function.
     *
     * If an action needs to be completed before the app starts running, ensure you also
     * add your own "root" loading state changes, otherwise don't bother.
     */
    [actions.INIT_APP]({ dispatch, commit }) {
      // Run various actions that initialise the app
      return Promise.all([
        dispatch(actions.FETCH_USER),
        dispatch(`users/${userActions.RESOLVE_USER}`),
        dispatch(actions.FETCH_COUNTRIES),
      ])
        .then(() => {
          commit(mutations.SET_INITIATED);
        });
    },
    async [actions.INIT_MERCHANT]({ dispatch, state }) {
      // Figure out if a merchant is already available
      if (state.merchant?.id) {
        return;
      }

      // Check if there has been an attempt to load a merchant
      if (state.totalCount === null || state.totalCount === undefined) {
        // Dispatch the action to resolve the merchant from local state or something
        await dispatch('merchant/FETCH_MERCHANT', { showLoading: false });
      }
    },
    /**
     * Configures Intercom settings for the current user and merchant and launches the Intercom
     * messenger. This runs after INIT_APP has fulfilled its promise.
     */
    [actions.BOOT_INTERCOM]({ state }) {
      // Do nothing if you're an admin, this prevents taking over accounts when using
      // the founders login process.
      if (state.user.admin) {
        console.log('Intercom boot skipped since user is an admin');
        return;
      }

      if (typeof state.merchant.merchant.id === 'undefined') {
        // Merchant not loaded, could be during registration. Skip.
        return;
      }

      if (state.merchant.merchant.billing.plan === 'Standard') {
        // Merchant does not have a plan that includes Intercom support.
        return;
      }

      if (!process.env.VUE_APP_INTERCOM_APP_ID) {
        console.log('VUE_APP_INTERCOM_APP_ID not defined, skipped booting Intercom');
        return;
      }

      const { id } = state.user;
      const maxNaturalId = process.env.VUE_APP_INTERCOM_USERID_FIX || Infinity;

      // eslint-disable-next-line no-underscore-dangle
      this._vm.$intercom.boot({
        user_id: id > maxNaturalId ? `u${id}` : id,
        user_hash: state.user.intercom_user_hash,
        user_name: state.user.name,
        email: state.user.email,
        merchant_id: state.merchant?.merchant.id,
        name: state.merchant.merchant.name,
        created_at: state.user.created_at,
        billing_plan: state.merchant.merchant.billing.plan,
        has_payment_method: state.merchant.merchant.billing.has_payment_method,
        unsubscribed_from_emails: !state.merchant.merchant.intercom,
      });
    },
    /**
     * Register Pusher to catch incoming order notifications
     */
    [actions.BOOT_PUSHER]({ dispatch, state }) {
      const pusherKey = process.env.VUE_APP_PUSHER_APP_KEY;
      if (!pusherKey) {
        return;
      }

      // In local development mode, log details about websocket connections for debugging
      Pusher.logToConsole = process.env.NODE_ENV === 'development';

      const pusher = new Pusher(pusherKey, {
        cluster: 'us3',
      });

      const token = state.merchant.merchant?.notifications?.token;
      if (!token) {
        // Notification token wasn't retrieved from the API, can't use Pusher yet
        return;
      }

      pusher
        // Look for new order notifications coming in
        .subscribe(`api.orders.${token}`)
        .bind('new-order', ({ order }) => {
          // Dispatch fetch new order action
          dispatch(`orders/${orderActions.FETCH_NEW_ORDER}`, order.id);
          // Dispatch new order notification action
          dispatch(`orders/${orderActions.NEW_ORDER_NOTIFICATION}`, order);
        });

      pusher
        // Look for notifications that integration statuses have changed, i.e. might be provisioned
        .subscribe(`api.integrations.${token}`)
        .bind('integration-status-changed', ({ integration: { id, type } }) => {
          // Force (re)fetch of the integration record, then reload any webhooks attached to it
          dispatch(`integrations/integrations/${paginationActions.LOAD_ITEM}`, {
            id,
            force: true,
          })
            .then((integration) => {
              if (integration.status === 'ready') {
                // Push a notification to say it's ready
                dispatch(
                  `integrations/integrations/${integrationsActions.INTEGRATION_READY_NOTIFICATION}`,
                  { id, type },
                );
              } else if (integration.status === 'awaiting_third_party') {
                dispatch(
                  `integrations/integrations/${integrationsActions.AWAITING_THIRD_PARTY}`,
                  { id, type },
                );
              }

              // Reload webhook history for each attached webhook
              integration.webhooks.forEach((webhook) => {
                dispatch(`webhooks/${actions.FETCH_WEBHOOKS_HISTORY}`, webhook.id);
              });
            });
        });

      pusher
        // Watch for notifications that Ray deployments have happened
        .subscribe(`api.rays.${token}`)
        .bind('ray-deployed', ({ ray: { id } }) => {
          // Reload the Ray in state
          dispatch(`rays/rays/${paginationActions.LOAD_ITEM}`, {
            id,
            force: true,
          });
        });
    },
    /**
     * Fetch a list of all countries
     */
    async [actions.FETCH_COUNTRIES]({ commit }) {
      let loadedFromStorage = false;

      if (window?.localStorage) {
        const countries = window.localStorage.getItem('countries');

        if (countries) {
          commit(mutations.SET_COUNTRIES, JSON.parse(countries));
          loadedFromStorage = true;
        }
      }

      const promise = makeApiRequest('GET', '/v1/services/locale/countries')
        .then(({ data: { countries } }) => {
          commit(mutations.SET_COUNTRIES, countries);
          if (window?.localStorage) {
            window.localStorage.setItem('countries', JSON.stringify(countries));
          }
        })
        .catch((error) => commit(mutations.PUSH_ERROR, error));

      // If we've already loaded some countries from storage, don't bother holding up any callers
      // that may be awaiting this action
      if (loadedFromStorage) {
        return;
      }

      await promise;
    },
    /**
     * Fetch a list of subdivisions for the given country code
     */
    [actions.FETCH_SUBDIVISIONS]({ commit }, countryCode) {
      return makeApiRequest('get', `/v1/services/locale/${countryCode}/subdivisions`)
        .then(({ data }) => {
          commit(mutations.SET_SUBDIVISIONS, {
            countryCode,
            subdivisions: data.subdivisions,
          });
        })
        .catch((error) => commit(mutations.PUSH_ERROR, error));
    },
    /**
     * Clear the application's global error stack
     */
    [actions.CLEAR_ERRORS]({ commit }) {
      commit(mutations.SET_ERRORS, []);
    },
    /**
     * Delays pushing an error onto the stack - in situations where Sentry should be logging for us
     */
    [actions.LOG_ERROR]({ commit }, error) {
      // We can handle errors with responses immediately
      if (Object.hasOwnProperty.call(error, 'response')) {
        commit(mutations.PUSH_ERROR, error);
        return Promise.resolve();
      }

      // Push the error after a short delay to give Sentry the time it needs
      return new Promise((resolve) => {
        setTimeout(() => {
          commit(mutations.PUSH_ERROR, {});
          resolve();
        }, 20);
      });
    },
    /**
     * Fetch user info
     */
    [actions.FETCH_USER]({ commit }) {
      return makeApiRequest(
        'GET',
        '/api/user',
        null,
        null,
        null,
        apiConfig.authUrl,
      )
        .then(({ data }) => {
          commit(mutations.SET_USER, data);
        })
        .catch((error) => commit(mutations.PUSH_ERROR, error));
    },
    /**
     * Update user info given some user data
     *
     * @param {Object} userData
     */
    [actions.UPDATE_USER]({ commit }, userData) {
      const request = new ApiRequest('PUT', '/api/user', apiConfig.authUrl);

      request.expectError(422, 'The password is incorrect');

      return request.send(userData).then(({ data }) => {
        commit(mutations.SET_USER, data);
      });
    },
  },
  getters: {
    isLoading: (state) => state.loading.length > 0,
    isAppInitiated: (state) => state.initiated,
    countries: (state) => state.countries,
    panelCount: (state) => state.panelCount,
    subdivisions: (state) => state.subdivisions,
    userData: ({
      user,
      merchant: {
        merchant: {
          id,
          logo = null,
          name: businessName = '',
        },
      },
    }) => ({
      ...user,
      id,
      logo,
      businessName,
    }),
    userInfo: (state, getters) => getters.userData.name || getters.userData.email || getters.userData.businessName || '',
  },
});
