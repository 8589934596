<template>
  <div class="templates-grid">
    <TemplateCard
      v-for="template in templates"
      :key="template.code"
      :template="template"
      @change-filters="(tag) => handleFilterTemplates(tag)"
    />
    <router-view />
  </div>
</template>

<script>
import TemplateCard from './TemplateCard.vue';

export default {
  name: 'TemplatesGrid',
  components: {
    TemplateCard,
  },
  props: {
    /**
     * List of templates
     */
    templates: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleFilterTemplates(tag) {
      this.$emit('change-filters', tag);
    },
  },
};
</script>

<style lang="scss">
.templates-grid {
  @apply py-8 grid grid-cols-1 col-gap-4 row-gap-8;

  @screen md {
    @apply grid-cols-2;
  }

  @screen lg {
    @apply grid-cols-3;
  }
}
</style>
