<template>
  <div v-if="notifications.length" class="notifications">
    <ChecToast
      v-for="notification in notifications"
      :key="notification.id"
      :variant="notification.type"
      :hide-time="notification.hideTime"
      @close="handleClose(notification.id)"
    >
      {{ notification.text }}
      <!-- Support for buttons if they were provided -->
      <ChecButton
        v-if="notification.buttonText && notification.buttonRoute"
        :to="notification.buttonRoute"
        tag-type="route"
        variant="round"
      >
        {{ notification.buttonText }}
      </ChecButton>
    </ChecToast>
  </div>
</template>

<script>
import { ChecButton, ChecToast } from '@chec/ui-library';
import { mapState } from 'vuex';
import actions from '@/store/actions';

const { DISMISS_NOTIFICATION } = actions;

export default {
  name: 'Notifications',
  components: {
    ChecButton,
    ChecToast,
  },
  computed: {
    ...mapState('notifications', ['notifications']),
  },
  methods: {
    /**
     * Close a notification. Also called when its timeout callback runs.
     *
     * @param {string} notificationId
     */
    handleClose(notificationId) {
      this.$store.dispatch(`notifications/${DISMISS_NOTIFICATION}`, notificationId);
    },
  },
};
</script>

<style lang="scss">
.notifications {
  @apply fixed w-full max-w-xs z-50;
  right: 2rem;
  top: 2rem;

  .notif {
    @apply mb-4;

    &__text {
      @apply break-words;
      // Note that tailwind uses overflow-wrap: break-word which behaves differently
      word-break: break-word;
    }
  }
}
</style>
