<template>
  <div>
    <header ref="header" class="navigation-bar">
      <ChecLogo class="navigation-bar__logo" />
      <ChecButton
        :icon="navOpen ? 'close' : 'hamburger'"
        variant="small"
        @click="navOpen = !navOpen"
      />
    </header>
    <nav ref="navigation" class="navigation" :class="{ 'navigation--open': navOpen }">
      <a href="#main" class="navigation__skip">
        {{ $t('navigation.skip') }}
      </a>
      <div class="navigation__logo">
        <ChecLogo />
      </div>
      <MerchantSelector />
      <div class="navigation__items">
        <template
          v-for="{ divider, key, icon, label, link, subMenu } in navMenuItems"
        >
          <div
            v-if="divider"
            :key="key"
            class="navigation__divider"
          />
          <NavItem
            v-else
            :key="key"
            :icon="icon || key"
            :label="$t(label)"
            :link="link"
            :has-sub-menu="Boolean(subMenu)"
            :sub-menu-open="subMenu && openMenus.includes(key)"
            :ping="pings[key]"
            :active="key === activeTopLevel"
            @toggle-sub-nav="() => toggleSubNav(key)"
          />
          <SubMenu
            v-if="subMenu && openMenus.includes(key)"
            :key="`${key}-menu`"
            :menu="subMenu"
          />
        </template>
      </div>
      <CurrentUser />
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ChecButton } from '@chec/ui-library';
import navigation from '@/router/navigation';
import ChecLogo from '@/assets/svgs/chec-logo.svg';
import MerchantSelector from './Navigation/MerchantSelector.vue';
import NavItem from './Navigation/NavItem.vue';
import SubMenu from './Navigation/SubMenu.vue';
import CurrentUser from './Navigation/CurrentUser.vue';

export default {
  name: 'Navigation',
  components: {
    ChecButton,
    ChecLogo,
    CurrentUser,
    MerchantSelector,
    NavItem,
    SubMenu,
  },
  data() {
    return {
      navOpen: false,
      openMenus: [],
    };
  },
  computed: {
    ...mapGetters('merchant', ['hasCompletedSetup']),
    ...mapState('merchant', ['merchant']),
    ...mapGetters('notifications', ['hasNewOrder']),
    navTree() {
      if (!this.merchant.has.enabled_hosted_storefront) {
        return navigation.filter((item) => item.key !== 'spaces');
      }
      return navigation;
    },
    activeRouteName() {
      const { matched } = this.$route;
      const route = matched[matched.length - 1];
      if (route) {
        return route.name;
      }

      return null;
    },
    activeTopLevel() {
      // Try to find a nav item that has the exact current route
      const section = this.navTree.find(({ link, subMenu }) => {
        // Check the base link
        if (typeof link === 'object' && link.name && link.name === this.activeRouteName) {
          return true;
        }

        // Check in a submenu if there is one
        return subMenu && subMenu.some((candidate) => (
          typeof candidate.link === 'object'
          && candidate.link.name
          && candidate.link.name === this.activeRouteName
        ));
      });

      if (section) {
        return section.key;
      }

      // Now check if the active route name matches one of the "baseRoute" configurations on the nav
      // items
      const parent = this.navTree.find(
        ({ baseRoute }) => this.activeRouteName.startsWith(baseRoute),
      );

      if (parent) {
        return parent.key;
      }

      return null;
    },
    pings() {
      return {
        gettingStarted: this.hasCompletedSetup === false,
        orders: this.hasNewOrder,
      };
    },
    /**
     * Nav menu items
     *
     * @returns {Array}
     */
    navMenuItems() {
      // If setup is completed, remove 'Getting started' nav item
      if (this.hasCompletedSetup) {
        return this.navTree.slice(1);
      }
      const updatedNav = [...this.navTree];
      // Remove 'Home' nav item from original nav tree
      updatedNav.splice(1, 1);
      return updatedNav;
    },
  },
  watch: {
    $route() {
      this.openMenus = [this.activeTopLevel];
      this.navOpen = false;
    },
  },
  mounted() {
    this.openMenus = [this.activeTopLevel];
  },
  created() {
    // add event listener to listen to outside click events
    window.addEventListener('click', this.onOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onOutsideClick);
  },
  methods: {
    onOutsideClick(event) {
      // Only do anything if the nav is open
      if (!this.navOpen) {
        return;
      }

      // Check that part of the nav isn't being clicked
      if (
        this.$refs.header.contains(event.target) || this.$refs.navigation.contains(event.target)
      ) {
        return;
      }

      this.navOpen = false;
    },
    toggleSubNav(key) {
      // Check if we're closing a menu that's open
      if (this.openMenus.includes(key)) {
        this.openMenus = this.openMenus.filter((candidate) => candidate !== key);
        return;
      }

      // Close any menus that aren't the top level before opening another
      const menus = [];
      if (this.activeTopLevel && this.openMenus.includes(this.activeTopLevel)) {
        menus.push(this.activeTopLevel);
      }

      menus.push(key);
      this.openMenus = menus;
    },
  },
};
</script>

<style lang="scss">
%chec-logo {
  @apply fill-current text-gray-500 w-12 h-auto justify-start mx-2 my-4;
}

.navigation-bar {
  @apply
    fixed top-0 left-0 w-screen z-50 h-20 bg-white shadow-md
    flex items-center justify-between p-4;

  &__logo {
    @extend %chec-logo;
  }

  @screen lg {
    @apply hidden;
  }
}

.navigation {
  @apply h-screen bg-white fixed top-0 left-0 p-4 z-30 flex flex-col pt-24 w-screen shadow-md;

  @screen sm {
    width: 15rem;
  }

  // Transitions:
  @apply transform transition ease-in-out duration-500 delay-100;
  // stylelint-disable-next-line order/order
  --transform-translate-x: -100vw;

  @screen sm {
    // stylelint-disable-next-line order/order
    --transform-translate-x: -15rem;
  }

  @screen lg {
    @apply pt-0 translate-x-0 transition-none;
  }

  &--open {
    // delay-0 here ensures there's no delay when --open is applied (ie. during opening)
    transition-delay: 0ms;
    @apply translate-x-0;
  }

  &__skip {
    @apply fixed p-1 bg-white;
    left: -500px;

    &:focus,
    &:active {
      @apply left-0 top-0 z-30;
    }
  }

  &__logo {
    @extend %chec-logo;
    // Hide the logo in the sidebar when it's on smaller viewports
    @apply hidden mt-6;

    @screen lg {
      @apply block;
    }
  }

  &__items {
    @apply my-5 flex-grow overflow-auto;
  }

  &__divider {
    @apply my-2 border-b border-gray-300 h-0;
  }
}
</style>
