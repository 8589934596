<template>
  <div class="timestamp">
    <span v-if="prefix" class="timestamp__prefix">
      {{ prefix }}
    </span>
    <span class="timestamp__date">
      {{ date }}
    </span>
    <template v-if="!hideTime && moment">
      <span class="timestamp__separator">
        @
      </span>
      <span class="timestamp__time">
        {{ moment.format('LT') }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Timestamp',
  props: {
    /**
     * A moment object, a number for a unix timestamp, or a string that can be parsed by Moment
     */
    timestamp: {
      type: [Number, String, Object],
    },
    /**
     * Whether to omit showing the time
     */
    hideTime: Boolean,
    /**
     * Whether to show dates relative to the current date (and show dates like "today")
     */
    fromNow: Boolean,
    /**
     * An optional prefix for the date (eg. Last saved)
     */
    prefix: String,
    /**
     * Optional text to use for null timestamps. Defaults to "never"
     */
    missingText: String,
  },
  computed: {
    /**
     * Convert the given prop to an actual moment object
     *
     * @returns {Moment}
     */
    moment() {
      const { timestamp } = this;

      if (!timestamp || this.$moment.isMoment(timestamp)) {
        return timestamp;
      }

      if (typeof timestamp === 'number') {
        return this.$moment.unix(timestamp);
      }

      return this.$moment(timestamp);
    },
    /**
     * Calculate what to display for the date. Either a date string or something like "today"
     *
     * @returns {string}
     */
    date() {
      if (!this.moment) {
        return this.missingText || this.$t('general.never');
      }

      if (!this.fromNow) {
        return this.moment.format('L');
      }

      if (this.moment.isSame(this.$moment(), 'day')) {
        return this.$t('general.today');
      }

      if (this.moment.isSame(this.$moment().subtract(1, 'day'), 'day')) {
        return this.$t('general.yesterday');
      }

      return this.moment.format('L');
    },
  },
};
</script>

<style lang="scss">
.timestamp {
  @apply caps-xxs text-gray-500;

  &__prefix {
    @apply font-normal;
  }

  &__separator,
  &__time {
    @apply text-gray-400;
  }
}
</style>
