import resolveDomain from '@/lib/resolveDomain';

export default [
  {
    icon: 'star',
    key: 'gettingStarted',
    label: 'home.gettingStarted',
    link: { name: 'home' },
  },
  {
    icon: 'home',
    key: 'home',
    label: 'general.home',
    link: { name: 'home' },
  },
  {
    key: 'orders',
    label: 'order.orders',
    baseRoute: 'orders',
    link: { name: 'orders.home' },
  },
  {
    key: 'products',
    label: 'product.products',
    link: { name: 'products.home' },
  },
  {
    key: 'categories',
    label: 'category.categories',
    link: { name: 'categories.home' },
  },
  {
    key: 'customers',
    label: 'customer.customers',
    link: { name: 'customers.home' },
  },
  {
    key: 'discounts',
    label: 'discount.discounts',
    link: { name: 'discounts.home' },
  },
  {
    key: 'developers',
    label: 'settings.developer',
    subMenu: [
      { label: 'developer.apiKeysCors', link: { name: 'developer.apiKeysCors' } },
      { label: 'webhooks.webhooks', link: { name: 'settings.webhooks.home' } },
      { label: 'logs.logs', link: { name: 'developer.logs' } },
    ],
  },
  {
    icon: 'integrations',
    key: 'integrations',
    label: 'integration.integrations',
    link: { name: 'integrations.home' },
  },
  {
    key: 'settings',
    label: 'settings.settings',
    subMenu: [
      { label: 'general.merchant', link: { name: 'settings.merchant' } },
      { label: 'settings.paymentGateways', link: { name: 'settings.paymentGateways' } },
      { label: 'settings.shipping', link: { name: 'settings.shipping' } },
      { label: 'settings.tax.title', link: { name: 'settings.tax' } },
      { label: 'attributes.attributes', link: { name: 'settings.attributes.home' } },
      { label: 'extraFields.extraFields', link: { name: 'settings.extraFields.home' } },
      { label: 'billing.billing', link: { name: 'settings.billing.home' } },
      { label: 'settings.notifications.title', link: { name: 'settings.notifications.home' } },
    ],
  },
  {
    divider: true,
    key: 'spacesDivider',
  },
  {
    key: 'spaces',
    label: 'spaces.storefront',
    link: `${resolveDomain('spaces')}/{merchant}`,
  },
  // @todo add back once Vercel sorted.
  // {
  //   key: 'rays',
  //   label: 'rays.commerceRays',
  //   link: { name: 'rays.home' },
  // },
];
