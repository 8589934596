import {
  applyPagination,
  mutations as paginationMutations,
  getters as paginationGetters,
} from '@/lib/pagination';
import { makeApiRequest } from '@/lib/api';
import getterTypes from '@/lib/pagination/getters';

export const actions = {
  BULK_UPDATE: 'BULK_UPDATE',
  BULK_DELETE: 'BULK_DELETE',
  BULK_CREATE: 'BULK_CREATE',
  LOAD_INVALID_COUNT: 'LOAD_INVALID_COUNT',
};

export const mutations = {
  SET_INVALID_COUNT: 'SET_INVALID_COUNT',
};

export default applyPagination('/v1/products/:productId/variants', {
  searchProperties: ['sku'],
})({
  namespaced: true,
  state: {
    invalidCount: null,
  },
  mutations: {
    [mutations.SET_INVALID_COUNT](state, count) {
      state.invalidCount = count;
    },
  },
  actions: {
    [actions.BULK_UPDATE]({ commit, getters, state: { context } }, {
      optimistic = true,
      variants,
    }) {
      if (!Array.isArray(variants) || variants.length === 0) {
        return Promise.resolve();
      }

      // Find the existing variants in state for restoring failed updates
      const contextualState = getters[paginationGetters.GET_CONTEXTUAL_STATE](context);
      const replacer = (variant) => commit(paginationMutations.REPLACE_ITEM, {
        item: variant,
        context,
      });

      const variantIds = variants.map(({ id }) => id);
      const existingVariants = contextualState.items.filter(
        (candidate) => variantIds.includes(candidate.id),
      );

      if (optimistic) {
        variants.forEach(replacer);
      }

      return makeApiRequest('PUT', `/v1/products/${context.productId}/variants`, {
        // Map the variants to objects only containing data that can be updated (and the ID)
        // Note that the API supports more than this, but the UI only supports these 3 right now
        variants: variants.map(({
          id,
          sku,
          price,
          inventory,
          description,
        }) => ({
          id,
          sku,
          price: price && typeof price === 'object' ? price.raw : price,
          inventory,
          description,
        })),
      })
        .then(({ data: { data: updatedVariants } }) => {
          updatedVariants.forEach(replacer);
          return updatedVariants;
        })
        .catch((error) => {
          if (!optimistic) {
            throw error;
          }

          // Switch all the variants back to their old values as there was an issue
          existingVariants.forEach(replacer);
          throw error;
        });
    },
    [actions.BULK_DELETE]({ commit, getters, state: { context } }, {
      optimistic = true,
      variantIds,
    }) {
      if (!Array.isArray(variantIds) || variantIds.length === 0) {
        return Promise.resolve();
      }

      // If we're not doing this optimistically then the action is pretty straight forward
      if (!optimistic) {
        return makeApiRequest('DELETE', `/v1/products/${context.productId}/variants`, {
          variants: variantIds,
        })
          .then(() => {
            // Remove the deleted variants from state
            variantIds.forEach((id) => {
              commit(paginationMutations.REMOVE_ITEM, { context, item: { id } });
            });
          });
      }

      const contextualState = getters[paginationGetters.GET_CONTEXTUAL_STATE](context);

      // Find the variants in state that are being deleted, and their location in state (index). We
      // need to do this as we have to restore them in the correct location if the delete fails (as
      // we're deleting optimistically)
      const [existingVariants, existingIndexes] = contextualState.items.reduce(
        ([variants, indexes], candidate, index) => {
          if (!variantIds.includes(candidate.id)) {
            return [variants, indexes];
          }

          return [[
            ...variants,
            candidate,
          ], [
            ...indexes,
            index,
          ]];
        },
        [[], []],
      );

      // Remove the variants from state when being optimistic
      if (optimistic) {
        existingVariants.forEach((variant) => {
          commit(paginationMutations.REMOVE_ITEM, { context, item: variant });
        });
      }

      return makeApiRequest('DELETE', `/v1/products/${context.productId}/variants`, {
        variants: variantIds,
      })
        .then(() => {
          const existingCount = getters[getterTypes.GET_CONTEXTUAL_STATE](context)?.count || 0;
          commit(paginationMutations.SET_TOTAL_COUNT, {
            count: existingCount - existingVariants.length,
            context,
          });
        })
        .catch(() => {
          // Restore the variants in their original locations
          existingVariants.forEach((variant, index) => {
            commit(paginationMutations.ADD_ITEM, {
              context,
              item: variant,
              atIndex: existingIndexes[index],
            });
          });
        });
    },
    [actions.BULK_CREATE]({ commit, getters, state }, {
      context: providedContext,
      variants,
    }) {
      if (!Array.isArray(variants) || variants.length === 0) {
        return Promise.resolve();
      }

      const { context: defaultContext } = state;
      const context = providedContext || defaultContext;

      return makeApiRequest('POST', `/v1/products/${context.productId}/variants`, {
        variants,
      }).then(({ data: { data: newVariants } }) => {
        newVariants.forEach((item) => commit(paginationMutations.ADD_ITEM, {
          context,
          item,
          prepend: true,
        }));
        const existingCount = getters[getterTypes.GET_CONTEXTUAL_STATE](context)?.count || 0;
        commit(paginationMutations.SET_TOTAL_COUNT, {
          count: existingCount + variants.length,
          context,
        });
      });
    },
    [actions.LOAD_INVALID_COUNT]({ commit, state }, { context: providedContext } = {}) {
      const { context: defaultContext } = state;
      const context = providedContext || defaultContext;

      makeApiRequest('GET', `/v1/products/${context.productId}/variants?is_valid=0`)
        .then(({ data: { meta: { pagination: { count } } } }) => {
          commit(mutations.SET_INVALID_COUNT, count);
        });
    },
  },
});
