<template>
  <footer class="footer container">
    <span class="footer__copyright">
      &copy; {{ new Date().getFullYear() }} Chec Platform, Inc.
    </span>
    <ul class="footer__links space-x-8">
      <li>
        <a href="https://status.chec.io" target="_blank" class="footer__link">
          <StatusIndicator />
          Status
        </a>
      </li>
      <li><a href="https://commercejs.com/docs" target="_blank" class="footer__link">Documentation</a></li>
      <li><a href="http://support.commercejs.com" target="_blank" class="footer__link">Support</a></li>
    </ul>
  </footer>
</template>

<script>
import StatusIndicator from './StatusIndicator.vue';

export default {
  name: 'ChecFooter',
  components: {
    StatusIndicator,
  },
};
</script>

<style lang="scss">
.footer {
  @apply flex flex-col-reverse text-gray-400 font-bold caps-xxs py-8
    text-center border-t border-gray-300 mt-20 mx-auto;

  @screen sm {
    @apply mt-20 flex-row text-left;
  }

  &__copyright {
    @apply flex-grow;
  }

  &__links {
    @apply flex text-gray-500 justify-center mb-2;

    @screen sm {
      @apply mb-0;
    }
  }

  &__link {
    &:hover,
    &:focus,
    &:active {
      @apply text-gray-600;
    }
  }
}
</style>
