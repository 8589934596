<template>
  <span
    v-if="hasNavigatorClipboard"
    v-tooltip="tooltipText"
    @click.stop="handleCopy"
  >
    <slot />
  </span>
</template>

<script>

export default {
  name: 'CopyInline',
  props: {
    copyContent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      timers: [],
    };
  },
  computed: {
    tooltipText() {
      return this.copied
        ? this.$t('general.copied')
        : this.$t('general.clickToCopy');
    },
  },
  methods: {
    /**
     * @returns {boolean}
     */
    hasNavigatorClipboard() {
      return (
        typeof window.navigator !== 'undefined'
        && typeof window.navigator.clipboard !== 'undefined'
      );
    },
    handleCopy() {
      window.navigator.clipboard.writeText(this.copyContent).then(() => {
        this.copied = true;

        // Cancel old timers
        this.timers.forEach((timer) => window.clearTimeout(timer));
        this.timers = [];

        // Push new timer
        this.timers.push(
          window.setTimeout(() => {
            this.copied = false;
          }, 3000),
        );
      });
    },
  },
};
</script>
