import actions from '../store/actions';
import i18n from '../lang';

export default {
  methods: {
    /**
     * Open a modal confirmation dialog presenting the message with cancel/accept actions.
     *
     * @param {string} title E.g. "Are you sure?"
     * @param {string} message E.g. "Are you sure you wish to delete this record?"
     * @param {string} options Customise the "cancel" and "accept" button labels
     */
    async confirm(title, message, options = {
      cancelLabel: i18n.t('general.cancel'),
      confirmLabel: i18n.t('general.yes'),
    }) {
      return this.$store.dispatch(`notifications/${actions.OPEN_CONFIRM_DIALOG}`, {
        title,
        message,
        options,
      });
    },
  },
};
