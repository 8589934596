export default [
  {
    path: '/settings/extra-fields',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('extraFields.extraFields'),
    },
    children: [
      {
        path: '',
        name: 'settings.extraFields.home',
        component: () => import(/* webpackChunkName: "settings" */ './views/home.vue'),
      },
      {
        path: 'new',
        name: 'settings.extraFields.add',
        component: () => import(/* webpackChunkName: "settings" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('extraFields.add'),
        },
      },
      {
        path: ':id(extr_[^/]+)',
        name: 'settings.extraFields.edit',
        component: () => import(/* webpackChunkName: "settings" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('extraFields.edit'),
        },
      },
    ],
  },
];
