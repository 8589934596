export default [
  {
    path: '/customers',
    component: () => import(/* webpackChunkName: "customers" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('customer.customers'),
    },
    children: [
      {
        path: '',
        name: 'customers.home',
        component: () => import(/* webpackChunkName: "customers" */ './views/home.vue'),
      },
      {
        path: ':id(cstmr_[^/]+)',
        name: 'customers.edit',
        component: () => import(/* webpackChunkName: "customers" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('customer.edit'),
        },
      },
      {
        path: 'add',
        name: 'customers.add',
        component: () => import(/* webpackChunkName: "customers" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('customer.add'),
        },
      },
    ],
  },
];
