import * as Sentry from '@sentry/browser';
import i18n from '@/lang';
import actions from '@/store/actions';
import {
  actions as paginationActions,
  applyPagination,
  getters as paginationGetters,
} from '@/lib/pagination';
import { setExtraParams } from '@/lib/api';
import mutations from '@/store/mutations';
import apiKeys from './api-keys';

const {
  FETCH_MERCHANT,
} = actions;
const {
  SET_MERCHANT,
  SET_ERROR,
  SET_LOADING,
} = mutations;

/**
 * Runs things that configure for a merchant, e.g. Sentry
 */
const configureMerchant = async (merchantId, dispatch) => {
  await Promise.all([
    dispatch(actions.BOOT_INTERCOM, {}, { root: true }),
    dispatch(actions.BOOT_PUSHER, {}, { root: true }),
  ]);
  // Let Sentry know which merchant is in use
  Sentry.setTag('merchant', merchantId);

  console.debug('Intercom/Pusher/Sentry merchant context switched to:', merchantId);
};

export default applyPagination('/v1/merchants?sortBy=id&sortDirection=desc', {
  excludeGlobalContext: true,
  individualItemEndpoint: '/v1/merchants',
  prepItemForPost: (item) => {
    if (!item.images) {
      return item;
    }

    const images = {
      cover: null,
      logo: null,
    };
    if (item.images.logo !== null) {
      images.logo = item.images.logo.id;
    }
    if (item.images.cover !== null) {
      images.cover = item.images.cover.id;
    }
    return {
      ...item,
      images,
    };
  },
})({
  namespaced: true,
  modules: {
    apiKeys,
  },
  state: {
    error: null,
    merchant: {},
  },
  mutations: {
    [SET_MERCHANT](state, { merchant }) {
      const merchantChanged = state.merchant?.id === merchant.id;

      // Refresh merchant state regardless of if we're the current merchant.
      state.merchant = merchant;

      // Don't bother doing work if we don't need to
      if (merchantChanged) {
        return;
      }

      // Do a bunch of side-effects that shouldn't affect Vue(X) state and cause rerenders

      // Add the merchant as a global parameter for making API requests
      setExtraParams(Object.freeze({ merchant: merchant.id }));

      // Set the merchant in local storage so we remember going forwards
      if (window?.localStorage) {
        window.localStorage.setItem('active_merchant', merchant.id);
      }
    },
    [SET_ERROR](state, error) {
      state.error = error;
    },
  },
  actions: {
    /**
     * Gets information about the current merchant
     */
    async [FETCH_MERCHANT](
      {
        commit,
        dispatch,
        getters,
        state,
      },
      {
        id,
        showLoading = true,
        force = false,
      } = {},
    ) {
      if (showLoading) {
        commit(SET_LOADING, true, { root: true });
      }

      // Use the given merchant ID, or check local storage for one saved from the last session
      let activeMerchant = id || null;
      if (!id && window?.localStorage) {
        activeMerchant = parseInt(window.localStorage.getItem('active_merchant'), 10) || null;
      }

      if (activeMerchant) {
        try {
          // Rely on pagination actions to fetch the active merchant
          await dispatch(paginationActions.LOAD_ITEM, { id: activeMerchant, force });
          const merchant = getters[paginationGetters.GET_ITEM](activeMerchant);

          // If we have a merchant now, we can set that and be done. We will still fall back to the
          // logic below, in case the active merchant in local storage is not valid. This can happen
          // when a user switches between multiple accounts
          if (merchant) {
            commit(SET_MERCHANT, {
              merchant,
            });
            // Set the global context to include the merchant
            commit(mutations.SET_GLOBAL_CONTEXT, { merchant: merchant.id }, { root: true });
            commit(SET_ERROR, null);

            // Re-configure things that require a merchant for context
            await configureMerchant(merchant.id, dispatch);
            return;
          }
        } catch (error) {
          // If a specific ID was provided, throw the error to the caller
          if (id) {
            throw error;
          }
        } finally {
          if (showLoading) {
            commit(SET_LOADING, false, { root: true });
          }
        }
      }

      // Prepare the first page of merchants for the select merchant screen, or we can pre-select
      // the only merchant if there's just one
      await dispatch(paginationActions.SET_PAGE, { page: 1, isLoading: true });

      if (state.totalCount >= 1 && state.items[0].id) {
        // Recursive call to set the merchant with the known ID
        await dispatch(actions.FETCH_MERCHANT, {
          id: state.items[0].id,
          showLoading: false,
        });
        // Configure the new merchant
        await configureMerchant(state.items[0].id, dispatch);
      }

      if (showLoading) {
        commit(SET_LOADING, false, { root: true });
      }
    },
  },
  getters: {
    /**
     * Returns a list of the merchant's setup/onboarding steps and whether they're
     * completed yet.
     *
     * @param {string} state
     * @returns {object[]}
     */
    setupSteps: (state) => {
      if (!state.merchant) {
        return [];
      }

      const { signup_purpose: purpose, statistics } = state.merchant;

      return [
        {
          route: 'products.home',
          name: i18n.t('home.addProducts'),
          complete: statistics?.products > 0,
        },
        ...(purpose === 'rays' ? [{
          route: 'rays.home',
          name: i18n.t('home.createCommerceRay'),
          complete: statistics?.rays > 0,
        }] : []),
        {
          route: 'settings.paymentGateways',
          name: i18n.t('home.enableGateway'),
          // eslint-disable-next-line camelcase
          complete: statistics?.has_payment_gateway,
        },
        {
          route: 'settings.tax',
          name: i18n.t('home.configureTax'),
          // eslint-disable-next-line camelcase
          complete: statistics?.tax_rates > 0,
        },
        {
          route: 'settings.shipping',
          name: i18n.t('home.configureShipping'),
          // eslint-disable-next-line camelcase
          complete: statistics?.shipping_zones > 0,
        },
      ]
        // Sort these entries putting completed ones at the top and preserve order otherwise
        .sort((a, b) => {
          if (a.complete === b.complete) {
            return 0;
          }

          if (a.complete) {
            return -1;
          }

          return 1;
        });
    },
    /**
     * Determines whether the merchant has completed their setup process/onboarding.
     *
     * Show the getting started process as complete if there's a live order, or they've completed
     * all the steps.
     *
     * @param {object} state
     * @param {object} getters
     * @returns {boolean}
     */
    hasCompletedSetup: (state, getters) => (state.merchant && state.merchant.statistics?.orders > 0)
      || getters.setupSteps.filter((step) => !step.complete).length === 0,
  },
});
