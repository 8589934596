import { createPaginationModule } from '@/lib/pagination';

export default createPaginationModule('/v1/attributes?sortBy=created_at&sortDirection=DESC', {
  namespaced: true,
  prepItemForPost: (item) => {
    const saveableAttribute = item;
    if (![
      'options',
      'radio',
    ].includes(item.type)) {
      delete saveableAttribute.options;
      saveableAttribute.is_multiselect = false;
    }

    if (item.description === '') {
      delete saveableAttribute.description;
    }

    if (item.default_value === '') {
      delete saveableAttribute.default_value;
    }
    return saveableAttribute;
  },
});
