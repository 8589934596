import Index from './views/index.vue';
import Home from './views/home.vue';
import Add from './views/add.vue';
import Configure from './views/configure.vue';
import Edit from './views/edit.vue';

export default [
  {
    path: '/integrations',
    component: Index,
    meta: {
      title: (i18n) => i18n.t('integration.integrations'),
    },
    children: [
      {
        path: '',
        name: 'integrations.home',
        component: Home,
        children: [
          {
            path: 'add',
            name: 'integrations.add',
            component: Add,
            meta: {
              title: (i18n) => i18n.t('integration.add'),
            },
            children: [
              {
                path: ':templateId',
                name: 'integrations.configure',
                component: Configure,
                meta: {
                  title: (i18n) => i18n.t('integration.add'),
                },
              },
            ],
          },
        ],
      },
      {
        path: ':id(int_[^/]+)',
        name: 'integrations.edit',
        component: Edit,
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('integration.edit'),
        },
      },
    ],
  },
];
