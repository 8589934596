import { v4 as uuidv4 } from 'uuid';
import i18n from '@/lang';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import { makeApiRequest } from '@/lib/api';

const {
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_SETTINGS,
  SET_CONFIRM_DIALOG,
  SET_ERRORS,
  PUSH_ERROR,
  SET_NEW_ORDER,
} = mutations;

const {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  FETCH_NOTIFICATION_SETTINGS,
  NEW_ORDER,
  OPEN_CONFIRM_DIALOG,
  UPDATE_NOTIFICATION_SETTINGS,
} = actions;

const timers = [];

export default {
  namespaced: true,
  state: {
    notifications: [],
    confirmDialog: {
      open: false,
      onCancel: () => false,
      onConfirm: () => true,
      title: null,
      message: null,
      options: {
        cancelLabel: i18n.t('general.cancel'),
        confirmLabel: i18n.t('general.yes'),
      },
    },
    newOrder: false,
    settings: {},
  },
  mutations: {
    [SET_NOTIFICATIONS](state, payload) {
      state.notifications = payload;
    },
    /**
     * Set the notification settings for the merchant
     *
     * @param {Object} state
     * @param {Object} payload
     */
    [SET_NOTIFICATION_SETTINGS](state, payload) {
      state.settings = payload;
    },
    /**
     * Adjust the state of the modal confirmation dialog
     *
     * @param {Object} state
     * @param {Object} payload
     */
    [SET_CONFIRM_DIALOG](state, payload) {
      state.confirmDialog = payload;
    },
    /**
     * Sets state for whether there is a new order or not
     *
     * @param {Object} state
     * @param {boolean} payload
     */
    [SET_NEW_ORDER](state, payload) {
      state.newOrder = payload;
    },
  },
  actions: {
    [ADD_NOTIFICATION]({ commit, state }, {
      type,
      text,
      buttonText,
      buttonRoute,
      hideTime = 5000,
    }) {
      commit(SET_NOTIFICATIONS, [
        {
          // Generate an ID for it
          id: uuidv4(),
          type,
          text,
          hideTime,
          buttonText,
          buttonRoute,
        },
        ...state.notifications,
      ]);
    },
    [DISMISS_NOTIFICATION]({ commit, state }, notificationId) {
      commit(SET_NOTIFICATIONS, [
        ...state.notifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      ]);
    },
    /**
     * Opens a confirmation dialog
     */
    [OPEN_CONFIRM_DIALOG]({ commit }, payload) {
      return new Promise((resolve) => {
        commit(SET_CONFIRM_DIALOG, {
          open: true,
          onCancel: () => resolve(false),
          onConfirm: () => resolve(true),
          ...payload,
        });
      }).finally(() => commit(SET_CONFIRM_DIALOG, { open: false }));
    },
    /**
     * Sets state for a new order notification. This is shown as a ping on the Orders
     * nav item and disappears after a few seconds.
     */
    [NEW_ORDER]({ commit }) {
      // Clear any old timers
      timers.forEach((timer) => window.clearTimeout(timer));

      commit(SET_NEW_ORDER, true);
      timers.push(setTimeout(() => commit(SET_NEW_ORDER, false), 20000));
    },
    /**
     * Fetches notification settings for the merchant, e.g. whether emails are turned
     * on or not.
     */
    [FETCH_NOTIFICATION_SETTINGS]({ commit, rootState }) {
      return makeApiRequest(
        'get',
        `/v1/merchants/${rootState.merchant.merchant.id}/notifications`,
      )
        .then(({ data }) => {
          commit(SET_NOTIFICATION_SETTINGS, data);
          commit(SET_ERRORS, [], { root: true });
        })
        .catch((error) => commit(PUSH_ERROR, error, { root: true }));
    },
    /**
     * Save the notification settings
     */
    [UPDATE_NOTIFICATION_SETTINGS]({ commit, rootState, dispatch }, payload) {
      makeApiRequest(
        'put',
        `/v1/merchants/${rootState.merchant.merchant.id}/notifications`,
        payload,
      )
        .then(({ data }) => {
          commit(SET_NOTIFICATION_SETTINGS, data);
          // Re-fetch merchant, since "dashboard notifications" setting is stored there
          dispatch('merchant/FETCH_MERCHANT', {
            showLoading: false,
            force: true,
          }, { root: true });
        });
    },
  },
  getters: {
    confirmDialog: (state) => state.confirmDialog,
    notificationSettings: (state) => state.settings,
    hasNewOrder: (state) => state.newOrder,
  },
};
