export default {
  account: {
    account: "アカウント",
    currentPassword: "現在のパスワード",
    dashboardLanguage: "ダッシュボード言語",
    deleteAccount: "アカウントの削除",
    deleteCallout: "アカウントを完全に削除しようとしています。",
    deleteFailed: "アカウントの削除に失敗しました。後ほど再度お試しください。",
    deleteMessage: "アカウントの削除を取り消すことはできません。すべての注文情報をエクスポートしたことを確認してください。",
    deleteMyAccount: "アカウントの削除",
    details: "アカウントの詳細",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "アカウント情報",
    language: "言語",
    logout: "ログアウト",
    missingPasswords: "現在のパスワードと新しいパスワードを入力してください。",
    newPassword: "新しいパスワード",
    passwordUpdateFailed: "パスワードを更新できませんでした。もう一度お試しください。",
    passwordUpdated: "パスワードが更新されました",
    updateDetails: "更新内容",
    updateFailed: "アカウント情報の保存に失敗しました。もう一度お試しください。",
    updateLanguage: "言語の更新",
    updatePassword: "パスワードの更新",
    updatedDetailsSuccessfully: "Details updated successfully.",
    updatedLanguageSuccessfully: "Language updated successfully."
  },
  address: {
    city: "市区町村",
    country: "国",
    line1: "番地",
    line2: "建物名・部屋番号など",
    name: "名前",
    state: "都道府県",
    zipCode: "郵便番号"
  },
  attribute: {
    attributes: "Attributes"
  },
  attributes: {
    add: "Add attribute",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Attributes",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Default value",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "フィールドタイプは必須です",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Something went wrong. Please try logging in again.",
    unableToAuth: "Unable to authorize your account. Please try again."
  },
  billing: {
    accruedFees: "未払取引手数料",
    addCard: "カードを追加",
    addPaymentMethod: "お支払い方法を追加",
    billing: "請求",
    changePlan: "プランの変更",
    checFee: "Chec手数料 ({currency})",
    choosePlan: "プランを選択",
    confirmChangePlan: "変更するプランについて、プラットフォーム機能（Commerce Raysなど）に制限がある場合、新しいプランの制限に合わせてリソースが自動的にスケールバックされます。",
    currentPlan: "現在のプラン",
    customPricing: "カスタム価格",
    emailAddress: "請求先メールアドレス",
    invoiceAmount: "金額",
    invoiceDate: "日付",
    invoiceDescription: "詳細",
    invoiceDownload: "ダウンロード",
    invoiceStatus: "状態",
    invoices: "請求",
    monthlyCeiling: "{ceiling} 月間最大値",
    noPaymentMethod: "支払い方法が登録されていません",
    orderValue: "注文金額 ({currency})",
    overageFee: "{fee} 超過額",
    paid: "支払い済み",
    paymentGateway: "決済ゲートウェイ",
    paymentMethod: "支払い方法",
    paymentMethodRequired: "新しいプランを選択する前に、お支払い方法を追加してください",
    perMonth: "/月",
    perTransaction: "トランザクションあたり",
    planDetails: "プランの詳細",
    planMonthlyCeiling: "{ceiling} 月ごとの最大枠",
    planName: "{name} プラン",
    planOverage: "{overage} 超過",
    planSummary: "月ごとに {perMonth} 、トランザクションごとに {perTransaction} が課金されます。",
    planTransactionFee: "{fee} 取引手数料",
    transactionFeeOrder: "注文",
    transactionFeesTitle: "{date} に請求された手数料",
    unpaid: "未払い",
    updateCard: "更新",
    updateEmail: "請求先メールアドレスの更新",
    viewInvoice: "請求書を表示",
    voided: "無効化されました"
  },
  category: {
    add: "カテゴリーを追加",
    addSubCategory: "Add sub-category",
    added: "カテゴリーを追加しました。",
    addedSubCategory: "Added sub-category.",
    categories: "カテゴリー",
    chooseParentCategory: "Choose parent category",
    confirmDelete: "このカテゴリを削除してもよろしいですか？",
    confirmDeleteTitle: "よろしいですか？",
    create: "カテゴリーを作成",
    createError: "カテゴリーを作成する際に問題が発生しました。",
    deleteFailed: "Failed to delete your category! Please try again.",
    deleted: "カテゴリーが削除されました",
    edit: "カテゴリーの編集",
    editMultiple: "カテゴリーを編集する",
    images: {
      title: "画像ギャラリー",
      types: "| {types} 形式がアップできます | {types} , {lastType} 形式がアップできます"
    },
    introSplash: {
      description: "Create infinitely nestable product categories with category images and descriptions. {more}.",
      more: "詳細",
      title: "Add categories and sub-categories."
    },
    noAvailableCategories: "Please add another category first",
    noSubCategories: "This category has no sub-categories.",
    permalink: "Permalink",
    pleaseEnterName: "名前を入力してください",
    pleaseEnterPermalink: "Please enter a permalink",
    save: "カテゴリーを保存",
    selectPlaceholder: "カテゴリーを選択",
    subCategories: "サブカテゴリ",
    update: "カテゴリーを更新",
    updateError: "カテゴリーの保存中に問題が発生しました。",
    updateSubCategoryError: "Sorry, we're having problems saving this sub-category.",
    updated: "カテゴリーを更新しました。"
  },
  customer: {
    add: "顧客を追加",
    added: "顧客を追加しました",
    confirmDelete: "この顧客情報を削除してもよろしいですか？",
    create: "顧客を作成する",
    createError: "顧客の作成中に問題が発生しています",
    customers: "顧客",
    deleteFailed: "Failed to delete your customer! Please try again.",
    deleted: "顧客情報が削除されました",
    edit: "顧客の編集",
    emailTaken: "そのメールアドレスは既に使用されています",
    externalId: "外部ID",
    introSplash: {
      description: "Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.",
      title: "Your customers will be displayed here"
    },
    notesTitle: "備考",
    save: "顧客を保存する",
    update: "顧客情報の更新",
    updateError: "顧客情報の保存に失敗しました",
    updated: "顧客情報を更新しました"
  },
  developer: {
    apiKeys: "APIキー",
    apiKeysCors: "API keys & CORS",
    checCli: "Chec CLI",
    cliNotice: "{cli} を使用してリアルタイムにログをストリームします:{cliCommand}",
    copyCodeFailure: "コードをクリップボードにコピーできませんでした。ページを更新して、もう一度お試しください。",
    copyCodeSuccess: "コードはクリップボードにコピーされました。",
    corsDomainsHelp: "You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the \"Access-Control-Allow-Origin\" header. If you specify multiple, API calls will compare against the request's \"Origin\" header. If you leave this blank, all origins will be allowed (default).",
    crossOriginResourceSharing: "Cross-Origin Resource Sharing (CORS) origins",
    dateTime: "日付/時刻",
    ipAddress: "IPアドレス",
    keyUsed: {
      label: "使用されたキー",
      "public": "パブリック",
      secret: "シークレット"
    },
    method: "メソッド",
    request: "リクエスト",
    requestMethod: {
      "delete": "DELETE",
      get: "GET",
      label: "リクエストメソッド",
      post: "POST",
      put: "PUT"
    },
    response: "レスポンス",
    responseDate: {
      label: "レスポンス日"
    },
    responseStatus: {
      badRequest: "不正なリクエスト",
      label: "レスポンスステータス",
      notFound: "見つかりません",
      ok: "OK",
      other: "サーバまたはその他のエラー",
      requestFailed: "リクエストに失敗しました",
      unauthorized: "無許可",
      validation: "バリデーション"
    },
    summary: "概要",
    url: "URL"
  },
  discount: {
    add: "割引クーポンを追加",
    added: "割引クーポンを追加しました。",
    all: "すべて",
    code: "コード",
    codeRequired: "割引クーポンコードが必要です",
    confirmDelete: "この割引クーポンを削除してもよろしいですか？",
    create: "割引クーポンの作成",
    deleteFailed: "Failed to delete your discount! Please try again.",
    deleted: "割引クーポンが削除されました",
    discountCode: "割引クーポンコード",
    discountOffProduct: "{discount} オフ {product}",
    discounts: "割引",
    edit: "割引クーポンの編集",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "詳細",
      title: "Add a discount code and run a promotion."
    },
    neverExpires: "利用不可",
    productTooltip: "If left empty, this discount will work for all products",
    productsLabel: "Limit to these products",
    saveError: "割引クーポンの保存中に問題が発生しました。",
    updated: "割引が更新されました。",
    valueRequired: "割引額を入力してください"
  },
  error: {
    notFoundSuggestion: "前のページに戻り、もう一度お試しください。",
    notFoundTitle: "見つかりませんでした",
    permissionErrorSuggestion: "ログアウトしてから再度ログインをお試しください。問題が解決しない場合は、サポートにお問い合わせください。",
    permissionErrorTitle: "アクセスが拒否されました",
    serverErrorSuggestion: "恐れ入りますが、数分後に再度お試しください。なお、このエラーは開発者に通知されています。",
    serverErrorTitle: "エラーが発生しました",
    somethingWentWrong: "何らかの問題が発生しました"
  },
  extraFields: {
    add: "フィールドを追加する",
    addOptions: "入力またはカンマを押してオプションを追加します。",
    collectsBilling: "請求先住所を必須にする",
    collectsFullName: "フルネームを必須にする",
    collectsShipping: "配送先住所を必須にする",
    confirmDeleteExtraField: "この追加フィールドを削除してもよろしいですか?この追加フィールドに依存するストアフロントが影響を受ける可能性があります。",
    deleteFailed: "追加フィールドを削除できませんでした！もう一度お試しください。",
    deleted: "追加フィールドが正常に削除されました。",
    description: "顧客からカスタム情報を収集します。",
    edit: "追加フィールドの編集:{name}",
    extraFields: "追加フィールド",
    fieldType: "フィールドタイプ",
    fieldTypeDescription: "追加フィールドのフィールドタイプを選択します。これはフロントエンド用です。",
    introDescription: "グローバル追加フィールドは、通常はチェックアウト時にグローバルに使用できる、出品者が定義したカスタムデータフィールドです。たとえば、チェックアウトフォームで顧客の電話番号を収集するためのカスタム追加フィールドがあります。",
    introTitle: "Collect custom data from customers with global extra fields.",
    name: "追加フィールド名",
    nameRequired: "[名前]は必須です",
    saveFailure: "申し訳ありませんが、追加フィールドの保存でエラーが発生しました。",
    saveSuccess: "追加フィールドが正常に保存されました。",
    typeRequired: "フィールドタイプは必須です",
    updateSuccess: "追加フィールドが正常に更新されました。"
  },
  gateways: {
    add: "支払いゲートウェイを追加する",
    braintree: "Braintree",
    braintreeDocumentation: "ドキュメントを参照してください。",
    braintreeHelp: "Braintree のマーチャントID、公開キー、プライベートキー、トークン化キーを入力します。{documentation} トークン化キーの詳細については。",
    chooseGateway: "ゲートウェイを選択してください",
    confirmDelete: "この支払いゲートウェイを削除してもよろしいですか?これを使用している店舗は、この支払いゲートウェイからの支払いをすぐに受け付けなくなります。",
    deleteFailed: "この支払いゲートウェイを削除しようとしたときに何か問題が発生しました。もう一度お試しください。",
    deleted: "支払いゲートウェイが削除されました。",
    edit: "支払いゲートウェイの編集:{gateway}",
    email: "メールアドレス",
    gateway: "ゲートウェイ",
    introDescription: "支払いゲートウェイは、オンラインビジネスのクレジットカードまたは直接支払い処理を承認します。\n\nテストゲートウェイは有効になっています。ライブ支払いゲートウェイを追加するには、{introLink} に支払い方法を追加してください。",
    introTitle: "支払いゲートウェイを有効にして、支払いの回収を開始します。",
    keyId: "キー ID",
    keySecret: "キーシークレット",
    manual: "手動",
    manualConfig: {
      detailsHelp: "この支払い方法についての詳細-注文前に表示されます。",
      name: "支払い方法の名前",
      paymentInstructions: "支払い指示",
      paymentInstructionsHelp: "支払い方法の詳細-注文後に顧客に表示されます。"
    },
    manualHelp: "手動で支払いを記録し、この方法を使用して注文を出荷する必要があります。",
    manualName: "マニュアル: {name}",
    merchantId: "マーチャントID",
    omise: "おみせ",
    omiseDocumentation: "おみせドキュメント",
    omiseHelp: "Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.",
    paymentMethodHelper: "To add a live payment gateway, please add a payment method in the billing settings page.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "プライベートキー",
    publicKey: "パブリックキー",
    publishableKey: "公開可能なキー",
    razorpay: "Razorpay",
    redirectingToSquare: "Square にリダイレクトしています...",
    sandboxNotAvailable: "サンドボックスモードはこのゲートウェイでは使用できません。",
    saveFailed: "支払いゲートウェイの保存に失敗しました。すべての必須フィールドに入力されていることを確認してください。",
    saved: "支払いゲートウェイは正常に保存されました。",
    secretKey: "シークレットキー",
    square: "Square",
    squareHelp: "下の [変更を保存] をクリックすると、Square にリダイレクトされ、お支払いアカウントにログインします。",
    stripe: "Stripe",
    stripeDocumentation: "Stripeのドキュメント。",
    stripeHelp: "公開可能なキーとシークレットキーを入力します。両方とも Stripe ダッシュボードから入手できます。{documentation} で詳しく知る",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "テストゲートウェイ",
    tokenizationKey: "トークン化キー",
    transactionVolume: "トランザクションボリューム",
    weSupport: "サポートしているゲートウェイ"
  },
  general: {
    about: "バージョン情報",
    actions: "アクション",
    active: "有効",
    add: "追加する",
    adding: "追加中…",
    address: {
      city: "市区町村",
      country: "国",
      postalZip: "郵便番号",
      provinceState: "都道府県/州",
      street: "番地"
    },
    advanced: "詳細",
    all: "すべて",
    amount: "金額",
    amountValue: "金額: {value}",
    andMore: " & {n} もっと",
    areYouSure: "よろしいですか？",
    assets: "アセット",
    auto: "自動",
    backTo: "{page}に戻る",
    backToPreviousPage: "前のページに戻る",
    byAuthor: "{author} による",
    calculation: "計算",
    cancel: "取り消し",
    cancelled: "キャンセル済み",
    checkbox: "チェックボックス",
    checked: "Checked",
    clearSearch: "検索をクリア",
    clickToCopy: "クリックしてコピー",
    close: "閉じる",
    contactUs: "お問い合わせ",
    copied: "コピーしました",
    copy: "コピー",
    country: "国",
    creating: "作成中...",
    currency: "通貨",
    date: "日付",
    dateTime: "日付/時刻",
    "default": "デフォルト",
    "delete": "削除",
    description: "詳細",
    descriptionOptional: "詳細（任意）",
    details: "詳細",
    digital: "デジタル",
    disable: "無効にする",
    disabled: "無効",
    discount: "割引",
    draft: "下書き",
    duplicate: "コピー",
    edit: "編集",
    emailAddress: "メールアドレス",
    empty: "データがありません",
    enable: "有効にする",
    enabled: "有効",
    environment: "環境",
    error: "申し訳ありませんが、エラーが発生しました。もう一度お試しください。",
    expired: "期限切れ",
    expires: "有効期限",
    "false": "偽",
    firstName: "名",
    "for": "対象",
    fulfill: "実行する",
    fulfilled: "実行しました",
    generate: "生成する",
    getStarted: "開始する",
    goBack: "戻る",
    health: "Health",
    hidden: "非表示",
    home: "ホーム",
    hostedExperiences: "Hosted experiences",
    id: "ID",
    images: "画像",
    inactive: "無効",
    item: "項目",
    label: "Label",
    lastName: "姓",
    learnMore: "詳細",
    learnMoreButton: "詳細",
    live: "ライブ",
    loading: "読み込み中",
    loggedInAs: "{name}としてログイン中",
    logo: "ロゴ",
    longText: "Long text",
    merchant: "販売者",
    month: {
      april: "4月",
      august: "8月",
      december: "12月",
      february: "2月",
      january: "1月",
      july: "7月",
      june: "6月",
      march: "3月",
      may: "5月",
      november: "11月",
      october: "10月",
      september: "9月"
    },
    name: "名前",
    never: "利用不可",
    "new": "New",
    next: "次へ",
    nextName: "次へ: {name}",
    no: "いいえ",
    noResults: "検索結果はありません",
    notAvailable: "N/A",
    notFulfilled: "未完了",
    notPaid: "未払い",
    notRequired: "Not required",
    notes: "備考",
    number: "数値",
    open: "開く",
    option: "オプション",
    options: "オプション",
    override: "上書き",
    paid: "支払い済み",
    parent: "親",
    partiallyFulfilled: "一部実行済み",
    paymentGateway: "決済ゲートウェイ",
    permalinkSlug: "パーマリンク/スラッグ",
    phoneNumber: "電話番号:",
    placed: "配置済",
    previous: "前へ",
    previousName: "前へ: {name}",
    price: "価格",
    "private": "Private",
    province: "都道府県",
    published: "公開済み",
    quantity: "数量",
    radio: "ラジオ",
    rate: "レート",
    rates: "料金",
    reference: "設定",
    refund: "返金",
    refunded: "返金済み",
    refunding: "返金中",
    regenerate: "Regenerate",
    region: "地域",
    regions: "地域",
    remaining: "在庫",
    required: "必須",
    sales: "売上",
    sandbox: "サンドボックス",
    sandboxMode: "サンドボックスモード",
    save: "保存",
    saveAndClose: "保存して閉じる",
    saveChanges: "変更を保存",
    saved: "変更を保存しました",
    saving: "保存中...",
    search: "検索",
    security: "セキュリティ",
    selectOption: "オプション選択",
    sending: "送信中です…",
    shareEmbed: "共有/埋め込み",
    shipping: "発送",
    shippingMethod: "Shipping ({method})",
    shortText: "Short text",
    slug: "スラッグ",
    standard: "標準",
    startDate: "開始日",
    state: "州",
    status: "状態",
    subtotal: "小計",
    tax: "税",
    template: "テンプレート",
    text: "SMS",
    time: "時刻",
    timestamp: "タイムスタンプ",
    today: "本日",
    total: "合計",
    totalPaid: "総合計",
    "true": "真",
    type: "タイプ",
    unchecked: "Unchecked",
    unfulfilled: "未完了",
    unlimited: "Unlimited",
    update: "更新",
    upgradePlan: "プランをアップグレードする",
    url: "URL",
    value: "Value",
    view: "表示",
    viewDetails: "詳細を見る",
    visibility: "Visibility",
    visible: "Visible",
    whatIsThis: "これは何",
    xOfY: "{x} / {y}",
    yes: "はい",
    yesterday: "昨日"
  },
  home: {
    addProducts: "商品を追加する",
    configureShipping: "送料を設定する",
    configureTax: "消費税を設定する",
    createCommerceRay: "テーマを選び、LPを作成する",
    enableGateway: "決済サービスを有効化する",
    gettingStarted: "はじめに",
    gettingStartedRays: "Commerce Rayでフォーム一体型LPを作成する",
    latestAnnouncements: "最新のお知らせ",
    latestChanges: "最新の変更点",
    messageUs: "お問い合わせ",
    speakToAnExpert: "エキスパートに尋ねる",
    speakToCopy: "当社のコマースエキスパートは、Commerce.js がお客様のビジネスに適しているかを検討し、ソリューションの構築を支援します。",
    title: "ようこそ",
    viewChangelog: "変更履歴を見る",
    welcomeSubtitle: "ノーコードでフォーム一体型LPを手軽に作成できます"
  },
  integration: {
    about: "バージョン情報: {integration}",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration added",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Configure integration",
    confirmDelete: "Are you sure you want to delete this integration?",
    createError: "Sorry, we're having problems activating this integration.",
    creating: "Creating integration...",
    deleteFailed: "Failed to delete your integration! Try again later.",
    deleted: "Integration deleted.",
    edit: "Edit integration",
    editNamed: "Edit integration - {name}",
    filter: {
      checkout: "チェックアウト",
      content: "コンテンツ",
      dataMesh: "Data mesh",
      email: "メールアドレス",
      frontend: "Frontend",
      fulfillment: "Fulfillment",
      orders: "注文",
      payments: "支払い",
      personalization: "パーソナライゼーション",
      tax: "税"
    },
    filterLabel: "フィルター",
    filterTagLabel: "フィルタ:{tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "統合",
    integrations: "統合",
    introSplash: {
      description: "Connect with other services and compose your desired business stack.",
      title: "Configure integrations with 3rd party providers."
    },
    lastRun: "Last run",
    noConfigNeeded: "This template requires no additional configuration",
    noLogo: "No logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "ヘルシー",
      notApplicable: "N/A",
      unhealthy: "不健康",
      unstable: "Unstable"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API request logs with details will be listed here for ease of debugging.",
      title: "Your request logs will be shown here"
    },
    logs: "ログ"
  },
  merchant: {
    createFull: "マーチャントをセットアップしよう",
    createLeadIn: "お客様の情報は、いつでも変更または更新できます。",
    createMerchant: "マーチャントを作成する",
    seeAll: "すべてのマーチャントを表示",
    selectMerchant: "マーチャントを選択"
  },
  notes: {
    add: "メモを追加",
    addANote: "メモを追加",
    confirmDelete: "このメモを削除しますか？",
    note: "メモ"
  },
  order: {
    capturedOn: "キャプチャ：",
    cardEndingIn: "カード番号末尾 {number}",
    changeCustomer: "顧客情報を変更する",
    confirmRefund: "この注文を返金してもよろしいですか？",
    customerDetails: "お客様情報",
    customerUpdateFailed: "顧客情報の更新に失敗しました",
    customerUpdated: "注文番号 {reference} の顧客情報を更新しました",
    date: "注文日",
    dateTime: "日付/時刻",
    deleteTest: "Delete test orders",
    deleteTestConfirmation: "Are you sure you want to delete all of your sandbox orders?",
    edit: "注文を編集",
    "export": "注文のエクスポート",
    exportConfirmation: "現在のフィルタ設定でのご注文内容を後ほどCSV形式で販売者にメール送信します。",
    fulfillItems: "商品を発送",
    fulfillment: {
      confirmCopy: "Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?",
      confirmHeader: "Please confirm your fulfillment changes",
      dateShipped: "出荷日:{date}",
      reference: "参照: {reference}",
      shipmentNumber: "配送番号 {number}",
      shippingCarrier: "配送業者",
      trackingNotProvided: "追跡番号が登録されていません",
      trackingNumber: "追跡番号 {number}",
      trackingNumberOptional: "追跡番号（任意）"
    },
    fulfillmentStatus: {
      fulfilled: "実行しました",
      label: "配送ステータス",
      not_fulfilled: "未完了",
      partially_fulfilled: "一部実行済み",
      returned: "返品済"
    },
    gatewayTransactionId: "ゲートウェイトランザクションID: {id}",
    itemFulfilled: "商品が正常に出荷されました。",
    newNotification: "{value} の新しい注文 {reference}",
    nextOrder: "次の注文",
    noOrders: {
      copy: "時間の経過とともに表示された注文と売上が表示されます。",
      title: "最初の販売を行いましょう"
    },
    orderNotes: "注文メモ",
    orderNumber: "順序",
    orderStatus: "注文ステータス",
    orders: "注文",
    ordersAllTime: "注文-すべての時間",
    ordersOnMonth: "注文 - {month}",
    ordersOnMonthHelptip: "Number of orders since the start of this month, compared with the same period in the previous month",
    overview: "概要",
    paymentMethod: "お支払い方法",
    paymentSaveFailed: "申し訳ありませんが、この支払方法の保存には問題があります。",
    paymentSaved: "支払方法は正常に保存されました。",
    paymentStatus: {
      label: "お支払い状況",
      not_paid: "未払い",
      paid: "支払い済み",
      partially_paid: "一部支払済",
      pending: "支払い保留",
      refunded: "返金済み"
    },
    previousOrder: "前の注文",
    receiptSendFailed: "領収書を再送信できません。もう一度お試しください。",
    receiptSent: "領収書を送信しました。",
    recordManualPayment: "手動支払方法を記録する",
    reference: "参照： {reference}",
    refundFailed: "注文を返金できません。もう一度お試しください。",
    refundSuccess: "注文が正常に返金されました。",
    resendReceipt: "領収書を再送する",
    salesAllTime: "セールス-すべての時間",
    salesOnMonth: "販売 - {month}",
    salesOnMonthHelptip: "Sales performance since the start of this month, compared with the same period in the previous month",
    sandbox: "テスト注文",
    shipments: "発送",
    status: "状態",
    testOrdersDeleted: "Test orders successfully deleted.",
    transactionReferenceId: "トランザクション/リファレンスID",
    updateAddressError: "アドレスの保存中に何か問題が発生しました。もう一度お試しください。",
    updatedBilling: "請求先住所を更新しました。",
    updatedShipping: "配送先住所を更新しました。",
    viewMultiple: "注文を表示",
    viewOne: "注文を表示"
  },
  product: {
    add: "商品を追加",
    added: "商品が追加されました",
    additionalActions: "Additional product actions",
    basePrice: "基本価格",
    chooseRelatedProducts: "関連商品を選択する",
    confirmDelete: "この商品を削除してもよろしいですか？",
    confirmDeleteTitle: "よろしいですか？",
    copyPaste: "このコードをコピーして、サイトにペーストしてください",
    count: "商品がありません | 1商品 | {n}商品",
    customPermalink: "カスタムパーマリンク",
    "delete": "商品の削除",
    deleteFailed: "商品の削除に失敗しました。後ほど再度お試しください。",
    deleted: "商品が削除されました",
    description: "詳細",
    digitalFulfillment: {
      doesNotExpire: "リンクの有効期限はありません",
      downloadLimit: "ダウンロード制限",
      duration: "期間",
      durationPeriod: "期間",
      expiryMessage: "購入の {duration} {period} 以降",
      linkExpiration: "リンクの有効期限",
      period: {
        days: "日 | 日 | 日",
        months: "月 | 月 | 月",
        weeks: "週 | 週 | 週"
      },
      title: "デジタル配信"
    },
    duplicateProduct: "商品をコピー",
    duplicated: "商品は正常にコピーされました。コピー先データを編集しています。",
    edit: "商品を編集",
    embed: "埋め込み",
    embedCheckout: "チェックアウトの埋め込み",
    embedCheckoutDescription: "ウェブサイトやブログに直接チェックアウトを埋め込みます。",
    embedShareSell: "埋め込み、シェアして、販売しましょう！",
    images: {
      alert: "画像ギャラリーには、付属のバリエーションを含め、この商品に関連するすべての画像が含まれています。",
      title: "画像ギャラリー",
      types: "| {types} 形式がアップできます | {types} , {lastType} 形式がアップできます"
    },
    includesNCountries: "Includes 1 country | Includes {number} countries",
    introSplash: {
      api: "Create product API",
      description: "Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.",
      more: "詳細",
      title: "Add your first physical or digital product"
    },
    inventory: {
      available: "在庫数",
      managed: "在庫を追跡する",
      tooltip: "在庫追跡中は、販売可能な在庫数を設定できます。そうでない場合は、無制限になります。"
    },
    lastSavedAt: "最終保存日",
    linkCustomers: "このURLを使用して、顧客を直接チェックアウトにリンク",
    minimumPrice: "最低価格",
    misc: "その他",
    name: "商品名",
    noImage: "画像がありません",
    noRelatedAvailable: "他の製品はありません",
    payWhatYouWant: "任意の価格でのお支払い",
    price: "価格",
    product: "商品",
    products: "商品",
    pwywEnabled: "「Pay what you want」機能が有効になっています",
    relatedProducts: "関連商品",
    saveFailed: "商品の保存に失敗しました。もう一度お試しください。",
    saved: "変更を保存しました",
    searchEngineDescription: "検索エンジン向けのディスクリプション",
    searchEngineTitle: "検索エンジン向けのタイトル",
    seo: "SEO",
    shippingCard: {
      baseRateLabel: "基本料金",
      baseRateTooltip: "この料金の基本価格。この価格に他の料金が加算されます。",
      nameLabel: "名前",
      onOwnLabel: "自身で",
      onOwnTooltip: "この商品を単品で注文した場合の価格です。これは基本料金に加算されます。例: 基本料金+独自料金 = 合計配送料",
      title: "配送オプション",
      withOthersLabel: "その他を含む",
      withOthersTooltip: "他の商品と一緒に注文した場合の価格。他の商品の配送料と基本料金に加算されます。例：基本料金+他の商品との料金 (この商品の価格) + 他の商品との料金 (その他の商品の価格) = 合計配送料"
    },
    sku: "SKU",
    sortOrder: "並べ替え順",
    sortOrderTooltip: "Lower values make your product show up in lists earlier, whereas higher values make it show up later.",
    thankYouPage: "サンキューページ",
    variants: {
      addGroup: "バリエーショングループの追加",
      addOption: "オプションを追加",
      addVariant: "バリエーションを追加",
      amount: "金額",
      andMore: "{count} 個のバリエーションを追加",
      bulkAdd: "一括追加",
      bulkFailure: "申し訳ありませんが、バリエーションの更新中に問題が発生しました。",
      bulkSuccess: "バリエーションは正常に更新されました",
      chooseGroupsAndOptions: "グループとオプションを選択する",
      create: "バリエーションの作成 | バリエーションの作成",
      createBulk: "一括でバリエーションを作成",
      createSingle: "バリエーションを1個作成する",
      defaultPrice: "デフォルト価格",
      defaultPriceTooltip: "バリーショングループのオプションのデフォルト価格",
      defaultVariantTitle: "新しいバリエーション",
      edit: "バリエーションの編集",
      editSingle: "バリエーションを編集",
      editSingleNamed: "バリエーションを編集: {name}",
      generateVariants: "バリエーションの作成",
      groups: "グループ | グループ | グループ",
      hasInvalidVariants: "この商品には無効なバリエーションがあります。無効なバリエーションは、カートやチェックアウトへの追加、および販売することができません。無効なバリエーションを再度有効にするには、バリエーションを編集してください。",
      help: "この商品の異なるタイプ（例：サイズ、色）",
      image: "画像",
      invalidReason: {
        duplicate_option_config: "この無効なバリエーションは、別のバリエーションと、オプションを共有しています。特定のオプションセットには 1 つのバリエーションしか存在できません。",
        incomplete_option_config: "この無効なバリエーションには、この商品に設定されているバリエーショングループのオプションがありません。バリエーションには、各グループに対応するオプションセットが必要です。"
      },
      invalidVariantsOnSave: "保存すると、バリエーションは「無効」としてマークされ、問題を修正するまでは無効なバリエーションを販売できなくなります。",
      mustSave: "新しいバリエーションを追加する前に、保存する必要があります",
      noGroups: "バリエーションは、同じ商品のわずかな違いがある商品を販売するのに使用します。たとえば、異なる色で T シャツを販売したり、異なるサイズのプランターを販売したりできます。各バリエーションは、独自の価格、SKU、在庫を持つように設定できます。\n\n開始するには、バリアント用のグループとオプションを作成します。グループは、バリアントのタイプ (色など) を定義します。オプションは、顧客がそのグループ内で行うことができる選択肢です (例:青)。",
      noGroupsTitle: "同じ商品のバリエーションを販売するバリエーショングループを設定",
      noOptions: "少なくとも1つのオプションを入力してください",
      noVariants: "上記のボタンを使用して、バリエーションを作成します。バリエーションとは、設定したバリエーショングループとバリエーションオプションの組み合わせです。バリエーションごとに価格と在庫を個別に設定できます (有効な場合)",
      noVariantsTitle: "バリエーションは作成されていません",
      noVariantsToGenerate: "選択したオプションにはすべてのバリエーションがすでに存在します",
      optionDefaultImage: "デフォルト・イメージ",
      options: "オプション | オプション | オプション",
      optionsInGroupsRequired: "バリアントを一括作成するには、各グループで少なくとも1つのオプションを選択する必要があります",
      optionsUpdateFailure: "バリエーショングループのオプションを更新する際に問題が発生しました。",
      optionsUpdateSuccess: "バリエーショングループのオプションが正常に更新されました",
      override: "上書き",
      preview: "バリエーションプレビュー",
      previewHelp: "上で選択したオプションに基づいて作成されるバリエーションのプレビュー",
      quantity: "数量",
      requiresGroupsSave: "この機能を使用するには、最初にグループを保存する必要があります。",
      requiresSave: "この機能を使用するには、最初に商品を保存する必要があります。",
      saveAndGenerate: "保存してバリエーションを生成する",
      selectOptions: "バリエーションのオプションを選択してください",
      singleCreateSuccess: "バリエーションは正常に作成されました",
      singleUpdateFailure: "バリエーションの保存中に問題が発生しました。",
      singleUpdateSuccess: "バリエーションは正常に更新されました",
      title: "バリエーション",
      variantDetails: "バリエーションの詳細",
      variantExists: "このバリエーションはすでに存在しているようです。",
      variantGroup: "バリエーショングループ",
      variantGroupName: "バリエーショングループ名",
      variantGroupTitle: "バリエーショングループ - {group}",
      variantName: "バリエーション名",
      willInvalidateVariants: "バリエーショングループを追加または削除すると、既存のバリエーションが無効になります。各バリエーションはグループごとに 1 つのオプションを持っている必要があり、コピーすることはできません。"
    },
    viewProducts: "商品を表示"
  },
  rays: {
    add: "新しいLPを作成する",
    commerceRays: "Commerce Rays",
    confirmDelete: "このLPを削除してもよろしいですか？",
    confirmDeleteTitle: "よろしいですか？",
    create: "LPを作成",
    "delete": "LPを削除",
    deleteFailed: "Rayの削除に失敗しました。後ほどもう一度お試しください。",
    deleted: "LPを削除しました",
    deploying: "公開しています...",
    desktop: "PC",
    edit: "LPの編集",
    embed: "埋め込み",
    embedModal: "「今すぐ購入」ボタンをクリックして表示されるモーダル画面に、チェックアウトを埋め込むこともできます。",
    exit: "終了",
    images: {
      title: "画像",
      types: "| {types} 形式がアップできます | {types} , {lastType} 形式がアップできます"
    },
    introduction: {
      description: "フォーム一体型のNoCodeページビルダー",
      "for": {
        campaigns: "キャンペーン",
        digitalProducts: "デジタル商品",
        influencers: "インフルエンサーマーケティング",
        mediaCheckouts: "決済機能のあるメディア",
        ppc: "PPCキャンペーン",
        productDrops: "新商品の発売",
        title: "Rayの用途",
        videoCommerce: "ビデオコマース"
      },
      line1: "購入につながる、美しい商品+コンテンツのページを簡単に作成できます。",
      line2: "編集可能な美しいテーマを選択して、ブランドのストーリーとともに販売できます。",
      title: "✨ Commerce Rayへようこそ"
    },
    lastDeployment: "最終更新日時：",
    mobile: "モバイル",
    nameHelper: "内部の識別用",
    noProducts: "少なくとも一つの商品が必要です",
    noRays: "LPが見つかりませんでした",
    noThemes: "テーマが見つかりません",
    pickTheme: "テンプレートを選択",
    planWarning: {
      limitReached: "プランの上限に達しました。公開するには {upgrade} してください。",
      notice: "現在のプランでは、Raysの使用はサポートされていません。{upgrade} して公開してください。",
      upgrade: "プランをアップグレードする"
    },
    ray: "Ray",
    rayName: "LPの名称",
    rays: "Rays",
    sandboxHelper: "サンドボックスモードを有効にすると、テストモードでStripeを使用できます。なお、サンドボックスモードではライブ注文を作成できません。",
    save: "保存",
    saveDeploy: "保存して公開",
    saveFailed: "Rayの保存に失敗しました。もう一度お試しください。",
    saved: "変更を保存しました",
    savedAndDeploying: "変更を保存し、Rayを公開しています...",
    saving: "保存中...",
    select: "テーマを選択",
    selectProducts: "商品を選択",
    validationFailed: "選択した設定内容に問題があります。サイドバーにエラー表示がないか確認してください。",
    viewPublishedRay: "公開済みのLPを表示",
    viewRay: "LPを表示",
    viewportWarning: "このダッシュボードは、大きな画面で利用することを推奨しています。Rayを見るには、サインインしてください。"
  },
  settings: {
    analytics: "分析",
    data: "データ",
    developer: "開発者",
    developerSettings: {
      addKey: "API キーの追加",
      apiKey: "APIキー",
      apiKeys: "APIキー",
      apiVersion: "APIバージョン",
      changeKey: "キーの変更",
      changelog: "変更履歴を見る",
      confirmDeleteKey: "この API キーを削除してもよろしいですか?",
      createKey: "API キーを作成する",
      createSuccess: "API キーが正常に作成されました",
      deleteFailed: "API キーの削除に失敗しました。後でもう一度お試しください。",
      deleted: "API キーが削除されました。",
      editKey: "API キーの編集",
      generateKey: "キーを再生成してもよろしいですか？",
      keyChangFailed: "API キーの更新に失敗しました。後でもう一度お試しください。",
      keyChanged: "キーが正常に更新されました",
      label: "API キーラベル",
      live: "公開中",
      "public": "公開",
      resetKeyButtonTooltip: "新しいキーを生成する",
      resetKeyConfirmationHeader: "新しいキーを生成しますか？",
      sandbox: "サンドボックス",
      secret: "非公開",
      type: "タイプ",
      updateFailure: "API キーの更新に失敗しました。もう一度お試しください。",
      updateSuccess: "API キーが正常に更新されました"
    },
    merchant: {
      analyticsCardTitle: "Google アナリティクス",
      analyticsGaTrackingId: "トラッキング ID",
      analyticsGaTrackingIdTooltip: "一意の Google アナリティクスのトラッキング ID",
      analyticsLinkedDomain: "リンクされたドメインを追加する",
      analyticsLinkedDomainTooltip: "You can add multiple domains separated by a comma.",
      coverImage: "表紙画像",
      coverImageTooltip: "これはストアフロントの表紙画像です。",
      descriptionTooltip: "A brief description about yourself/your company",
      emailTooltip: "This email is visible to your customers. Customer support emails will go here.",
      enableHostedCheckouts: "Enable hosted checkout",
      enableHostedCheckoutsTooltip: "The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Enable hosted storefront",
      isPublic: "パブリック API に可視にする",
      merchantAddress: "住所",
      merchantId: "マーチャントID",
      merchantIdInfo: "This is your unique merchant ID. We may ask for it when you contact us.",
      name: "ビジネス名",
      nameTooltip: "どのようなブランド名/屋号で販売していますか？",
      save: "設定を保存",
      saveFailed: "Failed to save the merchant settings! Please try again.",
      saved: "変更を保存しました",
      timezoneTooltip: "All dates and times in Chec will be relative to this timezone",
      title: "マーチャントの詳細"
    },
    notifications: {
      customerLoginToken: "顧客宛：ログイントークン",
      customerOrderReceipt: "顧客宛：注文確認メール",
      customerOrderShipped: "顧客宛：発送完了メール",
      dashboard: "ダッシュボード通知",
      dashboardSummary: "Checダッシュボードで通知の挙動を制御します。",
      emailNotificationSummary: "Checから送信されるデフォルトのメール通知を有効/無効化する",
      emailNotifications: "メール通知",
      emails: "メール",
      merchantOrderReceipt: "マーチャント宛：注文通知メール",
      navMenuHighlight: "ナビゲーションメニューのハイライト",
      news: "ニュース＆特集のお知らせ",
      none: "なし",
      notificationPopup: "通知ポップアップ",
      title: "通知"
    },
    paymentGateways: "決済ゲートウェイ",
    settings: "設定",
    shipping: "配送",
    tax: {
      addState: "Add state",
      addTax: "税務管理を有効にする",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Add tax zone",
      addedToRate: "Added to country rate",
      address: "住所",
      addressAlert: "This is used to calculate tax and is displayed on invoices.",
      calculateAutomatically: "Calculate taxes automatically",
      configureTaxZone: "Configure tax zone",
      confirmDeleteZone: "Are you sure you want to delete this tax zone?",
      confirmDeleteZoneTitle: "よろしいですか？",
      countrySelectInfo: "The tax rate configured here will be applied to all orders in this tax zone.",
      countryTax: "Country tax",
      deleted: "Tax configuration deleted",
      digitalRate: "Digital rate",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Edit tax zone",
      enableTax: "税の有効化",
      enableVat: "EU Digital Goods VATを有効にする",
      enableVatInfo: "Collect EU digital goods VAT (VAT MOSS) tax on digital goods. Tax rates are automatically calculated and applied to EU customers.",
      euVatMoss: "European Union (VAT MOSS)",
      introCopy: "Commerce.js allows you to easily define your region's tax rates to help you easily collect the appropriate tax during checkout. You can also choose to integrate any tax provider and implement custom business rules with your own serverless functions.",
      introTitle: "Commerce.jsで税金を管理しましょう",
      nexusHelp: "Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.",
      nexusStates: "Nexus states",
      overrideRate: "Instead of country rate",
      pricesIncludeTax: "Do your prices include tax?",
      ratesAutoUpdated: "Rates are automatically updated & set.",
      saveFailed: "Failed to save the tax configuration! Please try again.",
      standardRate: "標準料金",
      taxRateZones: "税率ゾーン",
      taxRates: "Tax rates",
      title: "税",
      zipAlreadyExists: "The provided ZIP code has already been added",
      zipInvalid: "The provided ZIP code does not appear to be a valid US postal ZIP code"
    },
    updateFailed: "通知設定を保存できませんでした。もう一度お試しください。",
    updatedSuccessfully: "通知設定が更新されました",
    viewStorefront: "サイトを見る"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "エリアを追加する",
    confirmDeleteZone: "Are you sure you want to delete this zone?",
    countriesRegions: "Countries & regions",
    countriesRegionsHelp: "Please note that changes to this panel will not be saved until the shipping zone is saved.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "ゾーンを編集 {name}",
    enableOnProductsInfo: "If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.",
    enableOnProductsTitle: "Enable on all products",
    introSplash: {
      description: "A shipping zone is added by default as an example. Once you've added additional shipping zones, save and enable for the zones to be available for your products. Shipping zones can also be selected in the {productPage}.",
      title: "Add shipping zones for your products"
    },
    noCountriesRegions: "No countries or regions have been added to your zone. Start adding countries or regions to your zone before saving.",
    noCountriesRegionsTitle: "No countries or regions have been added",
    numCountries: "No. of countries",
    numRegions: "No. of regions",
    productPage: "product edit page",
    rateNameRequired: "レート名は必須です",
    ratePriceRequired: "The rate price is required. For free rates use 0.",
    ratesLeadIn: "Increases to these rates can also be added in the {productPage}.",
    saveFailure: "Sorry, we're having a problem saving your shipping zone.",
    saveSuccess: "Your shipping zone has been saved successfully.",
    selectRegion: "Configure regions",
    shippingZones: "配送ゾーン",
    zone: "ゾーン:",
    zoneDeleteFailed: "Something went wrong while trying to delete this zone. Please try again.",
    zoneDeleted: "Zone deleted.",
    zoneName: "エリア名",
    zoneNameDescription: "The zone name is for internal use only and customers will not see this.",
    zoneNameRequired: "The zone name is required"
  },
  signup: {
    error: "アカウントの作成中に問題が発生しました。詳細を確認して、もう一度お試しください。",
    form: {
      experience: "最高の体験を提供します。",
      merchantDetails: "マーチャントの詳細",
      merchantEmail: "マーチャントのメールアドレス",
      merchantEmailDescription: "このメールアドレスは顧客に表示されます。注文通知もこちらに届きます。",
      merchantName: "マーチャントの名称",
      merchantNameDescription: "どのようなブランド名/屋号で販売していますか？",
      next: "次へ",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "保存中..."
    },
    intro: {
      text: "数ステップでアカウントを作成し、Commerce.js の使用を開始します。",
      title: "ようこそ！アカウントを設定してください。"
    },
    page: {
      title: "アカウント設定"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Something else",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Marketing",
      other: "Other",
      owner: "Business owner",
      solution_architect: "Solution Architect",
      student: "Student"
    }
  },
  slack: {
    alreadyJoined: "すでに登録されていますか？",
    description: "ECの開発者とデザイナーのコミュニティ",
    error: {
      alreadyInTeam: "このメールアドレスはすでにコミュニティに登録済みです。",
      alreadyInvited: "このメールアドレスには、招待を送付済みです。メールをご確認ください。",
      invalidEmail: "有効なメールアドレスを入力してください",
      missingEmail: "メールアドレスを入力してください"
    },
    getMyInvite: "招待メールを受け取る",
    inviteSent: "招待メールが送信されました。メールをご確認ください。",
    join: "Slackコミュニティに参加する",
    joinCommerceJs: "Commerce.jsのSlackに参加しませんか？",
    joinUs: "Slackに参加してみませんか？",
    openSlack: "Slackを開く",
    registeredCount: "{count} ユーザーがこれまでに登録しています",
    slack: "Slack"
  },
  spaces: {
    storefront: "ストアフロント"
  },
  status: {
    maintenance: "メンテナンス中のサービス",
    major: "部分的なシステム停止",
    minor: "軽微なサービス停止",
    none: "すべてのシステムが動作可能"
  },
  validation: {
    enterEmail: "Please enter an email address",
    enterName: "名前を入力してください",
    enterOption: "オプション名を入力してください",
    enterPrice: "価格を入力してください",
    enterQuantity: "数量を入力してください",
    enterUrl: "URLを入力してください",
    enterValue: "Please enter an attribute value",
    generic: "{field} の値を入力してください",
    number: "数字を入力してください",
    positiveNumber: "正の数を入力してください",
    typeRequired: "Please enter a type",
    validBusinessName: "Please enter a business name",
    validCity: "市区町村を入力してください。",
    validCountry: "Please select a country",
    validEmail: "Please enter a valid email address",
    validNumber: "有効な数字を入力してください",
    validPostalZipCode: "Please enter a postal/zip code",
    validPrice: "有効な価格を入力してください",
    validRegion: "Please select a province/state",
    validStreet: "住所（番地）を入力してください",
    validSupportEmail: "メールを入力してください。",
    validUrl: "有効なURLを入力してください"
  },
  versions: {
    current: "{version} ​- 現在"
  },
  webhooks: {
    add: "webhookを追加",
    added: "Webhookが追加されました。",
    confirmDelete: "このWebhookを削除してもよろしいですか？",
    confirmRegenerateKey: "署名キーを再生成してもよろしいですか？これはすべてのWebhookに影響します。",
    copiedKey: "コピーしました",
    copyKey: "キーをコピー",
    created: "作成日時",
    "delete": "Webhookを削除する",
    deleteFailed: "Webhookの削除に失敗しました。",
    deleted: "Webhookが削除されました。",
    deliveryDetails: "Delivery details",
    deliveryHistory: "配送履歴",
    edit: "Webhookを編集する",
    event: "イベント",
    eventName: "Event name",
    events: "イベント",
    generateKey: "新しいキーを生成する",
    introSplash: {
      description: "Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.",
      more: "詳細",
      title: "Add a webhook to extend the possibilities of your business."
    },
    regenerateKeyFailed: "署名キーを再生成できませんでした",
    responseBody: "Response body",
    responseCode: "HTTPレスポンスコード:{code}",
    save: "Webhookを保存する",
    saveFailed: "Webhookの保存に失敗しました。もう一度お試しください。",
    saved: "変更を保存しました",
    sendTestRequest: "テストリクエストを送信",
    sendingTestRequest: "テストリクエストを送信中...",
    signingKey: "署名キー",
    testRequestCallbackFailed: "テストリクエストが送信されましたが、コールバック応答に失敗しました。",
    testRequestFailed: "テストリクエストを送信できませんでした",
    testRequestSuccess: "テスト要求が正常に送信されました",
    view: "Webhookを見る",
    webhook: "Webhook",
    webhooks: "Webhook"
  }
};