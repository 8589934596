export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('home.title'),
    },
  },
];
