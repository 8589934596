import Vue from 'vue';
import i18n from '@/lang';

export default (route) => {
  // Resolve and update the page title
  Vue.nextTick(() => {
    const title = (route.matched || [])
      .map(({ meta }) => {
        if (typeof meta.title === 'string') {
          console.error('Titles in route meta now must be a function with i18n as a parameter.');
          return false;
        }
        if (typeof meta.title !== 'function') {
          return false;
        }

        return meta.title(i18n);
      })
      .filter((routeTitle) => typeof routeTitle === 'string' && routeTitle.length > 0)
      .join(' > ');
    document.title = title ? `${title} ~ Dashboard` : 'Dashboard';
  });
};
