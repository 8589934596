import cryptoRandomString from 'crypto-random-string';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

/**
 * Generates the necessary state, verifier, and challenge hash for OAuth Authorization Code with
 * PKCE grant flow. The client_oauth2 library we use doesn't have built in support for PKCE yet.
 *
 * @returns {object}
 */
export default () => {
  if (!window.sessionStorage.getItem('auth_state')) {
    window.sessionStorage.setItem('auth_state', cryptoRandomString({ length: 40 }));
  }

  if (!window.sessionStorage.getItem('auth_code_verifier')) {
    window.sessionStorage.setItem('auth_code_verifier', cryptoRandomString({ length: 128 }));
  }

  const challengeHash = sha256(window.sessionStorage.getItem('auth_code_verifier'));
  const encodedChallenge = Base64.stringify(challengeHash)
    // Remove trailing = and replace characters for RFC-7636 compatibility
    .replace(/=+$/, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return {
    state: window.sessionStorage.getItem('auth_state'),
    code_challenge: encodedChallenge,
    code_challenge_method: 'S256', // sha256
    code_verifier: window.sessionStorage.getItem('auth_code_verifier'),
  };
};

export const clearState = () => {
  window.sessionStorage.removeItem('auth_code_verifier');
  window.sessionStorage.removeItem('auth_state');
};
