import { createPaginationModule } from '@/lib/pagination';
import moment from 'moment';

export default createPaginationModule('/v1/discounts', {
  searchProperties: ['code'],
  prepItemForPost: (item) => {
    const savableDiscount = item;

    savableDiscount.limit_quantity = item.quantity !== null && item.quantity !== '';
    savableDiscount.expires = !!item.expires_on;
    savableDiscount.expires_on = item.expires_on
      ? moment(item.expires_on)
        .endOf('day')
        .unix()
      : null;
    savableDiscount.starts_on = item.starts_on
      ? moment(item.starts_on)
        .startOf('day')
        .unix()
      : null;
    return savableDiscount;
  },
});
