export default {
  SET_DIGITAL_PACKAGE: 'SET_DIGITAL_PACKAGE',
  SET_DIGITAL_PACKAGE_ACCESS: 'SET_DIGITAL_PACKAGE_ACCESS',
  REVOKE_DIGITAL_PACKAGE: 'REVOKE_DIGITAL_PACKAGE',
  LOAD_MONTHLY_STATISTICS: 'LOAD_MONTHLY_STATISTICS',
  LOAD_ALL_TIME_STATISTICS: 'LOAD_ALL_TIME_STATISTICS',
  LOAD_MONTHLY_COMPARISON: 'LOAD_MONTHLY_COMPARISON',
  FETCH_NEW_ORDER: 'FETCH_NEW_ORDER',
  NEW_ORDER_NOTIFICATION: 'NEW_ORDER_NOTIFICATION',
  RELOAD_ORDERS: 'RELOAD_ORDERS',
};
