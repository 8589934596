<template>
  <ChecHeader
    :class="classNames"
    :header-tag="headerTag"
    :label="label"
    :title="title"
    :variant="variant"
  >
    <slot />
  </ChecHeader>
</template>

<script>
import { ChecHeader } from '@chec/ui-library';

export default {
  name: 'DashboardHeader',
  components: { ChecHeader },
  props: {
    headerTag: String,
    label: String,
    title: String,
    variant: String,
    margin: {
      type: String,
      validate(margin) {
        return ['small', 'normal'].includes(margin);
      },
      default: 'normal',
    },
  },
  computed: {
    classNames() {
      return {
        'chec-dashboard__header': true,
        'chec-dashboard__header--lean': this.margin === 'small',
      };
    },
  },
};
</script>

<style lang="scss">
.chec-dashboard {
  &__header {
    @apply mb-8;

    &--lean {
      @apply mb-4;
    }
  }
}
</style>
