<template>
  <ErrorContent :title="$t('error.permissionErrorTitle')">
    <p>{{ $t('error.permissionErrorSuggestion') }}</p>
    <template #graphic>
      <PermissionDeniedGraphic />
    </template>
  </ErrorContent>
</template>

<script>
import PermissionDeniedGraphic from '../../../public/img/403.svg';
import ErrorContent from '../../components/ErrorContent.vue';

export default {
  name: 'PermissionErrorPage',
  components: {
    PermissionDeniedGraphic,
    ErrorContent,
  },
};
</script>
