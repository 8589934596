<template>
  <TableRow
    class="integration-logs-row"
    @click="$emit('handle-view', message)"
  >
    <td>
      <code>
        {{ message.event }}
      </code>
    </td>
    <td>
      <ChecDataPill :color="statusColor">
        {{ message.response_code }}
      </ChecDataPill>
    </td>
    <td>
      <Timestamp :timestamp="message.created" />
    </td>
  </TableRow>
</template>

<script>
import {
  ChecDataPill,
} from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'LogRow',
  components: {
    TableRow,
    Timestamp,
    ChecDataPill,
  },
  props: {
    message: Object,
  },
  computed: {
    /**
     * Determine the data pill color from the status code
     *
     * @returns {String}
     */
    statusColor() {
      if (this.message.response_code >= 200 && this.message.response_code < 300) {
        return 'green';
      }
      return 'red';
    },
  },
};
</script>

<style lang="scss">
.integration-logs-row {
  @apply h-full;

  code {
    @apply py-1 px-2 bg-gray-200 rounded-sm break-words
      text-xs font-mono tracking-wide text-gray-600;
  }
}
</style>
