<template>
  <div class="integrations-home">
    <DashboardHeader :title="$t('integration.integrations')">
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        @click="handleAddIntegration"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('integration.introSplash.title')"
      button
      graphic
      @click="handleAddIntegration"
    >
      {{ $t('integration.introSplash.description') }}
      <template #graphic>
        <img
          class="integrations-home__splash-graphic"
          src="/img/integrations/integrationscity.svg"
          alt="Integrations graphic"
        >
      </template>
    </IntroSplash>
    <IntegrationsTable
      v-else
    />
    <DashboardPagination state-key="integrations/integrations" />
    <router-view />
  </div>
</template>

<script>
import { mapPagination } from '@/lib/pagination';
import { ChecButton } from '@chec/ui-library';
import IntroSplash from '@/components/IntroSplash.vue';
import DashboardPagination from '@/components/Pagination.vue';
import DashboardHeader from '@/components/DashboardHeader.vue';
import IntegrationsTable from '../components/IntegrationsTable.vue';

export default {
  name: 'Integrations',
  components: {
    ChecButton,
    IntroSplash,
    DashboardPagination,
    DashboardHeader,
    IntegrationsTable,
  },
  computed: {
    ...mapPagination('integrations/integrations'),
    /**
     * Show intro splash when there are no integrations and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.integrations.length === 0 && !this.isLoading;
    },
  },
  methods: {
    /**
     * Navigate to the "enable integrations" panel view
     */
    handleAddIntegration() {
      this.$router.push({ name: 'integrations.add' });
    },
  },
};
</script>

<style lang="scss">

</style>
