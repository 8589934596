<template>
  <DashboardTable
    class="integrations-table"
    :align-right-after="3"
    :columns="[
      { title: $t('integration.integration'), class: 'integrations-table__header-name' },
      { title: $t('general.id'), class: 'integrations-table__header-id' },
      { title: $t('general.status'), class: 'integrations-table__header-status' },
      { title: $t('general.url'), class: 'integrations-table__header-url' },
      { title: $t('integration.lastRun'), class: 'integrations-table__header-last-run' },
      { title: $t('general.actions'), class: 'integrations-table__options' },
    ]"
    state-key="integrations/integrations"
  >
    <TableRow
      v-for="integration in integrations"
      :id="integration.id"
      :key="integration.id"
      :route-name="integration.id ? 'integrations.edit' : null"
    >
      <td>
        <div class="integrations-table__logo-container">
          <img
            v-if="integration.template && integration.template.icon_url"
            :src="integration.template.icon_url"
            :alt="integration.name"
            class="integrations-table__logo"
          >
          <div v-else class="integrations-table__logo-placeholder">
            {{ $t('integration.noLogo') }}
          </div>
          <span class="integrations-table__template-label">
            {{ integration.template && integration.template.name }}
          </span>
        </div>
      </td>
      <td>
        <div v-if="integration.id" class="integrations-table__integration-id-container">
          <CopyInline
            class="integrations-table__inline"
            :copy-content="integration.id || ''"
          >
            <div
              class="integrations-table__integration-id"
            >
              {{ integration.id }}
            </div>
          </CopyInline>
        </div>
      </td>
      <td>
        <ChecDataPill v-tooltip="statusTooltip(integration)" :color="status(integration).color">
          {{ status(integration).label }}
        </ChecDataPill>
      </td>
      <td>
        <code v-if="integration.url">
          {{ integration.url }}
        </code>
      </td>
      <td>
        <Timestamp :timestamp="integration.last_run" />
      </td>
      <td class="integrations-table__options">
        <ChecOptionsMenu
          menu-placement="bottom-end"
        >
          <ChecOption @option-selected="handleEdit(integration.id)">
            {{ $t('general.edit') }}
          </ChecOption>
          <ChecOption
            destructive
            @option-selected="handleDeleteIntegration(integration.id)"
          >
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import {
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import DashboardTable from '@/components/DashboardTable.vue';
import CopyInline from '@/components/CopyInline.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'IntegrationsTable',
  components: {
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
    DashboardTable,
    TableRow,
    Timestamp,
    CopyInline,
  },
  mixins: [
    crud('integrations/integrations', true),
    addNotification,
    confirm,
  ],
  methods: {
    /**
     * Returns the colour and label for the "health" status badge depending on the success rate
     * of the integration.
     */
    status(integration) {
      const { success_rate: successRate, last_run: lastRun } = integration;

      if (lastRun === null) {
        return { color: 'gray', label: this.$t('integration.status.notApplicable') };
      }

      if (successRate === 1) {
        return { color: 'green', label: this.$t('integration.status.healthy') };
      }

      if (successRate < 1 && successRate > 0) {
        return { color: 'orange', label: this.$t('integration.status.unstable') };
      }

      return { color: 'red', label: this.$t('integration.status.unhealthy') };
    },
    /**
     * A tooltip for context on what the health status means
     */
    statusTooltip(integration) {
      const { success_rate: successRate } = integration;

      const percent = `${Math.round((successRate || 0) * 100)}%`;

      return this.$t('integration.healthTooltip', { percent });
    },
    /**
     * Route to integration edit view
     */
    handleEdit(integrationId) {
      this.$router.push({
        name: 'integrations.edit',
        params: { id: integrationId },
      });
    },
    /**
     * Deletes integration
     */
    async handleDeleteIntegration(integrationId) {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('integration.confirmDelete'),
      )) {
        return;
      }

      this.delete(integrationId).then(() => {
        this.addNotification(this.$t('integration.deleted'));
      }).catch((error) => {
        this.addNotification(this.$t('integration.deleteFailed'), 'error');
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.integrations-table {
  &__header-name {
    @apply w-1/5;
  }

  &__header-url {
    @apply w-2/5;
  }

  &__header-last-run {
    width: 6.5rem; // Fits the timestamp and time onto separate lines nicely
  }

  &__logo-container {
    @apply flex items-center;
  }

  &__logo-placeholder {
    @apply flex justify-center items-center bg-gray-300 text-center opacity-50 text-xs;
  }

  &__logo,
  &__logo-placeholder {
    @apply rounded w-12 h-12;
  }

  &__template-label {
    @apply ml-3;
  }

  code {
    @apply py-1 px-2 rounded-sm text-xs font-mono tracking-wide bg-gray-200;
  }

  &__options {
    @apply w-24 text-right;

    > div {
      @apply inline-flex; // to right align the options menu
    }
  }

  &__integration-id-container {
    @apply inline-block items-center border border-gray-300 p-2 bg-gray-100 rounded;

    min-width: 10rem;
  }

  &__integration-id {
    @apply font-mono text-xs select-all break-all;
  }
}
</style>
