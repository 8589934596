<template>
  <div>
    <div
      v-if="template.config_frame_url"
      ref="editor"
      class="integrations-edit__custom-frame"
      :style="{ height: frameHeight }"
    />
    <IntegrationConfigurationForm
      v-if="formSchema"
      v-model="configModel"
      :errors="errors"
      :form-schema="formSchema"
      @row-event="handleFieldEvent"
    />
    <div v-if="noConfigNeeded" class="integrations-edit__no-config">
      {{ $t('integration.noConfigNeeded') }}
    </div>
  </div>
</template>

<script>
import Postmate from 'postmate';
import debounce from 'lodash.debounce';
import IntegrationConfigurationForm from './IntegrationConfigurationForm.vue';

export default {
  name: 'Configuration',
  components: {
    IntegrationConfigurationForm,
  },
  model: {
    prop: 'config',
    event: 'set-config',
  },
  props: {
    config: Object,
    editing: Boolean,
    errors: Object,
    template: Object,
  },
  data() {
    return {
      customSchema: null,
      frameHeight: 0,
      // Allow the frame to indicate explicitly that saving should be prevented. We default this to
      // true if a frame is used so there's a chance to report whether it should not be savable at
      // the start
      explicityUnsavable: Boolean(this.template.config_frame_url),
    };
  },
  computed: {
    configModel: {
      get() {
        return this.config;
      },
      set(value) {
        // Send an update to any config iFrame that may be configured
        this.debouncedFrameUpdate(value);
        this.$emit('set-config', value);
        // Report changes in savability
        this.$nextTick(() => this.$emit('savable', this.isSavable));
      },
    },
    formSchema() {
      return this.customSchema || this.template.form_schema;
    },
    noConfigNeeded() {
      return !this.formSchema && !this.template.config_frame_url;
    },
    isSavable() {
      // First check the obvious...
      if (this.noConfigNeeded) {
        return true;
      }

      // Frames can override the savability and explicitly prevent saving
      if (this.explicityUnsavable) {
        return false;
      }

      // Now we check that we have form schema to check for required fields
      if (!this.formSchema) {
        return true;
      }

      // This shouldn't happen, as the config should be created up front based off the schema. If
      // we are here before config is created though, assume it's unsavable
      if (!this.config) {
        return false;
      }

      // Check that required schema fields are provided
      // We'll create a factory that produces a function that can be used recursively to take schema
      // items (from form schema), check if they're required, and check that the matching entry in
      // config is provided.
      const hasMissingConfigFactory = (config) => (schemaItem) => {
        // Skip schema items that don't have a "key" or are a button
        if (!schemaItem.key || schemaItem.type === 'button') {
          return false;
        }

        const { key } = schemaItem;

        // Check if the key is missing from config. Sub-schemas should be pre-defined
        if (!Object.hasOwnProperty.call(config, key)) {
          // Missing config
          return true;
        }

        if (Object.hasOwnProperty.call(schemaItem, 'schema')) {
          // Has sub-schema - run recursively to check sub-schema items
          const schema = Array.isArray(schemaItem.schema)
            ? schemaItem.schema
            : Object.values(schemaItem.schema);
          return schema.some(hasMissingConfigFactory(config[key]));
        }

        // Check if the value is required
        const required = schemaItem.required === true;

        if (!required) {
          return false;
        }

        // Handle numbers separately from strings
        if (schemaItem.type === 'number') {
          return typeof config[key] !== 'number';
        }

        // Assume we're missing config if the type of the value is not a string or array, or it's
        // empty
        return (!Array.isArray(config[key]) && typeof config[key] !== 'string')
          || config[key].length === 0;
      };

      // Schema should be an array, but we'll support it being an object.
      const schema = Array.isArray(this.formSchema)
        ? this.formSchema
        : Object.values(this.formSchema);

      // Run against form schema items
      return !schema.some(hasMissingConfigFactory(this.config));
    },
  },
  created() {
    // Ensure that there is attributes in the configuration for fields within the formSchema
    this.createDefaultConfigAttributes();

    // Specify frameApi on the component directly without using `data` so that it is _not_ reactive
    // Having this be reactive can cause iFrame cross-origin security errors as Vue tries to set
    // properties on the (cross origin) child frame.
    this.frameApi = null;

    // Create a noop for config updates to a child frame. This will be replaced when a connection to
    // a config frame is established.
    this.debouncedFrameUpdate = () => {};
  },
  async mounted() {
    const { config, template: { code, config_frame_url: configUrl } } = this;

    // Report to the parent component that we're savable at the outset
    if (this.isSavable) {
      this.$emit('savable', true);
    }

    // Set up an iFrame for communication with an integration configuration app
    if (!configUrl) {
      return;
    }

    // Yay side-effects: This will add the iFrame into the editor above
    const handshake = new Postmate({
      container: this.$refs.editor,
      url: configUrl,
      name: 'config_frame',
      model: {
        code,
        config,
        editMode: this.editing,
      },
    });

    this.frameApi = await handshake;

    // Update the savable state reported by the frame (defaults to true)
    // We're strict-checking false here so `null` and `undefined` are defaulting to `true`
    this.explicityUnsavable = this.frameApi.get('savable') === false;

    this.debouncedFrameUpdate = debounce((payload) => {
      this.frameApi.call('event', {
        event: 'set-config',
        field: null,
        payload,
      });
    }, 200);

    this.frameApi.on('set-height', (height) => {
      this.frameHeight = `${height}px`;
    });
    this.frameApi.on('set-schema', this.setCustomSchema);
    this.frameApi.on('set-external-id', (externalId) => {
      this.$emit('set-external-id', externalId);
    });
    this.frameApi.on('update-config', (updatedConfig) => {
      this.configModel = {
        ...this.configModel,
        ...updatedConfig,
      };
    });
    this.frameApi.on('save', (updatedConfig) => {
      // Backwards compatibility for configuration SDK versions before 0.0.9
      if (updatedConfig) {
        this.configModel = {
          ...this.configModel,
          ...updatedConfig,
        };
      }
      // This event is only handled when editing configuration, not with new integrations
      this.$emit('save');
    });

    this.frameApi.on('set-savable', (state) => {
      // Set whether it's explicitly unsavable, and then trigger the event to report save state
      this.explicityUnsavable = !state;
      this.$emit('savable', this.isSavable);
    });
  },
  methods: {
    /**
     * Parses the form schema and creates default values for each input defined by the schema
     */
    createDefaultConfigAttributes() {
      if (!this.formSchema) {
        return;
      }

      // Create a function to generate default values from the field type
      const guessDefault = ({ type, multiselect }) => {
        /* eslint-disable vue/script-indent */
        switch (type) {
          case 'boolean':
            return false;
          case 'select':
            if (multiselect) {
              return [];
            }
            return '';
          default:
            return '';
        }
        /* eslint-enable vue/script-indent */
      };

      // This is a factory function to create a reducer for looping through the form schema. It's
      // a factory function as we're parsing a recursive schema. It will generate a reducer that
      // will support a specific section of the form schema.
      //
      // "currentConfig" is the existing config values for this level of the schema.
      const createSchemaReducer = (currentConfig) => (acc, schema) => {
        // Deal with recursive schema.
        if (Object.hasOwnProperty.call(schema, 'schema')) {
          return {
            ...acc,
            [schema.key]: schema.schema.reduce(
              createSchemaReducer((currentConfig && currentConfig[schema.key]) || {}),
              {},
            ),
          };
        }

        // Don't bother creating config values for inputs that don't have relevant values
        if (['button', 'link'].includes(schema.type)) {
          return acc;
        }

        // Use the existing value if there is any, falling back to the specified default, falling
        // back again to a guessed default.
        return {
          ...acc,
          [schema.key]: (currentConfig && currentConfig[schema.key])
            || schema.default
            || guessDefault(schema),
        };
      };

      this.$emit('set-config', {
        ...this.formSchema.reduce(createSchemaReducer(this.config), {}),
        ...this.config,
      });
    },
    setCustomSchema(schema) {
      this.customSchema = [
        ...(this.template.form_schema || []),
        ...schema,
      ];
      // Ensure default values are created for new schema elements
      this.createDefaultConfigAttributes();
    },
    handleFieldEvent(event, field) {
      if (!this.frameApi) {
        return;
      }

      this.frameApi.call('event', {
        event,
        field,
        payload: null,
      });
    },
  },
};
</script>

<style lang="scss">
.integrations-edit {
  &__custom-frame {
    @apply w-full overflow-hidden;

    iframe {
      @apply w-full h-full;
    }
  }
}
</style>
