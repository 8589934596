import moment from 'moment';
import get from 'lodash.get';

/**
 * An object with methods that match available filter functions that can be applied client side with
 * the pagination helper. Read the individual methods for descriptions
 */
export const matchers = {
  /**
   * Filters a list by ensuring a column falls within a given date range
   *
   * @param {Array} items The item list to filter
   * @param {String} column The attribute on the resource that should be matched against
   * @param {{ start: String, end: String }} param An object with `start` and `end` dates to define
   *                                               the range that the param date must fall between.
   * @returns {Boolean}
   */
  dateRange(items, column, param) {
    return items.filter((candidate) => {
      const candidateDate = moment.unix(candidate[column]);

      if (!candidateDate.isValid()) {
        return false;
      }

      if (Object.hasOwnProperty.call(param, 'start')) {
        const start = moment(param.start);

        if (start.isValid() && candidateDate.isBefore(start)) {
          return false;
        }
      }

      if (Object.hasOwnProperty.call(param, 'end')) {
        const end = moment(param.end);

        if (end.isValid() && candidateDate.isAfter(end)) {
          return false;
        }
      }

      return true;
    });
  },
  /**
   * Filters a list ensuring that items have a column that exactly matches the given parameter
   *
   * @param {Array} items The item list to filter
   * @param {String} column The attribute on the resource that should be matched against
   * @param {any} param Any value that will be strictly compared to the value in the resource
   * @returns {Boolean}
   */
  exact(items, column, param) {
    return items.filter((candidate) => get(candidate, column) === param);
  },
  /**
   * Filters a list by checking if the given param exists within an array that appears within the
   * resource being matched
   *
   * @param {Array} items The item list to filter
   * @param {String} column The attribute on the resource that should be matched against
   * @param {any} param Any value that might be "included" in the array value in the resource
   * @returns {Boolean}
   */
  in(items, column, param) {
    return items.filter((candidate) => {
      let values = candidate[column];

      if (values && typeof values === 'object') {
        values = Object.values(values);
      }

      if (!Array.isArray(values)) {
        return false;
      }

      return values.includes(param);
    });
  },
};

const convertDotToBrackets = (string) => string.replaceAll(/\.([^.]+)/g, '[$1]');

/**
 * Builds a query string for supported filters so the filter can be run with an API request. See
 * above for explanations on the available filters.
 */
export const queryStringBuilders = {
  dateRange({ column, query, param }) {
    const resultParts = [];

    if (Object.hasOwnProperty.call(param, 'start')) {
      const start = moment(param.start);
      const startParam = query?.start || `${convertDotToBrackets(column)}[after]`;

      if (start.isValid()) {
        resultParts.push(`${encodeURIComponent(startParam)}=${start.unix()}`);
      }
    }

    if (Object.hasOwnProperty.call(param, 'end')) {
      const end = moment(param.end);
      const endParam = query?.end || `${convertDotToBrackets(column)}[before]`;

      if (end.isValid()) {
        resultParts.push(`${encodeURIComponent(endParam)}=${end.unix()}`);
      }
    }

    return resultParts.join('&');
  },
  exact({ column, query, param }) {
    // eslint-disable-next-line no-nested-ternary
    const urlSafeParam = typeof param === 'boolean'
      ? (param ? '1' : '0')
      : encodeURIComponent(param);

    const queryParam = encodeURIComponent(query || convertDotToBrackets(column));
    return `${queryParam}=${urlSafeParam}`;
  },
  in({ column, query, param }) {
    const queryParam = encodeURIComponent(query || convertDotToBrackets(column));
    return `${queryParam}[]=${encodeURIComponent(param)}`;
  },
};

/**
 * A helper function to build a query strings from a given array of filters and a search term
 *
 * @param {Array} filters
 * @param {String} term
 * @returns {String}
 */
export function buildQueryString(filters, term = '') {
  const queryStringParts = filters
    .map((filter) => queryStringBuilders[filter.type](filter));

  // Add a search term if one was provided
  if (term.trim().length > 0) {
    queryStringParts.push(`query=${encodeURIComponent(term)}`);
  }

  // Finish the query string and send a request off to the API
  return queryStringParts.join('&');
}
