<template>
  <ChecModal
    v-if="showConfirm"
    :header="header"
    @dismiss="handleCancel"
  >
    <p v-if="calloutMessage" class="confirm__callout">
      {{ calloutMessage }}
    </p>
    <p v-if="message">
      {{ message }}
    </p>

    <template #toolbar>
      <ChecButton
        class="confirm__cancel"
        color="primary"
        text-only
        @click="handleCancel"
      >
        {{ cancelLabel }}
      </ChecButton>
      <ChecButton button-type="submit" color="primary" @click.prevent="handleConfirm">
        {{ confirmLabel }}
      </ChecButton>
    </template>
  </ChecModal>
</template>

<script>
import { ChecButton, ChecModal } from '@chec/ui-library';
import { mapGetters } from 'vuex';

export default {
  name: 'Confirm',
  components: { ChecButton, ChecModal },
  computed: {
    ...mapGetters('notifications', ['confirmDialog']),
    showConfirm() {
      return this.confirmDialog.open ?? false;
    },
    header() {
      return this.confirmDialog.title ?? null;
    },
    message() {
      return this.confirmDialog.message ?? null;
    },
    calloutMessage() {
      return this.confirmDialog.options.callout ?? null;
    },
    cancelLabel() {
      return this.confirmDialog.options.cancelLabel ?? 'Cancel';
    },
    confirmLabel() {
      return this.confirmDialog.options.confirmLabel ?? 'Yes';
    },
  },
  methods: {
    handleCancel() {
      this.confirmDialog.onCancel();
    },
    handleConfirm() {
      this.confirmDialog.onConfirm();
    },
  },
};
</script>

<style lang="scss">
.confirm {
  &__callout {
    @apply font-bold mb-2;
  }
}
</style>
