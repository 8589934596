<template>
  <div class="default-layout" :class="classNames">
    <Navigation v-if="showNav" />
    <main id="main" class="main" :class="{'container': !fullscreenPage }">
      <DetailsPageNavigator v-if="!fullscreenPage || showFallbackNav" />
      <ErrorPage v-if="applicationError" />
      <slot v-else />
      <ChecFooter v-if="!hideFooter" />
    </main>
    <Confirm />
    <Notifications />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Confirm from '@/modules/notifications/components/Confirm.vue';
import Notifications from '@/modules/notifications/components/Notifications.vue';
import DetailsPageNavigator from '@/components/DetailsPageNavigator.vue';
import ChecFooter from '@/components/ChecFooter.vue';
import Navigation from '@/components/Navigation.vue';
import ErrorPage from '@/views/ErrorPage.vue';

export default {
  name: 'DefaultLayout',
  components: {
    ChecFooter,
    Confirm,
    DetailsPageNavigator,
    ErrorPage,
    Notifications,
    Navigation,
  },
  computed: {
    ...mapState(['errors']),
    ...mapState('merchant', ['merchant']),
    ...mapGetters(['isLoading', 'userData']),
    ...mapGetters('merchant', ['hasCompletedSetup']),
    ...mapGetters('notifications', ['hasNewOrder']),
    /**
     * Returns true if there's an application error that should be shown
     *
     * @returns {boolean}
     */
    applicationError() {
      return process.env.NODE_ENV !== 'development' && this.errors.length > 0;
    },
    classNames() {
      return {
        'default-layout--no-nav': !this.showNav,
        'default-layout--fullscreen': this.fullscreenPage,
      };
    },
    /**
     * Returns true if there's a page is set to fullscreen in the meta
     *
     * @returns {boolean}
     */
    fullscreenPage() {
      const fullscreenPage = this.$route.matched.findIndex((route) => route.meta.fullscreenPage);
      return fullscreenPage >= 0;
    },
    /**
     * Some pages may not want to have a footer shown, this determines whether to show it.
     *
     * @returns {Boolean}
     */
    hideFooter() {
      // Check for a direct entry on this route
      if (typeof this.$route.meta.hideFooter !== 'undefined') {
        return this.$route.meta.hideFooter;
      }

      // Check hierarchy in reverse direction for the first definition
      // .reverse will mutate the existing array so we'll copy it
      const matches = [...this.$route.matched];
      // Our linting rules prevent a for..of loop here which would be way cleaner
      // (https://github.com/airbnb/javascript/issues/1271)
      const firstDefinitionIndex = matches.reverse().findIndex(
        ({ meta: { hideFooter } }) => hideFooter !== undefined,
      );

      if (firstDefinitionIndex < 0) {
        return false;
      }

      return matches[firstDefinitionIndex].meta.hideFooter;
    },
    /**
     * Check if we should show the "DetailsPageNavigator" component in order to show a fallback nav.
     * This only applies (atm) to routes that are merchantless (and when we're not showing the nav).
     */
    showFallbackNav() {
      return this.$route.meta.merchantless && !this.showNav;
    },
    /**
     * Only show the navigation if a merchant was successfully fetched. This temporarily allows
     * us to show an empty layout during the merchant signup wizard process. In future we will
     * make this a first class citizen of the dashboard, where it is currently part of the iframed
     * "merchant" codebase.
     *
     * @returns {boolean}
     */
    showNav() {
      return (
        // State isn't set to loading
        !this.isLoading
        // We have merchant context
        && this.merchant?.id
        // There's no application level error
        && !this.applicationError
        // The route doesn't specify that the nav should be hidden
        && this.$route.meta.hideNav !== true
      );
    },
  },
};
</script>

<style lang="scss">
.default-layout {
  @apply bg-gray-100 pt-20 px-2 relative mt-20;

  @screen sm {
    @apply px-10;
  }

  @screen lg {
    // Move nav from top to left
    @apply mt-0;
    margin-left: 15rem;
  }
  min-height: calc(100vh - 0rem); // todo swap with footer's min. possible footer height

  &--no-nav {
    @apply ml-0 mt-0;
  }

  &--fullscreen {
    @apply p-0;

    .main {
      z-index: 1;
    }
  }

  .main {
    @apply relative font-lato mx-auto;
    min-width: 375px;
  }
}

.back-link {
  @apply flex items-center mb-12 text-gray-500;

  &__icon {
    @apply w-4 mr-2;
  }
}
</style>
