import axios from 'axios';
import authState from './auth/state';
import resolveDomain from './resolveDomain';
import Sentry from './sentry';
import ApiError from './api/ApiError';

let extraParams = {};

export const apiConfig = {
  url: resolveDomain('api'),
  authUrl: resolveDomain('authorize'),
};

export const setExtraParams = (params) => { extraParams = params; };

export class ApiRequest {
  constructor(method, endpoint, baseUrl = apiConfig.url) {
    this.method = method;
    this.endpoint = endpoint;

    this.extraHeaders = {};
    this.excludeExtraParams = false;
    this.extraOptions = {};
    this.baseUrl = baseUrl;
    this.expectedErrors = [];
  }

  addHeaders(headers) {
    this.extraHeaders = {
      ...this.extraHeaders,
      ...headers,
    };
  }

  disableExtraParams(setting = true) {
    this.excludeExtraParams = setting;
  }

  setAxiosOptions(options) {
    this.extraOptions = options;
  }

  expectError(status, messageMatcher) {
    this.expectedErrors.push([status, messageMatcher]);
  }

  getFullUrl() {
    const url = `${this.baseUrl}${this.endpoint}`;
    let extra = '';

    // Add in extra parameters if set globally
    if (!this.excludeExtraParams && Object.keys(extraParams).length > 0) {
      const concatSymbol = url.includes('?') ? '&' : '?';
      extra = `${concatSymbol}${Object.entries(extraParams)
        // Filter out params that are already in the URL
        .filter(([param]) => !url.match(new RegExp(`[?&]${param}=`, 'i')))
        .map(([param, value]) => `${encodeURIComponent(param)}=${encodeURIComponent(value)}`)
        .join('&')
      }`;
    }

    return `${url}${extra}`;
  }

  handleError(error) {
    if (!error || !error.response) {
      // Pass through the error
      return Promise.reject(error);
    }

    const { status } = error.response;

    // Prepare the URL for fingerprinting, by removing things that look like IDs
    const preppedUrl = this.getFullUrl().replace(/(\/)[a-z_]+_[a-zA-Z0-9]{14,}(\/?)/g, '$1:id$2')
      .replace(/\/$/, '');

    if (!status
      || typeof status !== 'number'
      // Catch any 4xx error that's a generic 400 statuses and anything over 404
      || (status !== 400 && (status < 404 || status >= 500))
    ) {
      // Sentry doesn't care, but the implementation should still catch errors
      return Promise.reject(error);
    }

    // Check if the error is expected by looping through "expected errors" that might be configured
    if (this.expectedErrors.some(([candidateStatus, messageMatcher]) => {
      // We're looking for any one of these "expected errors" to match the actual error, and return
      // _true_ if it should be ignored

      // Check that the status code matches
      if (status !== candidateStatus) {
        return false;
      }

      const { errors } = (error.response.data.error || {});

      // Handle different types of `messageMatcher`
      switch (true) {
        // An undefined matcher means the intent was to ignore all errors with the provided status
        case messageMatcher === undefined:
          return true;
        // If the message matcher is just a string or regexp then we'll match the JSON body
        case messageMatcher instanceof RegExp:
          return JSON.stringify(error.response.data).match(messageMatcher);
        case typeof messageMatcher === 'string':
          return JSON.stringify(error.response.data).includes(messageMatcher);
        // Allow providing possible messages keyed by the field they should appear under
        case typeof messageMatcher === 'object':
          if (!errors) {
            return false;
          }

          return Object.entries(messageMatcher).some(([field, message]) => (
            Object.hasOwnProperty.call(errors, field) && errors[field].some(
              // Note that the errors for a field given by the API is an array of errors
              (candidate) => candidate.includes(message),
            )
          ));
        default:
          return false;
      }
    })) {
      // Skip Sentry
      return Promise.reject(error);
    }

    Sentry.captureException(new ApiError(
      error,
      `API request failed with status ${status}`,
    ), {
      // Set up custom fingerprinting to split issues by HTTP code and API URL
      fingerprint: [
        `api-${status}`,
        `${preppedUrl}`,
      ],
    });

    // Always re-throw the error so consumers can handle it
    return Promise.reject(error);
  }

  send(data = {}) {
    if (!authState.isTokenValid()) {
      // todo throw an error and use errorCaptured() hook. At time of writing
      // I couldn't get this to work.
      authState.clear();
      window.location.reload();
      return Promise.reject();
    }

    const headers = {
      Accept: 'application/json',
      ...this.extraHeaders,
      Authorization: `Bearer ${authState.getToken()}`,
    };

    return axios({
      ...this.extraOptions,
      method: this.method,
      url: this.getFullUrl(),
      headers,
      [this.method.toLowerCase() === 'get' ? 'params' : 'data']: data,
    })
      // Catch errors returned so that we can report them to Sentry
      .catch(this.handleError.bind(this));
  }
}

/**
 * Returns an axios client with the provided HTTP method, API endpoint, and the JWT bearer
 * token attached.
 *
 * @param {string} method get, post, put, patch, delete
 * @param {string} endpoint Including leading slash e.g. `/v1/orders/ord_s9f894`
 * @param {object} data Data to send with the payload
 * @param {object} extraHeaders Any additional headers to use for the request
 * @param {object} extraOptions Any additional axios options
 * @param {string} baseUrl The base URL to use for the request, defaults to the API url
 * @param {boolean} excludeExtraParams Whether to avoid adding extra params that are globally set
 * @returns {Promise}
 */
export const makeApiRequest = (
  method,
  endpoint,
  data = {},
  extraHeaders = {},
  extraOptions = {},
  baseUrl = apiConfig.url,
  excludeExtraParams = false,
) => {
  const request = new ApiRequest(method, endpoint, baseUrl);
  request.addHeaders(extraHeaders);
  request.setAxiosOptions(extraOptions);
  request.disableExtraParams(excludeExtraParams);
  return request.send(data);
};
