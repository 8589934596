/**
 * Recursively redacts properties from objects where their names that match (partially or in full)
 * names in a blacklist. Intended for use in logging (eg. Sentry) where sensitive data should not be
 * stored
 *
 * @param {*} object
 * @param {Array<String>} blacklist
 * @returns {*}
 */
export default function sanitize(object, blacklist) {
  if (Array.isArray(object)) {
    return object.map((value) => sanitize(value, blacklist));
  }

  if (!object || typeof object !== 'object') {
    return object;
  }

  return Object.entries(object).reduce((acc, [prop, value]) => {
    // Blacklist whatever the value is if the prop matches
    if (blacklist.some((badProp) => prop.match(new RegExp(badProp)))) {
      return {
        ...acc,
        [prop]: '[redacted]',
      };
    }

    // Recurse into arrays and objects
    if (Array.isArray(value)) {
      return {
        ...acc,
        [prop]: value.map((maybeObject) => sanitize(maybeObject, blacklist)),
      };
    }

    if (value && typeof value === 'object') {
      return {
        ...acc,
        [prop]: sanitize(value, blacklist),
      };
    }

    return { ...acc, [prop]: value };
  }, {});
}
