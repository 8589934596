<template>
  <ChecTable class="integration__info-card">
    <tbody>
      <tr v-if="!shortcutTemplate">
        <td class="integration__info-card-header">
          {{ $t('general.health') }}
        </td>
        <td>
          <ChecDataPill v-tooltip="statusTooltip" :color="status.color">
            {{ status.label }}
          </ChecDataPill>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t('webhooks.created') }}
        </td>
        <td>
          <Timestamp :timestamp="integration.created" />
        </td>
      </tr>
      <tr v-if="!shortcutTemplate">
        <td>
          {{ $t('integration.lastRun') }}
        </td>
        <td>
          <Timestamp :timestamp="integration.last_run" />
        </td>
      </tr>
    </tbody>
  </ChecTable>
</template>

<script>
import {
  ChecTable,
  ChecDataPill,
} from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'InfoCard',
  components: {
    ChecTable,
    ChecDataPill,
    Timestamp,
  },
  props: {
    integration: {
      type: Object,
      default: () => {},
    },
    shortcutTemplate: Boolean,
  },
  computed: {
    /**
     * Returns the colour and label for the "health" status badge depending on the success rate
     * of the integration.
     */
    status() {
      const { success_rate: successRate, last_run: lastRun } = this.integration;

      if (lastRun === null) {
        return { color: 'gray', label: this.$t('integration.status.notApplicable') };
      }

      if (successRate === 1) {
        return { color: 'green', label: this.$t('integration.status.healthy') };
      }

      if (successRate < 1 && successRate > 0) {
        return { color: 'orange', label: this.$t('integration.status.unstable') };
      }

      return { color: 'red', label: this.$t('integration.status.unhealthy') };
    },
    /**
     * A tooltip for context on what the health status means
     */
    statusTooltip() {
      const { success_rate: successRate } = this.integration;

      const percent = `${Math.round((successRate || 0) * 100)}%`;

      return this.$t('integration.healthTooltip', { percent });
    },
  },
};
</script>

<style lang="scss">
.integration {
  &__info-card {
    .chec-table__table {
      @apply w-full mb-8;

      td:first-of-type {
        @apply caps-xxs;
      }

      td:last-of-type {
        @apply text-right;
      }
    }

    code {
      @apply py-1 px-2 bg-gray-200 rounded-sm break-words
        text-xs font-mono tracking-wide text-gray-600;
    }
  }

  &__info-card-header {
    @apply w-1/3 align-middle;

    &--events {
      @apply flex;
    }
  }
}
</style>
