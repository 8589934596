import { actions } from '@/lib/pagination';

export default function debouncedSearch(dispatch, term, debounceInterval = 500) {
  // If all the items are already in state (aka fully loaded), or there's no debounce
  // requested then we can just immediately dispatch the action to search
  if (this.isFullyLoaded() || debounceInterval === 0) {
    return dispatch(actions.SEARCH_ITEMS, { term });
  }

  // Clear any existing timeout for the debounce
  clearTimeout(this.debouncedSearchTimeout);

  // Return a promise to keep the return type consistent
  return new Promise((resolve) => {
    // Create a timeout that resolves this promise after the requested interval, and store
    // the timeout reference so it can be cancelled later
    this.debouncedSearchTimeout = setTimeout(() => {
      resolve();
    }, debounceInterval);
  })
    // Attach a promise chain to perform our search action once the promise is resolved.
    .then(() => dispatch(actions.SEARCH_ITEMS, { term }));
}
