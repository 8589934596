export default [
  {
    path: '/settings/attributes',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('attributes.attributes'),
    },
    children: [
      {
        path: '',
        name: 'settings.attributes.home',
        component: () => import(/* webpackChunkName: "settings" */ './views/home.vue'),
      },
      {
        path: 'add',
        name: 'settings.attributes.add',
        component: () => import(/* webpackChunkName: "settings" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('attributes.add'),
        },
      },
      {
        path: ':id(attr_[^/]+)',
        name: 'settings.attributes.edit',
        component: () => import(/* webpackChunkName: "settings" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('attributes.edit'),
        },
      },
    ],
  },
];
