<template>
  <ChecCard
    inner-class="integrations__actions-card"
    tailwind="p-4"
  >
    <div class="integrations__actions-card-top">
      <ChecButton
        color="green"
        button-type="submit"
        :disabled="disableSave"
        @click="() => $emit('save-integration')"
      >
        {{ $t('general.saveChanges') }}
      </ChecButton>
      <ChecOptionsMenu
        class="integrations__actions-card-options"
        :aria-label="$t('product.additionalActions')"
      >
        <ChecOption
          destructive
          @option-selected="() => $emit('delete-integration')"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecCard,
    ChecButton,
    ChecOption,
    ChecOptionsMenu,
  },
  props: {
    disableSave: Boolean,
  },
};
</script>

<style lang="scss">
.integrations {
  &__actions-card {
    @apply flex flex-col;
  }

  &__actions-card-top {
    @apply flex flex-row gap-2;

    .button {
      @apply w-full;
    }
  }

  &__actions-card-options {
    @apply flex items-center;
  }
}
</style>
