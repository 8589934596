import { mapActions, mapMutations } from 'vuex';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import router from 'vue-router';

const { isNavigationFailure, NavigationFailureType } = router;

export default {
  methods: {
    ...mapActions({ setMerchant: `merchant/${actions.FETCH_MERCHANT}` }),
    // Hopefully there'll be no collision on this
    ...mapMutations({ setGlobalLoading: mutations.SET_LOADING }),

    /**
     * Update the current merchant, and change the route to the home page for that merchant
     *
     * @param id
     * @returns {Promise<void>}
     */
    async switchMerchant(id) {
      // Enable a global loader
      this.setGlobalLoading(true);
      // Dispatch the action
      try {
        await this.setMerchant({ id });
      } finally {
        this.setGlobalLoading(false);
      }

      // Redirect to the homepage, catching redundant navigation
      this.$router.push({ name: 'home' }).catch((error) => {
        // Avoid errors for duplicated clicks on the account button
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }

        throw error;
      });
    },
  },
};
