<template>
  <ChecPaginate
    v-if="show"
    :count="totalCount"
    :page="page"
    :page-size="pageSize"
    class="pagination"
    @choose-page="setPage"
    @choose-page-size="setPageSize"
  />
</template>

<script>
import { ChecPaginate } from '@chec/ui-library';
import { actions } from '@/lib/pagination';
import withPropDefinedState from '@/mixins/withPropDefinedState';

export default {
  name: 'DashboardPagination',
  components: { ChecPaginate },
  mixins: [
    withPropDefinedState({
      state: ['page', 'pageSize', 'totalCount', { currentContext: 'context' }],
      actions: { setPage: actions.SET_PAGE, setPageSize: actions.SET_PAGE_SIZE },
    }),
  ],
  props: {
    /**
     * The context of the pagination. Refer to the pagination docs for a run-down on context
     */
    context: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Whether to switch back to page 1 when the component is mounted, regardless of the current
     * page remembered in state. Note this prop will be ignored if the context has changed
     */
    keepPageOnMount: Boolean,
    /**
     * Whether to avoid dispatching any loading action at all
     */
    skipLoadingResources: Boolean,
  },
  computed: {
    show() {
      // Is a page loaded?
      return typeof this.page === 'number'
        // Do we have any items at all and whether we have more than one page (15 items by default)
        && this.totalCount > 0 && this.totalCount > 15;
    },
  },
  mounted() {
    if (this.skipLoadingResources) {
      return;
    }

    // Skip resetting the page if there's already one set, and we've been told not to
    if (typeof this.page === 'number' && this.keepPageOnMount) {
      // Ensure the context hasn't changed - if it has then we should still set a page to update the
      // "context" in state.
      if (
        !Object.entries(this.currentContext)
          .some(([key, value]) => value !== this.context[key])
      ) {
        return;
      }
    }

    this.setPage({ page: 1, context: this.context });
  },
};
</script>

<style lang="scss">
.pagination {
  @apply mt-4;
}
</style>
