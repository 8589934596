export default [
  {
    path: '/developer',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    children: [
      {
        path: 'api-keys',
        name: 'developer.apiKeysCors',
        component: () => import(/* webpackChunkName: "settings" */ './views/api-keys-cors.vue'),
        meta: {
          title: (i18n) => i18n.t('developer.apiKeysCors'),
        },
      },
      {
        path: 'logs',
        name: 'developer.logs',
        component: () => import(/* webpackChunkName: "settings" */ './views/logs.vue'),
        meta: {
          title: (i18n) => i18n.t('logs.logs'),
        },
        children: [
          {
            path: ':id(log_[^/]+)',
            name: 'developer.logs.view',
          },
        ],
      },
    ],
  },
];
