import { mapActions, mapMutations, mapState } from 'vuex';

// Helper function to find nested state from a typical state key (eg. billing/invoices)
const stateByKey = (state, key) => key.split('/')
  .reduce((carriedState, deeperKey) => carriedState[deeperKey], state);

/**
 * Maps requested state properties from Vuex based off of a Vuex module name (or a key that relates
 * a subset of the state) given as a prop to the component.
 *
 * Eg.
 * `withPropDefinedState({ state: ['page']})` will create a prop definition on the component
 * (`state-key`) that can be given a module (ie. `products`) and it will map the current the `page`
 * attribute from "products" state
 *
 * You may also specify getters and actions to pull from the VueX module for the given state key:
 *
 * ```
 * withPropDefinedState({
 *   actions: { search: paginationActions.SEARCH_ITEMS },
 *   getters: { isLoaded: paginationGetters.IS_FULLY_LOADED },
 *   state: { 'page' },
 * }
 * ```
 *
 * The above example will create the `search` method, and the `page` and `isLoaded` computed
 * properties on your component, from the VueX modules defined by the state key passed in as a prop
 *
 * Note that property-access style getters need to be separated from method-access style getters,
 * known by "functionalGetters" as an argument.
 *
 * @param {Object} mapping The state property names, actions names, getter names, and "functional
 *                         getters" that need to be mapped
 * @param {Object} stateKeyProp The name of the prop that is added
 * @param {Boolean} required Whether the state key is required in the component
 * @returns {Object}
 */
export default ({
  actions,
  functionalGetters,
  getters,
  mutations,
  state: requiredState,
}, stateKeyProp = 'stateKey', required = true) => ({
  props: {
    [stateKeyProp]: {
      type: String,
      required,
    },
  },
  computed: {
    // Map state using the less common functional method, where we can rely on props. We reduce the
    // requested state keys into an object where each key is a function that takes the state and
    // returns the relevant state
    ...mapState(Object.entries((Array.isArray(requiredState) ? requiredState : [requiredState])
      .reduce((acc, value) => {
        if (Array.isArray(value)) {
          return {
            ...acc,
            ...(value.reduce((obj, key) => ({ ...acc, [key]: key }))),
          };
        }

        if (typeof value === 'object') {
          return {
            ...acc,
            ...value,
          };
        }

        return {
          ...acc,
          [value]: value,
        };
      }, {}))
      .reduce((acc, [mapping, stateKey]) => ({
        ...acc,
        [mapping](state) {
          if (!required && !this[stateKeyProp]) {
            return null;
          }

          return stateByKey(state, this[stateKeyProp])[stateKey];
        },
      }), {})),
    ...(Object.entries(getters || {}).reduce(
      (acc, [key, getter]) => ({
        ...acc,
        [key]() {
          if (!required && !this[stateKeyProp]) {
            return null;
          }

          return this.$store.getters[`${this[stateKeyProp]}/${getter}`]();
        },
      }),
      {},
    )),
  },
  methods: {
    ...mapMutations(Object.entries(mutations || {}).reduce((acc, [method, mutation]) => ({
      ...acc,
      [method](commit, payload) {
        if (!required && !this[stateKeyProp]) {
          return null;
        }

        return commit(`${this[stateKeyProp]}/${mutation}`, payload);
      },
    }), {})),
    ...mapActions(Object.entries(actions || {}).reduce((acc, [method, action]) => ({
      ...acc,
      [method](dispatch, payload) {
        if (!required && !this[stateKeyProp]) {
          return Promise.resolve(null);
        }
        if (typeof action === 'function') {
          return action.bind(this)((rawAction, ...args) => (
            dispatch(`${this[stateKeyProp]}/${rawAction}`, ...args)
          ), payload);
        }
        return dispatch(`${this[stateKeyProp]}/${action}`, payload);
      },
    }), {})),
    ...(Object.entries(functionalGetters || {}).reduce((acc, [key, getter]) => ({
      ...acc,
      [key](...args) {
        if (!required && !this[stateKeyProp]) {
          return null;
        }

        return this.$store.getters[`${this[stateKeyProp]}/${getter}`](...args);
      },
    }), {})),
  },
});
