<template>
  <component
    :is="component"
    class="navigation-item"
    :class="{ 'navigation-item--current': active }"
    v-bind="bindings"
    v-on="handlers"
  >
    <ChecNavIcon :icon="icon" class="navigation-item__icon" />
    <Ping v-if="ping" />
    <span class="navigation-item__label">{{ label }}</span>
    <ChecButton
      v-if="hasSubMenu"
      :icon="subMenuOpen ? 'up' : 'down'"
      variant="small"
      @click.stop.prevent="$emit('toggle-sub-nav')"
    />
  </component>
</template>

<script>
import { mapState } from 'vuex';
import { ChecButton, ChecNavIcon } from '@chec/ui-library';
import isExternalLink from '@/lib/isExternalLink';
import Ping from './Ping.vue';

export default {
  name: 'NavItem',
  components: {
    ChecButton,
    ChecNavIcon,
    Ping,
  },
  props: {
    active: Boolean,
    hasSubMenu: Boolean,
    icon: String,
    label: String,
    link: [String, Object],
    ping: Boolean,
    subMenuOpen: Boolean,
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    bindings() {
      const { link } = this;

      if (!link) {
        return {};
      }

      if (typeof link === 'object' || !isExternalLink(link)) {
        return {
          to: link,
        };
      }

      return {
        // Slightly awkward way of tying in the merchant ID to links for spaces
        href: link.replace('{merchant}', this.merchant.id || ''),
        target: '_blank',
        rel: 'noopener',
      };
    },
    component() {
      const { link } = this;

      if (!link) {
        return 'div';
      }

      if (typeof link === 'string') {
        return 'a';
      }

      return 'router-link';
    },
    handlers() {
      if (!this.link) {
        return {
          click: () => {
            this.$emit('toggle-sub-nav');
          },
        };
      }

      return {};
    },
  },
};
</script>

<style lang="scss">
.navigation-item {
  @apply flex caps-xs text-gray-500 py-4 px-3 items-center rounded cursor-pointer;

  &:hover {
    @apply bg-gray-200;
  }

  &:active {
    @apply bg-gray-300;
  }

  &__icon {
    @apply w-6 h-6 mr-2;
  }

  &__label {
    @apply flex-grow;
  }

  &--current {
    &:not(:active):not(:hover) {
      @apply bg-gray-200;
    }
  }

  & + & {
    @apply mt-2;
  }
}
</style>
