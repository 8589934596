export default {
  account: {
    account: "Cuenta",
    currentPassword: "Contraseña actual",
    dashboardLanguage: "Idioma del panel",
    deleteAccount: "Eliminar cuenta",
    deleteCallout: "Estás a punto de eliminar tu cuenta de forma permanente.",
    deleteFailed: "No se ha podido eliminar tu cuenta! Inténtalo mas tarde.",
    deleteMessage: "Si eliminas tu cuenta será permanente y no se puede revertir. Asegúrate de haber exportado todos tus pedidos.",
    deleteMyAccount: "Eliminar mi cuenta",
    details: "Detalles de la cuenta",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "Información de la cuenta",
    language: "Idioma",
    logout: "Cerrar sesión",
    missingPasswords: "Introduzca las contraseñas actuales y las nuevas deseadas.",
    newPassword: "Contraseña nueva",
    passwordUpdateFailed: "No se ha podido actualizar la contraseña. Inténtalo de nuevo.",
    passwordUpdated: "La contraseña se ha actualizado correctamente.",
    updateDetails: "Actualizar datos",
    updateFailed: "No se pudo guardar la información de su cuenta. Inténtalo de nuevo.",
    updateLanguage: "Actualizar idioma",
    updatePassword: "Actualizar contraseña",
    updatedDetailsSuccessfully: "Details updated successfully.",
    updatedLanguageSuccessfully: "Language updated successfully."
  },
  address: {
    city: "Ciudad",
    country: "País",
    line1: "Dirección - línea 1",
    line2: "Dirección - línea 2",
    name: "Nombre",
    state: "Estado/Provincia",
    zipCode: "Codigo postal"
  },
  attribute: {
    attributes: "Atributos"
  },
  attributes: {
    add: "Add attribute",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Atributos",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Valor predeterminado",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "El tipo de campo es obligatorio",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Something went wrong. Please try logging in again.",
    unableToAuth: "Unable to authorize your account. Please try again."
  },
  billing: {
    accruedFees: "Tasas por transacción acumuladas",
    addCard: "Añadir tarjeta",
    addPaymentMethod: "Añadir forma de pago",
    billing: "Facturación",
    changePlan: "Cambiar plan",
    checFee: "Tasa Chec ({currency})",
    choosePlan: "Elige un plan",
    confirmChangePlan: "Si el plan al que vas a cambiar tiene restricciones en cuanto a las funciones de la plataforma (como Commerce Rays), tus recursos se reducirán automáticamente para ajustarse a los límites de tu nuevo plan.",
    currentPlan: "Plan actual",
    customPricing: "Precios personalizados",
    emailAddress: "Correo electrónico de facturación",
    invoiceAmount: "Cantidad",
    invoiceDate: "Fecha",
    invoiceDescription: "Descripción",
    invoiceDownload: "Descargar",
    invoiceStatus: "Estado",
    invoices: "Facturas",
    monthlyCeiling: "{ceiling} límite mensual",
    noPaymentMethod: "No se seleccionó un método de pago",
    orderValue: "Valor del pedido ({currency})",
    overageFee: "{fee} excedente",
    paid: "Pagado",
    paymentGateway: "Plataforma de pago",
    paymentMethod: "Método de pago",
    paymentMethodRequired: "Por favor añade un método de pago antes de elegir nuevos planes",
    perMonth: "por mes",
    perTransaction: "Por transacción",
    planDetails: "Detalles del plan",
    planMonthlyCeiling: "{ceiling} límite mensual",
    planName: "plan {name}",
    planOverage: "{overage} excedente",
    planSummary: "Se te cobrará {perMonth} por mes y {perTransaction} por transacción",
    planTransactionFee: "{fee} tasa de transacción",
    transactionFeeOrder: "Pedido",
    transactionFeesTitle: "Tasas de transacción facturadas el {date}",
    unpaid: "No pagado",
    updateCard: "Actualizar",
    updateEmail: "Actualizar correo electrónico de facturación",
    viewInvoice: "Ver factura",
    voided: "Anulado"
  },
  category: {
    add: "Añadir categoría",
    addSubCategory: "Add sub-category",
    added: "Categoría añadida.",
    addedSubCategory: "Added sub-category.",
    categories: "Categorías",
    chooseParentCategory: "Choose parent category",
    confirmDelete: "¿Seguro de que deseas eliminar esta categoría?",
    confirmDeleteTitle: "¿Estás seguro?",
    create: "Crear categoría",
    createError: "Lo sentimos, tenemos problemas para crear esta categoría.",
    deleteFailed: "Failed to delete your category! Please try again.",
    deleted: "Categoría eliminada",
    edit: "Editar categoria",
    editMultiple: "Editar categorías",
    images: {
      title: "Galería de imágenes",
      types: "| {types} aceptados | {types} & {lastType} aceptados"
    },
    introSplash: {
      description: "Create infinitely nestable product categories with category images and descriptions. {more}.",
      more: "Más información",
      title: "Add categories and sub-categories."
    },
    noAvailableCategories: "Please add another category first",
    noSubCategories: "This category has no sub-categories.",
    permalink: "Enlace permanente",
    pleaseEnterName: "Por favor, introduzca nombre",
    pleaseEnterPermalink: "Please enter a permalink",
    save: "Guardar categoría",
    selectPlaceholder: "Elige categoría",
    subCategories: "Subcategorías",
    update: "Actualizar categoría",
    updateError: "Lo sentimos, tenemos problemas para guardar esta categoría.",
    updateSubCategoryError: "Sorry, we're having problems saving this sub-category.",
    updated: "Categoría actualizada."
  },
  customer: {
    add: "Añadir cliente",
    added: "Cliente añadido.",
    confirmDelete: "¿Estás seguro de que quieres eliminar a este cliente?",
    create: "Crear cliente",
    createError: "Lo sentimos, tenemos problemas para crear este cliente.",
    customers: "Clientes",
    deleteFailed: "Failed to delete your customer! Please try again.",
    deleted: "Cliente eliminado.",
    edit: "Editar cliente",
    emailTaken: "Esa dirección de correo electrónico ya está en uso",
    externalId: "ID externo",
    introSplash: {
      description: "Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.",
      title: "Your customers will be displayed here"
    },
    notesTitle: "Notas",
    save: "Guardar cliente",
    update: "Actualizar cliente",
    updateError: "Lo sentimos, tenemos problemas para guardar a este cliente.",
    updated: "Cliente actualizado."
  },
  developer: {
    apiKeys: "Claves API",
    apiKeysCors: "API keys & CORS",
    checCli: "Chec CLI",
    cliNotice: "Utilice {cli} para transmitir los registros en tiempo real: {cliCommand}",
    copyCodeFailure: "No se pudo copiar el código en el portapapeles. Actualiza la página e inténtalo de nuevo.",
    copyCodeSuccess: "El código se ha copiado en el portapapeles.",
    corsDomainsHelp: "You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the \"Access-Control-Allow-Origin\" header. If you specify multiple, API calls will compare against the request's \"Origin\" header. If you leave this blank, all origins will be allowed (default).",
    crossOriginResourceSharing: "Cross-Origin Resource Sharing (CORS) origins",
    dateTime: "Fecha/Hora",
    ipAddress: "Dirección IP",
    keyUsed: {
      label: "Clave utilizada",
      "public": "Público",
      secret: "Privado"
    },
    method: "Método",
    request: "Solicitud",
    requestMethod: {
      "delete": "ELIMINAR",
      get: "GET",
      label: "Método de solicitud",
      post: "POST",
      put: "PUT"
    },
    response: "Respuesta",
    responseDate: {
      label: "Fecha de respuesta"
    },
    responseStatus: {
      badRequest: "Solicitud incorrecta",
      label: "Estado de respuesta",
      notFound: "No encontrado",
      ok: "Aceptar",
      other: "Otros/error de servidor",
      requestFailed: "Error en la solicitud",
      unauthorized: "No autorizado",
      validation: "Validación"
    },
    summary: "Resumen",
    url: "Dirección URL"
  },
  discount: {
    add: "Añadir descuento",
    added: "Descuento añadido.",
    all: "Todo",
    code: "Código",
    codeRequired: "Se requiere código de descuento",
    confirmDelete: "¿Estás seguro de que quieres eliminar este descuento?",
    create: "Crear descuento",
    deleteFailed: "Failed to delete your discount! Please try again.",
    deleted: "Descuento eliminado.",
    discountCode: "Código de descuento",
    discountOffProduct: "{discount} de descuento {product}",
    discounts: "Descuentos",
    edit: "Editar descuento",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "Más información",
      title: "Add a discount code and run a promotion."
    },
    neverExpires: "Nunca",
    productTooltip: "If left empty, this discount will work for all products",
    productsLabel: "Limit to these products",
    saveError: "Lo sentimos, tenemos problemas para guardar este descuento.",
    updated: "Descuento actualizado.",
    valueRequired: "Introduce el valor de descuento"
  },
  error: {
    notFoundSuggestion: "Vuelve a la página anterior e inténtalo de nuevo.",
    notFoundTitle: "No encontrado...",
    permissionErrorSuggestion: "Intenta cerrar sesión y volver a iniciar sesión. Si el problema persiste, póngase en contacto con el soporte técnico.",
    permissionErrorTitle: "¡Permiso denegado!",
    serverErrorSuggestion: "Toma un café y vuelve a intentarlo en unos minutos. También se nos ha notificado este error.",
    serverErrorTitle: "Se ha producido un error",
    somethingWentWrong: "Ups. Algo salió mal!"
  },
  extraFields: {
    add: "Añadir campo extra",
    addOptions: "Pulsa enter o coma para añadir una opción",
    collectsBilling: "Requerir dirección de facturación",
    collectsFullName: "Requerir nombre completo",
    collectsShipping: "Requerir dirección de envío",
    confirmDeleteExtraField: "¿Estás seguro de que quieres eliminar este campo adicional? Los escaparates que dependen de este campo adicional pueden verse afectados.",
    deleteFailed: "No se ha podido eliminar el campo adicional. Inténtalo de nuevo.",
    deleted: "El campo adicional se ha eliminado correctamente.",
    description: "Recopile información personalizada de su cliente.",
    edit: "Editar campo extra: {name}",
    extraFields: "Campos adicionales",
    fieldType: "Tipo de campo",
    fieldTypeDescription: "Seleccione el tipo de campo para el campo adicional. Esto es para uso del cliente.",
    introDescription: "Los campos adicionales globales son campos de datos personalizados definidos por el comerciante que están disponibles globalmente, generalmente en un proceso de pago. Un ejemplo podría ser un campo adicional personalizado para recopilar los números de teléfono de los clientes en un formulario de pago.",
    introTitle: "Recopile datos personalizados de los clientes con campos adicionales globales.",
    name: "Nombre de campo adicional",
    nameRequired: "Se requiere un nombre",
    saveFailure: "Lo sentimos, tenemos problemas para guardar el campo adicional.",
    saveSuccess: "El campo adicional se ha guardado correctamente.",
    typeRequired: "El tipo de campo es obligatorio",
    updateSuccess: "El campo adicional se ha actualizado correctamente."
  },
  gateways: {
    add: "Añadir pasarela de pago",
    braintree: "Braintree",
    braintreeDocumentation: "Ver la documentación",
    braintreeHelp: "Introduce tu ID de vendedor de Braintree, clave pública, clave privada y clave de tokenización. {documentation} para obtener más información sobre las claves de tokenización.",
    chooseGateway: "Elija una puerta de enlace",
    confirmDelete: "¿Estás seguro de que quieres eliminar esta pasarela de pago? Cualquier tienda que lo utilice dejará de aceptar pagos inmediatamente desde este portal.",
    deleteFailed: "Algo salió mal al intentar eliminar esta pasarela de pago. Inténtalo de nuevo.",
    deleted: "Pasarela de pago eliminada.",
    edit: "Editar pasarela de pago: {gateway}",
    email: "Dirección de correo electrónico",
    gateway: "Portal",
    introDescription: "Una pasarela de pago autoriza el procesamiento de pagos directos o tarjetas de crédito para empresas en línea.\n\nLa puerta de enlace de prueba se ha habilitado. Para añadir una pasarela de pago, añade un método de pago en {introLink}.",
    introTitle: "Comience a cobrar pagos habilitando sus pasarelas de pago.",
    keyId: "ID clave",
    keySecret: "Clave secreta",
    manual: "Manual",
    manualConfig: {
      detailsHelp: "Detalles sobre este método de pago - se muestran antes de realizar un pedido.",
      name: "Nombre del método de pago",
      paymentInstructions: "Instrucciones de pago",
      paymentInstructionsHelp: "Detalles sobre cómo pagar - se muestran a los clientes después de realizar un pedido."
    },
    manualHelp: "Tendrás que registrar manualmente los pagos y gestionar los pedidos realizados con este método.",
    manualName: "Manual: {name}",
    merchantId: "ID del comercio",
    omise: "Omise",
    omiseDocumentation: "Omise documentation",
    omiseHelp: "Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.",
    paymentMethodHelper: "To add a live payment gateway, please add a payment method in the billing settings page.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "Clave privada",
    publicKey: "Clave pública",
    publishableKey: "Clave publicable",
    razorpay: "Razorpay",
    redirectingToSquare: "Redireccionando a Square...",
    sandboxNotAvailable: "El modo sandbox no está disponible para esta puerta de enlace.",
    saveFailed: "No se ha podido guardar la pasarela de pago. Comprueba que has rellenado todos los campos obligatorios.",
    saved: "La pasarela de pago se ha guardado correctamente.",
    secretKey: "Clave secreta",
    square: "Square",
    squareHelp: "Cuando haga clic en «Guardar cambios» a continuación, se le redirigirá a Square para iniciar sesión en su cuenta de pago.",
    stripe: "Stripe",
    stripeDocumentation: "Documentación de Stripe.",
    stripeHelp: "Introduzca la clave publicable y la clave secreta, ambas disponibles en el panel de Stripe. Más información en la {documentation}",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "Pasarela de prueba",
    tokenizationKey: "Clave tokenizacion",
    transactionVolume: "Volumen de transacciones",
    weSupport: "Pasarelas que admitimos"
  },
  general: {
    about: "Acerca de",
    actions: "Acciones",
    active: "Activo",
    add: "Añadir",
    adding: "Añadiendo…",
    address: {
      city: "Ciudad",
      country: "País",
      postalZip: "Código Postal",
      provinceState: "Provincia/estado",
      street: "Calle"
    },
    advanced: "Avanzado",
    all: "Todo",
    amount: "Cantidad",
    amountValue: "Importe: {value}",
    andMore: " & {n} más",
    areYouSure: "¿Estás seguro?",
    assets: "Activos",
    auto: "Automático",
    backTo: "Volver a {page}",
    backToPreviousPage: "Volver a la página anterior",
    byAuthor: "por {author}",
    calculation: "Cálculo",
    cancel: "Cancelar",
    cancelled: "Cancelado",
    checkbox: "Casilla de verificación",
    checked: "Marcado",
    clearSearch: "Borra la búsqueda",
    clickToCopy: "Haga clic para copiar",
    close: "Cerrar",
    contactUs: "Contáctenos",
    copied: "¡Copiado!",
    copy: "Copiar",
    country: "País",
    creating: "Creando…",
    currency: "Moneda",
    date: "Fecha",
    dateTime: "Fecha/Hora",
    "default": "Predeterminado",
    "delete": "Eliminar",
    description: "Descripción",
    descriptionOptional: "Descripción (opcional)",
    details: "Detalles",
    digital: "Digital",
    disable: "Deshabilitar",
    disabled: "Desactivado",
    discount: "Descuento",
    draft: "Borrador",
    duplicate: "Duplicar",
    edit: "Editar",
    emailAddress: "Dirección de correo electrónico",
    empty: "Vacío",
    enable: "Habilitar",
    enabled: "Activado",
    environment: "medio ambiente",
    error: "Lo sentimos, se ha producido un error. Inténtalo de nuevo.",
    expired: "Expirado",
    expires: "Expira",
    "false": "Falso",
    firstName: "Nombre",
    "for": "Para",
    fulfill: "Cumplir",
    fulfilled: "Cumplido",
    generate: "Generar",
    getStarted: "Comenzar",
    goBack: "Volver",
    health: "Salud",
    hidden: "Oculto",
    home: "Inicio",
    hostedExperiences: "Hosted experiences",
    id: "ID",
    images: "Imágenes",
    inactive: "Inactivo",
    item: "Artículo",
    label: "Etiqueta",
    lastName: "Apellido",
    learnMore: "Más información",
    learnMoreButton: "Más información",
    live: "En directo",
    loading: "Cargando…",
    loggedInAs: "Has iniciado sesión como {name}",
    logo: "Logotipo",
    longText: "Texto largo",
    merchant: "Comerciante",
    month: {
      april: "Abril",
      august: "Agosto",
      december: "Diciembre",
      february: "Febrero",
      january: "Enero",
      july: "Julio",
      june: "Junio",
      march: "Marzo",
      may: "Mayo",
      november: "Noviembre",
      october: "Octubre",
      september: "Septiembre"
    },
    name: "Nombre",
    never: "Nunca",
    "new": "Nuevo",
    next: "Siguiente",
    nextName: "Siguiente: {name}",
    no: "No",
    noResults: "Ningún resultado coincide con su búsqueda",
    notAvailable: "N/D",
    notFulfilled: "No cumplido",
    notPaid: "No pagado",
    notRequired: "No necesario",
    notes: "Notas",
    number: "Número",
    open: "Abierto",
    option: "Opción",
    options: "Opciones",
    override: "Reemplazar",
    paid: "Pagado",
    parent: "padre",
    partiallyFulfilled: "Cumplido parcialmente",
    paymentGateway: "Plataforma de pago",
    permalinkSlug: "Enlace permanente/slug",
    phoneNumber: "Número de teléfono",
    placed: "Encargado",
    previous: "Anterior",
    previousName: "Anterior: {name}",
    price: "Precio",
    "private": "Privado",
    province: "Provincia",
    published: "Publicado",
    quantity: "Cantidad",
    radio: "Radio",
    rate: "Tarifa",
    rates: "Tarifas",
    reference: "Referencia",
    refund: "Reembolso",
    refunded: "Reembolsado",
    refunding: "Reembolso...",
    regenerate: "Regenerar",
    region: "Región",
    regions: "Regiones",
    remaining: "Restante",
    required: "Requerido",
    sales: "Ventas",
    sandbox: "Sandbox",
    sandboxMode: "Modo sandbox",
    save: "Guardar",
    saveAndClose: "Guardar y cerrar",
    saveChanges: "Guardar cambios",
    saved: "Cambios guardados",
    saving: "Guardando...",
    search: "Buscar",
    security: "Seguridad",
    selectOption: "Seleccione una opción...",
    sending: "Enviando…",
    shareEmbed: "Compartir/insertar",
    shipping: "Envío",
    shippingMethod: "Shipping ({method})",
    shortText: "Texto corto",
    slug: "Slug",
    standard: "Estándar",
    startDate: "Fecha de inicio",
    state: "Estado/Provincia",
    status: "Estado",
    subtotal: "Subtotal",
    tax: "Impuesto",
    template: "Plantilla",
    text: "Texto",
    time: "Hora",
    timestamp: "Marca de tiempo",
    today: "Hoy",
    total: "Total",
    totalPaid: "Total pagado",
    "true": "Verdadero",
    type: "Tipo",
    unchecked: "Desmarcado",
    unfulfilled: "Incumplido",
    unlimited: "Unlimited",
    update: "Actualizar",
    upgradePlan: "Actualiza tu plan",
    url: "Dirección URL",
    value: "Valor",
    view: "Ver",
    viewDetails: "Ver detalles",
    visibility: "Visibilidad",
    visible: "Visible",
    whatIsThis: "¿Qué es esto?",
    xOfY: "{x} de {y}",
    yes: "Si",
    yesterday: "Ayer"
  },
  home: {
    addProducts: "Añade tus productos",
    configureShipping: "Configura tu envío",
    configureTax: "Configura tu impuesto",
    createCommerceRay: "Crea tu primer Commerce Ray",
    enableGateway: "Activar una pasarela de pago",
    gettingStarted: "Cómo empezar",
    gettingStartedRays: "Crea tu Commerce Ray",
    latestAnnouncements: "Anuncios más recientes",
    latestChanges: "Cambios más recientes",
    messageUs: "Envíanos un mensaje",
    speakToAnExpert: "Habla con un experto",
    speakToCopy: "Nuestros expertos en E-Commerce pueden asegurarse de que Commerce.js sea el adecuado para su negocio y ayudar a diseñar una solución.",
    title: "Bienvenido",
    viewChangelog: "Ver registro de cambios",
    welcomeSubtitle: "Bienvenido! Estamos aquí para ayudarte a poner las cosas en marcha."
  },
  integration: {
    about: "Acerca de: {integration}",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration added",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Configure integration",
    confirmDelete: "Are you sure you want to delete this integration?",
    createError: "Sorry, we're having problems activating this integration.",
    creating: "Creating integration...",
    deleteFailed: "Failed to delete your integration! Try again later.",
    deleted: "Integration deleted.",
    edit: "Edit integration",
    editNamed: "Edit integration - {name}",
    filter: {
      checkout: "Salida",
      content: "Contenido",
      dataMesh: "Data mesh",
      email: "Correo electrónico",
      frontend: "Frontend",
      fulfillment: "Envio",
      orders: "Pedidos",
      payments: "Pagos",
      personalization: "Personalización",
      tax: "Impuesto"
    },
    filterLabel: "Filtro",
    filterTagLabel: "Filtro: {tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "Integración",
    integrations: "Integraciones",
    introSplash: {
      description: "Connect with other services and compose your desired business stack.",
      title: "Configure integrations with 3rd party providers."
    },
    lastRun: "Last run",
    noConfigNeeded: "This template requires no additional configuration",
    noLogo: "No logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "Saludable",
      notApplicable: "N/D",
      unhealthy: "Insalubre",
      unstable: "Unstable"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API request logs with details will be listed here for ease of debugging.",
      title: "Your request logs will be shown here"
    },
    logs: "Logs"
  },
  merchant: {
    createFull: "Vamos a configurar tu comercio",
    createLeadIn: "Su información se puede cambiar o actualizar en cualquier momento.",
    createMerchant: "Crear comercio",
    seeAll: "Ver todos los comerciantes",
    selectMerchant: "Selecciona un comerciante"
  },
  notes: {
    add: "Agregar nota",
    addANote: "Agregar una nota",
    confirmDelete: "¿Está seguro de que desea eliminar esta nota?",
    note: "Nota"
  },
  order: {
    capturedOn: "Capturado:",
    cardEndingIn: "Tarjeta que termina en {number}",
    changeCustomer: "Cambiar la información del cliente",
    confirmRefund: "¿Estás seguro de que quieres reembolsar este pedido?",
    customerDetails: "Detalles del cliente",
    customerUpdateFailed: "Lo sentimos, tenemos problemas al actualizar la información del cliente.",
    customerUpdated: "Información actualizada del cliente en el número de pedido {reference}",
    date: "Fecha de pedido",
    dateTime: "Fecha/Hora",
    deleteTest: "Delete test orders",
    deleteTestConfirmation: "Are you sure you want to delete all of your sandbox orders?",
    edit: "Editar pedido",
    "export": "Exportar pedidos",
    exportConfirmation: "Enviaremos una copia de tus pedidos (con los filtros actuales) en formato CSV a tu correo electrónico comercial cuando esté listo.",
    fulfillItems: "Gestionar artículos",
    fulfillment: {
      confirmCopy: "Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?",
      confirmHeader: "Please confirm your fulfillment changes",
      dateShipped: "Fecha de envío: {date}",
      reference: "Referencia: {reference}",
      shipmentNumber: "Envío #{number}",
      shippingCarrier: "Empresa de envío",
      trackingNotProvided: "Seguimiento no proporcionado",
      trackingNumber: "Número de seguimiento #{number}",
      trackingNumberOptional: "Número de seguimiento (opcional)"
    },
    fulfillmentStatus: {
      fulfilled: "Cumplido",
      label: "Estado de entrega",
      not_fulfilled: "No cumplido",
      partially_fulfilled: "Cumplido parcialmente",
      returned: "Devuelto"
    },
    gatewayTransactionId: "ID de transacción de pasarela de pago: {id}",
    itemFulfilled: "Artículos gestionados correctamente.",
    newNotification: "Nuevo pedido {reference} para {value}.",
    nextOrder: "Próximo pedido",
    noOrders: {
      copy: "A continuación, le mostraremos los pedidos y las ventas que se muestran a lo largo del tiempo.",
      title: "¡Haz tu primera venta!"
    },
    orderNotes: "Notas de pedido",
    orderNumber: "Pedido #",
    orderStatus: "Estado del pedido",
    orders: "Pedidos",
    ordersAllTime: "Pedidos - Todos",
    ordersOnMonth: "Pedidos - {month}",
    ordersOnMonthHelptip: "Cantidad de pedidos desde principios de este mes, en comparación con el mismo periodo del mes anterior",
    overview: "Resumen",
    paymentMethod: "Método de pago",
    paymentSaveFailed: "Lo sentimos, tenemos problemas para guardar este pago.",
    paymentSaved: "El pago se guardó correctamente.",
    paymentStatus: {
      label: "Estado del pago",
      not_paid: "No pagado",
      paid: "Pagado",
      partially_paid: "Pagado parcialmente",
      pending: "Pago pendiente",
      refunded: "Reembolsado"
    },
    previousOrder: "Pedido anterior",
    receiptSendFailed: "No se ha podido volver a enviar el recibo, inténtelo de nuevo.",
    receiptSent: "Recibo enviado.",
    recordManualPayment: "Registro pago manual",
    reference: "Referencia: {reference}",
    refundFailed: "No se ha podido reembolsar el pedido, vuelve a intentarlo.",
    refundSuccess: "El pedido se ha reembolsado correctamente.",
    resendReceipt: "Reenviar recibo",
    salesAllTime: "Ventas - Todas",
    salesOnMonth: "Ventas - {month}",
    salesOnMonthHelptip: "Rendimiento de ventas desde principios de este mes, en comparación con el mismo periodo del mes anterior",
    sandbox: "Pedido de prueba",
    shipments: "Envíos",
    status: "Estado",
    testOrdersDeleted: "Test orders successfully deleted.",
    transactionReferenceId: "ID de referencia o transacción",
    updateAddressError: "Algo salió mal al guardar la dirección. Inténtalo de nuevo.",
    updatedBilling: "Dirección de facturación actualizada.",
    updatedShipping: "Dirección de envío actualizada.",
    viewMultiple: "Ver pedidos",
    viewOne: "Ver pedido"
  },
  product: {
    add: "Añadir Producto",
    added: "Producto añadido",
    additionalActions: "Additional product actions",
    basePrice: "Precio base",
    chooseRelatedProducts: "Elige productos relacionados",
    confirmDelete: "¿Estás seguro de que quieres eliminar este producto?",
    confirmDeleteTitle: "¿Estás seguro?",
    copyPaste: "Copia y pega este código en tu sitio.",
    count: "Sin productos | 1 producto | {n} productos",
    customPermalink: "Enlace permanente personalizado",
    "delete": "Eliminar producto",
    deleteFailed: "No se ha podido eliminar el producto. Inténtalo más tarde.",
    deleted: "Producto eliminado",
    description: "Descripción",
    digitalFulfillment: {
      doesNotExpire: "Los enlaces no caducan",
      downloadLimit: "Límite de descarga",
      duration: "Duración",
      durationPeriod: "Periodo de duración",
      expiryMessage: "Después de {duration} {period} de la compra",
      linkExpiration: "Validez del link",
      period: {
        days: "días | día | días",
        months: "meses | mes | meses",
        weeks: "semanas | semana | semanas"
      },
      title: "Entrega digital"
    },
    duplicateProduct: "Duplicar producto",
    duplicated: "El producto se ha duplicado correctamente y ahora está editando el duplicado.",
    edit: "Editar producto",
    embed: "embeber",
    embedCheckout: "Incorporación de pago",
    embedCheckoutDescription: "Inserta tu modulo de pago directamente en tu sitio web o blog.",
    embedShareSell: "¡Inserta, comparte, vende!",
    images: {
      alert: "La galería de imágenes contiene todas las imágenes asociadas a este producto, incluidas las variantes adjuntas.",
      title: "Galería de imágenes",
      types: "| {types} aceptados | {types} & {lastType} aceptados"
    },
    includesNCountries: "Includes 1 country | Includes {number} countries",
    introSplash: {
      api: "Create product API",
      description: "Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.",
      more: "Más información",
      title: "Add your first physical or digital product"
    },
    inventory: {
      available: "Inventario disponible",
      managed: "Gestionado",
      tooltip: "Cuando se gestiona el inventario, puedes establecer el número de artículos disponibles; de lo contrario, será ilimitado."
    },
    lastSavedAt: "Última guardado",
    linkCustomers: "Conecte a los clientes directamente a la finalización de compra mediante esta URL.",
    minimumPrice: "Importe mínimo",
    misc: "Varios",
    name: "Nombre",
    noImage: "Sin imágen",
    noRelatedAvailable: "No tienes otros productos",
    payWhatYouWant: "Paga lo que quieras",
    price: "Precio",
    product: "Producto",
    products: "Productos",
    pwywEnabled: "«Pagar lo que quieras» habilitado",
    relatedProducts: "Productos similares",
    saveFailed: "No se pudo guardar el producto. Inténtalo de nuevo.",
    saved: "Cambios guardados",
    searchEngineDescription: "Descripción del motor de búsqueda",
    searchEngineTitle: "Título del motor de búsqueda",
    seo: "SEO",
    shippingCard: {
      baseRateLabel: "Tarifa base",
      baseRateTooltip: "El precio base de esta tarifa. Además de esto, se añadirán otras tarifas.",
      nameLabel: "Nombre",
      onOwnLabel: "Por cuenta propia",
      onOwnTooltip: "El precio de esta tarifa cuando este artículo se pide por sí solo. Esto se añadirá a la tarifa base, por ejemplo, Tarifa base + Tarifa propia = Tarifa total de envío cargada",
      title: "Opciones de envío",
      withOthersLabel: "Con otros",
      withOthersTooltip: "El precio de esta tarifa cuando se pide con otros artículos. Se añadirá con la tarifa de envío de otros artículos y la tarifa base, por ejemplo, Tarifa base + Tarifa con otros (Tarifa de este artículo) + Tarifa con otros artículos (Tarifa de otros artículos) = Tarifa de envío total cargada"
    },
    sku: "SKU",
    sortOrder: "Orden de clasificación",
    sortOrderTooltip: "Lower values make your product show up in lists earlier, whereas higher values make it show up later.",
    thankYouPage: "Página de agradecimiento",
    variants: {
      addGroup: "Añadir grupo de variantes",
      addOption: "Añadir una opción",
      addVariant: "Añadir variante",
      amount: "Cantidad",
      andMore: "Y {count} variantes adicionales",
      bulkAdd: "Añadir al por mayor",
      bulkFailure: "Lo sentimos, tenemos problemas para actualizar tus variantes.",
      bulkSuccess: "Las variantes se han actualizado correctamente",
      chooseGroupsAndOptions: "Elige grupos y opciones",
      create: "Crear variante | Crear variantes",
      createBulk: "Creación de variantes al por mayor",
      createSingle: "Crear variante única",
      defaultPrice: "Precio por defecto",
      defaultPriceTooltip: "El precio predeterminado de la opción variante.",
      defaultVariantTitle: "Nueva variante",
      edit: "Modificar variantes",
      editSingle: "Editar variante",
      editSingleNamed: "Editar variante: {name}",
      generateVariants: "Generar variantes",
      groups: "Grupos | Grupo | Grupos",
      hasInvalidVariants: "Este producto tiene variantes no válidas. Estas variantes no se pueden añadir a un carrito ni a la compra y no se pueden vender. Para volver a habilitar las variantes no válidas, edítalas y resuelve cualquier problema.",
      help: "Diferentes tipos de este producto (por ejemplo, talla, color)",
      image: "Imagen",
      invalidReason: {
        duplicate_option_config: "Esta variante no válida comparte opciones con otra variante. Solo puede existir una variante para un conjunto específico de opciones.",
        incomplete_option_config: "A esta variante no válida le falta una opción de uno de los grupos de variantes configurados para este producto. Las variantes deben tener un conjunto de opciones para cada grupo."
      },
      invalidVariantsOnSave: "Una vez que hayas guardado, tus variantes se marcarán como «no válidas» y no podrás vender variantes no válidas hasta que soluciones los problemas.",
      mustSave: "Debe guardar antes de añadir nuevas variantes",
      noGroups: "Las variantes te ayudan a vender productos con ligeras diferencias, pero siguen siendo el mismo producto. Por ejemplo, puedes vender una camiseta de diferentes colores o una maceta de diferentes tamaños. Puedes configurar variantes para que tengan su propio precio, SKU e inventario de existencias.\n\nPara empezar, crea grupos y opciones para tus variantes. Los grupos definen el tipo de variante (por ejemplo, color). Las opciones son una elección que su cliente puede tomar dentro de ese grupo (por ejemplo, azul).",
      noGroupsTitle: "Configurar grupos de variantes para vender variaciones del mismo producto",
      noOptions: "Por favor introduce al menos una opción",
      noVariants: "Utilice los botones anteriores para crear variantes. Las variantes son combinaciones de los grupos de variantes configurados y las opciones de variante. Podrás establecer individualmente el precio y el inventario de cada variante (si está habilitada).",
      noVariantsTitle: "No se han creado variantes",
      noVariantsToGenerate: "Ya existen todas las variantes para las opciones seleccionadas.",
      optionDefaultImage: "Imagen predeterminada",
      options: "Opciones | Opción | Opciones",
      optionsInGroupsRequired: "Debe seleccionar al menos una opción en cada grupo para generar variantes en bloque.",
      optionsUpdateFailure: "Lo sentimos, tenemos problemas para actualizar las opciones de variante.",
      optionsUpdateSuccess: "Las opciones de variante se han actualizado correctamente",
      override: "Reemplazar",
      preview: "Vista previa variante",
      previewHelp: "Vista previa de las variantes que se crearán según las opciones seleccionadas anteriormente.",
      quantity: "Cantidad",
      requiresGroupsSave: "El uso de esta función requiere guardar primero los grupos.",
      requiresSave: "El uso de esta función requiere que guardes el producto en primer lugar.",
      saveAndGenerate: "Guardar y generar variantes",
      selectOptions: "Selecciona las opciones para tu variante",
      singleCreateSuccess: "La variante se ha creado correctamente",
      singleUpdateFailure: "Lo sentimos, hemos tenido problemas al guardar la variante.",
      singleUpdateSuccess: "La variante se ha actualizado correctamente",
      title: "Variantes",
      variantDetails: "Detalles de variante",
      variantExists: "Parece que esta variante ya existe.",
      variantGroup: "Grupo de variantes",
      variantGroupName: "Nombre del grupo de variantes",
      variantGroupTitle: "Grupo de variantes: {group}",
      variantName: "Nombre de variante",
      willInvalidateVariants: "La adición o eliminación de grupos de variantes invalidará las variantes existentes, ya que cada variante debe tener una opción para cada grupo y no se puede duplicar."
    },
    viewProducts: "Ver productos"
  },
  rays: {
    add: "Crear nuevo Ray",
    commerceRays: "Commerce Rays",
    confirmDelete: "¿Estás seguro de que quieres eliminar este Ray?",
    confirmDeleteTitle: "¿Estás seguro?",
    create: "Crear Ray",
    "delete": "Eliminar Ray",
    deleteFailed: "No se pudo eliminar el Ray! Inténtalo más tarde.",
    deleted: "Ray eliminado.",
    deploying: "Publicando...",
    desktop: "Escritorio",
    edit: "Editar Ray",
    embed: "Insertar",
    embedModal: "También puedes insertar tu modulo de pago en una ventana emergente, activada haciendo clic en el botón «Comprar ahora».",
    exit: "Salir",
    images: {
      title: "Imágenes",
      types: "| {types} aceptados | {types} & {lastType} aceptados"
    },
    introduction: {
      description: "Un creador de páginas sin código con pasarela de pagos integradas.",
      "for": {
        campaigns: "Campañas",
        digitalProducts: "Productos digitales",
        influencers: "Asociaciones con influencers",
        mediaCheckouts: "Pago de medios",
        ppc: "Campañas PPC",
        productDrops: "Lanzamiento de producto",
        title: "Rays es para",
        videoCommerce: "Video Commerce"
      },
      line1: "Cualquiera puede crear hermosas páginas de productos y contenido que se conviertan.",
      line2: "Elige entre nuestra selección de temas hermosos y editables y vende la historia de tu marca.",
      title: "✨ Bienvenido a Commerce Rays"
    },
    lastDeployment: "Última publicación:",
    mobile: "Móvil",
    nameHelper: "Para identificación interna.",
    noProducts: "Debe tener al menos un producto. Para crear un Ray, empieza añadiendo un producto.",
    noRays: "No se han encontrado Rays.",
    noThemes: "No se han encontrado temas",
    pickTheme: "Elige una plantilla",
    planWarning: {
      limitReached: "Has alcanzado el límite de tu plan. Por favor {upgrade} para publicar más.",
      notice: "Tu plan actual no admite el uso de Rays. Por favor {upgrade} para publicar.",
      upgrade: "Actualiza tu plan"
    },
    ray: "Ray",
    rayName: "Nombre de Ray",
    rays: "Rays",
    sandboxHelper: "Habilitar el modo sandbox te permitirá usar Stripe en modo de prueba. No se pueden crear pedidos en directo en modo sandbox.",
    save: "Guardar",
    saveDeploy: "Guardar y publicar",
    saveFailed: "¡No se ha podido guardar tu Ray! Inténtalo de nuevo.",
    saved: "Se han guardado los cambios.",
    savedAndDeploying: "Cambios guardados y publicando tu Ray...",
    saving: "Guardando...",
    select: "Seleccionar tema",
    selectProducts: "Seleccionar producto (s)",
    validationFailed: "Hay un problema con la configuración elegida. Comprueba si hay errores en la barra lateral.",
    viewPublishedRay: "Ver Ray publicado",
    viewRay: "Ver Ray",
    viewportWarning: "Esta parte de nuestro panel de control se experimenta mejor en una pantalla más grande. Inicia sesión en tu ordenador para ver Rays."
  },
  settings: {
    analytics: "Analítica",
    data: "Data",
    developer: "Desarrollador",
    developerSettings: {
      addKey: "Añadir clave API",
      apiKey: "Clave API",
      apiKeys: "Claves API",
      apiVersion: "Versión API",
      changeKey: "Cambiar clave",
      changelog: "Ver registros de cambios",
      confirmDeleteKey: "¿Estás seguro de que quieres eliminar esta clave de API? Las tiendas que utilicen esta clave dejarán de funcionar inmediatamente.",
      createKey: "Crear clave API",
      createSuccess: "La clave API se ha creado correctamente",
      deleteFailed: "No se ha podido eliminar la clave API. Inténtalo más tarde.",
      deleted: "Clave API eliminada.",
      editKey: "Editar clave API",
      generateKey: "¿Seguro que quieres regenerar la clave?",
      keyChangFailed: "No se ha podido actualizar la clave de API. Inténtalo más tarde.",
      keyChanged: "La clave se ha actualizado correctamente",
      label: "Etiqueta clave API",
      live: "En directo",
      "public": "Público",
      resetKeyButtonTooltip: "Generar una nueva clave",
      resetKeyConfirmationHeader: "¿Generar una nueva clave?",
      sandbox: "Sandbox",
      secret: "Secreto",
      type: "Tipo",
      updateFailure: "No se ha podido actualizar la clave de API. Inténtalo de nuevo.",
      updateSuccess: "La clave API se ha actualizado correctamente"
    },
    merchant: {
      analyticsCardTitle: "Google Analytics",
      analyticsGaTrackingId: "ID de seguimiento",
      analyticsGaTrackingIdTooltip: "Tu identificador de seguimiento de Google Analytics",
      analyticsLinkedDomain: "Añadir dominio vinculado",
      analyticsLinkedDomainTooltip: "Puede agregar varios dominios separados por una coma.",
      coverImage: "Imagen de portada",
      coverImageTooltip: "Esta es la imagen de portada que aparecerá en tu escaparate.",
      descriptionTooltip: "Breve descripción sobre usted y su empresa",
      emailTooltip: "Este correo electrónico es visible para sus clientes. Los correos electrónicos de atención al cliente irán aquí.",
      enableHostedCheckouts: "Enable hosted checkout",
      enableHostedCheckoutsTooltip: "The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Enable hosted storefront",
      isPublic: "Hacer visible para la API pública",
      merchantAddress: "Dirección",
      merchantId: "ID de comerciante",
      merchantIdInfo: "Este es tu ID de vendedor único. Es posible que lo solicitemos cuando te pongas en contacto con nosotros.",
      name: "Nombre de la empresa",
      nameTooltip: "¿Con qué nombre vendes?",
      save: "Guardar configuración",
      saveFailed: "No se pudo guardar la configuración del comerciante. Inténtalo de nuevo.",
      saved: "Se han guardado los cambios.",
      timezoneTooltip: "Todas las fechas y horas de Chec serán relativas a esta zona horaria.",
      title: "Detalles del comerciante"
    },
    notifications: {
      customerLoginToken: "Cliente: token de inicio de sesión",
      customerOrderReceipt: "Cliente: recibo de pedido",
      customerOrderShipped: "Cliente: pedido enviado",
      dashboard: "Notificaciones panel de control",
      dashboardSummary: "Controle el comportamiento de las notificaciones en el panel de control Chec.",
      emailNotificationSummary: "Habilitar/deshabilitar las notificaciones por correo electrónico predeterminadas que se envían desde Chec.",
      emailNotifications: "Notificaciones de correo",
      emails: "Correos electrónicos",
      merchantOrderReceipt: "Vendedor: nuevo pedido recibido",
      navMenuHighlight: "Resaltar en el menú de navegación",
      news: "Novedades y anuncios de funcionalidades",
      none: "Ninguno",
      notificationPopup: "Ventana emergente de notificaciones",
      title: "Notificaciones"
    },
    paymentGateways: "Plataforma de pago",
    settings: "Ajustes",
    shipping: "Envío",
    tax: {
      addState: "Add state",
      addTax: "Habilitar la gestión fiscal",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Add tax zone",
      addedToRate: "Added to country rate",
      address: "Dirección",
      addressAlert: "Se utiliza para calcular impuestos y se muestra en las facturas.",
      calculateAutomatically: "Calculate taxes automatically",
      configureTaxZone: "Configure tax zone",
      confirmDeleteZone: "Are you sure you want to delete this tax zone?",
      confirmDeleteZoneTitle: "¿Estás seguro?",
      countrySelectInfo: "The tax rate configured here will be applied to all orders in this tax zone.",
      countryTax: "Country tax",
      deleted: "Tax configuration deleted",
      digitalRate: "Digital rate",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Edit tax zone",
      enableTax: "Activar impuestos",
      enableVat: "Habilitar el IVA de productos digitales de UE",
      enableVatInfo: "Recaudar el impuesto sobre el IVA de los bienes digitales de la UE (IVA MOSS) sobre los bienes digitales. Los tipos impositivos se calculan y aplican automáticamente a los clientes de la UE.",
      euVatMoss: "European Union (VAT MOSS)",
      introCopy: "Commerce.js le permite definir fácilmente las tasas impositivas de su región para ayudarle a recaudar fácilmente los impuestos correspondientes durante el proceso de pago. También puede optar por integrar cualquier proveedor fiscal e implementar reglas comerciales personalizadas con sus propias funciones sin servidor.",
      introTitle: "¡Deje que Commerce.js administre sus impuestos!",
      nexusHelp: "Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.",
      nexusStates: "Nexus states",
      overrideRate: "Instead of country rate",
      pricesIncludeTax: "¿Sus precios incluyen impuestos?",
      ratesAutoUpdated: "Rates are automatically updated & set.",
      saveFailed: "No se pudo guardar la configuración de impuestos. Inténtalo de nuevo.",
      standardRate: "Tarifa estándar",
      taxRateZones: "Zonas impositivas",
      taxRates: "Tax rates",
      title: "Impuesto",
      zipAlreadyExists: "The provided ZIP code has already been added",
      zipInvalid: "The provided ZIP code does not appear to be a valid US postal ZIP code"
    },
    updateFailed: "No se han podido guardar las preferencias de notificación. Inténtalo de nuevo.",
    updatedSuccessfully: "Las preferencias de notificación se han actualizado correctamente.",
    viewStorefront: "Ver escaparate"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "Añadir una zona",
    confirmDeleteZone: "¿Estás seguro de que quieres eliminar esta zona?",
    countriesRegions: "Países y regiones",
    countriesRegionsHelp: "Ten en cuenta que los cambios en este panel no se guardarán hasta que se guarde la zona de envío.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "Editar una zona: {name}",
    enableOnProductsInfo: "If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.",
    enableOnProductsTitle: "Enable on all products",
    introSplash: {
      description: "Se añade una zona de envío de forma predeterminada como ejemplo. Una vez que hayas añadido zonas de envío adicionales, guarda y habilita que las zonas estén disponibles para tus productos. Las zonas de envío también se pueden seleccionar en {productPage}.",
      title: "Añade zonas de envío para tus productos"
    },
    noCountriesRegions: "No se han añadido países ni regiones a tu zona. Empieza a añadir países o regiones a tu zona antes de guardar.",
    noCountriesRegionsTitle: "No se han añadido países ni regiones",
    numCountries: "Número de países",
    numRegions: "Número de regiones",
    productPage: "página de edición de productos",
    rateNameRequired: "The rate name is required",
    ratePriceRequired: "The rate price is required. For free rates use 0.",
    ratesLeadIn: "El aumento de estas tarifas también se puede añadir en {productPage}.",
    saveFailure: "Lo sentimos, hemos tenido problemas al guardar tu zona de envío.",
    saveSuccess: "Tu zona de envío se ha guardado correctamente.",
    selectRegion: "Configure regions",
    shippingZones: "Zonas de envío",
    zone: "Zona",
    zoneDeleteFailed: "Algo salió mal al intentar eliminar esta zona. Inténtalo de nuevo.",
    zoneDeleted: "Zona eliminada.",
    zoneName: "Nombre de la zona",
    zoneNameDescription: "El nombre de la zona es solo para uso interno y los clientes no lo verán.",
    zoneNameRequired: "El nombre de la zona es obligatorio"
  },
  signup: {
    error: "Algo salió mal al crear tu cuenta. Comprueba tus datos e inténtalo de nuevo.",
    form: {
      experience: "Podemos ofrecerle la mejor experiencia posible.",
      merchantDetails: "Detalles del comerciante",
      merchantEmail: "Correo electrónico del comerciante",
      merchantEmailDescription: "Este correo electrónico es visible para sus clientes. También recibirás notificaciones de pedidos aquí.",
      merchantName: "Nombre del comerciante",
      merchantNameDescription: "¿Con qué nombre vendes?",
      next: "Siguiente",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "Guardando..."
    },
    intro: {
      text: "Cree su cuenta en unos pocos pasos y comience a utilizar Commerce.js.",
      title: "Bienvenido, vamos a configurar tu cuenta"
    },
    page: {
      title: "Configuración de cuenta"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Algo más",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Márketing",
      other: "Otro",
      owner: "Dueño del negocio",
      solution_architect: "Solution Architect",
      student: "Estudiante"
    }
  },
  slack: {
    alreadyJoined: "¿Ya te has unido?",
    description: "Nuestra comunidad de desarrolladores y diseñadores de comercio electrónico.",
    error: {
      alreadyInTeam: "¡Esta dirección de correo electrónico ya está en nuestra comunidad!",
      alreadyInvited: "Esta dirección de correo electrónico tiene una invitación pendiente. ¡Revisa tu correo electrónico!",
      invalidEmail: "Debe proporcionar una dirección de correo electrónico válida",
      missingEmail: "Debe proporcionar una dirección de correo electrónico."
    },
    getMyInvite: "Recibe mi invitación",
    inviteSent: "¡Invitación enviada! Revisa tu correo electrónico.",
    join: "Únete a la comunidad de Slack",
    joinCommerceJs: "Únete a Commerce.js en Slack",
    joinUs: "Únase a nosotros en Slack",
    openSlack: "Abrir Slack",
    registeredCount: "{count} usuarios están registrados hasta el momento.",
    slack: "Slack"
  },
  spaces: {
    storefront: "Escaparate"
  },
  status: {
    maintenance: "Servicio en mantenimiento",
    major: "INTERRUPCION PARCIAL DEL SISTEMA",
    minor: "Cortes de servicio menores",
    none: "Todos los sistemas operativos"
  },
  validation: {
    enterEmail: "Por favor, introduce una dirección de email",
    enterName: "Por favor, introduzca nombre",
    enterOption: "Introduzca el nombre de una opción",
    enterPrice: "Por favor, introduzca precio",
    enterQuantity: "Por favor, introduzca cantidad",
    enterUrl: "Por favor introduce URL",
    enterValue: "Please enter an attribute value",
    generic: "Por favor introduce un valor para {field}",
    number: "Ingrese un número",
    positiveNumber: "Introduce un número positivo",
    typeRequired: "Please enter a type",
    validBusinessName: "Por favor introduzca un nombre de empresa",
    validCity: "Por favor introduce una ciudad",
    validCountry: "Por favor seleccione país",
    validEmail: "Por favor, introduce una dirección de correo electrónico válida.",
    validNumber: "Por favor ingrese un número valido",
    validPostalZipCode: "Introduce un código postal ",
    validPrice: "Introduce un precio válido",
    validRegion: "Por favor seleccione una provincia/estado",
    validStreet: "Por favor introduzca el nombre y número de calle",
    validSupportEmail: "Por favor ingrese un correo electrónico.",
    validUrl: "Por favor, introduzca una URL válida"
  },
  versions: {
    current: "{version} - actual"
  },
  webhooks: {
    add: "Añadir webhook",
    added: "Webhook añadido.",
    confirmDelete: "¿Estás seguro de que quieres eliminar este webhook?",
    confirmRegenerateKey: "¿Estás seguro de que quieres regenerar la clave de entrada? Esto afectará a todos los webhooks.",
    copiedKey: "¡Copiado!",
    copyKey: "Copiar clave",
    created: "Creado",
    "delete": "Eliminar webhook",
    deleteFailed: "No se pudo eliminar webhook.",
    deleted: "Webhook eliminado.",
    deliveryDetails: "Entregas con demora",
    deliveryHistory: "Historial de entregas",
    edit: "Editar webhook",
    event: "Evento",
    eventName: "Nombre de evento",
    events: "Eventos",
    generateKey: "Generar nueva clave",
    introSplash: {
      description: "Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.",
      more: "Más información",
      title: "Add a webhook to extend the possibilities of your business."
    },
    regenerateKeyFailed: "No se ha podido regenerar la clave de entrada.",
    responseBody: "Response body",
    responseCode: "Código de respuesta HTTP: {code}",
    save: "Guardar webhook",
    saveFailed: "No se pudo guardar el webhook. Inténtalo de nuevo.",
    saved: "Se han guardado los cambios.",
    sendTestRequest: "Enviar solicitud de prueba",
    sendingTestRequest: "Enviando solicitud de prueba...",
    signingKey: "Clave de entrada",
    testRequestCallbackFailed: "Se ha enviado la solicitud de prueba pero la respuesta ha fallado",
    testRequestFailed: "No se ha podido enviar la solicitud de prueba.",
    testRequestSuccess: "La solicitud de prueba se ha enviado correctamente.",
    view: "Ver webhook",
    webhook: "Webhook",
    webhooks: "Webhooks"
  }
};