<template>
  <div class="navigation-submenu">
    <component
      :is="component"
      v-for="{ active, bindings, component, label } in parsedMenu"
      :key="label"
      class="navigation-submenu__link"
      :class="{ 'navigation-submenu__link--active': active }"
      v-bind="bindings"
    >
      {{ $t(label) }}
    </component>
  </div>
</template>

<script>
import isExternalLink from '@/lib/isExternalLink';

export default {
  name: 'SubMenu',
  props: {
    menu: Array,
  },
  computed: {
    parsedMenu() {
      // Get the route for highlighting the current route in the submenu
      const { matched } = this.$route;
      const route = matched[matched.length - 1];

      return this.menu.map(({ label, link }) => {
        const external = typeof link === 'string' && isExternalLink(link);
        const base = {
          label,
          external,
          active: typeof link === 'object' && route && route.name === link.name,
        };

        if (external) {
          return {
            ...base,
            component: 'a',
            bindings: {
              href: link,
              target: '_blank',
              rel: 'noopener',
            },
          };
        }

        return {
          ...base,
          component: 'router-link',
          bindings: {
            to: link,
          },
        };
      });
    },
  },
};
</script>

<style lang="scss">
.navigation-submenu {
  @apply py-4;

  // Would be "ml-11". Adds "w-6", "mr-2" from the icon styling, and "px-3" from the nav item
  // padding
  margin-left: 2.75rem;

  &__link {
    @apply block leading-none;

    // Custom padding is half 1.25 rem (p-5) on each side.
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;

    &:first-of-type {
      @apply pt-0;
    }

    &:last-of-type {
      @apply pb-0;
    }

    &--active {
      @apply font-bold;
    }

  }
}
</style>
