/**
 * Returns a Chec domain, including protocol and maybe port, depending on the service
 * name you provide.
 *
 * Example returns (local):
 *  - `api` -> https://api.chec.local
 *  - `checkout` -> https://checkout.chec.local
 *  - `dashboard` -> http://dashboard-beta.chec.local:4200
 *
 * Example returns (staging)
 *  - `api` -> https://api-stage.chec.io
 *  - `dashboard` -> https://dashboard-stage.chec.io
 *
 * Example returns (production):
 *  - `api` -> https://api.chec.io
 *  - `dashboard` -> https://dashboard.chec.io
 *
 * @param {string} service
 * @returns {string}
 */
export default (service = 'dashboard') => {
  const currentDomain = window.location.hostname;
  if (service === 'dashboard' && process.env.NODE_ENV === 'development') {
    return `https://${currentDomain}:4200`;
  }

  // Special case for Cypress when running on localhost
  if (currentDomain === 'localhost') {
    return `https://${service}.chec.local`;
  }

  if (service === 'dashboard') {
    return `https://${currentDomain}`;
  }

  if (process.env.VUE_APP_STAGING && currentDomain.match(/\.netlify\.app\/?$/)) {
    return `https://${service}-stage.chec.io`;
  }

  const domain = currentDomain.replace(/dashboard(-beta)?/, service);
  return `https://${domain}`;
};
