<template>
  <div v-if="templateConfig" class="integrations-configure">
    <div
      v-for="(row, index) in formSchema"
      :key="index"
      class="integrations-configure__row"
    >
      <DynamicField
        v-if="showField(row)"
        :value="templateConfig[row.key]"
        :schema="row"
        :error="getError(row.key)"
        @input="(value) => setValue(row.key, value)"
        @clear-error="() => clearError(row.key)"
      />

      <!-- HTML -->
      <div
        v-else-if="row.type === 'html'"
        class="integration-description-content"
        v-html="row.content"
      />

      <div
        v-else-if="isButton(row)"
        class="integrations-configure__button-row"
      >
        <DynamicButton
          :schema="row"
          @click="() => $emit('row-event', 'click', row)"
        />
      </div>
      <div
        v-if="Object.hasOwnProperty.call(row, 'schema')"
      >
        <ChecDivider align="left">
          {{ row.label }}
        </ChecDivider>
        <div
          v-if="row.description"
        >
          {{ row.description }}
        </div>
        <IntegrationConfigurationForm
          :form-schema="formSchema[row.key]"
          :errors="getError(row.key)"
          :template-config="templateConfig[row.key]"
          @clear-error="(key) => clearError(`${row.key}.${key}`)"
          @input="(config) => setValue(row.key, config)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChecDivider } from '@chec/ui-library';
import DynamicField from './DynamicField.vue';
import DynamicButton from './DynamicButton.vue';

export default {
  name: 'IntegrationConfigurationForm',
  components: {
    DynamicButton,
    ChecDivider,
    DynamicField,
  },
  model: {
    prop: 'templateConfig',
    event: 'input',
  },
  props: {
    formSchema: {
      type: Array,
    },
    errors: {
      type: Object,
    },
    templateConfig: {
      type: [Array, Object],
    },
  },
  methods: {
    showField(row) {
      return !Object.hasOwnProperty.call(row, 'schema')
        && Object.hasOwnProperty.call(this.templateConfig, row.key)
        && !this.isButton(row)
        && row.type !== 'html';
    },
    isButton(row) {
      return row && ['link', 'button'].includes(row.type);
    },
    getError(key) {
      if (!this.errors) {
        return null;
      }

      const fullKey = `form_schema.${key}`;

      if (Object.hasOwnProperty.call(this.errors, fullKey)) {
        return this.errors[fullKey];
      }

      return null;
    },
    clearError(key) {
      if (!this.errors) {
        return;
      }

      const fullKey = `form_schema.${key}`;

      if (Object.hasOwnProperty.call(this.errors, fullKey)) {
        const { [fullKey]: _, ...newErrors } = this.errors;

        this.$emit('update-errors', newErrors);
      }
    },
    setValue(key, value) {
      this.$emit('input', {
        ...this.templateConfig,
        [key]: value,
      });
    },
  },
};
</script>

<style lang="scss">
.integrations-configure {
  &__row {
    &:not(:first-child) {
      @apply pt-2;
    }
  }

  &__button-row {
    @apply mt-2 flex justify-between items-center;
  }
}
</style>
