import preferences from './preferences';
import authState from '../../../lib/auth/state';

const actions = {
  RESOLVE_USER: 'RESOLVE_USER',
};

const mutations = {
  SET_USER_ID: 'SET_USER_ID',
};

export { actions, mutations };

export default {
  namespaced: true,
  modules: {
    preferences,
  },
  state: {
    userId: null,
  },
  actions: {
    [actions.RESOLVE_USER]({ commit }) {
      commit(mutations.SET_USER_ID, authState.getUserId());
    },
  },
  mutations: {
    [mutations.SET_USER_ID](state, payload) {
      state.userId = payload;
    },
  },
};
