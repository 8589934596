export default [
  {
    path: 'notifications',
    name: 'settings.notifications.home',
    component: () => import(/* webpackChunkName: "settings" */ './views/notifications.vue'),
    meta: {
      title: (i18n) => i18n.t('settings.notifications.title'),
    },
  },
];
