<template>
  <ChecOptionsMenu
    menu-placement="right-end"
    class="current-user"
    :class="{ 'current-user--inverted': invert }"
    menu-class="current-user__menu"
    :color="invert ? 'primary' : 'secondary'"
    :popper-options="popperOptions"
  >
    <template #button-content>
      <div class="current-user__button">
        <ChecNavIcon icon="account" class="current-user__icon" />
        <div class="current-user__name" :title="userInfo">
          {{ userInfo }}
        </div>
        <div class="current-user__chevron">
          <ChecIcon icon="up" size="sm" />
        </div>
      </div>
    </template>
    <ChecOption @option-selected="goToAccount">
      {{ $t('account.account') }}
    </ChecOption>
    <ChecOption class="current-user__logout" @option-selected="logout">
      <span>
        {{ $t('account.logout') }}
      </span>
      <LogoutSvg />
    </ChecOption>
  </ChecOptionsMenu>
</template>

<script>
import { mapGetters } from 'vuex';
import router from 'vue-router';
import {
  ChecIcon,
  ChecNavIcon,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import authState from '@/lib/auth/state';
import LogoutSvg from '@/assets/svgs/logout-icon.svg';

const { isNavigationFailure, NavigationFailureType } = router;

export default {
  name: 'CurrentUser',
  components: {
    ChecIcon,
    ChecNavIcon,
    ChecOption,
    ChecOptionsMenu,
    LogoutSvg,
  },
  props: {
    invert: Boolean,
  },
  data() {
    return {
      popperOptions: {
        modifiers: [{
          name: 'flip',
          options: {
            fallbackPlacements: ['top-end'],
          },
        }],
      },
    };
  },
  computed: {
    ...mapGetters(['userData', 'userInfo']),
  },
  methods: {
    goToAccount() {
      this.$router.push({ name: 'settings.account' }).catch((error) => {
        // Avoid errors for duplicated clicks on the account button
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }

        throw error;
      });
    },
    logout() {
      authState.logout();
    },
  },
};
</script>

<style lang="scss">
.current-user {
  &__button {
    @apply flex items-center my-2 mx-1;

    // Override the annoying set fill colour on the profile icon
    > svg {
      @apply fill-current;

      * {
        @apply fill-current;
      }
    }
  }

  &:not(.current-user--inverted) {
    &:hover {
      @apply bg-gray-200;
    }

    &:active {
      @apply bg-gray-300;
    }
  }

  &__icon {
    @apply w-6 h-6 mr-2 flex-shrink-0;
  }

  &__name {
    @apply text-sm text-gray-600 font-bold flex-grow truncate text-left;
  }

  &__logout .option__label {
    @apply w-full flex items-center justify-between;

    svg {
      @apply w-4 fill-current;
    }
  }

  &__chevron {
    @apply flex-shrink-0 p-2 bg-white rounded text-gray-600;

    @screen sm {
      @apply transform rotate-90;
    }

    &:hover {
      @apply bg-gray-200;
    }

    &:active {
      @apply bg-gray-300;
    }
  }

  // Manipulate widths of the button components to stretch properly
  .button {
    @apply w-full;
  }

  .button__content {
    @apply w-full;
  }

  &__menu {
    @apply text-gray-500;
  }

  &--inverted {
    @apply text-white;

    .current-user__name {
      @apply text-white;
    }
  }
}
</style>
