import { createPaginationModule } from '@/lib/pagination';

export default createPaginationModule(
  '/v1/categories?include=breadcrumbs',
  {
    prepItemForPost: (item) => {
      const savableCategory = item;
      savableCategory.slug = item.permalink;
      savableCategory.assets = item.assets.map(({ id }) => id);
      savableCategory.children = (item.children || []).map(({ id }) => id);

      return savableCategory;
    },
  },
);
