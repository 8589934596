import accountRoutes from '../account/routes';
import billingRoutes from '../billing/routes';
import notificationRoutes from '../notifications/routes';
import webhooksRoutes from '../webhooks/routes';
import extraFieldsRoutes from '../extraFields/routes';

export default [
  {
    path: '/settings',
    alias: '/setup',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    children: [
      // Old settings/developer route -> moved to Developer > API keys
      {
        name: 'settings.developer',
        path: 'developer',
        redirect: { name: 'developer.apiKeysCors' },
      },
      // Old route settings/payment
      {
        name: 'settings.payment',
        path: 'payment',
        redirect: { name: 'settings.paymentGateways' },
      },
      {
        path: 'gateways',
        name: 'settings.paymentGateways',
        component: () => import(/* webpackChunkName: "gateways" */ './views/gateways.vue'),
        meta: {
          title: (i18n) => i18n.t('settings.paymentGateways'),
        },
        children: [
          {
            path: ':id(gway_[^/]+)',
            name: 'settings.paymentGateways.edit',
            component: () => (
              import(/* webpackChunkName: "gateways" */ './views/gateways/add-edit.vue')
            ),
            meta: {
              title: (i18n) => i18n.t('gateways.edit'),
            },
          },
          {
            path: 'new',
            name: 'settings.paymentGateways.add',
            component: () => (
              import(/* webpackChunkName: "gateways" */ './views/gateways/add-edit.vue')
            ),
            meta: {
              title: (i18n) => i18n.t('gateways.add'),
            },
          },
        ],
      },
      {
        path: 'merchant',
        name: 'settings.merchant',
        component: () => import(/* webpackChunkName: "settings" */ './views/merchant.vue'),
        meta: {
          title: (i18n) => i18n.t('general.merchant'),
        },
      },
      {
        path: 'tax',
        name: 'settings.tax',
        component: () => import(/* webpackChunkName: "settings" */ './views/tax.vue'),
        meta: {
          title: (i18n) => i18n.t('settings.tax.title'),
        },
        children: [{
          path: 'add-zone',
          name: 'settings.tax.addZone',
          component: () => import(/* webpackChunkName: "settings" */ './views/tax/add-edit.vue'),
        },
        {
          path: ':id(txzn_[^/]+)',
          name: 'settings.tax.editZone',
          component: () => import(/* webpackChunkName: "settings" */ './views/tax/add-edit.vue'),
          meta: {
            title: (i18n) => i18n.t('settings.tax.editTaxZone'),
          },
        },
        {
          path: 'show-vat-moss',
          name: 'settings.tax.showVatMass',
          component: () => import(/* webpackChunkName: "settings" */ './views/tax/vat-moss.vue'),
          meta: {
            title: (i18n) => i18n.t('settings.tax.euVatMoss'),
          },
        }],
      },
      {
        path: 'shipping',
        name: 'settings.shipping',
        meta: {
          title: (i18n) => i18n.t('shipping.shippingZones'),
        },
        component: () => import(/* webpackChunkName: "shipping" */ './views/shipping.vue'),
        children: [
          {
            path: 'new',
            name: 'settings.shipping.add',
            component: () => (
              import(/* webpackChunkName: "shipping" */ './views/shipping/add-edit.vue')
            ),
            meta: {
              title: (i18n) => i18n.t('shipping.addZone'),
            },
          },
          {
            path: ':id(zone_[^/]+)',
            name: 'settings.shipping.edit',
            component: () => (
              import(/* webpackChunkName: "shipping" */ './views/shipping/add-edit.vue')
            ),
            meta: {
              title: (i18n) => i18n.t('shipping.editZone'),
            },
          },
        ],
      },
      ...accountRoutes,
      ...billingRoutes,
      ...extraFieldsRoutes,
      ...webhooksRoutes,
      ...notificationRoutes,
    ],
  },
];
