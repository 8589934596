<template>
  <div class="error-page">
    <div class="error-page__circle-left" />
    <div class="error-page__circle-right" />
    <div class="error-page__left">
      <div class="error-page__content">
        <h1 class="error-page__heading">
          {{ title }}
        </h1>
        <slot />
      </div>
    </div>
    <div class="error-page__right">
      <div class="error-page__graphic">
        <slot name="graphic" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorContent',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.error-page {
  @apply flex flex-col-reverse items-center justify-center text-center w-full;
  height: calc(100vh - 5rem - 183px); // removing top padding from .default-layout and footer height

  @screen lg {
    @apply flex-row text-left;
    height: calc(100vh - 5rem - 160px); // Footer height changes in small viewports
  }

  &__circle-left,
  &__circle-right {
    @apply fixed bg-no-repeat w-full h-full z-0;
    background-image: url('/img/pulse.svg'); // see "public" folder
    background-position: top -34rem left -25rem;
  }

  &__circle-left {
    @apply top-0 left-0;
  }

  &__circle-right {
    @apply bottom-0 right-0;
    background-position: bottom -34rem right -28rem;
  }

  &__left,
  &__right {
    @apply flex items-center w-full h-full z-10;

    @screen lg {
      @apply w-1/2 h-full;
    }

    @screen xl {
      @apply px-32;
    }
  }

  &__left {
    @apply justify-center items-start px-0 py-8;

    @screen lg {
      @apply justify-end px-8 py-0 items-center;
    }
  }

  &__right {
    @apply justify-center items-end py-8;

    @screen lg {
      @apply justify-start px-8 py-0 items-center;
    }
  }

  &__content {
    @apply max-w-sm text-sm;

    @screen lg {
      @apply text-xl;
    }
  }

  &__heading {
    @apply text-2xl mb-5 font-bold;

    @screen lg {
      @apply text-4xl;
    }
  }
}
</style>
