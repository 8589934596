import { applyPagination } from '@/lib/pagination';
import { contextMatcher } from '@/lib/pagination/helpers';
import { makeApiRequest } from '@/lib/api';
import rootMutations from '@/store/mutations';
import paginationActions from '@/lib/pagination/actions';
import paginationGetters from '@/lib/pagination/getters';
import paginationMutations from '@/lib/pagination/mutations';
import i18n from '@/lang';
import actions from './actions';
import notes from './notes';

// Handle a response from an endpoint that returns a "download", and dispatch an action to update
// the download within the order.
const handleDigitalPackageResponse = (
  dispatch,
  orderId,
  lineItemId,
  { access_rules: accessRules, ...existingPackageDetail },
) => dispatch(
  actions.SET_DIGITAL_PACKAGE,
  {
    orderId,
    lineItemId,
    // Convert the package to the same format as provided by /v1/orders
    packageDetail: {
      ...existingPackageDetail,
      ...accessRules,
    },
  },
);

export const mutations = {
  SET_MONTHLY_STATISTICS: 'SET_MONTHLY_STATISTICS',
  SET_ALL_TIME_STATISTICS: 'SET_ALL_TIME_STATISTICS',
  SET_MONTHLY_COMPARISON: 'SET_MONTHLY_COMPARISON',
};

export const getters = {
  GET_STATISTICS: 'GET_STATISTICS',
};

const getStatisticsIndexForContext = (state, avoidMutation = false) => {
  const existingIndex = state.statisticsByContext.findIndex(contextMatcher(state.context || {}));

  // The state exist already for this context
  if (existingIndex >= 0 || avoidMutation) {
    return existingIndex;
  }

  // Add an entry and return that index
  state.statisticsByContext.push({
    context: state.context,
    byMonth: [],
    allTime: {
      orders: null,
      sales: null,
    },
    monthlyComparison: null,
  });

  return state.statisticsByContext.length - 1;
};

export default applyPagination('/v1/orders?sortBy=created_at&sortDirection=desc', {
  // Reduce the max number of records pulled as orders are slow
  clientSideHandleLimit: 50,
  individualItemEndpoint: '/v1/orders',
  searchProperties: [
    'customer_reference',
    ['customer.firstname', 'customer.lastname'],
    'customer.email',
  ],
})({
  namespaced: true,
  modules: {
    notes,
  },
  state: {
    statisticsByContext: [],
  },
  mutations: {
    [mutations.SET_ALL_TIME_STATISTICS](state, stats) {
      const index = getStatisticsIndexForContext(state);
      state.statisticsByContext[index].allTime = stats;
    },
    [mutations.SET_MONTHLY_STATISTICS](state, byMonth) {
      const index = getStatisticsIndexForContext(state);
      state.statisticsByContext[index].byMonth = state.statisticsByContext[index].byMonth
        // Remove duplicate entries
        .filter(({ year, month }) => !Object.keys(byMonth).includes(`${year}-${month}`))
        // Add new entries
        .concat(Object.values(byMonth))
        // Ensure they're sorted
        .sort((a, b) => (
          a.year === b.year
            ? parseInt(a.month, 10) - parseInt(b.month, 10)
            : parseInt(a.year, 10) - parseInt(b.year, 10)
        ));
    },
    [mutations.SET_MONTHLY_COMPARISON](state, comparison) {
      const index = getStatisticsIndexForContext(state);
      state.statisticsByContext[index].monthlyComparison = comparison;
    },
  },
  actions: {
    /**
     * Set a digital package within an order to a new one
     *
     * @param {Object} context Vuex action context
     * @param {Object} payload The action payload
     * @param {String} payload.orderId The ID for the order to update
     * @param {String} payload.lineItemId The "download" (identified by line item ID) to update
     * @param {Object} payload.packageDetail The new digital package to set
     */
    [actions.SET_DIGITAL_PACKAGE]({ commit, getters: getterInstances }, {
      orderId,
      lineItemId,
      packageDetail,
    }) {
      const order = getterInstances[paginationGetters.GET_ITEM](orderId);
      // Clone downloads
      const downloads = [...order.fulfillment.digital.downloads];
      // Find the existing download
      const updatedDownloadIndex = downloads.findIndex(
        (candidate) => candidate.line_item_id === lineItemId,
      );
      const download = downloads[updatedDownloadIndex];
      downloads[updatedDownloadIndex] = {
        ...download,
        packages: [...download.packages],
      };
      const updatedPackageIndex = downloads[updatedDownloadIndex].packages.findIndex(
        // Handle api.v1#461 - two different ID prefixes for the same object
        (candidate) => candidate.id.split('_')[1] === packageDetail.id.split('_')[1],
      );
      downloads[updatedDownloadIndex].packages[updatedPackageIndex] = {
        ...downloads[updatedDownloadIndex].packages[updatedPackageIndex],
        ...packageDetail,
      };

      commit(paginationMutations.REPLACE_ITEM, {
        context: {},
        item: {
          ...order,
          fulfillment: {
            ...order.fulfillment,
            digital: {
              ...order.fulfillment.digital,
              downloads,
            },
          },
        },
      });
    },
    /**
     * Update the access for a given digital package
     *
     * @param {Object} context Vuex action context
     * @param {Object} payload The action payload
     * @param {String} payload.orderId The ID for the order to update
     * @param {String} payload.lineItemId The "download" (identified by line item ID) to update
     * @param {String} payload.packageId The ID of the digital package
     * @param {Object} payload.access The access rules. Keys: access_expires and remaining_downloads
     */
    [actions.SET_DIGITAL_PACKAGE_ACCESS]({ commit, dispatch }, {
      orderId,
      lineItemId,
      packageId,
      access,
    }) {
      commit(rootMutations.SET_LOADING, true, { root: true });
      return makeApiRequest(
        'POST',
        `/v1/orders/${orderId}/fulfillments/digital/packages/${packageId}/enable`,
        access,
      )
        .then(({ data }) => handleDigitalPackageResponse(dispatch, orderId, lineItemId, data))
        .finally(() => commit(rootMutations.SET_LOADING, false, { root: true }));
    },
    /**
     * Revoke the access for a given digital package
     *
     * @param {Object} context Vuex action context
     * @param {Object} payload The action payload
     * @param {String} payload.orderId The ID for the order to update
     * @param {String} payload.lineItemId The "download" (identified by line item ID) to update
     * @param {String} payload.packageId The ID of the digital package
     */
    [actions.REVOKE_DIGITAL_PACKAGE]({ commit, dispatch }, {
      orderId,
      lineItemId,
      packageId,
    }) {
      commit(rootMutations.SET_LOADING, true, { root: true });
      return makeApiRequest(
        'POST',
        `/v1/orders/${orderId}/fulfillments/digital/packages/${packageId}/revoke`,
      )
        .then(({ data }) => handleDigitalPackageResponse(dispatch, orderId, lineItemId, data))
        .finally(() => commit(rootMutations.SET_LOADING, false, { root: true }));
    },
    [actions.LOAD_MONTHLY_STATISTICS]({ commit }, { startMonth, startYear }) {
      const year = startYear || new Date().getFullYear();
      const unix = Math.floor(new Date(year, startMonth - 1, 1).getTime() / 1000);

      return makeApiRequest('GET', `/v1/reports/orders/by-month?start=${unix}`)
        .then(({ data }) => {
          commit(mutations.SET_MONTHLY_STATISTICS, data);
        });
    },
    [actions.LOAD_ALL_TIME_STATISTICS]({ commit }) {
      return makeApiRequest('GET', '/v1/reports/orders/all-time')
        .then(({ data }) => {
          commit(mutations.SET_ALL_TIME_STATISTICS, data);
        });
    },
    [actions.LOAD_MONTHLY_COMPARISON]({ commit }, date) {
      const unix = Math.floor(date.getTime() / 1000);
      return makeApiRequest('GET', `/v1/reports/orders/compare-month?date=${unix}`)
        .then(({ data }) => {
          commit(mutations.SET_MONTHLY_COMPARISON, data);
        });
    },
    /**
     * Fetch a new order from the API (if not already in state) and prepend it to the list.
     */
    [actions.FETCH_NEW_ORDER]({
      commit,
      state,
      getters: getterInstances,
      dispatch,
    }, id) {
      const { totalCount } = state;

      // Check if any orders have been loaded. If not, don't do anything here. Loading the
      // order list will fetch everything it needs. We only want to run this logic if the
      // order list has already been loaded once to prevent it from having to happen again.
      const contextualState = getterInstances[paginationGetters.GET_CONTEXTUAL_STATE]();
      if (contextualState?.items[0] === undefined) {
        return;
      }

      // Fetch order from state
      const order = getterInstances[paginationGetters.GET_ITEM](id);
      if (order) {
        // Order already exists in state
        return;
      }

      // Fetch order from API, prepending it to the list if it doesn't already exist in state
      dispatch(paginationActions.LOAD_ITEM, {
        id,
        prepend: true,
        showLoading: false,
      });
      commit(paginationMutations.SET_TOTAL_COUNT, { count: totalCount + 1, context: {} });
    },
    /**
     * Adds and sets new order notification
     */
    [actions.NEW_ORDER_NOTIFICATION]({
      state,
      dispatch,
      rootState,
    }, order) {
      // Define 'level' to check what notification level is
      // enabled in the dashboard
      if (!state || !rootState) {
        return;
      }

      // Define level to check if and what notifications are enabled
      const level = rootState.merchant.merchant.notifications.dashboard;
      // Notifications are not enabled
      if (!level || level === 'off') {
        return;
      }
      // Quiet notifications as a ping on the navigation
      if (level === 'quiet') {
        dispatch('notifications/NEW_ORDER', {}, { root: true });
        return;
      }

      // Full notification as a toast message
      dispatch('notifications/ADD_NOTIFICATION',
        {
          type: 'success',
          text: i18n.t('order.newNotification', {
            reference: order.reference,
            value: order.value.formatted_with_symbol,
          }),
        },
        {
          root: true,
        });
    },
    /**
     * Refresh the list of orders stored in state.
     */
    [actions.RELOAD_ORDERS]({ dispatch }) {
      return Promise.all([
        dispatch(paginationActions.SET_PAGE, 1),
        dispatch(paginationActions.RESET),
      ]);
    },
  },
  getters: {
    [getters.GET_STATISTICS]: (state) => {
      const index = getStatisticsIndexForContext(state, true);

      // Give the stats component an empty object to deal with
      if (index < 0) {
        return null;
      }

      return state.statisticsByContext[getStatisticsIndexForContext(state)];
    },
    hasTestOrders: (state) => state.items.some((order) => order.sandbox),
  },
});
