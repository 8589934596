import { applyPagination } from '@/lib/pagination';
import variants from './variants';

export default applyPagination('/v1/products?sortBy=created&sortDirection=DESC', {
  // Drop the maximum number of products before filter/sort operations are sent to the server
  // todo increase to 100 after performance patch
  clientSideHandleLimit: 50,
  individualItemEndpoint: '/v1/products',
  searchProperties: ['permalink', 'name', 'sku'],
  /**
   * Hook into the configuration to transform the product from it's `GET` form to the structure
   * that the update endpoint expects
   */
  prepItemForPost: (item) => {
    const savableProduct = {};

    // Create a "product" key with some base data
    const product = {};
    [
      'active',
      'name',
      'sku',
      'price',
      'inventory',
      'description',
      'permalink',
      'thank_you_url',
    ].forEach((prop) => {
      if (!Object.hasOwnProperty.call(item, prop)) {
        return;
      }
      product[prop] = item[prop];
    });
    savableProduct.product = product;

    savableProduct.product.sort_order = item.sort_order || 0;

    if (item.variantGroups !== undefined) {
      savableProduct.variant_groups = item.variantGroups.map(({ id, name, options }) => ({
        id,
        name,
        options: options.map((option) => ({
          id: option.id === null ? undefined : option.id,
          description: option.name,
          price: option.price !== null ? option.price.raw : null,
          assets: option.assets,
        })),
      }));
    }

    if (Object.keys(item).length <= 2) {
      return savableProduct;
    }

    if (item.quantity !== undefined) {
      // If the quantity field is empty it will be an empty string, and we should
      // treat it as "unlimited" by passing null.
      savableProduct.product.quantity = item.quantity === '' ? null : parseInt(item.quantity, 10);
    }

    savableProduct.seo = item.seo;

    // Replace "collects" with "collect"
    savableProduct.collect = {
      shipping: item.collects.shipping_address,
      billing: item.collects.billing_address,
      fullname: item.collects.fullname,
      extra_fields: item.extra_fields.length > 0,
    };

    // Set the pay_what_you_want conditional
    savableProduct.product.pay_what_you_want = item.conditionals.is_pay_what_you_want;
    // Cast the price if pay what you want is enabled
    if (item.conditionals.is_pay_what_you_want && savableProduct.product.price === '') {
      savableProduct.product.price = '0';
    }

    // Images (aka assets)
    savableProduct.assets = item.assets.map(({ id }, index) => ({
      id,
      sort_order: index + 1,
    }));

    // Set extra fields
    savableProduct.extra_field = item.extra_fields;

    // Create the delivery object
    savableProduct.delivery = {
      digital: {},
      enabled: {},
    };

    if (item.conditionals.has_digital_delivery && item.fulfillment.digital.assets) {
      savableProduct.delivery.enabled.digital = true;

      const {
        assets,
        access_rules: accessRules,
      } = item.fulfillment.digital;

      // Set the assets to just the chosen IDs
      savableProduct.delivery.digital.assets = assets.map((asset) => asset.id);

      if (accessRules) {
        const {
          expires,
          duration,
          limit,
          period,
        } = accessRules;

        // Convert the period from a string to an int that matches the chosen period
        const periodIndex = ['days', 'weeks', 'months']
          .findIndex((candidate) => candidate === period);

        // Add the access_window settings
        savableProduct.delivery.digital.access_window = {
          expires,
          // Cast duration to a string
          duration: typeof duration === 'string' ? parseInt(duration, 10) : duration,
          // Limit should be passed as a string, and should be "unlimited" if unspecified
          limit: parseInt(limit, 10) > 0 ? limit : 'unlimited',
          period: periodIndex >= 0 ? periodIndex : null,
        };
      }
    } else {
      savableProduct.delivery.enabled.digital = false;
    }

    if (item.conditionals.has_physical_delivery) {
      savableProduct.delivery.shipping_zones = item.fulfillment.physical.reduce((acc, zone) => ({
        ...acc,
        [zone.zone_id]: {
          rates: item.fulfillment.physical.filter((rate) => rate.zone_id === zone.zone_id)
            .reduce((racc, rate) => ({
              ...racc,
              [rate.rate.id]: {
                base_rate_id: rate.rate.id,
                on_own: rate.on_own.raw || '0',
                with_others: rate.with_others.raw || '0',
              },
            }), {}),
          ...zone,
        },
      }), {});
    } else {
      savableProduct.delivery.shipping_zones = [];
    }
    // Only enable shipping if there are some zones provided
    savableProduct.delivery.enabled.shipping_native_v1 = item.conditionals.has_physical_delivery
      && Object.keys(savableProduct.delivery.shipping_zones).length > 0;

    savableProduct.categories = item.categories;

    savableProduct.related_products = item.related_products.map(({ id }) => id);

    // Ensure product attributes is an array and return the ID and value only
    savableProduct.attributes = Object.values(item.attributes)
      .map(({ id, value }) => ({ id, value }));

    return savableProduct;
  },
})({
  namespaced: true,
  modules: {
    variants,
  },
});
