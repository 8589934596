<template>
  <div>
    <NotFoundPage v-if="isNotFound" />
    <ServerErrorPage v-if="isServerError" />
    <ClientErrorPage v-if="isClientError" />
    <PermissionErrorPage v-if="isPermissionError" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NotFoundPage from './errors/NotFoundPage.vue';
import ServerErrorPage from './errors/ServerErrorPage.vue';
import PermissionErrorPage from './errors/PermissionErrorPage.vue';
import ClientErrorPage from './errors/ClientErrorPage.vue';

export default {
  name: 'ErrorPage',
  components: {
    ClientErrorPage,
    PermissionErrorPage,
    NotFoundPage,
    ServerErrorPage,
  },
  computed: {
    ...mapState(['errors']),
    errorCode() {
      // Sort by status code as we want to prioritise 500s above 400s.
      return this.errors.map((error) => error.code).sort().pop();
    },
    isNotFound() {
      return this.errorCode === 404;
    },
    isPermissionError() {
      return [401, 403].includes(this.errorCode);
    },
    isServerError() {
      // We want to use this as the catch-all error as well as just 500s
      return this.errorCode && ![401, 403, 404].includes(this.errorCode);
    },
    isClientError() {
      return !this.errorCode;
    },
  },
};
</script>
