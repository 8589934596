<template>
  <button
    class="merchant-selector"
    @click="handleClick"
  >
    <MerchantTag :merchant="merchant" invert />
    <ChecOptionsMenu
      ref="option-menu"
      menu-placement="right-start"
      menu-class="merchant-selector__menu"
      :popper-options="popperOptions"
      color="primary"
      @click="ensureLoadedMerchants"
    >
      <template #button-content>
        <ChecIcon icon="right" size="sm" />
      </template>
      <div
        v-if="totalCount === null || totalCount === undefined"
        class="merchant-selector__loading"
      >
        <ChecLoading />
      </div>
      <template v-else>
        <ChecOption
          v-for="merchant in switchableMerchants"
          :key="merchant.id"
          @option-selected="switchMerchant(merchant.id)"
        >
          <MerchantTag :merchant="merchant" />
        </ChecOption>
        <ChecOption
          v-if="totalCount > pageSize"
          class="merchant-selector__custom-option"
          @option-selected="navigateToList"
        >
          <div class="merchant-selector__option-icon">
            <ChecIcon icon="more" />
          </div>
          {{ $t('merchant.seeAll') }}
        </ChecOption>
        <ChecOption class="merchant-selector__custom-option" @option-selected="newMerchant">
          <div class="merchant-selector__option-icon">
            <ChecIcon icon="plus" />
          </div>
          {{ $t('merchant.createMerchant') }}
        </ChecOption>
        <ChecOption
          v-if="userIsAdmin"
          ref="log-in-as-button"
          class="merchant-selector__custom-option"
          @option-selected="openLogInAsPopover"
        >
          <div class="merchant-selector__option-icon">
            <ChecIcon icon="search" />
          </div>
          Log in as...
        </ChecOption>
      </template>
    </ChecOptionsMenu>
    <ChecPopover
      v-if="userIsAdmin"
      :open="showLogInAs"
      target-ref="option-menu"
      placement="right-start"
      :popper-options="popperOptions"
    >
      <ChecCard
        ref="log-in-as-card"
        tailwind="p-4"
      >
        <ChecHeader variant="card" title="Log in as..." />
        <form
          class="merchant-selector__log-in-as-form space-y-2"
          @submit.prevent="logInAsMerchant"
        >
          <TextField
            ref="log-in-as-input"
            v-model="logInAsId"
            class="merchant-selector__log-in-as-input"
            :label="$t('settings.merchant.merchantId')"
            currency
            currency-symbol="#"
            required
          />
          <ChecButton
            color="green"
            button-type="submit"
          >
            Switch merchant
          </ChecButton>
        </form>
      </ChecCard>
    </ChecPopover>
  </button>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import router from 'vue-router';
import {
  ChecButton,
  ChecCard,
  ChecHeader,
  ChecIcon,
  ChecLoading,
  ChecOption,
  ChecOptionsMenu,
  ChecPopover,
  TextField,
} from '@chec/ui-library';
import MerchantTag from '@/modules/merchant/components/MerchantTag.vue';
import { actions, mapPagination } from '@/lib/pagination';
import switchMerchant from '@/modules/merchant/mixins/switchMerchant';
import addNotification from '@/mixins/addNotification';

const { isNavigationFailure, NavigationFailureType } = router;

export default {
  name: 'MerchantSelector',
  components: {
    ChecButton,
    ChecCard,
    ChecHeader,
    ChecIcon,
    ChecLoading,
    ChecOption,
    ChecOptionsMenu,
    ChecPopover,
    MerchantTag,
    TextField,
  },
  mixins: [switchMerchant, addNotification],
  data() {
    return {
      popperOptions: {
        modifiers: [{
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-end'],
          },
        }, {
          name: 'offset',
          // Offset the menu to match up with the rest of the selector UI
          options: { offset: [-14, 16] },
        }],
      },
      logInAsId: '',
      showLogInAs: false,
    };
  },
  computed: {
    ...mapPagination({ merchants: 'merchant' }),
    ...mapState('merchant', ['merchant', 'totalCount', 'pageSize']),
    ...mapGetters(['userData']),
    switchableMerchants() {
      if (!Array.isArray(this.merchants)) {
        return [];
      }

      return this.merchants.filter((candidate) => candidate.id !== this.merchant?.id);
    },
    userIsAdmin() {
      return this.userData?.admin;
    },
  },
  created() {
    window.addEventListener('click', this.onOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onOutsideClick);
  },
  methods: {
    ...mapActions('merchant', { loadPage: actions.SET_PAGE }),
    ensureLoadedMerchants() {
      if (typeof this.totalCount === 'number') {
        return;
      }

      this.loadPage(1);
    },
    handleClick() {
      this.$router.push({ name: 'settings.merchant' }).catch((error) => {
        // Avoid errors for duplicated clicks on the account button
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }

        throw error;
      });
    },
    async logInAsMerchant() {
      try {
        await this.switchMerchant(parseInt(this.logInAsId, 10));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.addNotification(
            'The provided merchant ID does not exist',
            'error',
          );
          return;
        }
        this.addNotification(
          'An unknown error occured switching the merchant',
          'error',
        );
      }
    },
    navigateToList() {
      this.$router.push({ name: 'merchant.list' });
    },
    newMerchant() {
      this.$router.push({ name: 'merchant.new' });
    },
    /**
     * Close log in as popover when clicking outside of it
     */
    onOutsideClick(event) {
      if (!this.showLogInAs) {
        return;
      }

      if (
        // Skip if the card was clicked...
        this.$refs['log-in-as-card']?.$el.contains(event.target)
        // ...or the button to show the card was clicked
        || this.$refs['log-in-as-button']?.$el.contains(event.target)
      ) {
        return;
      }

      this.showLogInAs = false;
    },
    openLogInAsPopover() {
      this.showLogInAs = true;
      // Focus the input that's revealed. Note there's a double "nextTick" here as the portal mounts
      // in one, and then the text field renders.
      this.$nextTick(() => this.$nextTick(() => {
        this.$refs['log-in-as-input'].$el.querySelector('input').focus();
      }));
    },
  },
};
</script>

<style lang="scss">
.merchant-selector {
  @apply flex max-w-full overflow-hidden text-left items-center justify-between bg-gray-600 px-2
    py-3 rounded;
  min-height: 3rem;

  &:hover {
    @apply bg-gray-500 text-white;
  }

  &:active {
    @apply bg-gray-700;
  }

  &__menu {
    // Have to be extra specific to override the UI lib definition
    &.options-menu {
      min-width: 16rem;
    }
  }

  &__loading {
    @apply h-16;
  }

  &__custom-option .option__label {
    @apply flex items-center;
  }

  &__option-icon {
    @apply w-8 h-8 rounded-full bg-gray-500 text-white mr-2;

    svg {
      @apply m-2;
    }
  }

  &__log-in-as-input {
    @apply mt-4;
  }

  &__log-in-as-form {
    @apply flex flex-col;
  }
}
</style>
