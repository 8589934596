import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations/dist/vue';
import axios from 'axios';
import sanitize from '@/lib/sanitize';
import resolveDomain from './resolveDomain';
import ApiError from './api/ApiError';

export default Sentry;

export function configureSentry(Vue) {
  // Don't run Sentry on local
  if (process.env.VUE_APP_ENVIRONMENT === 'local') {
    return;
  }

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    debug: false,
    environment: process.env.VUE_APP_ENVIRONMENT,
    ignoreErrors: [
      // Ignore error about excess redirects which we can't seem to catch or resolve
      'Redirected when going from "/auth/',
    ],
    integrations: [
      new VueIntegration({
        Vue,
        logErrors: false,
      }),
    ],
  });

  // Configure Sentry to save requests and responses from HTTP errors with client error codes (4xx)
  Sentry.addGlobalEventProcessor((event, hint) => {
    if (!hint) {
      return event;
    }
    try {
      const { originalException } = hint;
      if (!originalException || !(originalException instanceof ApiError)) {
        return event;
      }

      // Gather detail from the original exception
      const { response } = originalException.axiosError;
      const { url, method, data } = response.config;

      // Discard debug information if provided - it might make the attachment payload too large for
      // Sentry to accept it
      const { internal_debug: _, ...responseData } = response.data;

      // Build a payload for the "attachment" that will be added to the error in Sentry
      const payload = {
        request: sanitize(JSON.parse(data || ''), ['password']),
        response: responseData,
      };

      // Build a declarative name for the attachment
      const baseUrl = resolveDomain('api');
      const name = `${method.toUpperCase()}${url.replace(baseUrl, '').replaceAll('/', '-')}-error-${response.status}.json`;

      // Create and send the attachment to Sentry
      const client = Sentry.getCurrentHub().getClient();
      const {
        host,
        path,
        projectId,
        port,
        protocol,
        user,
      } = client.getDsn();
      const endpoint = `${protocol}://${host}${port !== '' ? `:${port}` : ''}${path !== '' ? `/${path}` : ''}/api/${projectId}/events/${event.event_id}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;

      const attachment = new FormData();
      attachment.append(
        'my-attachment',
        new Blob([JSON.stringify(payload)], {
          type: 'application/json',
        }),
        name,
      );

      axios({
        method: 'POST',
        data: attachment,
        url: endpoint,
      }).catch(() => {
        // noop
      });
    } catch (error) {
      // noop
    }

    return event;
  });
}
