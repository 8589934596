/* eslint-disable max-len */
export default {
  general: {
    about: 'About',
    address: {
      street: 'Street',
      street_line_2: 'Street line 2',
      city: 'City',
      provinceState: 'Province/state',
      postalZip: 'Postal/zip code',
      country: 'Country',
      delivery_instructions: 'Delivery instructions',
    },
    // Used as a tooltip/column header for "more options" action menus
    actions: 'Actions',
    active: 'Active',
    add: 'Add',
    adding: 'Adding...',
    advanced: 'Advanced',
    all: 'All',
    amount: 'Amount',
    amountValue: 'Amount: {value}',
    andMore: ' & {n} more',
    areYouSure: 'Are you sure?',
    auto: 'Auto',
    assets: 'Assets',
    // For breadcrumb links
    backTo: 'Back to {page}',
    backToPreviousPage: 'Back to previous page',
    // For attributing content to a user in the system, e.g. order notes
    byAuthor: 'by {author}',
    calculation: 'Calculation',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cannotBeUndone: 'This action cannot be undone.',
    checkbox: 'Checkbox',
    checked: 'Checked',
    clickToCopy: 'Click to copy',
    close: 'Close',
    clearSearch: 'Clear search',
    contactUs: 'Contact us',
    copied: 'Copied!',
    copy: 'Copy',
    country: 'Country',
    created: 'Created',
    creating: 'Creating...',
    currency: 'Currency',
    date: 'Date',
    dateTime: 'Date/time',
    details: 'Details',
    delete: 'Delete',
    deleting: 'Deleting',
    default: 'Default',
    description: 'Description',
    descriptionOptional: 'Description (optional)',
    digital: 'Digital',
    disable: 'Disable',
    disabled: 'Disabled',
    discount: 'Discount',
    draft: 'Draft',
    duplicate: 'Duplicate',
    edit: 'Edit',
    emailAddress: 'Email address',
    // The list is empty. List can be discounts, categories, products, etc.
    empty: 'Empty',
    enable: 'Enable',
    enabled: 'Enabled',
    environment: 'Environment',
    error: 'Sorry, there has been an error. Please try again.',
    expired: 'Expired',
    expires: 'Expires',
    false: 'False',
    for: 'For',
    firstName: 'First name',
    fulfill: 'Fulfill',
    fulfilled: 'Fulfilled',
    generate: 'Generate',
    getStarted: 'Get started',
    goBack: 'Go back',
    health: 'Health',
    home: 'Home',
    hidden: 'Hidden',
    hostedExperiences: 'Hosted experiences',
    id: 'ID',
    images: 'Images',
    inactive: 'Inactive',
    item: 'Item',
    items: '{count} Item(s)',
    label: 'Label',
    lastName: 'Last name',
    learnMore: 'Learn more.',
    learnMoreButton: 'Learn more',
    live: 'Live',
    loading: 'Loading...',
    loggedInAs: 'Logged in as {name}',
    logo: 'Logo',
    longText: 'Long text',
    merchant: 'Merchant',
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    name: 'Name',
    next: 'Next',
    nextName: 'Next: {name}',
    // Default "null" timestamp text
    never: 'Never',
    new: 'New',
    no: 'No',
    noResults: 'No results match your search',
    notes: 'Notes',
    notAvailable: 'N/A',
    notFulfilled: 'Not fulfilled',
    notPaid: 'Not paid',
    notRequired: 'Not required',
    number: 'Number',
    open: 'Open',
    options: 'Options',
    option: 'Option',
    override: 'Override',
    paid: 'Paid',
    parent: 'Parent',
    partiallyFulfilled: 'Partially fulfilled',
    paymentGateway: 'Payment gateway',
    permalinkSlug: 'Permalink/slug',
    phoneNumber: 'Phone number',
    placed: 'Placed',
    previous: 'Previous',
    previousName: 'Previous: {name}',
    price: 'Price',
    private: 'Private',
    province: 'Province',
    published: 'Published',
    quantity: 'Quantity',
    radio: 'Radio', // Global extra field options label
    rate: 'Rate',
    rates: 'Rates',
    regions: 'Regions',
    reference: 'Reference',
    // verb e.g. "to refund"
    refund: 'Refund',
    refunded: 'Refunded',
    refunding: 'Refunding...',
    region: 'Region',
    regenerate: 'Regenerate',
    remaining: 'Remaining',
    required: 'Required',
    sales: 'Sales',
    sandbox: 'Sandbox',
    sandboxMode: 'Sandbox mode',
    save: 'Save',
    saveChanges: 'Save changes',
    saved: 'Changes saved',
    saveAndClose: 'Save and close',
    saving: 'Saving...',
    search: 'Search',
    security: 'Security',
    sending: 'Sending...',
    shareEmbed: 'Share/embed',
    shipping: 'Shipping',
    shippingMethod: 'Shipping ({method})',
    shortText: 'Short text',
    selectOption: 'Select option',
    slug: 'Slug',
    standard: 'Standard',
    startDate: 'Start date',
    state: 'State',
    status: 'Status',
    subtotal: 'Subtotal',
    tax: 'Tax',
    template: 'Template',
    text: 'Text',
    time: 'Time',
    timestamp: 'Timestamp',
    today: 'Today',
    total: 'Total',
    totalPaid: 'Total paid',
    true: 'True',
    type: 'Type',
    unfulfilled: 'Unfulfilled',
    unchecked: 'Unchecked',
    unlimited: 'Unlimited',
    update: 'Update',
    upgradePlan: 'Upgrade your plan',
    url: 'URL',
    value: 'Value',
    view: 'View',
    viewDetails: 'View details',
    visible: 'Visible',
    visibility: 'Visibility',
    whatIsThis: 'What is this',
    // Used for numbers e.g. "2 of 5"
    xOfY: '{x} of {y}',
    yes: 'Yes',
    yesterday: 'Yesterday',
  },
  account: {
    account: 'Account',
    currentPassword: 'Current password',
    dashboardLanguage: 'Dashboard language',
    // Section title
    deleteAccount: 'Delete account',
    deleteCallout: 'You are about to permanently delete your account.',
    deleteFailed: 'Failed to delete your account! Try again later.',
    deleteMessage: 'Deleting your account is permanent and cannot be reversed. Please make sure you have exported all of your orders.',
    deleteMyAccount: 'Delete my account',
    details: 'Account details',
    emailToolTip: 'This email is associated to your account. You will receive order notifications and account information at this email.',
    information: 'Account information',
    language: 'Language',
    logout: 'Logout',
    missingPasswords: 'Please enter your current and desired new passwords.',
    newPassword: 'New password',
    passwordUpdated: 'Password updated successfully.',
    passwordUpdateFailed: 'Failed to update your password. Please try again.',
    updateDetails: 'Update details',
    updateFailed: 'Failed to save your account information. Please try again.',
    updateLanguage: 'Update language',
    updatePassword: 'Update password',
    updatedDetailsSuccessfully: 'Details updated successfully.',
    updatedLanguageSuccessfully: 'Language updated successfully.',
  },
  address: {
    city: 'City',
    country: 'Country',
    line1: 'Address line 1',
    line2: 'Address line 2',
    name: 'Name',
    state: 'State',
    zipCode: 'Zip code',
  },
  attribute: {
    attributes: 'Attributes',
  },
  auth: {
    somethingWentWrong: 'Something went wrong. Please try logging in again.',
    unableToAuth: 'Unable to authorize your account. Please try again.',
  },
  billing: {
    accruedFees: 'Accrued transaction fees',
    addCard: 'Add card',
    addPaymentMethod: 'Edit plan details',
    // Section title
    billing: 'Billing',
    changePlan: 'Change plan',
    checFee: 'Chec fee ({currency})',
    // When changing your plan, this is an action
    choosePlan: 'Choose plan',
    confirmChangePlan: 'If the plan you\'re changing to has restrictions on platform features (such as Commerce Rays), your resources will automatically be scaled back to fit your new plan\'s limits.',
    currentPlan: 'Current plan',
    customPricing: 'Custom pricing',
    emailAddress: 'Billing email address',
    invoices: 'Invoices',
    invoiceAmount: 'Amount',
    invoiceDate: 'Date',
    invoiceDownload: 'Download',
    invoiceDescription: 'Description',
    invoiceStatus: 'Status',
    min: 'min,',
    minimum: 'minimum',
    monthlyCeiling: '{ceiling} monthly ceiling',
    noPaymentMethod: 'No payment method.',
    orderValue: 'Order value ({currency})',
    overageFee: '{fee} overage',
    paid: 'Paid',
    paymentGateway: 'Payment gateway',
    paymentMethod: 'Payment method',
    paymentMethodRequired: 'Please add a payment method before you choose new plans',
    // Referring to plan amount, e.g. $50 per month
    perMonth: 'per month',
    // Referring to a fee e.g. 0.3%
    perTransaction: 'Per transaction',
    planDetails: 'Plan details',
    planMonthlyCeiling: '{ceiling} monthly ceiling',
    planName: '{name} plan',
    planOverage: '{overage} overage',
    planSummary: 'You will be charged {perMonth} per month and {perTransaction} per transaction',
    planTransactionFee: '{fee} transaction fee',
    // ID/reference: "Order: ABC-123"
    transactionFeeOrder: 'Order',
    transactionFeesTitle: 'Transaction fees invoiced on {date}',
    unpaid: 'Unpaid',
    // credit card
    updateCard: 'Update',
    updateEmail: 'Update billing email address',
    viewInvoice: 'View invoice',
    voided: 'Voided',
  },
  category: {
    add: 'Add category',
    added: 'Added category.',
    addedSubCategory: 'Added sub-category.',
    addSubCategory: 'Add sub-category',
    categories: 'Categories',
    chooseParentCategory: 'Choose parent category',
    confirmDelete: 'Are you sure you want to delete this category?',
    confirmDeleteTitle: 'Are you sure?',
    create: 'Create category',
    createError: 'Sorry, we\'re having problems creating this category.',
    deleted: 'Category deleted',
    deleteFailed: 'Failed to delete your category! Please try again.',
    edit: 'Edit category',
    editMultiple: 'Edit categories',
    images: {
      title: 'Images gallery',
      types: '| {types} accepted | {types} & {lastType} accepted',
    },
    introSplash: {
      title: 'Add categories and sub-categories.',
      description: 'Create infinitely nestable product categories with category images and descriptions. {more}.',
      more: 'Learn more',
    },
    noAvailableCategories: 'Please add another category first',
    noSubCategories: 'This category has no sub-categories.',
    permalink: 'Permalink',
    pleaseEnterName: 'Please enter a name',
    pleaseEnterPermalink: 'Please enter a permalink',
    save: 'Save category',
    selectPlaceholder: 'Choose a category',
    subCategories: 'Sub-categories',
    update: 'Update category',
    updated: 'Updated category.',
    updateError: 'Sorry, we\'re having problems saving this category.',
    updateSubCategoryError: 'Sorry, we\'re having problems saving this sub-category.',
  },
  customer: {
    add: 'Add customer',
    addCustomerAddress: 'Add customer address',
    added: 'Added customer.',
    address: 'Address',
    addressAdded: 'Added address.',
    addressDeleted: 'Customer address deleted.',
    addressError: 'Sorry, we\'re having problems creating this customer address.',
    addressUpdated: 'Updated address.',
    customerAddresses: 'Customer addresses',
    customers: 'Customers',
    customerDetails: 'Customer details',
    customerOverview: 'Customer overview',
    confirmAddressDelete: 'Are you sure you want to delete this customer address?',
    confirmDelete: 'Are you sure you want to delete this customer?',
    create: 'Create customer',
    createError: 'Sorry, we\'re having problems creating this customer.',
    deleted: 'Customer deleted.',
    defaultBilling: 'Default billing',
    defaultBillingAddress: 'Default billing address',
    defaultShipping: 'Default shipping',
    defaultShippingAddress: 'Default shipping address',
    deleteCustomer: 'Delete customer',
    deleteFailed: 'Failed to delete your customer! Please try again.',
    edit: 'Edit customer',
    editCustomerAddress: 'Edit customer address',
    emailTaken: 'That email address is already in use',
    externalId: 'External ID',
    introSplash: {
      title: 'Your customers will be displayed here',
      description: 'Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.',
    },
    lastOrder: 'Last order',
    notesTitle: 'Notes',
    orderHistory: 'Order history',
    recentOrder: 'Recent Order',
    save: 'Save customer',
    totalOrders: 'Total orders',
    totalSpent: 'Total spent',
    totalPaid: 'Total paid',
    update: 'Update customer',
    updated: 'Updated customer.',
    updateError: 'Sorry, we\'re having problems saving this customer.',
    updateAddressError: 'Sorry, we\'re having problems saving this address.',
    viewOrder: 'View order',
  },
  developer: {
    apiKeys: 'API keys',
    apiKeysCors: 'API keys & CORS',
    checCli: 'Chec CLI',
    cliNotice: 'Use the {cli} to stream logs in real time: {cliCommand}',
    copyCodeSuccess: 'The code has been copied to your clipboard.',
    copyCodeFailure: 'Failed to copy the code to the clipboard. Please refresh the page and try again.',
    corsDomainsHelp: 'You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the "Access-Control-Allow-Origin" header. If you specify multiple, API calls will compare against the request\'s "Origin" header. If you leave this blank, all origins will be allowed (default).',
    crossOriginResourceSharing: 'Cross-Origin Resource Sharing (CORS) origins',
    dateTime: 'Date/time',
    ipAddress: 'IP address',
    keyUsed: {
      label: 'Key used',
      public: 'Public',
      secret: 'Secret',
    },
    method: 'Method',
    summary: 'Summary',
    response: 'Response',
    responseDate: {
      label: 'Response date',
    },
    responseStatus: {
      label: 'Response status',
      ok: 'OK',
      badRequest: 'Bad request',
      unauthorized: 'Unauthorized',
      validation: 'Validation',
      requestFailed: 'Request failed',
      notFound: 'Not found',
      other: 'Other/server errors',
    },
    request: 'Request',
    requestMethod: {
      label: 'Request method',
      get: 'GET',
      post: 'POST',
      put: 'PUT',
      delete: 'DELETE',
    },
    url: 'URL',
  },
  discount: {
    add: 'Add discount',
    added: 'Added discount.',
    all: 'All',
    code: 'Code',
    codeRequired: 'Discount code is required',
    confirmDelete: 'Are you sure you want to delete this discount?',
    create: 'Create discount',
    deleted: 'Discount deleted.',
    deleteFailed: 'Failed to delete your discount! Please try again.',
    discountCode: 'Discount code',
    discountOffProduct: '{discount} off {product}',
    discounts: 'Discounts',
    edit: 'Edit discount',
    introSplash: {
      title: 'Add a discount code and run a promotion.',
      description: 'Add $ or % based discount codes, their quantity, start and end dates. {more}.',
      more: 'Learn more',
    },
    neverExpires: 'Never',
    productsLabel: 'Limit to these products',
    productTooltip: 'If left empty, this discount will work for all products',
    saveError: 'Sorry, we\'re having problems saving this discount.',
    updated: 'Updated discount.',
    valueRequired: 'Please enter discount value',
  },
  error: {
    notFoundSuggestion: 'Navigate back to the page you came from and try again.',
    notFoundTitle: 'Not found...',
    permissionErrorSuggestion: 'Try logging out and logging back in again. If the problem persists, please contact Support.',
    permissionErrorTitle: 'Permission denied!',
    serverErrorSuggestion: 'Grab a coffee and try again in a few minutes. We\'ve also been notified of this error.',
    serverErrorTitle: 'Something went wrong...',
    somethingWentWrong: 'Whoops. Something went wrong!',
  },
  extraFields: {
    extraFields: 'Extra fields',
    name: 'Extra field name',
    // Specific to globally-scoped extra fields
    add: 'Add extra field',
    addOptions: 'Hit enter or comma to add an option',
    edit: 'Edit extra field',
    confirmDeleteExtraField: 'Are you sure you want to delete this extra field? Storefronts that rely on this extra field may be affected.',
    deleted: 'Extra field deleted successfully.',
    deleteFailed: 'Failed to delete your extra field! Please try again.',
    fieldType: 'Field type',
    fieldTypeDescription: 'Select the field type for your extra field. This is for frontend use.',
    introDescription: 'Extra fields are custom merchant defined data fields that are globally available, usually in a checkout. An example might be a custom extra field for collecting customers\' phone numbers in a checkout form.',
    introTitle: 'Collect custom data from customers with global extra fields.',
    nameRequired: 'Name is required',
    saveSuccess: 'Your extra field has been saved successfully.',
    saveFailure: 'Sorry, we\'re having a problem saving your extra field.',
    typeRequired: 'Field type is required',
    updateSuccess: 'Your extra field has been updated successfully.',
    // Specific to product-scoped extra fields
    description: 'Collect custom information from your customer.',
    collectsShipping: 'Require shipping address',
    collectsBilling: 'Require billing address',
    collectsFullName: 'Require full name',
  },
  gateways: {
    add: 'Add payment gateway',
    // Company brand
    braintree: 'Braintree',
    braintreeDocumentation: 'See the documentation',
    braintreeHelp: 'Enter your Braintree merchant ID, public key, private key, and your tokenization key. {documentation} for more information on tokenization keys.',
    chooseGateway: 'Please choose a gateway',
    confirmDelete: 'Are you sure you want to delete this payment gateway? Any stores using it will stop accepting payments immediately from this gateway.',
    deleted: 'Payment gateway deleted.',
    deleteFailed: 'Something went wrong while trying to delete this payment gateway. Please try again.',
    edit: 'Edit payment gateway: {gateway}',
    email: 'Email address',
    gateway: 'Gateway',
    introTitle: 'Start collecting payments by enabling your payment gateway(s).',
    introDescription: 'A payment gateway authorizes credit card or direct payments processing for online businesses. The test gateway has been enabled for making test orders.',
    keyId: 'Key ID',
    keySecret: 'Key secret',
    merchantId: 'Merchant ID',
    manual: 'Manual',
    manualHelp: 'You will manually have to record payments and fulfill orders placed using this method.',
    manualName: 'Manual: {name}',
    manualConfig: {
      name: 'Name of payment method',
      detailsHelp: 'Details about this payment method - shown before placing an order.',
      paymentInstructions: 'Payment instructions',
      paymentInstructionsHelp: 'Details on how to pay - shown to customers after placing an order.',
    },
    paymentMethodHelper: 'To add a live payment gateway, please update to the standard plan or higher and add a payment method in the billing settings page.',
    // Company brand
    paypal: 'PayPal',
    paystack: 'Paystack',
    paystackHelp: 'Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.',
    paystackDocumentation: 'Paystack documentation',
    privateKey: 'Private key',
    publicKey: 'Public key',
    publishableKey: 'Publishable key',
    // Company brand
    razorpay: 'Razorpay',
    redirectingToSquare: 'Redirecting to Square',
    sandboxNotAvailable: 'Sandbox mode is not available for this gateway.',
    saved: 'Your payment gateway was successfully saved.',
    saveFailed: 'Failed to save your payment gateway, please check you have filled out all required fields.',
    secretKey: 'Secret key',
    // Company brand
    stripe: 'Stripe',
    stripeMethodTypesLabel: 'Payment method types',
    stripeMethodTypesTooltip: 'The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, \'card\' is selected by default.  \n\nClick to see documentation.',
    // Used in stripeHelp as link text
    stripeDocumentation: 'Stripe documentation.',
    stripeHelp: 'Enter your publishable key and your secret key, both available from the Stripe Dashboard. Learn more in the {documentation}',
    // Company brand
    square: 'Square',
    squareHelp: 'When you click \'Save changes\' below, you will be redirected to Square to login to your payment account.',
    // Company brand
    omise: 'Omise',
    omiseHelp: 'Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.',
    omiseDocumentation: 'Omise documentation',
    testGateway: 'Test gateway',
    tokenizationKey: 'Tokenization key',
    transactionVolume: 'Transaction volume',
    weSupport: 'Gateways we support',

  },
  home: {
    addProducts: 'Add your products',
    configureShipping: 'Configure your shipping',
    configureTax: 'Configure your tax',
    createCommerceRay: 'Create your first Commerce Ray',
    enableGateway: 'Enable a payment gateway',
    gettingStarted: 'Getting started',
    gettingStartedRays: 'Create your Commerce Ray',
    latestAnnouncements: 'Latest announcements',
    latestChanges: 'Latest changes',
    messageUs: 'Message us',
    speakToAnExpert: 'Speak to an expert',
    speakToCopy: 'Our commerce experts can make sure Commerce.js is the right fit for your business and help architect a solution.',
    title: 'Welcome',
    viewChangelog: 'View changelog',
    welcomeSubtitle: 'Welcome! We\'re here to help you get things rolling.',
  },
  integration: {
    about: 'About: {integration}',
    aboutThisIntegration: 'About this integration',
    awaitingThirdParty: 'Your integration is being installed. This may take some time.',
    awaitingThirdPartyNotification: 'Your {type} integration has been created, and is being installed. This may take some time.',
    configureIntegration: 'Configure integration',
    addedIntegration: 'Integration added',
    add: 'Add integration',
    confirmDelete: 'Are you sure you want to delete this integration?',
    createError: 'Sorry, we\'re having problems activating this integration.',
    creating: 'Creating integration...',
    deleted: 'Integration deleted.',
    deleteFailed: 'Failed to delete your integration! Try again later.',
    edit: 'Edit integration',
    editNamed: 'Edit integration - {name}',
    filterLabel: 'Filter',
    filterTagLabel: 'Filter: {tag}',
    filter: {
      checkout: 'Checkout',
      content: 'Content',
      email: 'Email',
      dataMesh: 'Data mesh',
      frontend: 'Frontend',
      fulfillment: 'Fulfillment',
      payments: 'Payments',
      personalization: 'Personalization',
      orders: 'Orders',
      tax: 'Tax',
    },
    healthTooltip: '{percent} of the last few integration runs were successful',
    integration: 'Integration',
    integrations: 'Integrations',
    introSplash: {
      title: 'Configure integrations with 3rd party providers.',
      description: 'Connect with other services and compose your desired business stack.',
    },
    lastRun: 'Last run',
    noConfigNeeded: 'This template requires no additional configuration',
    noLogo: 'No logo',
    readyNotification: 'Good news, your {type} integration is ready!',
    status: {
      healthy: 'Healthy',
      notApplicable: 'N/A',
      unhealthy: 'Unhealthy',
      unstable: 'Unstable',
    },
    saved: 'The integration was saved.',
    savedProvisioning: 'Your integration is being set up, this will take a moment...',
    savingFailure: 'This integration failed to save. Please try again.',
    view: 'View integration',
  },
  logs: {
    logs: 'Logs',
    introSplash: {
      title: 'Your request logs will be shown here',
      description: 'API request logs with details will be listed here for ease of debugging.',
    },
  },
  merchant: {
    createMerchant: 'Create merchant',
    createFull: 'Let\'s set up your merchant',
    createLeadIn: 'Your information can be changed or updated at any time.',
    confirmDelete: 'Are you sure you want to delete this merchant? This process is irreversible.',
    confirmDeleteTitle: 'Are you sure?',
    deleteMerchant: 'Delete merchant',
    deleteCallout: 'You are about to permanently delete your account.',
    deleted: 'Merchant deleted.',
    deleteFailed: 'Failed to delete your merchant! Try again later.',
    deleteMessage: 'Deleting your merchant is permanent and cannot be reversed. Any other merchants associated with this account will not be deleted. Please make sure you have exported all of your orders.',
    noMerchants: 'You have no merchants associated with this account',
    seeAll: 'View all merchants',
    selectMerchant: 'Select a merchant',
  },
  notes: {
    add: 'Add note',
    addANote: 'Add a note',
    confirmDelete: 'Are you sure you want to delete this note?',
    note: 'Note',
  },
  order: {
    cancelOrder: 'Cancel order',
    cancelling: 'Cancelling',
    cancelFailed: 'Unable to cancel the order, please try again.',
    cancelSuccess: 'Order cancelled successfully.',
    capturedOn: 'Captured:',
    cardEndingIn: 'Card ending in {number}',
    changeCustomer: 'Change customer information',
    confirmCancel: 'Are you sure you want to cancel this order?',
    confirmRefund: 'Are you sure you want to refund this order?',
    customerDetails: 'Customer details',
    customerUpdated: 'Updated customer information on order #{reference}',
    customerUpdateFailed: 'Sorry, we\'re having problems updating the customer information.',
    date: 'Order date',
    dateTime: 'Date/time',
    deleteTest: 'Delete test orders',
    deleteTestConfirmation: 'Are you sure you want to delete all of your sandbox orders?',
    edit: 'Edit order',
    export: 'Export orders',
    exportConfirmation: 'We will send a copy of your orders (with the current filters) in CSV format to your merchant email when it\'s ready.',
    fulfillItems: 'Fulfill items',
    fulfillment: {
      confirmHeader: 'Please confirm your fulfillment changes',
      confirmCopy: 'Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?',
      dateShipped: 'Date shipped: {date}',
      header: 'Fulfillment',
      reference: 'Reference: {reference}',
      shipmentNumber: 'Shipment #{number}',
      shippingCarrier: 'Shipping carrier',
      trackingNumber: 'Tracking #{number}',
      trackingNumberOptional: 'Tracking number (optional)',
      trackingNotProvided: 'Tracking not provided',
    },
    fulfillmentStatus: {
      fulfilled: 'Fulfilled',
      label: 'Fulfillment status',
      not_fulfilled: 'Not fulfilled',
      partially_fulfilled: 'Partially fulfilled',
      returned: 'Returned',
    },
    gatewayTransactionId: 'Gateway transaction ID: {id}',
    itemFulfilled: 'Items fulfilled successfully.',
    nextOrder: 'Next order',
    newNotification: 'New order {reference} for {value}!',
    noOrders: {
      title: 'Make your first sale!',
      copy: 'We\'ll then show you orders and sales displayed over time.',
    },
    orderNotes: 'Order notes',
    orderNumber: 'Order #',
    orders: 'Orders',
    ordersAllTime: 'Orders - All time',
    ordersOnMonth: 'Orders - {month}',
    ordersOnMonthHelptip: 'Number of orders since the start of this month, compared with the same period in the previous month',
    orderStatus: 'Order status',
    overview: 'Overview',
    payment: 'Payment',
    paymentMethod: 'Payment method',
    paymentSaved: 'Payment saved successfully.',
    paymentSaveFailed: 'Sorry, we\'re having problems saving this payment.',
    paymentStatus: {
      label: 'Payment status',
      not_paid: 'Not paid',
      paid: 'Paid',
      partially_paid: 'Partially paid',
      pending: 'Payment pending',
      refunded: 'Refunded',
    },
    previousOrder: 'Previous order',
    receiptSendFailed: 'Unable to resend the receipt, please try again.',
    receiptSent: 'Receipt sent.',
    recordManualPayment: 'Record manual payment',
    reference: 'Reference: {reference}',
    refundFailed: 'Unable to refund the order, please try again.',
    refundSuccess: 'Order refunded successfully.',
    resendReceipt: 'Resend receipt',
    salesAllTime: 'Sales - All time',
    salesOnMonth: 'Sales - {month}',
    salesOnMonthHelptip: 'Sales performance since the start of this month, compared with the same period in the previous month',
    sandbox: 'Test order',
    shipments: 'Shipments',
    // Header for a table column for the order status (e.g. "Paid / Fulfilled" or "Refunded / Unfulfilled")
    status: 'Status',
    testOrdersDeleted: 'Test orders successfully deleted.',
    transactionReferenceId: 'Transaction/reference ID',
    updateAddressError: 'Something went wrong while saving the address, please try again.',
    updatedBilling: 'Updated billing address.',
    updatedShipping: 'Updated shipping address.',
    viewOne: 'View order',
    viewMultiple: 'View orders',
  },
  product: {
    add: 'Add product',
    added: 'Product added',
    // Accessibility label for the "more options" menu on the product screen (eg. delete product)
    additionalActions: 'Additional product actions',
    // Used when referring to the product price as a "base" price for adjustments (in the context of variants or discounts)
    basePrice: 'Base price',
    chooseRelatedProducts: 'Choose related products',
    count: 'No products | 1 product | {n} products',
    confirmDeleteTitle: 'Are you sure?',
    confirmDelete: 'Are you sure you want to delete this product?',
    copyPaste: 'Copy & paste this code into your site.',
    customPermalink: 'Custom permalink',
    delete: 'Delete product',
    deleted: 'Product deleted.',
    deleteFailed: 'Failed to delete your product! Try again later.',
    description: 'Description',
    digitalFulfillment: {
      downloadLimit: 'Download limit',
      duration: 'Duration',
      durationPeriod: 'Duration period',
      expiryMessage: 'After {duration} {period} of purchase',
      doesNotExpire: 'Links do not expire',
      linkExpiration: 'Link expiration',
      period: {
        // The following options are for zero, one, and more than one.
        days: 'days | day | days',
        months: 'months | month | months',
        weeks: 'weeks | week | weeks',
      },
      title: 'Digital delivery',
    },
    duplicateProduct: 'Duplicate product',
    duplicated: 'Product successfully duplicated and you are now editing the duplicate.',
    edit: 'Edit product',
    embed: 'Embed',
    embedCheckout: 'Embed checkout',
    embedCheckoutDescription: 'Embed your checkout directly onto your website or blog.',
    embedShareSell: 'Embed, share, sell!',
    images: {
      title: 'Images gallery',
      types: '| {types} accepted | {types} & {lastType} accepted',
      alert: 'The image gallery contains all images associated with this product including its attached variants.',
    },
    includesNCountries: 'Includes 1 country | Includes {number} countries',
    introSplash: {
      title: 'Add your first physical or digital product',
      description: 'Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.',
      api: 'Create product API',
      more: 'Learn more',
    },
    inventory: {
      available: 'Inventory available',
      managed: 'Managed',
      tooltip: 'When inventory is managed you can set the number of available items, otherwise it will be unlimited',
    },
    lastSavedAt: 'Last saved',
    linkCustomers: 'Link customers directly to the checkout using this URL.',
    minimumPrice: 'Minimum price',
    misc: 'Misc',
    name: 'Name',
    noImage: 'No image',
    noRelatedAvailable: 'You have no other products',
    payWhatYouWant: 'Pay what you want',
    price: 'Price',
    product: 'Product',
    products: 'Products',
    pwywEnabled: "'Pay what you want' enabled",
    relatedProducts: 'Related products',
    saved: 'Changes saved',
    saveFailed: 'Failed to save the product! Please try again.',
    searchEngineDescription: 'Search engine description',
    searchEngineTitle: 'Search engine title',
    seo: 'SEO',
    sku: 'SKU',
    sortOrder: 'Sort order',
    sortOrderTooltip: 'Lower values make your product show up in lists earlier, whereas higher values make it show up later.',
    thankYouPage: 'Thank you page',
    variants: {
      addGroup: 'Add variant group',
      addOption: 'Options (example: Red, Green, Blue, etc.)',
      addVariant: 'Add variant',
      amount: 'Amount',
      // Shown when previewing variants that will be generated. The count will always be more than one
      andMore: 'And {count} additional variants',
      bulkAdd: 'Bulk add',
      bulkFailure: 'Sorry, we\'re having problems updating your variants.',
      bulkSuccess: 'Variants have been updated successfully',
      chooseGroupsAndOptions: 'Choose groups & options',
      create: 'Create variant | Create variants',
      createBulk: 'Create bulk variants',
      createSingle: 'Create single variant',
      defaultVariantTitle: 'New variant',
      defaultPrice: 'Default price',
      defaultPriceTooltip: 'The default price for the variant option.',
      edit: 'Edit variants',
      editSingle: 'Edit variant',
      editSingleNamed: 'Edit variant: {name}',
      generateVariants: 'Generate variants',
      // Used as a shorthand label for "Variant groups"
      groups: 'Groups | Group | Groups',
      hasInvalidVariants: 'This product has invalid variants. These variants cannot be added to a cart or checkout, and cannot be sold. To re-enable invalid variants, please edit them and address any issues.',
      help: 'Different types of this product (e.g. size, color)',
      // Note this is used in a fairly narrow column
      image: 'Image',
      invalidReason: {
        duplicate_option_config: 'This invalid variant shares options with another variant. Only one variant may exist for a specific set of options.',
        incomplete_option_config: 'This invalid variant is missing an option from one of the variant groups configured for this product. Variants must have an option set for each group.',
      },
      invalidVariantsOnSave: 'Once you save, your variants will be marked as "invalid" and you will not be able to sell invalid variants until you fix the issues.',
      mustSave: 'You must save before adding new variants',
      // Show when variant groups and options have been created, but no variants have been created
      noGroups: 'Variants help you to sell products with slight differences, but are still the same product. For example, you might sell a t-shirt in different colors, or a plant pot in different sizes. You can configure variants to have their own price, SKU, and stock inventory.\n\nTo get started, create groups and options for your variants. Groups define the type of variant (e.g. color). Options are a choice your customer can make within that group (e.g. blue).',
      noGroupsTitle: 'Set up variant groups to sell variations of the same product',
      noOptions: 'Please enter at least one option',
      noVariants: 'Use the buttons above to create variants. Variants are combinations of your configured variant groups and variant options. You will be able to individually set the price and inventory for each variant (if enabled).',
      noVariantsTitle: 'No variants have been created',
      noVariantsToGenerate: 'All variants already exist for the selected options.',
      // Used as a shorthand label for "Variant options"
      options: 'Options | Option | Options',
      optionDefaultImage: 'Default image',
      optionsInGroupsRequired: 'You must select at least one option in each group to generate variants in bulk.',
      optionsUpdateFailure: 'Sorry, we\'re having problems updating your variant options.',
      optionsUpdateSuccess: 'Variant options have been updated successfully',
      // The label for a specific price set on a variant, when set independently from the price calculated with the base product price and the variant option prices
      override: 'Override',
      preview: 'Variant preview',
      previewHelp: 'Preview of the variants that will be created based on the options selected above.',
      quantity: 'Quantity',
      requiresSave: 'Using this feature requires you to save the product first.',
      requiresGroupsSave: 'Using this feature requires you to save the groups first.',
      saveAndGenerate: 'Save and generate variants',
      selectOptions: 'Select options for your variant',
      singleCreateSuccess: 'Variant has been created successfully',
      singleUpdateSuccess: 'Variant has been updated successfully',
      singleUpdateFailure: 'Sorry, we\'re having a problem saving your variant.',
      title: 'Variants',
      variantDetails: 'Variant details',
      variantExists: 'It looks like this variant exists already.',
      variantGroup: 'Variant group',
      variantGroupName: 'Group name (example: Size, Color, Material, etc.)',
      // Used in advanced group options panel title
      variantGroupTitle: 'Variant group: {group}',
      variantName: 'Variant name',
      willInvalidateVariants: 'Adding and/or removing variant groups will invalidate your existing variants, as each variant must have one option for each group, and cannot be duplicated.',
    },
    viewProducts: 'View products',
    shippingCard: {
      title: 'Shipping options',
      nameLabel: 'Name',
      baseRateLabel: 'Base rate',
      baseRateTooltip: 'The base price for this rate. Other rates will be added on top of this.',
      onOwnLabel: 'On own',
      onOwnTooltip: 'The price for this rate when this item is ordered on its own. This will be added to the base rate e.g. Base rate + Rate on own = Total shipping rate charged',
      withOthersLabel: 'With others',
      withOthersTooltip: 'The price for this rate when ordered with other items. Will be added with other items shipping rate and the base rate e.g. Base rate + Rate with others (This item rate) + Rate with others (Other item rate) = Total shipping rate charged',
    },
  },
  attributes: {
    add: 'Add attribute',
    added: 'Added attribute.',
    allowMultiselect: 'Allow multiselect',
    configureDropdown: 'Configure dropdown',
    configureRadio: 'Configure radio button',
    confirmDelete: 'Are you sure you want to delete this attribute?',
    defaultValue: 'Default value',
    defaultValueTooltip: 'You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).',
    deleted: 'Attribute deleted.',
    deleteFailed: 'Failed to delete your attribute! Try again later.',
    descriptionTooltip: 'This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.',
    duplicateWarning: 'It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.',
    edit: 'Edit attribute',
    introDescription: 'Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.',
    introTitle: 'Customize your data, your way, with attributes.',
    attributes: 'Attributes',
    saveFailure: 'Failed to save your attribute! Please try again.',
    saveSuccess: 'Your attribute has been saved successfully.',
    typeRequired: 'Field type is required',
    updated: 'Updated attribute.',
  },
  rays: {
    add: 'Create new Ray',
    commerceRays: 'Commerce Rays',
    confirmDeleteTitle: 'Are you sure?',
    confirmDelete: 'Are you sure you want to delete this Ray?',
    create: 'Create Ray',
    delete: 'Delete Ray',
    deleted: 'Ray deleted.',
    deleteFailed: 'Failed to delete your Ray! Try again later.',
    // in future we may change back to deploying for some users
    deploying: 'Publishing...',
    desktop: 'Desktop',
    edit: 'Edit Ray',
    embed: 'Embed',
    embedModal: 'You can also embed your checkout in a modal popup, triggered by clicking on a "Buy now" button.',
    exit: 'Exit',
    lastDeployment: 'Last publish:',
    nameHelper: 'For internal identification.',
    noRays: 'No Rays found.',
    noProducts: 'You must have at least one product. To create a Ray, please start by adding a product.',
    noThemes: 'No themes found',
    pickTheme: 'Pick a template',
    images: {
      title: 'Images',
      // No text for zero types | One type accepted | multiple types accepted
      types: '| {types} accepted | {types} & {lastType} accepted',
    },
    introduction: {
      title: '✨ Welcome to Commerce Rays',
      description: 'A no-code page builder with built in checkouts.',
      for: {
        title: 'Rays is for',
        campaigns: 'Campaigns',
        digitalProducts: 'Digital products',
        influencers: 'Influencer partnerships',
        mediaCheckouts: 'Media Checkouts',
        ppc: 'PPC campaigns',
        productDrops: 'Product drops',
        videoCommerce: 'Video Commerce',
      },
      line1: 'Anyone can create beautiful product and content pages that convert.',
      line2: 'Pick from our selection of beautiful, editable themes, and sell your brand story.',
    },
    mobile: 'Mobile',
    planWarning: {
      notice: 'Your current plan doesn\'t support using Rays. Please {upgrade} to publish.',
      limitReached: 'You\'ve reached your plan\'s limit. Please {upgrade} to publish more.',
      // designed to be used in the "notice" above
      upgrade: 'upgrade your plan',
    },
    ray: 'Ray',
    rayName: 'Ray name',
    rays: 'Rays',
    sandboxHelper: 'Enabling sandbox mode will allow you to use Stripe in test mode. No live orders can be created in sandbox mode.',
    save: 'Save',
    saved: 'Changes saved',
    savedAndDeploying: 'Changes saved and publishing your Ray...',
    saveDeploy: 'Save & publish',
    saveFailed: 'Failed to save your Ray! Please try again.',
    saving: 'Saving...',
    select: 'Select theme',
    selectProducts: 'Select product(s)',
    validationFailed: 'There is a problem with your chosen settings. Please check the sidebar for errors.',
    viewportWarning: 'This part of our dashboard is best experienced on a larger screen. Please sign in on your computer to view Rays.',
    viewPublishedRay: 'View published Ray',
    viewRay: 'View Ray',
  },
  settings: {
    analytics: 'Analytics',
    data: 'Data',
    developer: 'Developer',
    developerSettings: {
      addKey: 'Add API key',
      apiKey: 'API key',
      apiKeys: 'API keys',
      apiVersion: 'API version',
      createKey: 'Create API key',
      createSuccess: 'API key has been created successfully',
      changeKey: 'Change key',
      changelog: 'See changelogs',
      confirmDeleteKey: 'Are you sure you want to delete this API key? Any stores using this key will stop functioning immediately.',
      deleted: 'API key deleted.',
      deleteFailed: 'Failed to delete your API key! Try again later.',
      editKey: 'Edit API key',
      generateKey: 'Are you sure you want to regenerate the key?',
      keyChanged: 'Key has been successfully updated',
      keyChangFailed: 'Failed to update your API key! Try again later.',
      label: 'API key label',
      live: 'Live',
      public: 'Public',
      resetKeyButtonTooltip: 'Generate a new key',
      resetKeyConfirmationHeader: 'Generate a new key?',
      sandbox: 'Sandbox',
      secret: 'Secret',
      type: 'Type',
      updateSuccess: 'API key successfully updated',
      updateFailure: 'Failed to update your API key. Please try again.',
    },
    merchant: {
      analyticsCardTitle: 'Google Analytics',
      analyticsGaTrackingId: 'Tracking ID',
      analyticsGaTrackingIdTooltip: 'Your unique Google Analytics tracking ID',
      analyticsLinkedDomain: 'Add linked domain',
      analyticsLinkedDomainTooltip: 'You can add multiple domains separated by a comma.',
      coverImage: 'Cover image',
      coverImageTooltip: 'This is the cover image that will be on your storefront.',
      descriptionTooltip: 'A brief description about yourself/your company',
      emailTooltip: 'This email is visible to your customers. Customer support emails will go here.',
      enabledHostedStorefront: 'Enable hosted storefront',
      enableHostedCheckouts: 'Enable hosted checkout',
      enableHostedStorefrontTooltip: 'The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID',
      enableHostedCheckoutsTooltip: 'The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product',
      isPublic: 'Make visible to public API',
      merchantId: 'Merchant ID',
      merchantIdInfo: 'This is your unique merchant ID. We may ask for it when you contact us.',
      name: 'Business name',
      nameTooltip: 'What name are you selling under?',
      save: 'Save settings',
      saveFailed: 'Failed to save the merchant settings! Please try again.',
      saved: 'Changes saved',
      timezoneTooltip: 'All dates and times in Chec will be relative to this timezone',
      title: 'Merchant details',
      merchantAddress: 'Address',
    },
    notifications: {
      customerLoginToken: 'Customer: Login token',
      customerOrderReceipt: 'Customer: order receipt',
      customerOrderShipped: 'Customer: order shipped',
      dashboard: 'Dashboard notifications',
      dashboardSummary: 'Control the behavior of notifications in the Chec Dashboard.',
      emailNotifications: 'Email notifications',
      emailNotificationSummary: 'Enable/disable default email notifications that are sent from Chec.',
      emails: 'Emails',
      merchantOrderReceipt: 'Merchant: new order received',
      navMenuHighlight: 'Navigation menu highlight',
      news: 'News & feature announcements',
      none: 'None',
      notificationPopup: 'Notification popup',
      title: 'Notifications',
    },
    paymentGateways: 'Payment gateways',
    settings: 'Settings',
    shipping: 'Shipping',
    tax: {
      addedToRate: 'Added to country rate',
      address: 'Address',
      addressAlert: 'This is used to calculate tax and is displayed on invoices.',
      addState: 'Add state',
      addTax: 'Enable tax management',
      addTaxRate: 'Add tax rates: {name}',
      addTaxZone: 'Add tax zone',
      calculateAutomatically: 'Calculate taxes automatically',
      confirmDeleteZoneTitle: 'Are you sure?',
      confirmDeleteZone: 'Are you sure you want to delete this tax zone?',
      configureTaxZone: 'Configure tax zone',
      countrySelectInfo: 'The tax rate configured here will be applied to all orders in this tax zone.',
      countryTax: 'Country tax',
      deleted: 'Tax configuration deleted',
      digitalRate: 'Digital rate',
      editTaxRate: 'Edit tax rates: {name}',
      editTaxZone: 'Edit tax zone',
      enableTax: 'Enable tax',
      enableVat: 'Enable EU digital goods VAT',
      enableVatInfo: 'Collect EU digital goods VAT (VAT MOSS) tax on digital goods. Tax rates are automatically calculated and applied to EU customers.',
      euVatMoss: 'European Union (VAT MOSS)',
      introCopy: 'Commerce.js allows you to easily define your region\'s tax rates to help you easily collect the appropriate tax during checkout. You can also choose to integrate any tax provider and implement custom business rules with your own serverless functions.',
      introTitle: 'Let Commerce.js manage your tax!',
      nexusHelp: 'Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.',
      nexusStates: 'Nexus states',
      overrideRate: 'Instead of country rate',
      pricesIncludeTax: 'Do your prices include tax?',
      ratesAutoUpdated: 'Rates are automatically updated & set.',
      saveFailed: 'Failed to save the tax configuration! Please try again.',
      standardRate: 'Standard rate',
      taxRates: 'Tax rates',
      taxRateZones: 'Tax rate zones',
      title: 'Tax',
      zipAlreadyExists: 'The provided ZIP code has already been added',
      zipInvalid: 'The provided ZIP code does not appear to be a valid US postal ZIP code',
    },
    updateFailed: 'Failed to save your notification preferences. Please try again.',
    updatedSuccessfully: 'Notification preferences updated successfully.',
    viewStorefront: 'View storefront',
  },
  shipping: {
    addZone: 'Add zone',
    addCountries: 'Add countries',
    addRegions: 'Add regions',
    confirmDeleteZone: 'Are you sure you want to delete this zone?',
    countriesRegions: 'Countries & regions',
    countriesRegionsHelp: 'Please note that changes to this panel will not be saved until the shipping zone is saved.',
    editCountries: 'Edit countries',
    editRegions: 'Edit regions for {country}',
    editZone: 'Edit zone: {name}',
    enableOnProductsTitle: 'Enable on all products',
    enableOnProductsInfo: 'If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.',
    introSplash: {
      title: 'Add shipping zones for your products',
      description: 'A shipping zone is added by default as an example. Once you\'ve added additional shipping zones, save and enable for the zones to be available for your products. Shipping zones can also be selected in the {productPage}.',
    },
    noCountriesRegionsTitle: 'No countries or regions have been added',
    noCountriesRegions: 'No countries or regions have been added to your zone. Start adding countries or regions to your zone before saving.',
    numCountries: 'No. of countries',
    numRegions: 'No. of regions',
    // Appears mid-sentence as a link title within the shipping.introContent translation
    productPage: 'product edit page',
    rateNameRequired: 'The rate name is required',
    ratePriceRequired: 'The rate price is required. For free rates use 0.',
    ratesLeadIn: 'Increases to these rates can also be added in the {productPage}.',
    saveFailure: 'Sorry, we\'re having a problem saving your shipping zone.',
    saveSuccess: 'Your shipping zone has been saved successfully.',
    selectRegion: 'Configure regions',
    shippingZones: 'Shipping zones',
    zone: 'Zone',
    zoneDeleted: 'Zone deleted.',
    zoneDeleteFailed: 'Something went wrong while trying to delete this zone. Please try again.',
    zoneName: 'Zone name',
    zoneNameDescription: 'The zone name is for internal use only and customers will not see this.',
    zoneNameRequired: 'The zone name is required',
  },
  signup: {
    error: 'Something went wrong while creating your account. Please check your details and try again.',
    form: {
      experience: 'So we can provide you with the best experience possible.',
      merchantDetails: 'Merchant details',
      merchantEmail: 'Merchant email',
      merchantEmailDescription: 'This email is visible to your customers. You\'ll also receive order notifications here.',
      merchantName: 'Merchant name',
      merchantNameDescription: 'What name are you selling under?',
      next: 'Next',
      saving: 'Saving...',
      purposeTitle: 'What are you using Commerce.js for?',
      roleTitle: 'What is your primary role?',
    },
    intro: {
      text: 'Create your account in a few steps and get started using Commerce.js.',
      title: 'Welcome, let\'s setup your account',
    },
    page: {
      title: 'Account setup', // Used in the <title> tag
    },
    purposes: {
      migrating: 'Migrating from a legacy solution',
      launching: 'Launching a new eCommerce business',
      researching: 'Researching modern commerce solutions',
      student: 'Building a student project',
      other: 'Something else',
    },
    roles: {
      developer: 'Developer/engineer',
      cto: 'CTO/VP of Engineering',
      solution_architect: 'Solution Architect',
      owner: 'Business owner',
      manager: 'eCommerce Manager',
      marketing: 'Marketing',
      student: 'Student',
      other: 'Other',
    },
  },
  slack: {
    alreadyJoined: 'Already joined?',
    description: 'Our community of eCommerce developers & designers.',
    error: {
      alreadyInTeam: 'This email address is already in our community!',
      alreadyInvited: 'This email address has a pending invite. Check your email!',
      invalidEmail: 'You must provide a valid email address',
      missingEmail: 'You must provide an email address!',
    },
    getMyInvite: 'Get my invite',
    inviteSent: 'Invite sent! Check your email.',
    join: 'Join Slack community',
    joinUs: 'Join us on Slack',
    joinCommerceJs: 'Join Commerce.js on Slack',
    openSlack: 'Open Slack',
    registeredCount: '{count} users are registered so far.',
    slack: 'Slack',
  },
  spaces: {
    storefront: 'Storefront',
  },
  status: {
    none: 'All systems operational',
    minor: 'Minor service outage',
    major: 'Partial system outage',
    maintenance: 'Service under maintenance',
  },
  validation: {
    enterName: 'Please enter a name',
    enterEmail: 'Please enter an email address',
    enterOption: 'Please enter an option name',
    enterPrice: 'Please enter a price',
    enterQuantity: 'Please enter a quantity',
    enterUrl: 'Please enter a URL',
    enterValue: 'Please enter an attribute value',
    generic: 'Please enter a value for {field}',
    number: 'Please enter a number',
    typeRequired: 'Please enter a type',
    positiveNumber: 'Please enter a positive number',
    validEmail: 'Please enter a valid email address',
    validNumber: 'Please enter a valid number',
    validPrice: 'Please enter a valid price',
    validUrl: 'Please enter a valid URL',
    validBusinessName: 'Please enter a business name',
    validSupportEmail: 'Please enter an email',
    validCity: 'Please enter a city',
    validCountry: 'Please select a country',
    validPostalZipCode: 'Please enter a postal/zip code',
    validRegion: 'Please select a province/state',
    validStreet: 'Please enter a street name & number',
  },
  versions: {
    current: '{version} - current',
  },
  webhooks: {
    add: 'Add webhook',
    added: 'Webhook added.',
    created: 'Created',
    confirmDelete: 'Are you sure you want to delete this webhook?',
    confirmRegenerateKey: 'Are you sure you want to regenerate the signing key? This will affect all webhooks.',
    copiedKey: 'Copied!',
    copyKey: 'Copy key',
    delete: 'Delete webhook',
    deleted: 'Webhook deleted.',
    deleteFailed: 'Failed to delete webhook!',
    deliveryDetails: 'Delivery details',
    deliveryHistory: 'Delivery history',
    edit: 'Edit webhook',
    // Example: orders.create, products.update
    event: 'Event',
    eventName: 'Event name',
    events: 'Events',
    // Signing key
    generateKey: 'Generate new key',
    introSplash: {
      title: 'Add a webhook to extend the possibilities of your business.',
      description: 'Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.',
      more: 'Learn more',
    },
    regenerateKeyFailed: 'Could not regenerate signing key!',
    responseBody: 'Response body',
    responseCode: 'HTTP response code: {code}',
    save: 'Save webhook',
    saved: 'Changes saved.',
    saveFailed: 'Failed to save the webhook! Please try again.',
    sendingTestRequest: 'Sending test request...',
    sendTestRequest: 'Send test request',
    // used to sign webhook request payloads
    signingKey: 'Signing key',
    testRequestCallbackFailed: 'Test request sent but callback response failed.',
    testRequestFailed: 'Failed to send test request!',
    testRequestSuccess: 'Test request sent successfully.',
    view: 'View webhook',
    webhook: 'Webhook',
    webhooks: 'Webhooks',
  },
};
