import addNotification from '@/mixins/addNotification';

export default (defaultSuccessToast, defaultFailureToast) => ({
  mixins: [addNotification],
  computed: {
    canCopy() {
      return (
        typeof window.navigator !== 'undefined'
        && typeof window.navigator.clipboard !== 'undefined'
      );
    },
  },
  methods: {
    copyText(text, successToast = null, failureToast = null) {
      if (!this.canCopy) {
        return;
      }

      window.navigator.clipboard.writeText(text)
        .then(() => {
          this.addNotification(
            successToast || defaultSuccessToast,
            'info',
          );
        })
        .catch(() => {
          this.addNotification(
            failureToast || defaultFailureToast,
            'error',
          );
        });
    },
  },
});
