<template>
  <div class="default-layout--empty">
    <ErrorPage v-if="applicationError" />
    <slot v-else />
    <Confirm />
    <Notifications />
  </div>
</template>

<script>
import Confirm from '@/modules/notifications/components/Confirm.vue';
import Notifications from '@/modules/notifications/components/Notifications.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import { mapState } from 'vuex';

export default {
  name: 'EmptyLayout',
  components: {
    Confirm,
    ErrorPage,
    Notifications,
  },
  computed: {
    ...mapState(['errors']),
    /**
     * Returns true if there's an application error that should be shown
     *
     * @returns {boolean}
     */
    applicationError() {
      return this.errors.length > 0;
    },
  },
};
</script>

<style lang="scss">

</style>
