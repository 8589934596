import jwtDecode from 'jwt-decode';
import resolveDomain from '../resolveDomain';
import client from './client';

/**
 * The number of minutes before a token's expiry time at which it should be refreshed
 * anyway.
 *
 * @type {number}
 */
const tokenExpiryMargin = 10;

export default {
  /**
   * Set the user's authorization token (JWT) to session storage
   *
   * @param {Token} user
   */
  setOAuthUser(user) {
    window.localStorage.setItem('access_token', user.accessToken || null);

    // Store the refresh token in the shorter lived session storage, to make the experience smoother
    // for long-lived tabs
    window.sessionStorage.setItem('refresh_token', user.refreshToken || null);
  },

  /**
   * Gets the user's authorization token (JWT) for authorizing API calls
   *
   * @returns {string|null}
   */
  getToken() {
    return window.localStorage.getItem('access_token') || null;
  },

  async attemptRefresh() {
    const refreshToken = window.sessionStorage.getItem('refresh_token');
    if (!refreshToken) {
      return false;
    }

    const token = client.createToken({
      access_token: '',
      refresh_token: refreshToken,
    });

    try {
      const newToken = await token.refresh();
      if (newToken) {
        this.setOAuthUser(newToken);
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },

  getUserId() {
    const claims = this.getTokenClaims();

    if (!claims) {
      return null;
    }

    return parseInt(claims.sub, 10);
  },

  /**
   * Determines whether the token is valid (i.e. not expired) and that it will not
   * expire within a defined amount of time, e.g. 30 minutes.
   */
  isTokenValid() {
    if (!this.getToken()) {
      return false;
    }

    const tokenExpires = this.getTokenClaims().exp * 1000; // use milliseconds for JS Date API
    const margin = tokenExpiryMargin * 60 * 1000; // minutes to seconds to milliseconds

    // If token expiry time is more than now plus 10 minutes, then it's valid
    return tokenExpires >= (Date.now() + margin);
  },

  /**
   * Clear any session storage values. Example use: when OAuth flow fails due to
   * stale data.
   */
  clear() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  },

  /**
   * Logs the user out then redirects them to the auth service to log out there as well
   */
  logout() {
    this.clear();
    window.location.href = `${resolveDomain('authorize')}/logout`;
  },

  /**
   * Gets the claims from the user's authorization token (JWT)
   *
   * @returns {object|null}
   */
  getTokenClaims() {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    return jwtDecode(token);
  },
};
