import { contextMatcher, isCurrentContext } from './helpers';

// See documentation on each individual mutation implementation below.
const mutations = {
  ADD_ADDITIONAL_ITEM: 'ADD_ADDITIONAL_ITEM',
  ADD_ITEM: 'ADD_ITEM',
  ADD_ITEM_PAGE: 'ADD_ITEM_PAGE',
  CLEAR_CURRENT_PAGE: 'CLEAR_CURRENT_PAGE',
  COMPLETE_SEARCH: 'COMPLETE_SEARCH',
  CREATE_LOCK: 'CREATE_LOCK',
  EMPTY_CONTEXT: 'EMPTY_CONTEXT',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REPLACE_ITEM: 'REPLACE_ITEM',
  SEARCH: 'SEARCH',
  SET_CONTEXT: 'SET_CONTEXT',
  SET_LOADING: 'SET_LOADING',
  SET_PAGE: 'SET_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
};

export default mutations;

export const provideImplementations = ({ idColumn }) => {
  /**
   * Refreshes the current page in state, after determining whether it should be refreshed
   *
   * @param state {Object} The Vuex state
   * @param providedContext {Object} The context that was provided to the mutation (may differ from
   *        the current context in state)
   * @param affectedIndex {Number} The relevant index where an item was added, updated, or deleted
   */
  const refreshPage = (state, providedContext, affectedIndex) => {
    if (!isCurrentContext(state, providedContext)) {
      return;
    }

    const {
      byContext,
      context,
      page,
      pageSize,
    } = state;

    if (typeof page !== 'number' || page < 0) {
      return;
    }

    const pageStartIndex = pageSize * (page - 1);

    // Check if the affected index is outside of the page bounds
    if (affectedIndex < pageStartIndex || affectedIndex >= (pageStartIndex + pageSize)) {
      return;
    }

    // We have to reset search state as we can't trigger a dispatch from a mutation. We have to rely
    // on the action that's performing this mutation to re-apply the search afterwards if it needs
    // to be kept.
    state.currentFilters = [];
    state.currentSearchTerm = '';

    state.items = byContext.find(contextMatcher(context)).items.slice(
      pageStartIndex,
      pageStartIndex + pageSize,
    );
  };

  /**
   * Replace or remove an item in state. This covers the implementation of DELETE_ITEM
   * and UPDATE_ITEM.
   *
   * @param state {Object} The Vuex state
   * @param context {Object} The context that was provided to the mutation (may differ from the
   *        current context in state)
   * @param atIndex {Number} The index of the item that should be removed or replaced. Required if
   *        `item` is not provided.
   * @param item {Object} The existing item - can be used in place of `atIndex`.
   * @param newItem {Object} The new item (if replacing)
   */
  const replaceRemoveItem = (state, context, atIndex, item, newItem = null) => {
    const { byContext } = state;

    // Quick function to encapsulate handling the item existing in "additional items" (no page has
    // been loaded)
    const handleAdditionalItems = () => {
      const additionalItemIndex = state.additionalItems.findIndex(
        (candidate) => candidate[idColumn] === item[idColumn],
      );
      if (additionalItemIndex < 0) {
        return;
      }
      state.additionalItems.splice(additionalItemIndex, 1);
      if (newItem) {
        state.additionalItems.push(newItem);
      }
    };

    // Find where our contextual data is for the given context
    const contextIndex = byContext.findIndex(contextMatcher(context));
    if (contextIndex < 0) {
      handleAdditionalItems();
      return;
    }

    // Find the index of the item that's being replaced (or exit if it's not found)
    const contextItems = byContext[contextIndex].items;
    const itemIndex = atIndex >= 0 ? atIndex : contextItems.findIndex(
      (candidate) => candidate[idColumn] === item[idColumn],
    );

    if (itemIndex < 0) {
      handleAdditionalItems();
      return;
    }

    // Replace the item
    byContext[contextIndex].items = [
      ...contextItems.slice(0, itemIndex),
      ...(newItem ? [newItem] : []),
      ...contextItems.slice(itemIndex + 1),
    ];

    refreshPage(state, context, itemIndex);
  };

  return {
    /**
     * Sets the context of the pagination - used to fill params of the configured API endpoint
     *
     * @param state {Object} The existing VueX state
     * @param context {Object} The provided context to set
     */
    [mutations.SET_CONTEXT](state, context = {}) {
      // Skip doing anything if the context hasn't changed
      if (isCurrentContext(state, {
        ...state.context,
        ...context,
      })) {
        return;
      }

      state.context = Object.freeze({
        ...state.context,
        ...context,
      });

      state.items = [];
      state.page = null;
      const currentContext = state.byContext.find(contextMatcher(context));
      state.totalCount = currentContext ? currentContext.count : null;

      // Reset attributes related to searching
      state.currentSearchTerm = '';
      state.isSearching = false;
    },
    /**
     * Sets the loading state
     *
     * @param {object} state
     * @param {boolean} context
     */
    [mutations.SET_LOADING](state, context) {
      state.isLoading = context;
    },
    /**
     * Add a page of items to the stored items
     *
     * @param state {Object} The existing VueX state
     * @param payload {Object} The provided payload of the mutation
     * @param payload.items {Array<Object>} A full page of items to add to state
     * @param payload.page {Number} The page number that these items are shown on
     * @param payload.context {Object} The context this page applies to
     * @param payload.pageSize {Object} The page size that was specified when this page was fetched
     */
    [mutations.ADD_ITEM_PAGE](state, {
      items,
      page,
      context,
      pageSize,
    }) {
      // This mutation has to merge records, or create empty records between pages if there should
      // be gaps (ie loading pages 1 and 4 would make 2 pages of empty values).
      const { byContext } = state;

      // Handle contextual pagination
      let stateSectionIndex = byContext.findIndex(contextMatcher(context));
      let stateSection = { count: null, items: [], context };

      // Deal with this being the first time we've seen this context
      if (stateSectionIndex < 0) {
        stateSectionIndex = byContext.length;
      } else {
        stateSection = byContext[stateSectionIndex];
      }

      const existingItems = stateSection.items;

      // If it's the first page and we already have the first page (or less items than should be
      // on the first page), then we can just replace the items
      if (page === 1 && stateSection.length <= items.length) {
        state.byContext[stateSectionIndex] = {
          ...stateSection,
          items,
        };
        return;
      }
      // Otherwise, if we have the first page, we can shift the first page worth of items, and
      // unshift on the new items
      if (page === 1) {
        state.byContext[stateSectionIndex] = {
          ...stateSection,
          items: [...items, ...existingItems.slice(items.length)],
        };
        return;
      }
      // Now we'll have to insert our page somewhere...
      const insertOffset = pageSize * (page - 1);
      // When our insert offset is beyond the number of records we already have we need to fill in
      // the gap with empty records
      if (insertOffset > existingItems.length) {
        state.byContext[stateSectionIndex] = {
          ...stateSection,
          items: [
            ...existingItems,
            ...(new Array(insertOffset - existingItems.length)),
            ...items,
          ],
        };
        return;
      }

      // Otherwise, we can just splice in the new page
      stateSection.items = [
        ...existingItems.slice(0, insertOffset),
        ...items,
        ...existingItems.slice(insertOffset + items.length),
      ];
      state.byContext[stateSectionIndex] = stateSection;
    },
    /**
     * Set the total count of items
     *
     * @param state {Object} The existing VueX state
     * @param payload {Object} The provided payload of the mutation
     * @param payload.count {Number} The new total count of all items
     * @param payload.context {Object} The context that this total count applies to
     */
    [mutations.SET_TOTAL_COUNT](state, { count, context }) {
      if (isCurrentContext(state, context)) {
        state.totalCount = count;
      }

      const contextualDataIndex = state.byContext.findIndex(contextMatcher(context));
      if (contextualDataIndex < 0) {
        state.byContext.push({
          context,
          count,
        });
        return;
      }

      state.byContext[contextualDataIndex].count = count;
    },
    /**
     * Set the current page (number) that should be shown, and the items that should show on that
     * page
     *
     * @param {Object} state The existing VueX state
     * @param {Number} pageNumber The page number that should be switched to
     * @param {Array} pageItems The items on the new page
     */
    [mutations.SET_PAGE](state, { pageNumber, pageItems }) {
      state.items = pageItems;
      state.page = pageNumber;
    },
    /**
     * Set the page size that should be shown. Note that it's not intented for this to be used
     * directly as it can cause unpredictable behaviour when there are already fetches in progress.
     * There's an action for this that should be used instead
     *
     * @param state {Object} The existing VueX state
     * @param size {Number} The new page size
     */
    [mutations.SET_PAGE_SIZE](state, size) {
      state.pageSize = size;
    },
    /**
     * Add an item that was fetched outside of pagination - if an individual item was loaded
     *
     * @param state {Object} The existing VueX state
     * @param item {Object} The loaded "loose" item
     */
    [mutations.ADD_ADDITIONAL_ITEM](state, item) {
      state.additionalItems.push(item);
    },
    /**
     * Replace an item in state with the given item for the given context.
     *
     * @param state {Object} The existing VueX state
     * @param payload {Object} The provided payload of the mutation
     * @param payload.atIndex {Number} An index of an item that the given item should replace
     * @param payload.item {Object} The new item to replace the existing one with (if `atIndex` is
     *        not provided)
     * @param payload.context {Object} The context that this mutation should apply to
     */
    [mutations.REPLACE_ITEM](state, {
      atIndex,
      context,
      item,
    }) {
      replaceRemoveItem(state, context, atIndex, item, item);
    },
    /**
     * Add an item to state, assuming a location in the overall state (meaning it can be added to
     * existing pages)
     *
     * @param state {Object} The existing VueX state
     * @param payload {Object} The provided payload of the mutation
     * @param payload.item {Object} The new item to add
     * @param payload.prepend {Boolean} Whether this new item should be prepended to the list
     * @param payload.atIndex {Number} An optional index to insert this item at
     * @param payload.context {Object} The context that this item should be added to
     */
    [mutations.ADD_ITEM](state, {
      context,
      item,
      prepend = false,
      atIndex,
    }) {
      const { byContext } = state;

      const contextualState = byContext.find(contextMatcher(context));

      // Handle the first time we've seen this context
      if (!contextualState) {
        byContext.push({
          context,
          items: [item],
        });
        refreshPage(state, context, 0);
        return;
      }

      // Handle appending and no provided index (or the provided index being on the end)
      if ((!prepend && atIndex === undefined) || atIndex === contextualState.items.length) {
        contextualState.items.push(item);
        refreshPage(state, context, contextualState.items.length);
        return;
      }

      const newIndex = atIndex || (prepend ? 0 : contextualState.items.length);

      // Handle prepending (or specifically 0 index)
      if (newIndex === 0) {
        contextualState.items.unshift(item);
        refreshPage(state, context, 0);
        return;
      }

      // Handle the provided index being bigger than our array
      if (newIndex > contextualState.items.length) {
        contextualState.items = [
          ...contextualState.items,
          ...(new Array(newIndex - contextualState.items.length)),
          item,
        ];
        refreshPage(state, context, newIndex);
        return;
      }

      contextualState.items = [
        ...contextualState.items.slice(0, newIndex),
        item,
        ...contextualState.items.slice(newIndex),
      ];
      refreshPage(state, context, newIndex);
    },
    /**
     * Remove an item in state for the given context.
     *
     * @param state {Object} The existing VueX state
     * @param payload {Object} The provided payload of the mutation
     * @param payload.atIndex {Number} An index of an item that the given item should replace
     * @param payload.item {Object} The item to delete (if `atIndex` is not provided)
     * @param payload.context {Object} The context that this mutation should apply to
     */
    [mutations.REMOVE_ITEM](state, { atIndex, context, item }) {
      replaceRemoveItem(state, context, atIndex, item);
    },
    /**
     * Remove all state for a given context
     *
     * @param state {Object} The existing VueX state
     * @param context {Object} The context to clear
     */
    [mutations.EMPTY_CONTEXT](state, context) {
      const { byContext } = state;
      state.byContext[byContext.findIndex(contextMatcher(context))] = {
        context,
        items: [],
        count: null,
      };
      if (isCurrentContext(state, context)) {
        state.items = [];
      }
    },
    /**
     * Update state related to searching an existing list of items
     *
     * @param {Object} state The existing VueX state
     * @param {String} term The new search term being used
     * @param {Array} filters The new search term being used
     */
    [mutations.SEARCH](state, { term, filters }) {
      const resolvedTerm = term === undefined ? state.currentSearchTerm : term;
      const resolvedFilters = filters === undefined ? state.currentFilters : filters;

      if (!Array.isArray(resolvedFilters)) {
        throw Error('Provided filters for searching must be an array');
      }

      state.page = 1;
      state.currentSearchTerm = resolvedTerm;
      state.currentFilters = resolvedFilters;

      if (resolvedTerm.trim().length !== 0 || resolvedFilters.length > 0) {
        state.isSearching = true;
      }
    },
    /**
     * Complete a search action and update the current page of items
     *
     * @param {Object} state The existing VueX state
     * @param {Array|{ items: Array, total: Number }} payload Either the items that were matched, or
     *        the matched items and the total number of matches as a keyed object
     */
    [mutations.COMPLETE_SEARCH](state, payload) {
      state.isSearching = false;

      // Support the payload just being the matched items
      if (Array.isArray(payload)) {
        state.items = payload;
        return;
      }

      // Otherwise expect a keyed object
      const { items, total } = payload;

      state.items = Array.isArray(items) ? items : [];

      if (typeof total === 'number') {
        state.totalCount = total;
      }
    },
    /**
     * Manages a "promise stack" that allows actions to chain on the end of existing running actions
     * if they want to ensure they don't run at the same time as other actions. The `unlockPromise`
     * is the new promise to add to the stack, after which the lock will be completed (and
     * "unlocked")
     *
     * @param {Object} state VueX state
     * @param {Promise} unlockPromise
     */
    [mutations.CREATE_LOCK](state, unlockPromise) {
      if (!(unlockPromise instanceof Promise)) {
        return;
      }

      // Queue the given promise after any existing one.
      state.lock = state.lock.then(() => unlockPromise);
    },
    /**
     * Removes the current page of items and clears the current page number
     *
     * @param {Object} state VueX state
     */
    [mutations.CLEAR_CURRENT_PAGE](state) {
      state.page = null;
      state.items = [];
    },
  };
};
