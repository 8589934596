import extraFields from './extraFields';
import gateways from './gateways';
import attributes from './attributes';
import tax from './tax';

export default {
  namespaced: true,
  modules: {
    extraFields,
    gateways,
    attributes,
    tax,
  },
};
