<template>
  <ErrorContent :title="$t('error.notFoundTitle')">
    <p>{{ $t('error.notFoundSuggestion') }}</p>
    <template #graphic>
      <NotFoundGraphic />
    </template>
  </ErrorContent>
</template>

<script>
import NotFoundGraphic from '../../../public/img/404.svg';
import ErrorContent from '../../components/ErrorContent.vue';

export default {
  name: 'NotFoundPage',
  components: {
    NotFoundGraphic,
    ErrorContent,
  },
};
</script>
