import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import VueGtm from 'vue-gtm';
import VueRouter from 'vue-router';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueLoadScript from 'vue-plugin-load-script';
import { ChecTooltip } from '@chec/ui-library';
import { configureSentry } from '@/lib/sentry';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import LayoutPlugin from './plugins/LayoutPlugin';
import DefaultLayout from './layouts/DefaultLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';
import LoadingLayout from './layouts/LoadingLayout.vue';
import './assets/styles/tailwind.css';
import router from './router';
import i18n from './lang';
import intercomPanelController from './mixins/intercomPanelController';

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
});

if (process.env.VUE_APP_INTERCOM_APP_ID) {
  Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
}

Vue.use(VueRouter);
Vue.use(VueLoadScript);

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    enabled: true,
    debug: process.env.VUE_APP_ENVIRONMENT === 'local'
      || process.env.VUE_APP_ENVIRONMENT === 'staging',
    vueRouter: router,
  });
}

Vue.use(LayoutPlugin, {
  layouts: [
    [
      'default-layout',
      DefaultLayout,
    ],
    [
      'empty-layout',
      EmptyLayout,
    ],
    [
      'loading-layout',
      LoadingLayout,
    ],
  ],
});

// https://vuejs.org/v2/guide/filters.html
Vue.filter('capitalize', (value) => {
  if (!value) {
    return '';
  }
  const stringValue = value.toString();
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
});

// Add tooltips from the UI library
Vue.directive('tooltip', ChecTooltip.VTooltip);
Vue.directive('close-popover', ChecTooltip.VClosePopover);
Vue.component('VPopover', ChecTooltip.VPopover);

configureSentry(Vue);

new Vue({
  name: 'Vue',
  i18n,
  router,
  store,
  intercomPanelController,
  render: (h) => h(App),
}).$mount('#app');
