import Vue from 'vue';
import VueI18n from 'vue-i18n';
import merge from 'lodash.merge';
import preferredLocale from 'preferred-locale';
import { lang } from '@chec/ui-library';
import langs from './langs';

// Define/export supported language dictionaries here, merging in Lang from
// the UI library dictionaries too.
const messages = merge(lang.messages, langs);
const defaultLocale = preferredLocale(Object.keys(messages), 'en', {
  regionLowerCase: true,
  languageOnly: true,
});

Vue.use(VueI18n);

const i18n = new VueI18n({
  // Use a localstorage setting, but fallback to a locale sniffed from the browser
  locale: window.localStorage.getItem('chec_dashboard_language') || defaultLocale,
  fallbackLocale: 'en',
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
});
window.i18n = i18n; // todo use the Vuex store instead

export default i18n;
