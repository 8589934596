export default [
  {
    path: '/categories',
    component: () => import(/* webpackChunkName: "categories" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('category.categories'),
    },
    children: [
      {
        path: '',
        name: 'categories.home',
        component: () => import(/* webpackChunkName: "categories" */ './views/home.vue'),
      },
      {
        path: 'add',
        name: 'categories.add',
        component: () => import(/* webpackChunkName: "categories" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('category.add'),
        },
      },
      {
        path: ':id(cat_[^/]+)',
        name: 'categories.edit',
        component: () => import(/* webpackChunkName: "categories" */ './views/add-edit.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('category.edit'),
        },
        children: [
          {
            path: 'add/sub-category',
            name: 'categories.edit.subCategory.add',
            component: () => (
              import(/* webpackChunkName: "categories" */ './views/add-sub-category.vue')
            ),
            meta: {
              showBack: true,
              title: (i18n) => i18n.t('category.addSubCategory'),
            },
          },
        ],
      },
    ],
  },
];
