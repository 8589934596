const routes = [
  {
    path: '/wizard',
    name: 'signup',
    component: () => import(/* webpackChunkName: "merchants" */ './views/signup.vue'),
    meta: {
      merchantless: true,
      title: (i18n) => i18n.t('signup.page.title'),
      fullscreenPage: true,
      hideNav: true,
      hideFooter: true,
    },
  },
  {
    path: '/merchants',
    name: 'merchant.list',
    component: () => import(/* webpackChunkName: "merchants" */ './views/list.vue'),
    meta: {
      merchantless: true,
      title: (i18n) => i18n.t('merchant.selectMerchant'),
      fullscreenPage: true,
      hideNav: true,
      hideFooter: true,
    },
  },
  {
    path: '/merchants/new',
    name: 'merchant.new',
    component: () => import(/* webpackChunkName: "merchants" */ './views/signup.vue'),
    meta: {
      merchantless: true,
      title: (i18n) => i18n.t('merchant.createMerchant'),
      fullscreenPage: true,
      hideNav: true,
      hideFooter: true,
    },
  },
];

export default routes;
