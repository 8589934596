export default [
  {
    path: '/orders',
    component: () => import(/* webpackChunkName: "orders" */ './views/index.vue'),
    meta: {
      title: (i18n) => i18n.t('order.orders'),
    },
    children: [
      {
        path: '/',
        name: 'orders.home',
        component: () => import(/* webpackChunkName: "orders" */ './views/home.vue'),
        meta: {
          fullscreenPage: true,
        },
      },
      {
        path: ':productId(d+)',
        name: 'orders.forproduct',
        component: () => import(/* webpackChunkName: "orders" */ './views/home.vue'),
        meta: {
          fullscreenPage: true,
        },
      },
      {
        path: ':id(ord_[^/]+)',
        name: 'orders.view',
        component: () => import(/* webpackChunkName: "orders" */ './views/view.vue'),
        exact: true,
        meta: {
          title: (i18n) => i18n.t('order.viewOne'),
          showBack: true,
        },
        children: [
          {
            path: 'edit/customer',
            name: 'orders.edit.customer',
            component: () => import(/* webpackChunkName: "orders" */ './views/edit/customer.vue'),
            exact: true,
          },
          {
            path: 'edit/shipping',
            name: 'orders.edit.shipping',
            component: () => import(/* webpackChunkName: "orders" */ './views/edit/shipping.vue'),
          },
          {
            path: 'edit/billing',
            name: 'orders.edit.billing',
            component: () => import(/* webpackChunkName: "orders" */ './views/edit/billing.vue'),
          },
          {
            path: 'fulfill/items',
            name: 'orders.fulfill.items',
            component: () => import(/* webpackChunkName: "orders" */ './views/fulfill/items.vue'),
          },
        ],
      },
    ],
  },
];
