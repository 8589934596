<template>
  <ErrorContent :title="$t('error.serverErrorTitle')">
    <p>{{ $t('error.serverErrorSuggestion') }}</p>
    <ErrorCode />
    <template #graphic>
      <ServerErrorGraphic />
    </template>
  </ErrorContent>
</template>

<script>
import ServerErrorGraphic from '../../../public/img/500.svg';
import ErrorContent from '../../components/ErrorContent.vue';
import ErrorCode from './ErrorCode.vue';

export default {
  name: 'ServerErrorPage',
  components: {
    ErrorCode,
    ServerErrorGraphic,
    ErrorContent,
  },
};
</script>
