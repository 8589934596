import { createPaginationModule } from '@/lib/pagination';
import { makeApiRequest } from '@/lib/api';
import paginationActions from '@/lib/pagination/actions';
import globalMutations from '@/store/mutations';

export const actions = {
  BULK_ASSIGN_SHIPPING: 'BULK_ASSIGN_SHIPPING',
};

const {
  SET_ERROR,
} = globalMutations;

export default {
  namespaced: true,
  modules: {
    zones: createPaginationModule('/v1/fulfillment/physical/zones?sortBy=name&sortDirection=ASC', {
      individualItemEndpoint: '/v1/fulfillment/physical/zones',
      initialPageSize: 200,
      // Deal with inconsistencies in the way the API gives subdivisions with zones
      itemResolver(response, expectArray = false) {
        // Go through zones and refactor the subdivisions for each
        const zones = (expectArray ? response.data : [response])
          .map(({ subdivisions, ...zone }) => ({
            ...zone,
            // Don't bother refactoring if there aren't countries or regions are invalid
            subdivisions: !zone?.countries?.length || !subdivisions
              ? {}
              // Normalise subdivisions given some differing formats that we can see
              : Object.entries(subdivisions).reduce((acc, [key, value]) => {
                if (value === null) {
                  return null;
                }

                // The subdivisions are already an array
                if (Array.isArray(value)) {
                  return {
                    ...acc,
                    [key]: value,
                  };
                }

                // Subdivisions can be given as an object with a divisions key
                if (
                  typeof value === 'object'
                  && Object.hasOwnProperty.call(value, 'divisions')
                ) {
                  return {
                    ...acc,
                    [key]: Object.keys(value.divisions),
                  };
                }

                // We don't know, just assume an empty array
                return {
                  ...acc,
                  [key]: [],
                };
              }, {}),
          }));

        if (!expectArray) {
          return zones[0] || null;
        }

        return zones;
      },
      // Extract subdivisions from zone and pass in all zones attributes
      prepItemForPost({ subdivisions, ...zone }) {
        return {
          ...zone,
          // Structure countries object with subdivisions included
          // as per request data structure for POST request
          countries: zone.countries.map((countryCode) => ({
            code: countryCode,
            subdivisions: subdivisions[countryCode],
          })),
        };
      },
    }),
    rates: createPaginationModule('/v1/fulfillment/physical/zones/:zone/rates', {
      initialPageSize: 200,
    }),
  },
  state: {
    error: null,
  },
  mutations: {
    [SET_ERROR](state, error) {
      state.error = error;
    },
  },
  actions: {
    loadPhysicalOptions({ dispatch }) {
      return dispatch(`zones/${paginationActions.SET_PAGE}`, 1);
    },
    reloadPhysicalOptions({ dispatch }) {
      return dispatch(`zones/${paginationActions.RESET}`);
    },
    /**
     * Request PUT zone products
     *
     * Attempts to bulk add products for shipping zone
     */
    [actions.BULK_ASSIGN_SHIPPING]({ commit }, data) {
      const payload = {};
      if (data.products && data.products.length) {
        payload.ids = data.products;
      }
      return makeApiRequest('PUT', `/v1/fulfillment/physical/zones/${data.zoneId}/products`, payload)
        .catch((error) => commit(SET_ERROR, error));
    },
  },
};
