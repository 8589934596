export default {
  account: {
    account: "Account",
    currentPassword: "Password attuale",
    dashboardLanguage: "Lingua della dashboard",
    deleteAccount: "Cancella profilo",
    deleteCallout: "Stai per eliminare definitivamente il tuo profilo.",
    deleteFailed: "Impossibile eliminare il tuo account! Riprovare più tardi.",
    deleteMessage: "Leliminazione del tuo profilo è definitiva e non può essere annullata. Assicurati di aver esportato tutti i tuoi ordini.",
    deleteMyAccount: "Cancella il mio profilo",
    details: "Dettagli del profilo",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "Informazioni del profilo",
    language: "Lingua",
    logout: "Esci",
    missingPasswords: "Si prega di inserire la password attuale e quella nuova.",
    newPassword: "Nuova password",
    passwordUpdateFailed: "Impossibile aggiornare la tua password. Per favore riprovare.",
    passwordUpdated: "Password aggiornata con successo.",
    updateDetails: "Aggiorna i dettagli",
    updateFailed: "Impossibile salvare le informazioni sul tuo profilo. Per favore riprovare.",
    updateLanguage: "Aggiorna la lingua",
    updatePassword: "Aggiorna password",
    updatedDetailsSuccessfully: "Details updated successfully.",
    updatedLanguageSuccessfully: "Language updated successfully."
  },
  address: {
    city: "Città",
    country: "Paese",
    line1: "Indirizzo",
    line2: "Indirizzo (riga 2)",
    name: "Nome",
    state: "Provincia",
    zipCode: "Codice postale"
  },
  attribute: {
    attributes: "Attributes"
  },
  attributes: {
    add: "Add attribute",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Attributes",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Default value",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "Field type is required",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Something went wrong. Please try logging in again.",
    unableToAuth: "Unable to authorize your account. Please try again."
  },
  billing: {
    accruedFees: "Commissioni di transazione",
    addCard: "Aggiungi carta",
    addPaymentMethod: "Aggiungi un metodo di pagamento",
    billing: "Intestazione",
    changePlan: "Cambia il piano",
    checFee: "Chec Tasse ({currency})",
    choosePlan: "Scegli un piano",
    confirmChangePlan: "If the plan you're changing to has restrictions on platform features (such as Commerce Rays), your resources will automatically be scaled back to fit your new plan's limits.",
    currentPlan: "Piano attuale",
    customPricing: "Prezzo personalizzato",
    emailAddress: "Indirizzo email di fatturazione",
    invoiceAmount: "Totale",
    invoiceDate: "Data",
    invoiceDescription: "Descrizione",
    invoiceDownload: "Scarica",
    invoiceStatus: "Stato",
    invoices: "Fattura",
    monthlyCeiling: "{ceiling} massimale mensile",
    noPaymentMethod: "Nessun metodo di pagamento.",
    orderValue: "Valore dell'ordine ({currency})",
    overageFee: "{overage} tasse",
    paid: "Pagato",
    paymentGateway: "Gateway di pagamento",
    paymentMethod: "Metodo di pagamento",
    paymentMethodRequired: "Aggiungi un metodo di pagamento prima di scegliere nuovi piani",
    perMonth: "al mese",
    perTransaction: "Per transazione",
    planDetails: "Dettagli del piano",
    planMonthlyCeiling: "{ceiling} massimale mensile",
    planName: "{name} piano",
    planOverage: "{overage} piano",
    planSummary: "Ti verranno addebitati {per Month} al mese e {per Transaction} per ogni transazione",
    planTransactionFee: "{fee} costo della transazione",
    transactionFeeOrder: "Ordine",
    transactionFeesTitle: "Commissioni di transazione fatturate in data {date}",
    unpaid: "Non pagato",
    updateCard: "Aggiornare",
    updateEmail: "Aggiorna l'indirizzo email di fatturazione",
    viewInvoice: "Visualizza la fattura",
    voided: "Voided"
  },
  category: {
    add: "Aggiungi categoria",
    addSubCategory: "Add sub-category",
    added: "Categoria aggiunta.",
    addedSubCategory: "Added sub-category.",
    categories: "Categorie",
    chooseParentCategory: "Choose parent category",
    confirmDelete: "Sei sicuro di voler eliminare questa categoria?",
    confirmDeleteTitle: "Sei sicuro?",
    create: "Crea una categoria",
    createError: "Spiacenti, si sono verificati problemi durante la creazione di questa categoria.",
    deleteFailed: "Failed to delete your category! Please try again.",
    deleted: "Categoria eliminata",
    edit: "Modifica categoria",
    editMultiple: "Modifica categorie",
    images: {
      title: "Immagini",
      types: "| {types} accepted | {types} & {lastType} accepted"
    },
    introSplash: {
      description: "Create infinitely nestable product categories with category images and descriptions. {more}.",
      more: "Ulteriori informazioni",
      title: "Add categories and sub-categories."
    },
    noAvailableCategories: "Please add another category first",
    noSubCategories: "This category has no sub-categories.",
    permalink: "Permalink",
    pleaseEnterName: "Inserisci un nome",
    pleaseEnterPermalink: "Please enter a permalink",
    save: "Salva categoria",
    selectPlaceholder: "Scegli una categoria",
    subCategories: "Sotto-categorie",
    update: "Aggiorna categoria",
    updateError: "Spiacenti, abbiamo problemi a salvare questa categoria.",
    updateSubCategoryError: "Sorry, we're having problems saving this sub-category.",
    updated: "Categoria aggiornata."
  },
  customer: {
    add: "Aggiungere cliente",
    added: "Aggiunto cliente.",
    confirmDelete: "Sei sicuro di voler eliminare questo cliente?",
    create: "Crea cliente",
    createError: "Spiacenti, si sono verificati problemi durante la creazione di questo cliente.",
    customers: "Clienti",
    deleteFailed: "Failed to delete your customer! Please try again.",
    deleted: "Cliente eliminato.",
    edit: "Modificare cliente",
    emailTaken: "Questo indirizzo email è già in uso\n",
    externalId: "ID esterno",
    introSplash: {
      description: "Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.",
      title: "Your customers will be displayed here"
    },
    notesTitle: "Note",
    save: "Salva cliente",
    update: "Aggiorna cliente",
    updateError: "Spiacenti, abbiamo problemi a salvare questo cliente.",
    updated: "Cliente aggiornato."
  },
  developer: {
    apiKeys: "Chiavi API",
    apiKeysCors: "API keys & CORS",
    checCli: "Chec CLI",
    cliNotice: "Use the {cli} to stream logs in real time: {cliCommand}",
    copyCodeFailure: "Failed to copy the code to the clipboard. Please refresh the page and try again.",
    copyCodeSuccess: "The code has been copied to your clipboard.",
    corsDomainsHelp: "You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the \"Access-Control-Allow-Origin\" header. If you specify multiple, API calls will compare against the request's \"Origin\" header. If you leave this blank, all origins will be allowed (default).",
    crossOriginResourceSharing: "Cross-Origin Resource Sharing (CORS) origins",
    dateTime: "Data/ora",
    ipAddress: "Indirizzo IP",
    keyUsed: {
      label: "Key used",
      "public": "Pubblico",
      secret: "Segreto"
    },
    method: "Metodo",
    request: "Richiesta",
    requestMethod: {
      "delete": "Delete",
      get: "GET",
      label: "Metodo di richiesta",
      post: "POST",
      put: "PUT"
    },
    response: "Risposta:",
    responseDate: {
      label: "Response date"
    },
    responseStatus: {
      badRequest: "Richiesta Errata",
      label: "Response status",
      notFound: "Non trovato",
      ok: "Ok",
      other: "Other/server errors",
      requestFailed: "Richiesta non riuscita.",
      unauthorized: "Non autorizzato",
      validation: "VALIDAZIONE"
    },
    summary: "Riepilogo",
    url: "URL"
  },
  discount: {
    add: "Aggiungi uno sconto",
    added: "Aggiunto uno sconto",
    all: "Tutto",
    code: "Codice",
    codeRequired: "Richiesta di un codice di sconto",
    confirmDelete: "Sei sicuro di voler eliminare questo sconto?",
    create: "Crea uno sconto",
    deleteFailed: "Failed to delete your discount! Please try again.",
    deleted: "Sconto cancellato.",
    discountCode: "Codice di sconto",
    discountOffProduct: "{discount} sul {product}",
    discounts: "Sconti",
    edit: "Modifica sconto",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "Ulteriori informazioni",
      title: "Add a discount code and run a promotion."
    },
    neverExpires: "Mai",
    productTooltip: "If left empty, this discount will work for all products",
    productsLabel: "Limit to these products",
    saveError: "Spiacenti, abbiamo problemi a salvare lo sconto.",
    updated: "Sconto aggiornato.",
    valueRequired: "Inserisci il valore dello sconto"
  },
  error: {
    notFoundSuggestion: "Torna alla pagina da cui provieni e riprova.",
    notFoundTitle: "Non trovato...",
    permissionErrorSuggestion: "Prova a disconnetterti e ad accedere di nuovo. Se il problema persiste, contatta l'assistenza.",
    permissionErrorTitle: "Permesso negato!",
    serverErrorSuggestion: "prendi un caffè e riprova tra qualche minuto. Siamo stati informati anche di questo errore.",
    serverErrorTitle: "Qualcosa è andato storto...",
    somethingWentWrong: "Qualcosa è andato storto!"
  },
  extraFields: {
    add: "Add extra field",
    addOptions: "Hit enter or comma to add an option",
    collectsBilling: "Richiesta di un indirizzo di fatturazione",
    collectsFullName: "Richiesta di un nome completo",
    collectsShipping: "Richiesta di un indirizzo di spedizione",
    confirmDeleteExtraField: "Are you sure you want to delete this extra field? Storefronts that rely on this extra field may be affected.",
    deleteFailed: "Failed to delete your extra field! Please try again.",
    deleted: "Extra field deleted successfully.",
    description: "Raccogli informazioni personalizzate dal tuo cliente.",
    edit: "Edit extra field",
    extraFields: "Campi aggiuntivi",
    fieldType: "Tipo di campo",
    fieldTypeDescription: "Select the field type for your extra field. This is for frontend use.",
    introDescription: "Extra fields are custom merchant defined data fields that are globally available, usually in a checkout. An example might be a custom extra field for collecting customers' phone numbers in a checkout form.",
    introTitle: "Collect custom data from customers with global extra fields.",
    name: "Nome campi aggiuntivo",
    nameRequired: "nome è obbligatorio.",
    saveFailure: "Sorry, we're having a problem saving your extra field.",
    saveSuccess: "Your extra field has been saved successfully.",
    typeRequired: "Field type is required",
    updateSuccess: "Your extra field has been updated successfully."
  },
  gateways: {
    add: "Add payment gateway",
    braintree: "Braintree",
    braintreeDocumentation: "See the documentation",
    braintreeHelp: "Enter your Braintree merchant ID, public key, private key, and your tokenization key. {documentation} for more information on tokenization keys.",
    chooseGateway: "Please choose a gateway",
    confirmDelete: "Are you sure you want to delete this payment gateway? Any stores using it will stop accepting payments immediately from this gateway.",
    deleteFailed: "Something went wrong while trying to delete this payment gateway. Please try again.",
    deleted: "Payment gateway deleted.",
    edit: "Edit payment gateway: {gateway}",
    email: "Indirizzo email",
    gateway: "portale",
    introDescription: "A payment gateway authorizes credit card or direct payments processing for online businesses. The test gateway has been enabled for making test orders.",
    introTitle: "Start collecting payments by enabling your payment gateway(s).",
    keyId: "Key ID",
    keySecret: "Key secret",
    manual: "Manuale",
    manualConfig: {
      detailsHelp: "Details about this payment method - shown before placing an order.",
      name: "Name of payment method",
      paymentInstructions: "Payment instructions",
      paymentInstructionsHelp: "Details on how to pay - shown to customers after placing an order."
    },
    manualHelp: "You will manually have to record payments and fulfill orders placed using this method.",
    manualName: "Manuale: {name}",
    merchantId: "ID Commerciante",
    omise: "Omise",
    omiseDocumentation: "Omise documentation",
    omiseHelp: "Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.",
    paymentMethodHelper: "To add a live payment gateway, please add a payment method in the billing settings page.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "Chiave privata",
    publicKey: "Chiave pubblica",
    publishableKey: "Publishable key",
    razorpay: "Razorpay",
    redirectingToSquare: "Redirecting to Square",
    sandboxNotAvailable: "Sandbox mode is not available for this gateway.",
    saveFailed: "Failed to save your payment gateway, please check you have filled out all required fields.",
    saved: "Your payment gateway was successfully saved.",
    secretKey: "Chiave Segreta",
    square: "Square",
    squareHelp: "When you click 'Save changes' below, you will be redirected to Square to login to your payment account.",
    stripe: "Stripe",
    stripeDocumentation: "Stripe documentation.",
    stripeHelp: "Enter your publishable key and your secret key, both available from the Stripe Dashboard. Learn more in the {documentation}",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "Test gateway",
    tokenizationKey: "Tokenization key",
    transactionVolume: "Transaction volume",
    weSupport: "Gateways we support"
  },
  general: {
    about: "Informazioni",
    actions: "Azioni",
    active: "Attivo",
    add: "Aggiungi",
    adding: "Aggiunta in corso…",
    address: {
      city: "Città",
      country: "Paese",
      postalZip: "Codice Postale/Cap",
      provinceState: "Province/state",
      street: "Strada"
    },
    advanced: "Avanzate",
    all: "Tutto",
    amount: "Importo",
    amountValue: "Importo: {value}",
    andMore: " & {n} altro",
    areYouSure: "Sei si curo",
    assets: "Proprietà",
    auto: "Automatico",
    backTo: "Torna alla {page}",
    backToPreviousPage: "Torna alla pagina precedente",
    byAuthor: "by {author}",
    calculation: "Calcolo",
    cancel: "Cancella",
    cancelled: "Annullato",
    checkbox: "Casella di controllo",
    checked: "Checked",
    clearSearch: "Cancella le ricerche",
    clickToCopy: "Fare clic per copiare",
    close: "Chiudi",
    contactUs: "Contattaci",
    copied: "Copiato!",
    copy: "Copia",
    country: "Paese",
    creating: "Creazione in corso...",
    currency: "Valuta",
    date: "Data",
    dateTime: "Data/ora",
    "default": "Predefinito",
    "delete": "Cancella",
    description: "Descrizione",
    descriptionOptional: "Descrizione (facoltativo)",
    details: "Dettagli",
    digital: "Digitale",
    disable: "Disabilita",
    disabled: "Disabilitato",
    discount: "Sconto",
    draft: "Bozza",
    duplicate: "Duplica",
    edit: "correggere",
    emailAddress: "Indirizzo email",
    empty: "Vuoto",
    enable: "Attiva",
    enabled: "Abilitato",
    environment: "ambiente",
    error: "Sorry, there has been an error. Please try again.",
    expired: "Scaduto",
    expires: "Scade",
    "false": "falso",
    firstName: "Nome",
    "for": "Per",
    fulfill: "Adempiere",
    fulfilled: "Gestito",
    generate: "Crea",
    getStarted: "Inizia",
    goBack: "Indietro",
    health: "Health",
    hidden: "Nascosto",
    home: "Casa",
    hostedExperiences: "Hosted experiences",
    id: "id",
    images: "Immagini",
    inactive: "Inattivo",
    item: "Elemento",
    label: "Label",
    lastName: "Cognome",
    learnMore: "Ulteriori informazioni",
    learnMoreButton: "Ulteriori informazioni",
    live: "IN DIRETTA",
    loading: "Caricamento in corso...",
    loggedInAs: "Effettuato l'accesso come {name}",
    logo: "Logo",
    longText: "Long text",
    merchant: "Mercante",
    month: {
      april: "Aprile",
      august: "Agosto",
      december: "Dicembre",
      february: "Febbraio",
      january: "Gennaio",
      july: "Luglio",
      june: "Giugno",
      march: "Marzo",
      may: "Maggio",
      november: "Novembre",
      october: "Ottobre",
      september: "Settembre"
    },
    name: "Nome",
    never: "Mai",
    "new": "New",
    next: "Successivo",
    nextName: "Avanti: {name}",
    no: "No",
    noResults: "No results match your search",
    notAvailable: "N/A",
    notFulfilled: "Not fulfilled",
    notPaid: "Non pagato",
    notRequired: "Not required",
    notes: "Note",
    number: "numero",
    open: "Apri",
    option: "Opzione",
    options: "Opzioni",
    override: "Sostituisci",
    paid: "Pagato",
    parent: "Genitore",
    partiallyFulfilled: "Partially fulfilled",
    paymentGateway: "Gateway di pagamento",
    permalinkSlug: "Collegamento permanente",
    phoneNumber: "Numero di telefono",
    placed: "incaricato",
    previous: "Precedente",
    previousName: "Precedente: {name}",
    price: "Prezzo",
    "private": "Private",
    province: "Provincia",
    published: "Pubblicato",
    quantity: "Quantitativo",
    radio: "Radio",
    rate: "Valuta",
    rates: "Tariffe",
    reference: "Riferimento",
    refund: "Rimborso",
    refunded: "Rimborsato",
    refunding: "Sta rimborsando...",
    regenerate: "Rigenerare",
    region: "Regione",
    regions: "Regioni",
    remaining: "Rimanenza",
    required: "Necessario",
    sales: "Sconto",
    sandbox: "Sandbox",
    sandboxMode: "Modalità sandbox",
    save: "Salva",
    saveAndClose: "Salva e Chiudi",
    saveChanges: "Salvare le modifiche",
    saved: "Modifiche salvate!",
    saving: "Salvataggio...",
    search: "Ricerca",
    security: "Sicurezza",
    selectOption: "Seleziona un'opzione...",
    sending: "Invio in corso…",
    shareEmbed: "Condividi",
    shipping: "Spedizione",
    shippingMethod: "Shipping ({method})",
    shortText: "Short text",
    slug: "Slug",
    standard: "Normale",
    startDate: "Data di inizio",
    state: "Provincia",
    status: "Stato",
    subtotal: "Subtotale:",
    tax: "Tassa",
    template: "Modello",
    text: "Testo",
    time: "Tempo",
    timestamp: "Marcatura oraria",
    today: "Oggi",
    total: "Totale",
    totalPaid: "Totale pagato",
    "true": "Vero",
    type: "Genere",
    unchecked: "Unchecked",
    unfulfilled: "Unfulfilled",
    unlimited: "Unlimited",
    update: "Aggiornare",
    upgradePlan: "Aggiorna il tuo piano",
    url: "URL",
    value: "Value",
    view: "Visualizza",
    viewDetails: "Visualizza dettagli",
    visibility: "Visibility",
    visible: "Visible",
    whatIsThis: "Cosa significa questo?",
    xOfY: "{x} di {y}",
    yes: "Si",
    yesterday: "Ieri"
  },
  home: {
    addProducts: "Aggiungi i tuoi prodotti",
    configureShipping: "Configurare le impostazioni di spedizione",
    configureTax: "Configurare le impostazioni fiscali",
    createCommerceRay: "Crea il tuo primo Commerce Ray",
    enableGateway: "Impostare un gateway di pagamento",
    gettingStarted: "Guida introduttiva",
    gettingStartedRays: "Crea il tuo Commerce Ray",
    latestAnnouncements: "Ultimi annunci",
    latestChanges: "Ultime modifiche",
    messageUs: "Inviaci un messaggio",
    speakToAnExpert: "Parla con un esperto",
    speakToCopy: "Our commerce experts can make sure Commerce.js is the right fit for your business and help architect a solution.",
    title: "Benvenuto",
    viewChangelog: "Visualizza il changelog",
    welcomeSubtitle: "Welcome! We're here to help you get things rolling."
  },
  integration: {
    about: "Informazioni su: {integration}",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration added",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Configure integration",
    confirmDelete: "Are you sure you want to delete this integration?",
    createError: "Sorry, we're having problems activating this integration.",
    creating: "Creating integration...",
    deleteFailed: "Failed to delete your integration! Try again later.",
    deleted: "Integration deleted.",
    edit: "Edit integration",
    editNamed: "Edit integration - {name}",
    filter: {
      checkout: "Completa la transazione",
      content: "Contenuto",
      dataMesh: "Data mesh",
      email: "E-mail",
      frontend: "Frontend",
      fulfillment: "Fulfillment",
      orders: "Ordine",
      payments: "Pagamenti",
      personalization: "Personalizzazione",
      tax: "Tasse"
    },
    filterLabel: "Filtro",
    filterTagLabel: "Filtro: {tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "Integrazione",
    integrations: "Integrazioni",
    introSplash: {
      description: "Connect with other services and compose your desired business stack.",
      title: "Configure integrations with 3rd party providers."
    },
    lastRun: "Last run",
    noConfigNeeded: "This template requires no additional configuration",
    noLogo: "No logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "In salute",
      notApplicable: "N/A",
      unhealthy: "Insalubre",
      unstable: "Unstable"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API request logs with details will be listed here for ease of debugging.",
      title: "Your request logs will be shown here"
    },
    logs: "Logs"
  },
  merchant: {
    createFull: "Let's set up your merchant",
    createLeadIn: "Your information can be changed or updated at any time.",
    createMerchant: "Create merchant",
    seeAll: "View all merchants",
    selectMerchant: "Select a merchant"
  },
  notes: {
    add: "Aggiungi nota",
    addANote: "Aggiungi nota",
    confirmDelete: "Sei sicuro/a di voler eliminare questa nota?",
    note: "Nota"
  },
  order: {
    capturedOn: "Acquisito il:",
    cardEndingIn: "Le ultime cifre della carta sono {number}",
    changeCustomer: "Change customer information",
    confirmRefund: "Are you sure you want to refund this order?",
    customerDetails: "Dettagli cliente",
    customerUpdateFailed: "Spiacenti, abbiamo problemi con l'aggiornamento delle informazioni del cliente.",
    customerUpdated: "Updated customer information on order #{reference}",
    date: "Data dell'ordine",
    dateTime: "Data/ora",
    deleteTest: "Delete test orders",
    deleteTestConfirmation: "Are you sure you want to delete all of your sandbox orders?",
    edit: "Modifica l'ordine",
    "export": "Export orders",
    exportConfirmation: "We will send a copy of your orders (with the current filters) in CSV format to your merchant email when it's ready.",
    fulfillItems: "Fulfill items",
    fulfillment: {
      confirmCopy: "Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?",
      confirmHeader: "Please confirm your fulfillment changes",
      dateShipped: "Date shipped: {date}",
      reference: "Riferimento: {reference}",
      shipmentNumber: "Numero di spedizione {number}",
      shippingCarrier: "Shipping carrier",
      trackingNotProvided: "Tracking not provided",
      trackingNumber: "Tracciamento # {number}",
      trackingNumberOptional: "Tracking number (optional)"
    },
    fulfillmentStatus: {
      fulfilled: "Completato",
      label: "Fulfillment status",
      not_fulfilled: "Not fulfilled",
      partially_fulfilled: "Partially fulfilled",
      returned: "Returned"
    },
    gatewayTransactionId: "Gateway transazione ID: {id}",
    itemFulfilled: "Items fulfilled successfully.",
    newNotification: "New order {reference} for {value}!",
    nextOrder: "Ordine successivo",
    noOrders: {
      copy: "We'll then show you orders and sales displayed over time.",
      title: "Make your first sale!"
    },
    orderNotes: "Order notes",
    orderNumber: "Ordine",
    orderStatus: "Stato dell'ordine",
    orders: "Ordine",
    ordersAllTime: "Orders - All time",
    ordersOnMonth: "Ordini - {month}",
    ordersOnMonthHelptip: "Number of orders since the start of this month, compared with the same period in the previous month",
    overview: "Panoramica",
    paymentMethod: "Metodo di pagamento",
    paymentSaveFailed: "Sorry, we're having problems saving this payment.",
    paymentSaved: "Payment saved successfully.",
    paymentStatus: {
      label: "Stato del pagamento",
      not_paid: "Non pagato",
      paid: "Pagato",
      partially_paid: "Partially paid",
      pending: "Pagamento in sospeso",
      refunded: "Rimborsato"
    },
    previousOrder: "Ordine precedente",
    receiptSendFailed: "Unable to resend the receipt, please try again.",
    receiptSent: "Receipt sent.",
    recordManualPayment: "Record manual payment",
    reference: "Riferimento: {reference}",
    refundFailed: "Unable to refund the order, please try again.",
    refundSuccess: "Order refunded successfully.",
    resendReceipt: "Invia di nuovo ricevuta",
    salesAllTime: "Sales - All time",
    salesOnMonth: "Vendite - {month}",
    salesOnMonthHelptip: "Sales performance since the start of this month, compared with the same period in the previous month",
    sandbox: "Ordine di prova",
    shipments: "Spedizioni",
    status: "Stato",
    testOrdersDeleted: "Test orders successfully deleted.",
    transactionReferenceId: "Transaction/reference ID",
    updateAddressError: "Something went wrong while saving the address, please try again.",
    updatedBilling: "Updated billing address.",
    updatedShipping: "Updated shipping address.",
    viewMultiple: "Visualizza gli ordini",
    viewOne: "Visualizza ordine"
  },
  product: {
    add: "Aggiungi prodotto",
    added: "Prodotto aggiunto",
    additionalActions: "Additional product actions",
    basePrice: "Prezzo base",
    chooseRelatedProducts: "Choose related products",
    confirmDelete: "Sei sicuro di voler eliminare questo prodotto?",
    confirmDeleteTitle: "Sei sicuro?",
    copyPaste: "Copia e incolla questo codice nel tuo sito web.",
    count: "Nessun prodotto | 1 prodotto | {n} prodotti",
    customPermalink: "Custom permalink",
    "delete": "Elimina prodotto",
    deleteFailed: "Impossibile eliminare il tuo prodotto! Riprovare più tardi.",
    deleted: "Prodotto eliminato.",
    description: "Descrizione",
    digitalFulfillment: {
      doesNotExpire: "Links do not expire",
      downloadLimit: "Limite di download",
      duration: "Durata",
      durationPeriod: "Duration period",
      expiryMessage: "After {duration} {period} of purchase",
      linkExpiration: "Link expiration",
      period: {
        days: "days | day | days",
        months: "months | month | months",
        weeks: "weeks | week | weeks"
      },
      title: "Digital delivery"
    },
    duplicateProduct: "Duplica prodotto",
    duplicated: "Product successfully duplicated and you are now editing the duplicate.",
    edit: "Modifica prodotto",
    embed: "Incorpora",
    embedCheckout: "Incorporare il checkout",
    embedCheckoutDescription: "Incorpora il checkout sul tuo sito web o blog.",
    embedShareSell: "Incorpora, condividere, vendere",
    images: {
      alert: "The image gallery contains all images associated with this product including its attached variants.",
      title: "Immagini",
      types: "| {types} accepted | {types} & {lastType} accepted"
    },
    includesNCountries: "Includes 1 country | Includes {number} countries",
    introSplash: {
      api: "Create product API",
      description: "Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.",
      more: "Ulteriori informazioni",
      title: "Add your first physical or digital product"
    },
    inventory: {
      available: "Inventory available",
      managed: "Managed",
      tooltip: "When inventory is managed you can set the number of available items, otherwise it will be unlimited"
    },
    lastSavedAt: "Ultimo salvataggio",
    linkCustomers: "Collega i clienti direttamente al checkout utilizzando questo URL.",
    minimumPrice: "Prezzo minimo",
    misc: "Varie",
    name: "Nome",
    noImage: "Nessuna immagine",
    noRelatedAvailable: "You have no other products",
    payWhatYouWant: "Paga quello che vuoi",
    price: "Prezzo",
    product: "Prodotto",
    products: "Prodotti",
    pwywEnabled: "'Paga quello che vuoi' abilitato",
    relatedProducts: "Prodotti correlati",
    saveFailed: "Impossibile salvare il prodotto! Per favore riprova.",
    saved: "Cambiamento salvato",
    searchEngineDescription: "Search engine description",
    searchEngineTitle: "Search engine title",
    seo: "SEO",
    shippingCard: {
      baseRateLabel: "Tariffa base",
      baseRateTooltip: "The base price for this rate. Other rates will be added on top of this.",
      nameLabel: "Nome",
      onOwnLabel: "On own",
      onOwnTooltip: "The price for this rate when this item is ordered on its own. This will be added to the base rate e.g. Base rate + Rate on own = Total shipping rate charged",
      title: "Opzioni di spedizione",
      withOthersLabel: "Con altri",
      withOthersTooltip: "The price for this rate when ordered with other items. Will be added with other items shipping rate and the base rate e.g. Base rate + Rate with others (This item rate) + Rate with others (Other item rate) = Total shipping rate charged"
    },
    sku: "SKU",
    sortOrder: "Ordinamento",
    sortOrderTooltip: "Lower values make your product show up in lists earlier, whereas higher values make it show up later.",
    thankYouPage: "Pagina di ringraziamento",
    variants: {
      addGroup: "Add variant group",
      addOption: "Aggiungi un'opzione",
      addVariant: "Aggiungi variante",
      amount: "Importo",
      andMore: "And {count} additional variants",
      bulkAdd: "Bulk add",
      bulkFailure: "Sorry, we're having problems updating your variants.",
      bulkSuccess: "Variants have been updated successfully",
      chooseGroupsAndOptions: "Choose groups & options",
      create: "Create variant | Create variants",
      createBulk: "Create bulk variants",
      createSingle: "Create single variant",
      defaultPrice: "Prezzo predefinito",
      defaultPriceTooltip: "The default price for the variant option.",
      defaultVariantTitle: "New variant",
      edit: "Edit variants",
      editSingle: "Edit variant",
      editSingleNamed: "Edit variant: {name}",
      generateVariants: "Generate variants",
      groups: "Groups | Group | Groups",
      hasInvalidVariants: "This product has invalid variants. These variants cannot be added to a cart or checkout, and cannot be sold. To re-enable invalid variants, please edit them and address any issues.",
      help: "Different types of this product (e.g. size, color)",
      image: "Immagine",
      invalidReason: {
        duplicate_option_config: "This invalid variant shares options with another variant. Only one variant may exist for a specific set of options.",
        incomplete_option_config: "This invalid variant is missing an option from one of the variant groups configured for this product. Variants must have an option set for each group."
      },
      invalidVariantsOnSave: "Once you save, your variants will be marked as \"invalid\" and you will not be able to sell invalid variants until you fix the issues.",
      mustSave: "You must save before adding new variants",
      noGroups: "Variants help you to sell products with slight differences, but are still the same product. For example, you might sell a t-shirt in different colors, or a plant pot in different sizes. You can configure variants to have their own price, SKU, and stock inventory.\n\nTo get started, create groups and options for your variants. Groups define the type of variant (e.g. color). Options are a choice your customer can make within that group (e.g. blue).",
      noGroupsTitle: "Set up variant groups to sell variations of the same product",
      noOptions: "Please enter at least one option",
      noVariants: "Use the buttons above to create variants. Variants are combinations of your configured variant groups and variant options. You will be able to individually set the price and inventory for each variant (if enabled).",
      noVariantsTitle: "No variants have been created",
      noVariantsToGenerate: "All variants already exist for the selected options.",
      optionDefaultImage: "Immagine predefinita",
      options: "Options | Option | Options",
      optionsInGroupsRequired: "You must select at least one option in each group to generate variants in bulk.",
      optionsUpdateFailure: "Sorry, we're having problems updating your variant options.",
      optionsUpdateSuccess: "Variant options have been updated successfully",
      override: "Sostituisci",
      preview: "Variant preview",
      previewHelp: "Preview of the variants that will be created based on the options selected above.",
      quantity: "Quantitativo",
      requiresGroupsSave: "Using this feature requires you to save the groups first.",
      requiresSave: "Using this feature requires you to save the product first.",
      saveAndGenerate: "Save and generate variants",
      selectOptions: "Select options for your variant",
      singleCreateSuccess: "Variant has been created successfully",
      singleUpdateFailure: "Sorry, we're having a problem saving your variant.",
      singleUpdateSuccess: "Variant has been updated successfully",
      title: "Varianti",
      variantDetails: "Variant details",
      variantExists: "It looks like this variant exists already.",
      variantGroup: "Variant group",
      variantGroupName: "Group name (example: Size, Color, Material, etc.)",
      variantGroupTitle: "Variant group: {group}",
      variantName: "Variant name",
      willInvalidateVariants: "Adding and/or removing variant groups will invalidate your existing variants, as each variant must have one option for each group, and cannot be duplicated."
    },
    viewProducts: "Visualizza i prodotti"
  },
  rays: {
    add: "Create new Ray",
    commerceRays: "Commerce Rays",
    confirmDelete: "Are you sure you want to delete this Ray?",
    confirmDeleteTitle: "Sei sicuro?",
    create: "Create Ray",
    "delete": "Delete Ray",
    deleteFailed: "Failed to delete your Ray! Try again later.",
    deleted: "Ray deleted.",
    deploying: "Pubblicazione in corso",
    desktop: "Desktop",
    edit: "Edit Ray",
    embed: "Embed",
    embedModal: "You can also embed your checkout in a modal popup, triggered by clicking on a \"Buy now\" button.",
    exit: "Esci",
    images: {
      title: "Immagini",
      types: "| {types} accepted | {types} & {lastType} accepted"
    },
    introduction: {
      description: "A no-code page builder with built in checkouts.",
      "for": {
        campaigns: "Campagne",
        digitalProducts: "Prodotti digitali",
        influencers: "Influencer partnerships",
        mediaCheckouts: "Media Checkouts",
        ppc: "PPC campaigns",
        productDrops: "Product drops",
        title: "Rays is for",
        videoCommerce: "Video Commerce"
      },
      line1: "Anyone can create beautiful product and content pages that convert.",
      line2: "Pick from our selection of beautiful, editable themes, and sell your brand story.",
      title: "✨ Welcome to Commerce Rays"
    },
    lastDeployment: "Ultima pubblicazione:",
    mobile: "Cellulare",
    nameHelper: "For internal identification.",
    noProducts: "You must have at least one product. To create a Ray, please start by adding a product.",
    noRays: "No Rays found.",
    noThemes: "Nessun tema trovato",
    pickTheme: "Pick a template",
    planWarning: {
      limitReached: "You've reached your plan's limit. Please {upgrade} to publish more.",
      notice: "Your current plan doesn't support using Rays. Please {upgrade} to publish.",
      upgrade: "Aggiorna il tuo piano"
    },
    ray: "Ray",
    rayName: "Ray name",
    rays: "Rays",
    sandboxHelper: "Enabling sandbox mode will allow you to use Stripe in test mode. No live orders can be created in sandbox mode.",
    save: "Salva",
    saveDeploy: "Salva e pubblica",
    saveFailed: "Failed to save your Ray! Please try again.",
    saved: "Modifiche salvate",
    savedAndDeploying: "Changes saved and publishing your Ray...",
    saving: "Salvataggio...",
    select: "Seleziona tema",
    selectProducts: "Seleziona prodotti",
    validationFailed: "There is a problem with your chosen settings. Please check the sidebar for errors.",
    viewPublishedRay: "View published Ray",
    viewRay: "View Ray",
    viewportWarning: "This part of our dashboard is best experienced on a larger screen. Please sign in on your computer to view Rays."
  },
  settings: {
    analytics: "L'analisi",
    data: "Dati",
    developer: "Sviluppatore",
    developerSettings: {
      addKey: "Add API key",
      apiKey: "Chiave API",
      apiKeys: "Chiavi API",
      apiVersion: "Versione API",
      changeKey: "Cambia chiave",
      changelog: "See changelogs",
      confirmDeleteKey: "Are you sure you want to delete this API key? Any stores using this key will stop functioning immediately.",
      createKey: "Create API key",
      createSuccess: "API key has been created successfully",
      deleteFailed: "Failed to delete your API key! Try again later.",
      deleted: "API key deleted.",
      editKey: "Edit API key",
      generateKey: "Are you sure you want to regenerate the key?",
      keyChangFailed: "Failed to update your API key! Try again later.",
      keyChanged: "Key has been successfully updated",
      label: "API key label",
      live: "IN DIRETTA",
      "public": "Pubblico",
      resetKeyButtonTooltip: "Genera nuova chiave",
      resetKeyConfirmationHeader: "Genera nuova chiave?",
      sandbox: "Sandbox",
      secret: "Segreto",
      type: "Genere",
      updateFailure: "Failed to update your API key. Please try again.",
      updateSuccess: "API key successfully updated"
    },
    merchant: {
      analyticsCardTitle: "Google Analytics",
      analyticsGaTrackingId: "Tracking ID",
      analyticsGaTrackingIdTooltip: "Your unique Google Analytics tracking ID",
      analyticsLinkedDomain: "Add linked domain",
      analyticsLinkedDomainTooltip: "You can add multiple domains separated by a comma.",
      coverImage: "Immagine di copertina",
      coverImageTooltip: "This is the cover image that will be on your storefront.",
      descriptionTooltip: "A brief description about yourself/your company",
      emailTooltip: "This email is visible to your customers. Customer support emails will go here.",
      enableHostedCheckouts: "Enable hosted checkout",
      enableHostedCheckoutsTooltip: "The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Enable hosted storefront",
      isPublic: "Make visible to public API",
      merchantAddress: "Indirizzo",
      merchantId: "ID Commerciante",
      merchantIdInfo: "This is your unique merchant ID. We may ask for it when you contact us.",
      name: "Nome dell'azienda",
      nameTooltip: "What name are you selling under?",
      save: "Salva impostazioni",
      saveFailed: "Failed to save the merchant settings! Please try again.",
      saved: "Modifiche salvate!",
      timezoneTooltip: "All dates and times in Chec will be relative to this timezone",
      title: "Merchant details"
    },
    notifications: {
      customerLoginToken: "Customer: Login token",
      customerOrderReceipt: "Customer: order receipt",
      customerOrderShipped: "Customer: order shipped",
      dashboard: "Dashboard notifications",
      dashboardSummary: "Control the behavior of notifications in the Chec Dashboard.",
      emailNotificationSummary: "Enable/disable default email notifications that are sent from Chec.",
      emailNotifications: "Notifiche email",
      emails: "Emails",
      merchantOrderReceipt: "Merchant: new order received",
      navMenuHighlight: "Navigation menu highlight",
      news: "News & feature announcements",
      none: "Nessuno",
      notificationPopup: "Notification popup",
      title: "Notifiche"
    },
    paymentGateways: "Gateway di pagamento",
    settings: "Impostazioni",
    shipping: "Spedizione",
    tax: {
      addState: "Add state",
      addTax: "Enable tax management",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Add tax zone",
      addedToRate: "Added to country rate",
      address: "Indirizzo",
      addressAlert: "This is used to calculate tax and is displayed on invoices.",
      calculateAutomatically: "Calculate taxes automatically",
      configureTaxZone: "Configure tax zone",
      confirmDeleteZone: "Are you sure you want to delete this tax zone?",
      confirmDeleteZoneTitle: "Sei sicuro?",
      countrySelectInfo: "The tax rate configured here will be applied to all orders in this tax zone.",
      countryTax: "Country tax",
      deleted: "Tax configuration deleted",
      digitalRate: "Digital rate",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Edit tax zone",
      enableTax: "Enable tax",
      enableVat: "Enable EU digital goods VAT",
      enableVatInfo: "Collect EU digital goods VAT (VAT MOSS) tax on digital goods. Tax rates are automatically calculated and applied to EU customers.",
      euVatMoss: "European Union (VAT MOSS)",
      introCopy: "Commerce.js allows you to easily define your region's tax rates to help you easily collect the appropriate tax during checkout. You can also choose to integrate any tax provider and implement custom business rules with your own serverless functions.",
      introTitle: "Let Commerce.js manage your tax!",
      nexusHelp: "Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.",
      nexusStates: "Nexus states",
      overrideRate: "Instead of country rate",
      pricesIncludeTax: "Do your prices include tax?",
      ratesAutoUpdated: "Rates are automatically updated & set.",
      saveFailed: "Failed to save the tax configuration! Please try again.",
      standardRate: "Tariffa Standard",
      taxRateZones: "Tax rate zones",
      taxRates: "Tax rates",
      title: "Tasse",
      zipAlreadyExists: "The provided ZIP code has already been added",
      zipInvalid: "The provided ZIP code does not appear to be a valid US postal ZIP code"
    },
    updateFailed: "Failed to save your notification preferences. Please try again.",
    updatedSuccessfully: "Notification preferences updated successfully.",
    viewStorefront: "View storefront"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "Aggiungi una zona",
    confirmDeleteZone: "Are you sure you want to delete this zone?",
    countriesRegions: "Countries & regions",
    countriesRegionsHelp: "Please note that changes to this panel will not be saved until the shipping zone is saved.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "Modifica una zona: {name}",
    enableOnProductsInfo: "If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.",
    enableOnProductsTitle: "Enable on all products",
    introSplash: {
      description: "A shipping zone is added by default as an example. Once you've added additional shipping zones, save and enable for the zones to be available for your products. Shipping zones can also be selected in the {productPage}.",
      title: "Add shipping zones for your products"
    },
    noCountriesRegions: "No countries or regions have been added to your zone. Start adding countries or regions to your zone before saving.",
    noCountriesRegionsTitle: "No countries or regions have been added",
    numCountries: "No. of countries",
    numRegions: "No. of regions",
    productPage: "product edit page",
    rateNameRequired: "The rate name is required",
    ratePriceRequired: "The rate price is required. For free rates use 0.",
    ratesLeadIn: "Increases to these rates can also be added in the {productPage}.",
    saveFailure: "Sorry, we're having a problem saving your shipping zone.",
    saveSuccess: "Your shipping zone has been saved successfully.",
    selectRegion: "Configure regions",
    shippingZones: "Shipping zones",
    zone: "Zona",
    zoneDeleteFailed: "Something went wrong while trying to delete this zone. Please try again.",
    zoneDeleted: "Zone deleted.",
    zoneName: "Nome della zona",
    zoneNameDescription: "The zone name is for internal use only and customers will not see this.",
    zoneNameRequired: "The zone name is required"
  },
  signup: {
    error: "Something went wrong while creating your account. Please check your details and try again.",
    form: {
      experience: "So we can provide you with the best experience possible.",
      merchantDetails: "Merchant details",
      merchantEmail: "Merchant email",
      merchantEmailDescription: "This email is visible to your customers. You'll also receive order notifications here.",
      merchantName: "Merchant name",
      merchantNameDescription: "What name are you selling under?",
      next: "Successivo",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "Salvataggio..."
    },
    intro: {
      text: "Create your account in a few steps and get started using Commerce.js.",
      title: "Welcome, let's setup your account"
    },
    page: {
      title: "Impostazione account"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Something else",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Marketing",
      other: "Other",
      owner: "Business owner",
      solution_architect: "Solution Architect",
      student: "Student"
    }
  },
  slack: {
    alreadyJoined: "Already joined?",
    description: "Our community of eCommerce developers & designers.",
    error: {
      alreadyInTeam: "This email address is already in our community!",
      alreadyInvited: "This email address has a pending invite. Check your email!",
      invalidEmail: "You must provide a valid email address",
      missingEmail: "You must provide an email address!"
    },
    getMyInvite: "Ottieni il mio invito",
    inviteSent: "Invito inviato! Controlla la tua email.",
    join: "Join Slack community",
    joinCommerceJs: "Join Commerce.js on Slack",
    joinUs: "Join us on Slack",
    openSlack: "Open Slack",
    registeredCount: "{count} users are registered so far.",
    slack: "Slack"
  },
  spaces: {
    storefront: "Storefront"
  },
  status: {
    maintenance: "Service under maintenance",
    major: "Partial system outage",
    minor: "Minor service outage",
    none: "All systems operational"
  },
  validation: {
    enterEmail: "Please enter an email address",
    enterName: "Per favore scrivi il tuo nome",
    enterOption: "Please enter an option name",
    enterPrice: "Per favore scrivi il prezzo",
    enterQuantity: "Inserire una quantità",
    enterUrl: "Per favore scrivi un URL",
    enterValue: "Please enter an attribute value",
    generic: "Please enter a value for {field}",
    number: "Inserisci un numero",
    positiveNumber: "Please enter a positive number",
    typeRequired: "Please enter a type",
    validBusinessName: "Please enter a business name",
    validCity: "Inserisci una città.",
    validCountry: "Please select a country",
    validEmail: "Please enter a valid email address",
    validNumber: "Inserire un numero valido",
    validPostalZipCode: "Please enter a postal/zip code",
    validPrice: "Per favore scrivi un prezzo valido",
    validRegion: "Please select a province/state",
    validStreet: "Please enter a street name & number",
    validSupportEmail: "Per favore inserisci una email",
    validUrl: "Per favore scrivi un URL valido"
  },
  versions: {
    current: "{version} - Corrente"
  },
  webhooks: {
    add: "Aggiungi webhook",
    added: "Webhook aggiunto.",
    confirmDelete: "Sei sicuro di voler cancellare questo webhook?",
    confirmRegenerateKey: "Sei sicuro di voler rigenerare la chiave di entrata? Questo influenzerà tutti webhooks.",
    copiedKey: "Copiato!",
    copyKey: "Copia ",
    created: "Creato",
    "delete": "Delete webhook",
    deleteFailed: "Impossibile eliminare webhook!",
    deleted: "Webhook eliminata.",
    deliveryDetails: "Delivery details",
    deliveryHistory: "Cronologia delle consegne",
    edit: "Modifica webhook",
    event: "Evento",
    eventName: "Event name",
    events: "Eventi",
    generateKey: "Genera nuova chiave",
    introSplash: {
      description: "Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.",
      more: "Ulteriori informazioni",
      title: "Add a webhook to extend the possibilities of your business."
    },
    regenerateKeyFailed: "Impossibile rigenerare la chiave di entrata!",
    responseBody: "Response body",
    responseCode: "HTTP codice di risposta: {code}",
    save: "Salva webhook",
    saveFailed: "Impossibile salvare il webhook! Per favore riprova.",
    saved: "Modifiche salvate.",
    sendTestRequest: "Invia richiesta",
    sendingTestRequest: "Invio richiesta...",
    signingKey: "Chiave di entrata",
    testRequestCallbackFailed: "Richiesta inviata ma la risposta non e riuscita.",
    testRequestFailed: "Impossibile inviare la richiesta!",
    testRequestSuccess: "richiesta inviata con successo.",
    view: "Visualizza webhook",
    webhook: "Webhook",
    webhooks: "Webhooks"
  }
};