export default [
  {
    path: 'billing',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    children: [
      {
        path: '',
        name: 'settings.billing.home',
        component: () => import(/* webpackChunkName: "settings" */ './views/home.vue'),
        meta: {
          title: (i18n) => i18n.t('billing.billing'),
        },
      },
      {
        path: 'plan/:slug',
        name: 'settings.billing.plan',
        component: () => import(/* webpackChunkName: "settings" */ './views/home.vue'),
      },
      {
        path: 'invoice/:id',
        name: 'settings.billing.invoice',
        component: () => import(/* webpackChunkName: "settings" */ './views/invoiceView.vue'),
        meta: {
          showBack: true,
          title: (i18n) => i18n.t('billing.viewInvoice'),
        },
      },
    ],
  },
];
