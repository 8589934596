import VueRouter from 'vue-router';
import Client from '@/lib/auth/client';
import pkceParams from '@/lib/auth/pkceParams';
import authState from '@/lib/auth/state';
import refreshDocumentTitle from '@/lib/refreshDocumentTitle';
import NotFoundPage from '@/views/errors/NotFoundPage.vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import actions from '@/store/actions';

import attributesRoutes from '@/modules/attributes/routes';
import categoriesRoutes from '@/modules/categories/routes';
import customersRoutes from '@/modules/customers/routes';
import discountsRoutes from '@/modules/discounts/routes';
import homeRoutes from '@/modules/home/routes';
import integrationsRoutes from '@/modules/integrations/routes';
import merchantRoutes from '@/modules/merchant/routes';
import ordersRoutes from '@/modules/orders/routes';
import productsRoutes from '@/modules/products/routes';
// import raysRoutes from '@/modules/rays/routes';
import settingsRoutes from '@/modules/settings/routes';
import developerRoutes from '@/modules/developer/routes';

const routes = [
  ...attributesRoutes,
  ...categoriesRoutes,
  ...customersRoutes,
  ...discountsRoutes,
  ...homeRoutes,
  ...integrationsRoutes,
  ...merchantRoutes,
  ...ordersRoutes,
  ...productsRoutes,
  // ...raysRoutes,
  ...settingsRoutes,
  ...developerRoutes,
  /**
   * Callback for oauth logins via authorize.chec.io
   */
  {
    path: '/auth/callback',
    name: 'auth.callback',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/AuthCallback.vue'),
    meta: {
      public: true,
      layout: 'empty-layout',
    },
  },
  {
    path: '/login',
    name: 'login',
    redirect: '/',
    meta: {
      public: true,
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFoundPage,
    meta: {
      public: true,
    },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return { selector: to.hash };
  }
  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.aborted)) {
      // This is a redirect to re-auth. We want to silence these errors, a redirect is about to
      // occur
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

/**
 * Enforce authentication requirement before any page is served. This means that an
 * access token is present and "valid" (not expiring within a certain period of time).
 *
 * If no token, or token is expiring soon, we'll redirect to auth service to get a
 * new token.
 */
router.beforeEach(async (to, from, next) => {
  // Clear any errors in state before each route is activated
  store.dispatch(actions.CLEAR_ERRORS);

  // Note that the app is not initialized for public routes as no user context can be fetched
  if (to.meta.public) {
    next();
    return;
  }

  if (authState.isTokenValid() || await authState.attemptRefresh()) {
    // Ensure the app is initiated
    const { initiated } = store.state;

    // And a merchant is initiated if it needs to be, or we know that we have an active merchant
    if (!to.meta.merchantless || (window && window.localStorage.getItem('active_merchant'))) {
      await store.dispatch(actions.INIT_MERCHANT);
    }

    // Ensure the app is initiated
    if (!initiated) {
      await store.dispatch(actions.INIT_APP);
    }

    // Handle instances where there might be no active merchant
    const { merchant: merchantState } = store.state;

    // First, continue if the merchant is available or we don't need a merchant
    if (merchantState.merchant?.id || to.meta.merchantless) {
      next();
      return;
    }

    // Figure out how many merchants are accessible
    const merchantCount = merchantState.totalCount;

    if (merchantCount === null) {
      // This should not be possible - initiating the app _should_ fetch an active merchant (and we
      // wouldn't have got this far) OR load the first page of merchants from the API, providing the
      // total number of merchants.
      throw Error('Active merchant incorrectly resolved');
    }

    // If there are no merchants, change the route to the wizard
    if (merchantCount === 0 && to.name !== 'signup') {
      // If we've gotten here from the auth route, then we'll just use a browser redirect instead of
      // redirecting with Vue as it will complain about duplicate redirects for some reason
      if (from.name === 'auth.callback' && window) {
        window.location = router.resolve('../wizard').href;
        return;
      }

      try {
        await next({ name: 'signup' });
      } catch (error) {
        // Can throw a navigation error if Vue redirects multiple times as you can arbitrarily only
        // have one redirect per navigation. We'll just reload, and this code should run again
        // without a prior redirect occurring
        if (window) {
          window.location.reload();
        }
      }
      return;
    }

    // Enforce merchant context for routes that require it
    if (!merchantState.merchant?.id) {
      next({ name: 'merchant.list' });
      return;
    }

    next();
    return;
  }

  // Store the requested "to" route _name_ so we can return the user to it after login
  window.localStorage.setItem('return_to', to.name || 'home');
  window.localStorage.setItem('return_to_params', JSON.stringify(to.params || {}));

  // Ensure the current token is removed from state (this should matter, but just in case)
  window.localStorage.removeItem('access_token');

  // Send user to login
  const authCodePkceParams = pkceParams();
  window.location = Client.code.getUri({
    state: authCodePkceParams.state,
    query: {
      code_challenge: authCodePkceParams.code_challenge,
      code_challenge_method: authCodePkceParams.code_challenge_method,
    },
  });
  next(false);
});

/**
 * Set a page title for each route
 */
router.afterEach((to) => {
  refreshDocumentTitle(to);
});

export default router;
