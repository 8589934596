<template>
  <ErrorContent title="Something went wrong...">
    <p>Refresh the page and try again. We've also been notified of this error.</p>
    <ErrorCode />
    <template #graphic>
      <ServerErrorGraphic />
    </template>
  </ErrorContent>
</template>

<script>
import ServerErrorGraphic from '../../../public/img/500.svg';
import ErrorContent from '../../components/ErrorContent.vue';
import ErrorCode from './ErrorCode.vue';

export default {
  name: 'ClientErrorPage',
  components: {
    ErrorCode,
    ServerErrorGraphic,
    ErrorContent,
  },
};
</script>
