<template>
  <ChecSlideoutPanel
    v-if="template !== null"
    :title="$t('integration.integrations')"
    close-on-overlay-click
    class="integrations-configure"
    @close="handleClose"
  >
    <ChecCard inner-class="integrations-configure__card">
      <div class="integrations-configure__header">
        <img
          v-if="template.icon_url"
          :src="template.icon_url"
          :alt="template.name"
          class="integrations-configure__logo"
        >
        <div v-else class="integrations-configure__logo-placeholder">
          <div>
            {{ $t('integration.noLogo') }}
          </div>
        </div>
        <h2 class="integrations-configure__header--copy">
          {{ template.name }}
        </h2>
      </div>
      <ChecButton
        color="gray"
        variant="small"
        text-only
        @click="showInfoPanel = true"
      >
        {{ $t('general.learnMoreButton') }}
      </ChecButton>
    </ChecCard>
    <Configuration
      v-if="template"
      v-model="templateConfig"
      :template="template"
      @savable="setValidConfig"
      @set-external-id="setExternalId"
    />
    <TemplateInfo
      v-if="showInfoPanel"
      :template="template"
      :errors="errors"
      @close="showInfoPanel = false"
    />
    <template #toolbar>
      <div class="integrations-configure__toolbar space-x-4">
        <ChecButton
          text-only
          color="primary"
          @click="handleClose"
        >
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          color="primary"
          :disabled="!savable"
          @click="handleSaveConfiguration"
        >
          {{ saving ? $t('general.saving') : $t('general.saveChanges') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import TemplateInfo from '../components/TemplateInfoPanel.vue';
import Configuration from '../components/Configuration.vue';
import createIntegration from '../mixins/create-integration';

export default {
  name: 'ConfigureIntegration',
  components: {
    Configuration,
    ChecButton,
    ChecCard,
    ChecSlideoutPanel,
    TemplateInfo,
  },
  mixins: [
    addNotification,
    createIntegration,
    crud('integrations/integrations', true),
    crud('integrations/templates', false, null, true),
  ],
  data() {
    return {
      errors: {},
      saving: false,
      showInfoPanel: false,
      templateConfig: null,
      template: null,
      validConfig: false,
    };
  },
  computed: {
    savable() {
      if (this.saving) {
        return false;
      }

      return this.validConfig;
    },
  },
  mounted() {
    const { templateId } = this.$route.params;

    this.loadTemplates(templateId).then(async () => {
      this.template = await this.getTemplates(templateId);
    }).catch(() => {
      this.$router.push({ name: 'notFound' });
    });
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'integrations.add' });
    },
    setValidConfig(state) {
      this.validConfig = state;
    },
  },
};
</script>

<style lang="scss">
.integrations-configure {
  &__card {
    @apply flex justify-between items-center flex-row mt-6 mb-8;
  }

  &__header {
    @apply flex;

    &--copy {
      @apply ml-4 font-bold;
    }
  }

  &__logo {
    @apply h-8;
  }

  &__logo-placeholder {
    @include aspect-ratio(1, 3);
    max-height: 3.5rem;
    min-width: 50%;

    div {
      @apply w-2/3 flex justify-center items-center bg-gray-300 text-center opacity-50 text-xs;
    }
    @apply rounded overflow-hidden pb-4;
  }

  &__no-config {
    @apply text-center;
  }

  &__description {
    @apply my-2;
  }

  &__toolbar {
    @apply flex justify-end w-full;
  }
}
</style>
