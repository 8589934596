import axios from 'axios';
import globalActions from '@/store/actions';
import globalMutations from '@/store/mutations';
import { makeApiRequest } from '@/lib/api';
import zones from './zones';

const {
  FETCH_TAX,
  UPDATE_TAX,
  FETCH_EU_VAT_MOSS,
} = globalActions;
const {
  SET_TAX,
  SET_EU_VAT_MOSS,
  SET_LOADING,
  SET_ERROR,
} = globalMutations;

const FETCH_US_ZIPCODE = 'FETCH_US_ZIPCODE';
const CACHE_US_ZIPCODE = 'CACHE_US_ZIPCODE';

export const actions = {
  FETCH_TAX,
  UPDATE_TAX,
  FETCH_US_ZIPCODE,
};

export const mutations = {
  SET_TAX,
  SET_EU_VAT_MOSS,
  SET_LOADING,
  SET_ERROR,
  CACHE_US_ZIPCODE,
};

export default {
  namespaced: true,
  modules: {
    zones,
  },
  state: {
    error: null,
    euVatMoss: null,
    tax: null,
    taxZones: null,
    usZipcodes: {},
  },
  mutations: {
    [CACHE_US_ZIPCODE](state, { zipcode, data }) {
      state.usZipcodes = {
        ...state.usZipcodes,
        [zipcode]: data,
      };
    },
    [SET_TAX](state, payload) {
      state.tax = payload;
    },
    [SET_EU_VAT_MOSS](state, payload) {
      state.euVatMoss = payload;
    },
    [SET_ERROR](state, error) {
      state.error = error;
    },
  },
  actions: {
    /**
     * request GET tax
     *
     * sets tax configurtation.
     */
    [FETCH_TAX]({ commit }) {
      return makeApiRequest('GET', '/v1/tax')
        .then(({ data }) => commit(SET_TAX, data))
        .catch((error) => commit(SET_ERROR, error));
    },
    async [FETCH_US_ZIPCODE]({ commit, state }, zipcode) {
      // Check the cache in the rare instance where a user is adding the same zip code more than
      // once in a single session
      if (state.usZipcodes[zipcode] !== undefined) {
        return state.usZipcodes[zipcode];
      }

      const key = process.env.VUE_APP_ZIPCODEBASE_KEY;
      const response = await axios({
        method: 'GET',
        url: `https://app.zipcodebase.com/api/v1/search?apikey=${key}&codes=${zipcode}&country=US`,
      });

      // Handle the response in a self-invoking function to use early returns
      const result = (({ data }) => {
        // No result for our given zipcode
        if (
          !data
          || !Object.hasOwnProperty.call(data, 'results')
          || !Object.hasOwnProperty.call(data.results, zipcode)
        ) {
          return null;
        }

        const results = data.results[zipcode];

        if (!Array.isArray(results) || !results.length) {
          return null;
        }

        return {
          zipcode: results[0].postal_code,
          state: results[0].state_code,
          town_city: results[0].city,
        };
      })(response);

      // We still cache invalid responses so that we don't call the API many times for a bad zipcode
      commit(CACHE_US_ZIPCODE, { zipcode, data: result });

      return result;
    },
    /**
     * Request PUT tax
     *
     * Attempts to update the tax configuration
     */
    [UPDATE_TAX]({ commit }, tax) {
      return makeApiRequest('PUT', '/v1/tax', tax)
        .then(({ data }) => commit(SET_TAX, data))
        .catch((error) => commit(SET_ERROR, error));
    },
    /**
     * Request GET tax/eu-vat-moss
     *
     * Get the EU Vat Moss data
     */
    [FETCH_EU_VAT_MOSS]({ commit }) {
      return makeApiRequest('GET', '/v1/tax/eu-vat-moss')
        .then(({ data }) => commit(SET_EU_VAT_MOSS, data))
        .catch((error) => commit(SET_ERROR, error));
    },
  },
};
