import {
  mutations as paginationMutations,
  applyPagination,
} from '@/lib/pagination';
import { makeApiRequest } from '@/lib/api';

export const actions = {
  LOAD_KEYS: 'LOAD_KEYS',
  REGENERATE_KEY: 'REGENERATE_KEY',
};
export const mutations = {
  ADD_API_KEY: 'ADD_API_KEY',
  SET_API_KEYS: 'SET_API_KEYS',
};
export const getters = {
  GET_KEY_BY_TYPE: 'GET_KEY_BY_TYPE',
};

export default applyPagination('/v1/api-keys?sortDirection=desc', {
  individualItemEndpoint: '/v1/api-keys',
})({
  namespaced: true,
  state: {
    keys: [],
  },
  mutations: {
    [mutations.ADD_API_KEY](state, key) {
      state.keys.push(key);
    },
    [mutations.SET_API_KEYS](state, keys) {
      state.keys = keys;
    },
  },
  actions: {
    async [actions.LOAD_KEYS]({ commit }) {
      const keys = await makeApiRequest('GET', '/v1/api-keys');
      if (!keys?.data) {
        // Not ready to do anything yet
        return;
      }
      commit(mutations.SET_API_KEYS, keys.data);
    },
    async [actions.REGENERATE_KEY]({ commit, state }, {
      keyId,
    }) {
      const key = await makeApiRequest(
        'POST',
        `/v1/api-keys/${keyId}/regenerate`,
      );
      commit(paginationMutations.REPLACE_ITEM, {
        item: key.data,
        context: state.context,
      });
    },
  },
  getters: {
    [getters.GET_KEY_BY_TYPE]: ({ keys }) => (type = 'public', sandbox = false) => {
      if (!keys?.data) {
        return [];
      }
      return keys.data.filter(
        (key) => key.type === type
          && key.is_sandbox === sandbox,
      );
    },
  },
});
