<template>
  <div
    class="dynamic-field"
    :class="{ 'dynamic-field--errored': Boolean(error) }"
  >
    <ChecFormField
      class="w-full"
      :append-label="schema.description
        ? $t('general.whatIsThis')
        : null"
      :tooltip="schema.description"
    >
      <!-- Type: short_text, long_text, number -->
      <TextField
        v-if="[
          'short_text',
          'long_text',
          'number',
        ].includes(schema.type)"
        v-bind="fieldProps"
        :multiline="schema.type === 'long_text'"
        :type="[
          'short_text',
          'long_text',
        ].includes(schema.type)
          ? 'text' : schema.type"
        @input="handleChange"
      />

      <!-- Type: api_key -->
      <TextField
        v-if="schema.type === 'api_key'"
        v-bind="fieldProps"
        inner-input-class="dynamic-field__password"
        @input="handleChange"
      />

      <!-- Type: password -->
      <PasswordField
        v-if="schema.type === 'password'"
        v-bind="fieldProps"
        :hide-show-button="value === ''"
        @input="handleChange"
      />

      <!-- Type: wysiwyg -->
      <ChecWysiwyg
        v-if="schema.type === 'wysiwyg'"
        v-bind="fieldProps"
        size="auto"
        @input="handleChange"
      />

      <!-- Type: boolean -->
      <ChecSwitch
        v-if="schema.type === 'boolean'"
        :toggled="value"
        name="schema.key"
        @input="handleChange"
      >
        {{ schema.label }}
      </ChecSwitch>

      <!-- Type: select -->
      <ChecDropdown
        v-if="schema.type === 'select'"
        v-bind="fieldProps"
        :placeholder="$t('general.selectOption')"
        :options="schema.options"
        :multiselect="Boolean(schema.multiselect)"
        @input="handleChange"
      />
    </ChecFormField>
  </div>
</template>

<script>
import {
  ChecDropdown,
  ChecFormField,
  ChecSwitch,
  PasswordField,
  TextField,
} from '@chec/ui-library';

import ChecWysiwyg from '@chec/ui-library/dist/ChecWysiwyg';

export default {
  name: 'DynamicField',
  components: {
    ChecDropdown,
    ChecFormField,
    ChecSwitch,
    ChecWysiwyg,
    PasswordField,
    TextField,
  },
  props: {
    schema: Object,
    error: String,
    value: [Array, String, Boolean],
  },
  computed: {
    fieldProps() {
      const {
        value,
        schema: {
          label,
          key,
          type,
          required = false,
          disabled = false,
        },
      } = this;

      const props = {
        value,
        label,
        name: key,
        required,
        disabled,
      };

      // Suppress the error that the dropdown component emits when the value provided doesn't match
      // an option provided for the dropdown. We control the value (from config), but not
      // necessarily the options, which might come from a framed configuration app.
      if (type === 'select') {
        const availableValues = this.schema.options.map(({ value: v }) => v);

        if (Array.isArray(value)) {
          // Filter out any given values that aren't options in the dropdown
          props.value = value.filter((candidate) => availableValues.includes(candidate));
        } else if (!availableValues.includes(value)) {
          // The given value doesn't exist in the list of options so blank it out
          props.value = '';
        }
      }

      // Support disabled states on components that don't use `disabled` as a prop
      if (disabled && ['select'].includes(type)) {
        props.variant = 'disabled';
      }

      // Support error states on components that use `variant="error"`
      if (!props.variant && this.error && !['boolean'].includes(type)) {
        props.variant = 'error';
      }

      return props;
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('clear-error');
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.dynamic-field {
  @apply text-gray-500;

  &__description {
    @apply px-0 pt-1 text-xs;
  }

  &__password {

    filter: blur(0);

    &:not(:focus) {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      @apply text-transparent;
    }
  }

  &--errored {
    @apply text-red-600;
  }
}
</style>
