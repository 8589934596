<template>
  <ChecCard
    inner-class="integration-logs-card"
    tailwind="p-4"
  >
    <ChecLoading v-if="loading" />
    <ChecHeader
      :title="$t('logs.logs')"
      variant="card"
      header-tag="h3"
    />
    <DashboardTable
      class="integration-logs-card__table"
      :columns="[
        { title: $t('webhooks.eventName') },
        { title: $t('general.status') },
        { title: $t('general.dateTime') },
      ]"
    >
      <LogRow
        v-for="message in webhookHistory"
        :key="message.id"
        :message="message"
        @handle-view="handleViewResponse"
      />
    </DashboardTable>
    <WebhookLogDetails
      v-if="loadedMessage"
      :webhook="loadedMessage"
      @close="loadedMessage = null"
    />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import DashboardTable from '@/components/DashboardTable.vue';
import WebhookLogDetails from '@/components/WebhookLogDetails.vue';
import LogRow from './LogRow.vue';

export default {
  name: 'LogsCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecLoading,
    DashboardTable,
    LogRow,
    WebhookLogDetails,
  },
  props: {
    webhookHistory: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      loadedMessage: null,
    };
  },
  methods: {
    handleViewResponse(message) {
      this.loadedMessage = message;
    },
  },
};
</script>

<style lang="scss">
.integration-logs-card {
  &__table {
    @apply mt-4;

    .chec-table__table {
      @apply w-full;
    }
  }

  &__empty-text {
    @apply text-gray-400;
  }
}
</style>
