<template>
  <ChecSlideoutPanel
    :title="$t('integration.integrations')"
    close-on-overlay-click
    @close="handleClose"
  >
    <div class="integrations-add__header">
      <ChecHeader
        header-tag="h3"
        :title="$t('integration.integrations')"
      />
      <ChecDropdown
        :options="filters"
        :label="filterLabel"
        name="template-tag"
        @input="(value) => handleFilterTemplates(value)"
      />
    </div>
    <div v-if="isLoading" class="integrations-add__loading">
      <ChecLoading without-background />
    </div>
    <TemplatesGrid
      v-else
      :templates="templates"
      @change-filters="(value) => handleFilterTemplates(value)"
    />

    <Pagination state-key="integrations/templates" />

    <template #toolbar>
      <ChecButton
        text-only
        color="primary"
        class="integrations-add__cancel"
        @click="handleClose"
      >
        {{ $t('general.cancel' ) }}
      </ChecButton>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import { mapState } from 'vuex';
import crud from '@/mixins/crud';
import {
  ChecButton,
  ChecDropdown,
  ChecHeader,
  ChecLoading,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import upperFirst from 'lodash.upperfirst';
import Pagination from '@/components/Pagination.vue';
import TemplatesGrid from '../components/TemplatesGrid.vue';

export default {
  name: 'AddIntegration',
  components: {
    ChecButton,
    ChecHeader,
    ChecDropdown,
    ChecLoading,
    ChecSlideoutPanel,
    Pagination,
    TemplatesGrid,
  },
  mixins: [crud('integrations/templates', true)],
  data() {
    return {
      templatesList: [],
    };
  },
  computed: {
    ...mapState('integrations/templates', ['currentFilters']),
    filterLabel() {
      if (!this.currentFilters.length) {
        return this.$t('integration.filterLabel');
      }
      return this.$t('integration.filterTagLabel', {
        tag: this.currentFilters[0].param,
      });
    },
    filters() {
      if (!this.templates) {
        return [];
      }

      return []
        .concat(...this.templatesList.map((template) => template.tags || []))
        .sort()
        .map((tag) => ({
          value: tag,
          label: upperFirst(tag), // todo the API should probably handle this
        }))
        .filter((option, index, self) => index === self.findIndex((item) => (
          item.value === option.value
        )));
    },
  },
  async mounted() {
    await this.loadPage(1);
    // Grab a copy of the templates for use in the filter listing.
    this.templatesList = this.templates;
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'integrations.home' });
    },
    /**
     * Filter template grid by selected tag
     */
    handleFilterTemplates(filter) {
      if (!filter || !filter.length) {
        this.setFilters([]);
        return;
      }

      this.setFilters([
        {
          column: 'tags',
          type: 'in',
          param: filter,
        },
      ]);
    },
  },
};
</script>

<style lang="scss">
.integrations-add {
  &__header {
    @apply flex;

    .dropdown {
      @apply w-56 border-none shadow-none bg-transparent; // Override dropwdown styles
    }

    .dropdown__label {
      @apply font-bold;

      &::first-line {
        text-transform: capitalize;
      }
    }
  }

  &__cancel {
    @apply flex justify-end w-full;
  }
}
</style>
