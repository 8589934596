import globalMutations from '@/store/mutations';
import mutations, { provideImplementations as mutationProvider } from './mutations';
import actions, { provideImplementations as actionProvider } from './actions';
import getters, { provideImplementations as getterProvider } from './getters';
import { mapPagination } from './helpers';

export const defaultOptions = {
  // The number of items that should be shown per page by default.
  initialPageSize: 15,
  // A string or function (taking `id`, `column` and `method`) that refers to the endpoint for
  // loading, deleting, or saving an individual item
  individualItemEndpoint: null,
  // The HTTP method that is used to update an item
  updateMethod: 'PUT',
  // The HTTP method that is used to add an item
  addMethod: 'POST',
  // The name of the property (in an item) that contains the ID
  idColumn: 'id',
  // How to resolve the items from the response
  itemResolver: (json, expectArray = false) => {
    const items = Object.hasOwnProperty.call(json, 'data') ? json.data : json;
    return expectArray && !Array.isArray(items) ? [] : items;
  },
  // How to resolve the total from a response
  totalResolver: (json) => json.meta && json.meta.pagination && json.meta.pagination.total,
  // The number of records a list needs to have before search operations are delegated to the server
  clientSideHandleLimit: 200,
  // Forwarded through to the generated VueX config
  namespaced: true,
  // Set handlers for events that can be run during pagination VueX actions. This can be either
  // a string for a mutation name to call, or a function that is called and given the commit
  // function and a payload defined by the hook
  hooks: {
    // Called with the loading state when API calls are dispatched/completed. Note that if you
    // override this property with your own hook, you will need to take care of any loading states
    // since it will replace this default.
    loading: (commit, payload) => commit(mutations.SET_LOADING, payload),
    // Called with an error object when an error occurs
    error: globalMutations.PUSH_ERROR,
  },
  // Properties on an object to search within for matches
  searchProperties: ['title', 'label', 'name'],
  // Optionally indicate how an item should be transformed before being sent in a POST/PUT request
  prepItemForPost: (item) => item,
  // Whether context configured globally should not be included for this module
  excludeGlobalContext: false,
};

export const makeDefaultState = () => ({
  // The context of the paginator. Used when the records only appear under a parent relation
  // eg. variants under products. See the pagination readme for a detailed explaination
  context: {},
  // Current page of items
  items: [],
  // Total count of all items (for the current context)
  totalCount: null,
  // The current search term being used to filter a list
  currentSearchTerm: '',
  // Any search filters that are currently applied
  currentFilters: [],
  // Whether a search action is taking place
  isSearching: false,
  // Whether an operation is in progress
  isLoading: false,
  // An array of objects, with all the items for specific context under the `items` key, and the
  // total count (provided by the API) under the `count` key. All the items are in order -
  // separated by `undefined` values for records not fetched yet.
  byContext: [],
  // Additional items that may have been fetched by an individual call
  additionalItems: [],
  // The current page
  page: null,
  // The number of records that should be in the page
  pageSize: 15,
  // A promise that is unresolved while an update/delete request is happening, implying that records
  // should not be modified further until the promise is resolved.
  lock: Promise.resolve(),
});

// Generates VueX config for the store, to be used as a module in an exiting configuration
export const createPaginationModule = (
  endpoint,
  options,
) => {
  // Combine default and provided config, and destruct it
  const config = {
    ...defaultOptions,
    ...options,
    endpoint,
    hooks: options && options.hooks
      ? { ...defaultOptions.hooks, ...options.hooks }
      : defaultOptions.hooks,
  };

  return {
    namespaced: config.namespaced,
    state: {
      ...makeDefaultState(),
      pageSize: config.initialPageSize,
    },
    mutations: mutationProvider(config),
    actions: actionProvider(config),
    getters: getterProvider(config),
  };
};

// Helper to apply the store config produced by the default export to an existing store config, if
// you don't want to use it as a module.
export const applyPagination = (...options) => (module) => {
  const pagination = createPaginationModule(...options);
  return {
    ...module,
    state: {
      ...module.state,
      ...pagination.state,
    },
    mutations: {
      ...module.mutations,
      ...pagination.mutations,
    },
    actions: {
      ...module.actions,
      ...pagination.actions,
    },
    getters: {
      ...module.getters,
      ...pagination.getters,
    },
  };
};

export {
  actions,
  getters,
  mapPagination,
  mutations,
};
