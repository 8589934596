export default [{
  path: '/products',
  component: () => import(/* webpackChunkName: "products" */ './views/index.vue'),
  meta: {
    title: (i18n) => i18n.t('product.products'),
  },
  children: [
    {
      path: '/',
      name: 'products.home',
      component: () => import(/* webpackChunkName: "products" */ './views/home.vue'),
    },
    {
      path: ':id(prod_[^/]+)',
      name: 'products.edit',
      component: () => import(/* webpackChunkName: "products" */ './views/edit.vue'),
      meta: {
        showBack: true,
        title: (i18n) => i18n.t('product.edit'),
      },
    },
    {
      path: 'add',
      name: 'products.add',
      component: () => import(/* webpackChunkName: "products" */ './views/edit.vue'),
      meta: {
        showBack: true,
        title: (i18n) => i18n.t('product.add'),
      },
    },
  ],
}];
