export default {
  account: {
    account: "Compte",
    currentPassword: "Mot de passe actuel",
    dashboardLanguage: "Langue du dashboard",
    deleteAccount: "Supprimer le compte",
    deleteCallout: "Vous êtes sur le point de supprimer définitivement votre compte.",
    deleteFailed: "Échec de la suppression de votre compte ! Réessayez ultérieurement.",
    deleteMessage: "La suppression de votre compte est permanente et ne peut pas être annulée. Veuillez vous assurer que vous avez exporté toutes vos commandes.",
    deleteMyAccount: "Supprimer mon compte",
    details: "Informations du compte",
    emailToolTip: "This email is associated to your account. You will receive order notifications and account information at this email.",
    information: "Informations du compte",
    language: "Langue",
    logout: "Déconnexion",
    missingPasswords: "Veuillez saisir vos mots de passe actuels et souhaités.",
    newPassword: "Nouveau mot de passe",
    passwordUpdateFailed: "Impossible de mettre à jour votre mot de passe. Veuillez réessayer.",
    passwordUpdated: "Mise à jour du mot de passe réussie",
    updateDetails: "Mettre à jour les informations",
    updateFailed: "Échec de l'enregistrement des informations de votre compte. Veuillez réessayer.",
    updateLanguage: "Mettre à jour la langue",
    updatePassword: "Mettre à jour le mot de passe",
    updatedDetailsSuccessfully: "Details updated successfully.",
    updatedLanguageSuccessfully: "Language updated successfully."
  },
  address: {
    city: "Ville",
    country: "Pays",
    line1: "Adresse ligne 1",
    line2: "Adresse ligne 2",
    name: "Nom",
    state: "État",
    zipCode: "Code postal"
  },
  attribute: {
    attributes: "Attributes"
  },
  attributes: {
    add: "Add attribute",
    added: "Added attribute.",
    allowMultiselect: "Allow multiselect",
    attributes: "Attributes",
    configureDropdown: "Configure dropdown",
    configureRadio: "Configure radio button",
    confirmDelete: "Are you sure you want to delete this attribute?",
    defaultValue: "Default value",
    defaultValueTooltip: "You will be able to select a default value for this attribute when you have configured the type and at least one option (if using radio or options type).",
    deleteFailed: "Failed to delete your attribute! Try again later.",
    deleted: "Attribute deleted.",
    descriptionTooltip: "This is for internal use only and is not visible to the API. Adds a tooltip to the dashboard.",
    duplicateWarning: "It looks like you have duplicated options or radio values, you are still able to save the attribute but this may have an undesired effect.",
    edit: "Edit attribute",
    introDescription: "Attributes allow you to add custom input types to help you customize your eCommerce experience. An example of this may be to add a selector for inventory location on each product.",
    introTitle: "Customize your data, your way, with attributes.",
    saveFailure: "Failed to save your attribute! Please try again.",
    saveSuccess: "Your attribute has been saved successfully.",
    typeRequired: "Field type is required",
    updated: "Updated attribute."
  },
  auth: {
    somethingWentWrong: "Something went wrong. Please try logging in again.",
    unableToAuth: "Unable to authorize your account. Please try again."
  },
  billing: {
    accruedFees: "Frais de transaction cumulés",
    addCard: "Ajouter une carte",
    addPaymentMethod: "Ajouter un mode de paiement",
    billing: "Facturation",
    changePlan: "Changer de niveau de service",
    checFee: "Frais Chec ({currency})",
    choosePlan: "Choisir un niveau de service",
    confirmChangePlan: "Si le forfait que vous modifiez comporte des restrictions sur les fonctionnalités de la plate-forme (telles que Commerce Rays), vos ressources seront automatiquement remises à l'échelle pour s'adapter aux limites de votre nouveau forfait.",
    currentPlan: "Niveau de service actuel",
    customPricing: "Tarification personnalisée",
    emailAddress: "Adresse e-mail de facturation",
    invoiceAmount: "Montant",
    invoiceDate: "Date",
    invoiceDescription: "Description",
    invoiceDownload: "Télécharger",
    invoiceStatus: "Statut",
    invoices: "Factures",
    monthlyCeiling: "{ceiling} plafond mensuel",
    noPaymentMethod: "Pas de mode de paiement.",
    orderValue: "Montant de la commande ({currency})",
    overageFee: "{fee} supplément",
    paid: "Payé",
    paymentGateway: "Plateforme de paiement",
    paymentMethod: "Moyen de paiement",
    paymentMethodRequired: "Veuillez ajouter un mode de paiement avant de choisir de nouveaux forfaits",
    perMonth: "Mensuel",
    perTransaction: "Par transaction",
    planDetails: "Détails du niveau de service",
    planMonthlyCeiling: "{ceiling} plafond mensuel",
    planName: "Niveau de service {name} ",
    planOverage: "{overage} excédent",
    planSummary: "Vous serez facturé {perMonth} par mois et {perTransaction} par transaction",
    planTransactionFee: "{fee} frais de transaction",
    transactionFeeOrder: "Commande",
    transactionFeesTitle: "Frais de transaction facturés le {date}",
    unpaid: "Non payé",
    updateCard: "Mettre à jour",
    updateEmail: "Mettre à jour l'adresse e-mail de facturation",
    viewInvoice: "Voir la facture",
    voided: "Voided"
  },
  category: {
    add: "Ajouter une catégorie",
    addSubCategory: "Add sub-category",
    added: "Catégorie ajoutée.",
    addedSubCategory: "Added sub-category.",
    categories: "Catégories",
    chooseParentCategory: "Choose parent category",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer cette catégorie ?",
    confirmDeleteTitle: "Êtes-vous sûr ?",
    create: "Créer une catégorie",
    createError: "Désolé, nous rencontrons des problèmes pour créer cette catégorie.",
    deleteFailed: "Failed to delete your category! Please try again.",
    deleted: "Catégorie supprimée",
    edit: "Modifier la catégorie",
    editMultiple: "Modifier les catégories",
    images: {
      title: "Galerie d'images",
      types: "| {types} acceptés | {types} & {lastType} acceptés"
    },
    introSplash: {
      description: "Create infinitely nestable product categories with category images and descriptions. {more}.",
      more: "En savoir plus",
      title: "Add categories and sub-categories."
    },
    noAvailableCategories: "Please add another category first",
    noSubCategories: "This category has no sub-categories.",
    permalink: "Permalink",
    pleaseEnterName: "Veuillez saisir un nom",
    pleaseEnterPermalink: "Please enter a permalink",
    save: "Enregistrer la catégorie",
    selectPlaceholder: "Choisir une catégorie",
    subCategories: "Sous-catégories",
    update: "Mettre à jour la catégorie",
    updateError: "Désolé, nous rencontrons des problèmes pour enregistrer cette catégorie.",
    updateSubCategoryError: "Sorry, we're having problems saving this sub-category.",
    updated: "Catégorie mise à jour."
  },
  customer: {
    add: "Ajouter un client",
    added: "Client ajouté",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer ce client ?",
    create: "Créer un client",
    createError: "Désolé, nous rencontrons des problèmes pour créer ce client.",
    customers: "Clients",
    deleteFailed: "Failed to delete your customer! Please try again.",
    deleted: "Client supprimé.",
    edit: "Modifier le client",
    emailTaken: "Cette adresse e-mail est déjà utilisée",
    externalId: "ID externe",
    introSplash: {
      description: "Manually add customers or wait to make a sale. Add an external customer ID if you manage customers via a CRM.",
      title: "Your customers will be displayed here"
    },
    notesTitle: "Remarques",
    save: "Enregistrer le client",
    update: "Mettre à jour client",
    updateError: "Désolé, nous rencontrons des problèmes pour enregistrer ce client.",
    updated: "Client mis à jour."
  },
  developer: {
    apiKeys: "Clés API",
    apiKeysCors: "API keys & CORS",
    checCli: "Chec CLI",
    cliNotice: "Use the {cli} to stream logs in real time: {cliCommand}",
    copyCodeFailure: "Failed to copy the code to the clipboard. Please refresh the page and try again.",
    copyCodeSuccess: "The code has been copied to your clipboard.",
    corsDomainsHelp: "You may configure a list of trusted domains (including the scheme and port) for CORS headers when using public API keys. If you configure one domain, all API responses will use that domain in the \"Access-Control-Allow-Origin\" header. If you specify multiple, API calls will compare against the request's \"Origin\" header. If you leave this blank, all origins will be allowed (default).",
    crossOriginResourceSharing: "Cross-Origin Resource Sharing (CORS) origins",
    dateTime: "Date et heure",
    ipAddress: "Adresse IP",
    keyUsed: {
      label: "Key used",
      "public": "Publique",
      secret: "secrète"
    },
    method: "Méthode",
    request: "Demande",
    requestMethod: {
      "delete": "supprimer",
      get: "Obtenir",
      label: "Méthode de demande",
      post: "POST",
      put: "PUT"
    },
    response: "Réponse",
    responseDate: {
      label: "Finalisé le"
    },
    responseStatus: {
      badRequest: "Mauvaise Demande",
      label: "Response status",
      notFound: "Introuvable",
      ok: "Valider",
      other: "Other/server errors",
      requestFailed: "Échec de la demande",
      unauthorized: "Non autorisé",
      validation: "Traitement des données"
    },
    summary: "Résumé",
    url: "URL"
  },
  discount: {
    add: "Ajouter une réduction",
    added: "Réduction ajoutée",
    all: "Tout",
    code: "Code",
    codeRequired: "Le code de réduction est requis",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer cette réduction ?",
    create: "Créer une réduction",
    deleteFailed: "Failed to delete your discount! Please try again.",
    deleted: "Remise supprimée.",
    discountCode: "Code de réduction",
    discountOffProduct: "{discount} ​de remise sur {product}\n",
    discounts: "Réductions",
    edit: "Modifier la remise",
    introSplash: {
      description: "Add $ or % based discount codes, their quantity, start and end dates. {more}.",
      more: "En savoir plus",
      title: "Add a discount code and run a promotion."
    },
    neverExpires: "Jamais",
    productTooltip: "If left empty, this discount will work for all products",
    productsLabel: "Limit to these products",
    saveError: "Désolé, nous rencontrons des problèmes pour enregistrer cette remise.",
    updated: "Remise mise à jour.",
    valueRequired: "Veuillez saisir la valeur de la remise"
  },
  error: {
    notFoundSuggestion: "Revenez à la page d'où vous venez et réessayez.",
    notFoundTitle: "Introuvable",
    permissionErrorSuggestion: "Essayez de vous déconnecter et de vous reconnecter. Si le problème persiste, veuillez contacter le support technique.",
    permissionErrorTitle: "Permission refusée !",
    serverErrorSuggestion: "Prenez un café et réessayez dans quelques minutes. Nous avons également été informés de cette erreur.",
    serverErrorTitle: "Quelque chose s'est mal passé...",
    somethingWentWrong: "Oups. Quelque chose s'est mal passé !"
  },
  extraFields: {
    add: "Add extra field",
    addOptions: "Hit enter or comma to add an option",
    collectsBilling: "Exiger adresse de facturation",
    collectsFullName: "Exiger le nom complet",
    collectsShipping: "Nécessite une adresse d'expédition",
    confirmDeleteExtraField: "Are you sure you want to delete this extra field? Storefronts that rely on this extra field may be affected.",
    deleteFailed: "Failed to delete your extra field! Please try again.",
    deleted: "Extra field deleted successfully.",
    description: "Recueillir des informations personnalisées auprès de votre client.",
    edit: "Edit extra field",
    extraFields: "Champs supplémentaires",
    fieldType: "Type de champ",
    fieldTypeDescription: "Select the field type for your extra field. This is for frontend use.",
    introDescription: "Extra fields are custom merchant defined data fields that are globally available, usually in a checkout. An example might be a custom extra field for collecting customers' phone numbers in a checkout form.",
    introTitle: "Collect custom data from customers with global extra fields.",
    name: "Nom du champ supplémentaire",
    nameRequired: "Le nom est obligatoire",
    saveFailure: "Sorry, we're having a problem saving your extra field.",
    saveSuccess: "Your extra field has been saved successfully.",
    typeRequired: "Field type is required",
    updateSuccess: "Your extra field has been updated successfully."
  },
  gateways: {
    add: "Add payment gateway",
    braintree: "Braintree",
    braintreeDocumentation: "See the documentation",
    braintreeHelp: "Enter your Braintree merchant ID, public key, private key, and your tokenization key. {documentation} for more information on tokenization keys.",
    chooseGateway: "Please choose a gateway",
    confirmDelete: "Are you sure you want to delete this payment gateway? Any stores using it will stop accepting payments immediately from this gateway.",
    deleteFailed: "Something went wrong while trying to delete this payment gateway. Please try again.",
    deleted: "Payment gateway deleted.",
    edit: "Edit payment gateway: {gateway}",
    email: "Adresse e-mail",
    gateway: "Passerelle",
    introDescription: "A payment gateway authorizes credit card or direct payments processing for online businesses. The test gateway has been enabled for making test orders.",
    introTitle: "Start collecting payments by enabling your payment gateway(s).",
    keyId: "ID de la clé",
    keySecret: "Clé secrète",
    manual: "Manuel",
    manualConfig: {
      detailsHelp: "Details about this payment method - shown before placing an order.",
      name: "Name of payment method",
      paymentInstructions: "Instructions de paiement",
      paymentInstructionsHelp: "Details on how to pay - shown to customers after placing an order."
    },
    manualHelp: "You will manually have to record payments and fulfill orders placed using this method.",
    manualName: "Manuel",
    merchantId: "Id Marchand",
    omise: "Omise",
    omiseDocumentation: "Omise documentation",
    omiseHelp: "Enter your Omise public key and secret key, both are available from the Omise Dashboard. Learn more in the {documentation}.",
    paymentMethodHelper: "To add a live payment gateway, please add a payment method in the billing settings page.",
    paypal: "PayPal",
    paystack: "Paystack",
    paystackDocumentation: "Paystack documentation",
    paystackHelp: "Enter your Paystack public key and secret key, both are available from the Paystack Dashboard. Learn more in the {documentation}.",
    privateKey: "Clé privée",
    publicKey: "Clé publique",
    publishableKey: "Clé publiable (Publishable Key)",
    razorpay: "Razorpay",
    redirectingToSquare: "Redirecting to Square",
    sandboxNotAvailable: "Sandbox mode is not available for this gateway.",
    saveFailed: "Failed to save your payment gateway, please check you have filled out all required fields.",
    saved: "Your payment gateway was successfully saved.",
    secretKey: "Clé secrète",
    square: "Square",
    squareHelp: "When you click 'Save changes' below, you will be redirected to Square to login to your payment account.",
    stripe: "Stripe",
    stripeDocumentation: "Stripe documentation.",
    stripeHelp: "Enter your publishable key and your secret key, both available from the Stripe Dashboard. Learn more in the {documentation}",
    stripeMethodTypesLabel: "Payment method types",
    stripeMethodTypesTooltip: "The list of payment method types that this gateway may use, if using Payment Intents. If none are choosen, 'card' is selected by default.  \n\nClick to see documentation.",
    testGateway: "Test gateway",
    tokenizationKey: "Tokenization key",
    transactionVolume: "Transaction volume",
    weSupport: "Gateways we support"
  },
  general: {
    about: "À propos",
    actions: "Actions",
    active: "Actif",
    add: "Ajouter",
    adding: "Ajout en cours…",
    address: {
      city: "Ville",
      country: "Pays",
      postalZip: "Code postal",
      provinceState: "Province/state",
      street: "Rue"
    },
    advanced: "Avancé",
    all: "Tout",
    amount: "Montant",
    amountValue: "Montant: {value}",
    andMore: " & {n} plus",
    areYouSure: "Êtes-vous sûr ?",
    assets: "Actifs",
    auto: "Automatique",
    backTo: "Retour à {page}",
    backToPreviousPage: "Retour à la page précédente",
    byAuthor: "by {author}",
    calculation: "Calcul",
    cancel: "Annuler",
    cancelled: "Annulé",
    checkbox: "Case à cocher",
    checked: "Checked",
    clearSearch: "Effacer la recherche",
    clickToCopy: "Click to copy",
    close: "Fermer",
    contactUs: "Contactez-nous",
    copied: "Copié !",
    copy: "Copier",
    country: "Pays",
    creating: "Création en cours...",
    currency: "Devise",
    date: "Date",
    dateTime: "Date / Heure",
    "default": "Par défaut",
    "delete": "Supprimer",
    description: "Description",
    descriptionOptional: "Description (facultatif)",
    details: "Détails",
    digital: "Numérique",
    disable: "Désactiver",
    disabled: "Désactivé",
    discount: "Réduction",
    draft: "Brouillon",
    duplicate: "Dupliquer",
    edit: "Modifier",
    emailAddress: "Adresse e-mail",
    empty: "Vide",
    enable: "Activer",
    enabled: "Activé",
    environment: "environnement",
    error: "Sorry, there has been an error. Please try again.",
    expired: "Expiré",
    expires: "Expire",
    "false": "Faux",
    firstName: "Prénom",
    "for": "Pour",
    fulfill: "Remplir",
    fulfilled: "Expédié",
    generate: "Générer",
    getStarted: "Commencer",
    goBack: "Retour",
    health: "Health",
    hidden: "Masqué",
    home: "Accueil",
    hostedExperiences: "Hosted experiences",
    id: "Identifiant",
    images: "Images",
    inactive: "Inactif",
    item: "élément",
    label: "Label",
    lastName: "Nom",
    learnMore: "En savoir plus",
    learnMoreButton: "En savoir plus",
    live: "EN DIRECT",
    loading: "Chargement...",
    loggedInAs: "Connecté(e) en tant que : %1$s",
    logo: "Logo",
    longText: "Long text",
    merchant: "Marchand",
    month: {
      april: "Avril",
      august: "Août",
      december: "Décembre",
      february: "Février",
      january: "Janvier",
      july: "Juillet",
      june: "Juin",
      march: "Mars",
      may: "Mai",
      november: "Novembre",
      october: "Octobre",
      september: "Septembre"
    },
    name: "Nom",
    never: "Jamais",
    "new": "New",
    next: "Suivant",
    nextName: "Suivant: {name}",
    no: "Non",
    noResults: "Aucun résultat ne correspond à votre recherche",
    notAvailable: "N/A",
    notFulfilled: "Non expédié",
    notPaid: "Non payé",
    notRequired: "Not required",
    notes: "Remarques",
    number: "Numéro",
    open: "Ouvrir",
    option: "Option",
    options: "Options",
    override: "Remplacer",
    paid: "Payé",
    parent: "Parent",
    partiallyFulfilled: "Partiellement complétée",
    paymentGateway: "Plateforme de paiement",
    permalinkSlug: "Permalien/identifiant",
    phoneNumber: "Numéro de téléphone",
    placed: "Placé",
    previous: "Précédent",
    previousName: "Précédent: {name}",
    price: "Prix",
    "private": "Private",
    province: "Province",
    published: "Publié",
    quantity: "Quantité",
    radio: "Radio",
    rate: "Noter",
    rates: "Taux",
    reference: "Référence",
    refund: "Remboursement",
    refunded: "Remboursé",
    refunding: "Remboursement en cours",
    regenerate: "Régénérer",
    region: "Région",
    regions: "Régions",
    remaining: "Restant",
    required: "Obligatoire",
    sales: "Ventes",
    sandbox: "Bac à sable",
    sandboxMode: "Mode développeur",
    save: "Sauvegarder",
    saveAndClose: "Sauvegarder et fermer",
    saveChanges: "Enregistrer les modifications",
    saved: "Modifications enregistrées",
    saving: "Enregistrement en cours…",
    search: "Rechercher",
    security: "Sécurité",
    selectOption: "Sélectionner une option",
    sending: "Envoi…",
    shareEmbed: "Partager/Intégrer",
    shipping: "Expédition",
    shippingMethod: "Shipping ({method})",
    shortText: "Short text",
    slug: "Identifiant",
    standard: "Standard",
    startDate: "Date de début",
    state: "État",
    status: "Statut",
    subtotal: "Sous-total",
    tax: "Taxe",
    template: "Modèle",
    text: "Texte",
    time: "Temps",
    timestamp: "Horodatage",
    today: "Aujourd'hui",
    total: "Total",
    totalPaid: "Total payé",
    "true": "Vrai",
    type: "Type :",
    unchecked: "Unchecked",
    unfulfilled: "Non complétée",
    unlimited: "Unlimited",
    update: "Mettre à jour",
    upgradePlan: "Mettre à niveau votre forfait",
    url: "URL",
    value: "Value",
    view: "Afficher",
    viewDetails: "Afficher les détails",
    visibility: "Visibility",
    visible: "Visible",
    whatIsThis: "Aide?",
    xOfY: "{x} de {y}",
    yes: "Oui",
    yesterday: "Hier"
  },
  home: {
    addProducts: "Ajouter vos produits",
    configureShipping: "Configurer votre expédition",
    configureTax: "Configurer votre taxe",
    createCommerceRay: "Créez votre premier Commerce Ray",
    enableGateway: "Activer une passerelle de paiement",
    gettingStarted: "Mise en route",
    gettingStartedRays: "Créez votre Commerce Ray",
    latestAnnouncements: "Dernières annonces",
    latestChanges: "Dernières modifications",
    messageUs: "Écrivez-nous",
    speakToAnExpert: "Parler à un expert",
    speakToCopy: "Nos experts en commerce peuvent s'assurer que Commerce.js convienne parfaitement à votre entreprise et vous aider à concevoir une solution.",
    title: "Bienvenue",
    viewChangelog: "Voir changelog",
    welcomeSubtitle: "Bienvenue ! Nous sommes là pour vous aider à faire fonctionner les choses."
  },
  integration: {
    about: "À propos {integration}",
    aboutThisIntegration: "About this integration",
    add: "Add integration",
    addedIntegration: "Integration added",
    awaitingThirdParty: "Your integration is being installed. This may take some time.",
    awaitingThirdPartyNotification: "Your {type} integration has been created, and is being installed. This may take some time.",
    configureIntegration: "Configure integration",
    confirmDelete: "Are you sure you want to delete this integration?",
    createError: "Sorry, we're having problems activating this integration.",
    creating: "Creating integration...",
    deleteFailed: "Failed to delete your integration! Try again later.",
    deleted: "Integration deleted.",
    edit: "Modifier l'intégration",
    editNamed: "Modifier l'intégration - {name}",
    filter: {
      checkout: "Caisse",
      content: "Contenu",
      dataMesh: "Data mesh",
      email: "Adresse courriel",
      frontend: "Frontend",
      fulfillment: "Épanouissement",
      orders: "Commandes",
      payments: "Paiements",
      personalization: "Personnalisation",
      tax: "Taxe"
    },
    filterLabel: "Filtre",
    filterTagLabel: "Filtre: {tag}",
    healthTooltip: "{percent} of the last few integration runs were successful",
    integration: "Intégration",
    integrations: "Intégrations",
    introSplash: {
      description: "Connect with other services and compose your desired business stack.",
      title: "Configure integrations with 3rd party providers."
    },
    lastRun: "Last run",
    noConfigNeeded: "This template requires no additional configuration",
    noLogo: "No logo",
    readyNotification: "Good news, your {type} integration is ready!",
    saved: "The integration was saved.",
    savedProvisioning: "Your integration is being set up, this will take a moment...",
    savingFailure: "This integration failed to save. Please try again.",
    status: {
      healthy: "En bonne santé",
      notApplicable: "N/A",
      unhealthy: "Malsaine",
      unstable: "Unstable"
    },
    view: "View integration"
  },
  logs: {
    introSplash: {
      description: "API request logs with details will be listed here for ease of debugging.",
      title: "Your request logs will be shown here"
    },
    logs: "Logs"
  },
  merchant: {
    createFull: "Let's set up your merchant",
    createLeadIn: "Your information can be changed or updated at any time.",
    createMerchant: "Create merchant",
    seeAll: "View all merchants",
    selectMerchant: "Select a merchant"
  },
  notes: {
    add: "Ajouter une note",
    addANote: "Ajouter une note",
    confirmDelete: "Êtes-vous sûr(e) de vouloir supprimer cette note ?",
    note: "Remarque"
  },
  order: {
    capturedOn: "Capturé :",
    cardEndingIn: "Carte se terminant dans {number}",
    changeCustomer: "Modifier les informations du client",
    confirmRefund: "Êtes-vous sûr de vouloir rembourser cette commande ?",
    customerDetails: "Détails du client",
    customerUpdateFailed: "Désolé, nous rencontrons des problèmes pour mettre à jour les informations du client.",
    customerUpdated: "Informations client mises à jour sur la commande # {reference}",
    date: "Date de la commande",
    dateTime: "Date / Heure",
    deleteTest: "Delete test orders",
    deleteTestConfirmation: "Are you sure you want to delete all of your sandbox orders?",
    edit: "Modifier la commande",
    "export": "Export orders",
    exportConfirmation: "We will send a copy of your orders (with the current filters) in CSV format to your merchant email when it's ready.",
    fulfillItems: "Compléter les articles",
    fulfillment: {
      confirmCopy: "Confirming will send an order status update email with a tracking number (if included) to your customer. These changes cannot be reversed. Is this okay?",
      confirmHeader: "Please confirm your fulfillment changes",
      dateShipped: "Date shipped: {date}",
      reference: "Référence : {reference}",
      shipmentNumber: "Numéro d'expédition {number}",
      shippingCarrier: "Transporteur",
      trackingNotProvided: "Suivi non fourni",
      trackingNumber: "Suivi # {number}",
      trackingNumberOptional: "Numéro de suivi (facultatif)"
    },
    fulfillmentStatus: {
      fulfilled: "Expédié",
      label: "Fulfillment status",
      not_fulfilled: "Non expédié",
      partially_fulfilled: "Partiellement complétée",
      returned: "Renvoyée"
    },
    gatewayTransactionId: "ID de la transaction sur la passerelle de paiement : {id}",
    itemFulfilled: "Items fulfilled successfully.",
    newNotification: "Nouvelle commande {reference} pour {value} !",
    nextOrder: "Commande suivante",
    noOrders: {
      copy: "We'll then show you orders and sales displayed over time.",
      title: "Make your first sale!"
    },
    orderNotes: "Order notes",
    orderNumber: "Ordre",
    orderStatus: "Statut de la commande",
    orders: "Commandes",
    ordersAllTime: "Ventes - Tous les temps",
    ordersOnMonth: "Commandes - {month}",
    ordersOnMonthHelptip: "Number of orders since the start of this month, compared with the same period in the previous month",
    overview: "Aperçu",
    paymentMethod: "Moyen de paiement",
    paymentSaveFailed: "Sorry, we're having problems saving this payment.",
    paymentSaved: "Payment saved successfully.",
    paymentStatus: {
      label: "Statut du paiement",
      not_paid: "Non payé",
      paid: "Payé",
      partially_paid: "Partiellement payé",
      pending: "Paiement en attente",
      refunded: "Remboursé"
    },
    previousOrder: "Commande précédente",
    receiptSendFailed: "Impossible de renvoyer le reçu, veuillez réessayer.",
    receiptSent: "Reçu envoyé.",
    recordManualPayment: "Record manual payment",
    reference: "Référence : {reference}",
    refundFailed: "Impossible de rembourser la commande, veuillez réessayer.",
    refundSuccess: "Commande remboursée avec succès.",
    resendReceipt: "Renvoyer le reçu",
    salesAllTime: "Soldes - Tous les temps",
    salesOnMonth: "Les ventes - {month}",
    salesOnMonthHelptip: "Sales performance since the start of this month, compared with the same period in the previous month",
    sandbox: "Commande test",
    shipments: "Envois",
    status: "Statut",
    testOrdersDeleted: "Test orders successfully deleted.",
    transactionReferenceId: "Transaction/reference ID",
    updateAddressError: "Something went wrong while saving the address, please try again.",
    updatedBilling: "Updated billing address.",
    updatedShipping: "Updated shipping address.",
    viewMultiple: "Afficher les commandes",
    viewOne: "Voir la commande"
  },
  product: {
    add: "Ajouter un produit",
    added: "Produit ajouté",
    additionalActions: "Additional product actions",
    basePrice: "Prix de base",
    chooseRelatedProducts: "Choisir des produits associés",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer ce produit ?",
    confirmDeleteTitle: "Êtes-vous sûr ?",
    copyPaste: "Copiez et collez ce code dans votre site.",
    count: "Aucun produit | 1 produit | {n} produits",
    customPermalink: "Permalien personnalisé",
    "delete": "Supprimer le produit",
    deleteFailed: "Échec de la suppression de votre produit ! Réessayez plus tard.",
    deleted: "Produit supprimé.",
    description: "Description",
    digitalFulfillment: {
      doesNotExpire: "Les liens n'expirent pas",
      downloadLimit: "Limite de téléchargement",
      duration: "Durée",
      durationPeriod: "Durée de la période",
      expiryMessage: "Après {duration} {period} d'achat",
      linkExpiration: "Expiration du lien",
      period: {
        days: "jours | jour | jours",
        months: "mois | mois | mois",
        weeks: "semaines | semaine | semaines"
      },
      title: "Livraison numérique"
    },
    duplicateProduct: "Dupliquer le produit",
    duplicated: "Product successfully duplicated and you are now editing the duplicate.",
    edit: "Modifier le produit",
    embed: "Intégrer",
    embedCheckout: "Intégrer le module de paiement en ligne",
    embedCheckoutDescription: "Intégrez le module de paiement en ligne directement sur votre site Web ou blog.",
    embedShareSell: "Intégrez, partagez, vendez !",
    images: {
      alert: "The image gallery contains all images associated with this product including its attached variants.",
      title: "Galerie d'images",
      types: "| {types} acceptés | {types} & {lastType} acceptés"
    },
    includesNCountries: "Includes 1 country | Includes {number} countries",
    introSplash: {
      api: "Create product API",
      description: "Add your product and variants manually via the dashboard or programatically via our {api}. Products must have at least a name and price. {more}.",
      more: "En savoir plus",
      title: "Add your first physical or digital product"
    },
    inventory: {
      available: "Stock disponible",
      managed: "Géré",
      tooltip: "Lorsque le stock est géré, vous pouvez définir le nombre d'articles disponibles, sinon il sera illimité"
    },
    lastSavedAt: "Dernière modification",
    linkCustomers: "Envoyez directement vos clients vers le module de paiement en utilisant cette URL.",
    minimumPrice: "Prix minimum",
    misc: "Divers",
    name: "Nom",
    noImage: "Aucune image",
    noRelatedAvailable: "You have no other products",
    payWhatYouWant: "Payez ce que vous voulez",
    price: "Prix",
    product: "Produit",
    products: "Produits",
    pwywEnabled: "« Payer ce que vous voulez » activé",
    relatedProducts: "Produits associés",
    saveFailed: "Échec de l'enregistrement du produit ! Veuillez réessayer.",
    saved: "Modifications enregistrées",
    searchEngineDescription: "Description pour les moteurs de recherche",
    searchEngineTitle: "Titre pour les moteurs de recherche",
    seo: "RÉFÉRENCEMENT",
    shippingCard: {
      baseRateLabel: "Taux de base",
      baseRateTooltip: "Le prix de base pour ce taux. D'autres tarifs seront ajoutés en plus de cela.",
      nameLabel: "Nom",
      onOwnLabel: "sur lui même",
      onOwnTooltip: "Le prix de ce tarif lorsque cet article est commandé seul. Ceci sera ajouté au tarif de base, par exemple Tarif de base + Tarif seul = Total du tarif d'expédition facturé",
      title: "Options d'expédition",
      withOthersLabel: "Avec d'autres",
      withOthersTooltip: "Le prix de ce tarif lorsqu'il est commandé avec d'autres articles. Il sera ajouté avec les autres frais d'expédition et le tarif de base par exemple Tarif de base + Tarif avec d'autres (tarif de cet article) + Tarif avec d'autres (tarif d'autres articles) = Total du tarif d'expédition facturé"
    },
    sku: "UGS",
    sortOrder: "Ordre de tri",
    sortOrderTooltip: "Lower values make your product show up in lists earlier, whereas higher values make it show up later.",
    thankYouPage: "Page de remerciement",
    variants: {
      addGroup: "Ajouter un groupe de variantes",
      addOption: "Ajouter une option",
      addVariant: "Ajouter une variante",
      amount: "Montant",
      andMore: "Et {count} variantes supplémentaires",
      bulkAdd: "Ajouter en bloc",
      bulkFailure: "Désolé, nous rencontrons des problèmes de mise à jour de vos variantes.",
      bulkSuccess: "Les variantes ont été mises à jour avec succès",
      chooseGroupsAndOptions: "Choisir des groupes et des options",
      create: "Créer une variante | Créer des variantes",
      createBulk: "Créer des variantes en bloc",
      createSingle: "Créer une seule variante",
      defaultPrice: "Prix initial",
      defaultPriceTooltip: "Le prix initial pour l'option du groupe de variantes",
      defaultVariantTitle: "Nouvelle variante",
      edit: "Modifier les variantes",
      editSingle: "Modifier la variante",
      editSingleNamed: "Modifier la variante",
      generateVariants: "Générer des variantes",
      groups: "Groupes | Groupe | Groupes",
      hasInvalidVariants: "This product has invalid variants. These variants cannot be added to a cart or checkout, and cannot be sold. To re-enable invalid variants, please edit them and address any issues.",
      help: "Différents types de ce produit (par exemple taille, couleur)",
      image: "Image",
      invalidReason: {
        duplicate_option_config: "This invalid variant shares options with another variant. Only one variant may exist for a specific set of options.",
        incomplete_option_config: "This invalid variant is missing an option from one of the variant groups configured for this product. Variants must have an option set for each group."
      },
      invalidVariantsOnSave: "Once you save, your variants will be marked as \"invalid\" and you will not be able to sell invalid variants until you fix the issues.",
      mustSave: "Vous devez enregistrer avant d'ajouter de nouvelles variantes",
      noGroups: "Les variantes vous aident à vendre des produits avec de légères différences, mais sont toujours le même produit. Par exemple, vous pouvez vendre un t-shirt de différentes couleurs, ou un pot de plantes de différentes tailles. Vous pouvez configurer les variantes pour avoir leur propre prix, UGS et inventaire.\n\nPour commencer, créez des groupes et des options pour vos variantes. Les groupes définissent le type de variante (par exemple la couleur). Les options sont un choix que votre client peut faire au sein de ce groupe (par exemple bleu).",
      noGroupsTitle: "Configurer des groupes de variantes pour vendre des variations d'un même produit",
      noOptions: "Veuillez saisir au moins une option",
      noVariants: "Utilisez le bouton « générer des variantes » ci-dessus pour créer des variantes. Les variantes sont des combinaisons de vos groupes et options de variantes. Vous pourrez définir individuellement le prix et l'inventaire pour chaque variante (si cette option est activée)",
      noVariantsTitle: "Aucune variante n'a été créée",
      noVariantsToGenerate: "Toutes les variantes existent déjà pour les options sélectionnées",
      optionDefaultImage: "Image par défaut",
      options: "Options | Option | Options",
      optionsInGroupsRequired: "Vous devez sélectionner au moins une option dans chaque groupe pour générer des variantes en bloc",
      optionsUpdateFailure: "Désolé, nous rencontrons des problèmes pour mettre à jour vos options de groupe de variantes.",
      optionsUpdateSuccess: "Les options du groupe de variantes ont été mises à jour avec succès",
      override: "Remplacer",
      preview: "Aperçu de variante",
      previewHelp: "Aperçu des variantes qui seront créées en fonction des options sélectionnées ci-dessus",
      quantity: "Quantité",
      requiresGroupsSave: "L'utilisation de cette fonctionnalité nécessite d'abord d'enregistrer les groupes.",
      requiresSave: "L'utilisation de cette fonctionnalité nécessite d'abord d'enregistrer le produit.",
      saveAndGenerate: "Save and generate variants",
      selectOptions: "Sélectionnez les options pour votre variante",
      singleCreateSuccess: "La variante a été créée avec succès",
      singleUpdateFailure: "Désolé, nous rencontrons un problème pour enregistrer votre variante.",
      singleUpdateSuccess: "La variante a été modifiée avec succès",
      title: "Variantes",
      variantDetails: "Détails de la variante",
      variantExists: "Il semble que cette variante existe déjà.",
      variantGroup: "Groupe de variantes",
      variantGroupName: "Nom du groupe de variantes",
      variantGroupTitle: "Groupe de variante: {group}",
      variantName: "Nom de variante",
      willInvalidateVariants: "Adding and/or removing variant groups will invalidate your existing variants, as each variant must have one option for each group, and cannot be duplicated."
    },
    viewProducts: "Voir les produits"
  },
  rays: {
    add: "Créer un nouveau Ray",
    commerceRays: "Commerce Rays",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer ce Ray ?",
    confirmDeleteTitle: "Êtes-vous sûr ?",
    create: "Créer Ray",
    "delete": "Supprimer le Ray",
    deleteFailed: "Échec de la suppression de votre Ray ! Réessayez plus tard.",
    deleted: "Ray supprimé.",
    deploying: "Publication en cours…",
    desktop: "Bureau",
    edit: "Modifier le Ray",
    embed: "Intégrer",
    embedModal: "Vous pouvez également intégrer votre module de paiement dans une fenêtre modale, déclenchée en cliquant sur un bouton « Acheter maintenant ».",
    exit: "Quitter",
    images: {
      title: "Images",
      types: "| {types} acceptés | {types} & {lastType} acceptés"
    },
    introduction: {
      description: "Un générateur de pages sans code avec des modules de paiements intégrés.",
      "for": {
        campaigns: "Campagnes",
        digitalProducts: "Produits numériques",
        influencers: "Partenariats d'influenceurs",
        mediaCheckouts: "Checkouts de médias",
        ppc: "Campagne de Paiement Par Clic (PPC)",
        productDrops: "Lancements de produits",
        title: "Les Rays sont pour",
        videoCommerce: "Video Commerce"
      },
      line1: "Tout le monde peut créer de belles pages de produits et de contenu qui attirent.",
      line2: "Choisissez parmi notre sélection de beaux thèmes éditables et vendez votre image de marque.",
      title: "✨ Bienvenue sur Commerce Rays"
    },
    lastDeployment: "Dernière publication :",
    mobile: "Mobile",
    nameHelper: "Pour l'identification interne.",
    noProducts: "You must have at least one product. To create a Ray, please start by adding a product.",
    noRays: "Pas de Ray trouvé.",
    noThemes: "Aucun thème trouvé",
    pickTheme: "Choisir un modèle",
    planWarning: {
      limitReached: "Vous avez atteint la limite de votre plan. Veuillez {upgrade} pour publier plus.",
      notice: "Votre plan actuel ne prend pas en charge l'utilisation de Rays. Veuillez {upgrade} pour publier.",
      upgrade: "Mettre à niveau votre forfait"
    },
    ray: "Ray",
    rayName: "Nom du Ray",
    rays: "Rays",
    sandboxHelper: "L'activation du mode sandbox vous permettra d'utiliser Stripe en mode test. Aucune commande réelle ne peut être créée en mode sandbox.",
    save: "Sauvegarder",
    saveDeploy: "Enregistrer et publier",
    saveFailed: "Impossible de sauver votre Ray ! Veuillez réessayer.",
    saved: "Modifications enregistrées",
    savedAndDeploying: "Modifications enregistrées et publication de votre Ray...",
    saving: "Enregistrement en cours…",
    select: "Sélectionner un thème",
    selectProducts: "Sélectionnez le (s) produit (s)",
    validationFailed: "Il y a un problème avec les paramètres que vous avez choisis. Veuillez vérifier la barre latérale pour les erreurs.",
    viewPublishedRay: "Voir le Ray publié",
    viewRay: "Voir le Ray",
    viewportWarning: "Cette partie de notre tableau de bord est mieux expérimentée sur un écran plus grand. Connectez-vous sur votre ordinateur pour afficher les Rays."
  },
  settings: {
    analytics: "Statistiques",
    data: "Données",
    developer: "Développeur",
    developerSettings: {
      addKey: "Add API key",
      apiKey: "Clé API",
      apiKeys: "Clés API",
      apiVersion: "Version API",
      changeKey: "Changer de clé",
      changelog: "See changelogs",
      confirmDeleteKey: "Are you sure you want to delete this API key? Any stores using this key will stop functioning immediately.",
      createKey: "Créer une clé d'API",
      createSuccess: "API key has been created successfully",
      deleteFailed: "Failed to delete your API key! Try again later.",
      deleted: "API key deleted.",
      editKey: "Edit API key",
      generateKey: "Are you sure you want to regenerate the key?",
      keyChangFailed: "Failed to update your API key! Try again later.",
      keyChanged: "Key has been successfully updated",
      label: "API key label",
      live: "EN DIRECT",
      "public": "Publique",
      resetKeyButtonTooltip: "Générer une nouvelle clé",
      resetKeyConfirmationHeader: "Générer une nouvelle clé?",
      sandbox: "Bac à sable",
      secret: "Secret",
      type: "Type :",
      updateFailure: "Failed to update your API key. Please try again.",
      updateSuccess: "API key successfully updated"
    },
    merchant: {
      analyticsCardTitle: "Google Analytics",
      analyticsGaTrackingId: "Tracking ID",
      analyticsGaTrackingIdTooltip: "Your unique Google Analytics tracking ID",
      analyticsLinkedDomain: "Add linked domain",
      analyticsLinkedDomainTooltip: "You can add multiple domains separated by a comma.",
      coverImage: "Image de couverture",
      coverImageTooltip: "This is the cover image that will be on your storefront.",
      descriptionTooltip: "A brief description about yourself/your company",
      emailTooltip: "This email is visible to your customers. Customer support emails will go here.",
      enableHostedCheckouts: "Enable hosted checkout",
      enableHostedCheckoutsTooltip: "The default hosted & embeddable checkout which is available for all of your products e.g. https://checkout.chec.io/example-product",
      enableHostedStorefrontTooltip: "The default storefront/space for your store e.g. https://spaces.chec.io/MERCHANT_ID",
      enabledHostedStorefront: "Enable hosted storefront",
      isPublic: "Make visible to public API",
      merchantAddress: "adresse",
      merchantId: "Id Marchand",
      merchantIdInfo: "This is your unique merchant ID. We may ask for it when you contact us.",
      name: "Nom de l’entreprise",
      nameTooltip: "Sous quel nom vendez vous ?",
      save: "Sauvegarder les paramètres",
      saveFailed: "Failed to save the merchant settings! Please try again.",
      saved: "Modifications enregistrées",
      timezoneTooltip: "All dates and times in Chec will be relative to this timezone",
      title: "Informations du marchand"
    },
    notifications: {
      customerLoginToken: "Client : jeton de connexion",
      customerOrderReceipt: "Client : reçu de la commande",
      customerOrderShipped: "Client : commande expédiée",
      dashboard: "Notifications du tableau de bord",
      dashboardSummary: "Contrôlez le comportement des notifications dans le tableau de bord Chec",
      emailNotificationSummary: "Activer/désactivez les notifications par e-mail par défaut envoyées par Chec.",
      emailNotifications: "Notification par e-mail",
      emails: "E-mails",
      merchantOrderReceipt: "Marchand : nouvelle commande reçue",
      navMenuHighlight: "Surbrillance du menu de navigation",
      news: "Nouvelles et annonces de fonctionnalités",
      none: "Aucun",
      notificationPopup: "fenêtre contextuelle de notification",
      title: "Notifications"
    },
    paymentGateways: "Passerelles de paiement",
    settings: "Paramètres",
    shipping: "Expédition",
    tax: {
      addState: "Add state",
      addTax: "Enable tax management",
      addTaxRate: "Add tax rates: {name}",
      addTaxZone: "Add tax zone",
      addedToRate: "Added to country rate",
      address: "adresse",
      addressAlert: "This is used to calculate tax and is displayed on invoices.",
      calculateAutomatically: "Calculate taxes automatically",
      configureTaxZone: "Configure tax zone",
      confirmDeleteZone: "Are you sure you want to delete this tax zone?",
      confirmDeleteZoneTitle: "Êtes-vous sûr ?",
      countrySelectInfo: "The tax rate configured here will be applied to all orders in this tax zone.",
      countryTax: "Country tax",
      deleted: "Tax configuration deleted",
      digitalRate: "Digital rate",
      editTaxRate: "Edit tax rates: {name}",
      editTaxZone: "Edit tax zone",
      enableTax: "Enable tax",
      enableVat: "Enable EU digital goods VAT",
      enableVatInfo: "Collect EU digital goods VAT (VAT MOSS) tax on digital goods. Tax rates are automatically calculated and applied to EU customers.",
      euVatMoss: "European Union (VAT MOSS)",
      introCopy: "Commerce.js allows you to easily define your region's tax rates to help you easily collect the appropriate tax during checkout. You can also choose to integrate any tax provider and implement custom business rules with your own serverless functions.",
      introTitle: "Let Commerce.js manage your tax!",
      nexusHelp: "Chec will automatically calculate sales tax including county, municipal, and other taxes. You are required to collect sales tax in states in which you have nexus.",
      nexusStates: "Nexus states",
      overrideRate: "Instead of country rate",
      pricesIncludeTax: "Do your prices include tax?",
      ratesAutoUpdated: "Rates are automatically updated & set.",
      saveFailed: "Failed to save the tax configuration! Please try again.",
      standardRate: "Tarif standard",
      taxRateZones: "Tax rate zones",
      taxRates: "Tax rates",
      title: "Taxe",
      zipAlreadyExists: "The provided ZIP code has already been added",
      zipInvalid: "The provided ZIP code does not appear to be a valid US postal ZIP code"
    },
    updateFailed: "Échec de l'enregistrement de vos préférences de notification. Veuillez réessayer.",
    updatedSuccessfully: "Préférences de notification mises à jour correctement.",
    viewStorefront: "Voir la vitrine"
  },
  shipping: {
    addCountries: "Add countries",
    addRegions: "Add regions",
    addZone: "Ajouter une zone",
    confirmDeleteZone: "Are you sure you want to delete this zone?",
    countriesRegions: "Countries & regions",
    countriesRegionsHelp: "Please note that changes to this panel will not be saved until the shipping zone is saved.",
    editCountries: "Edit countries",
    editRegions: "Edit regions for {country}",
    editZone: "Modifier la zone: {name}",
    enableOnProductsInfo: "If enabled, all products will inherit this shipping zone by default. Disable this to select specific products to inherit this shipping zone. All products will be enabled with no price delta attached.",
    enableOnProductsTitle: "Enable on all products",
    introSplash: {
      description: "A shipping zone is added by default as an example. Once you've added additional shipping zones, save and enable for the zones to be available for your products. Shipping zones can also be selected in the {productPage}.",
      title: "Add shipping zones for your products"
    },
    noCountriesRegions: "No countries or regions have been added to your zone. Start adding countries or regions to your zone before saving.",
    noCountriesRegionsTitle: "No countries or regions have been added",
    numCountries: "No. of countries",
    numRegions: "No. of regions",
    productPage: "product edit page",
    rateNameRequired: "The rate name is required",
    ratePriceRequired: "The rate price is required. For free rates use 0.",
    ratesLeadIn: "Increases to these rates can also be added in the {productPage}.",
    saveFailure: "Sorry, we're having a problem saving your shipping zone.",
    saveSuccess: "Your shipping zone has been saved successfully.",
    selectRegion: "Configure regions",
    shippingZones: "Shipping zones",
    zone: "Zone :",
    zoneDeleteFailed: "Something went wrong while trying to delete this zone. Please try again.",
    zoneDeleted: "Zone deleted.",
    zoneName: "Nom de la zone",
    zoneNameDescription: "The zone name is for internal use only and customers will not see this.",
    zoneNameRequired: "The zone name is required"
  },
  signup: {
    error: "Quelque chose s'est mal passé pendant la création de votre compte. Veuillez vérifier vos informations et réessayer.",
    form: {
      experience: "Nous pouvons donc vous offrir la meilleure expérience possible.",
      merchantDetails: "Informations du marchand",
      merchantEmail: "Courriel du marchand",
      merchantEmailDescription: "Cet e-mail est visible par vos clients. Vous recevrez également des notifications de commande ici.",
      merchantName: "Nom du marchand",
      merchantNameDescription: "Sous quel nom vendez vous ?",
      next: "Suivant",
      purposeTitle: "What are you using Commerce.js for?",
      roleTitle: "What is your primary role?",
      saving: "Enregistrement en cours…"
    },
    intro: {
      text: "Créez votre compte en quelques étapes et commencez à utiliser Commerce.js.",
      title: "Bienvenue, configurons votre compte"
    },
    page: {
      title: "Configuration du compte"
    },
    purposes: {
      launching: "Launching a new eCommerce business",
      migrating: "Migrating from a legacy solution",
      other: "Something else",
      researching: "Researching modern commerce solutions",
      student: "Building a student project"
    },
    roles: {
      cto: "CTO/VP of Engineering",
      developer: "Developer/engineer",
      manager: "eCommerce Manager",
      marketing: "Marketing",
      other: "Other",
      owner: "Business owner",
      solution_architect: "Solution Architect",
      student: "Student"
    }
  },
  slack: {
    alreadyJoined: "Déjà membre ?",
    description: "Notre communauté de développeurs et de concepteurs de commerce électronique.",
    error: {
      alreadyInTeam: "Cette adresse e-mail existe déjà dans notre communauté !",
      alreadyInvited: "Cette adresse e-mail contient une invitation en attente. Vérifiez votre email !",
      invalidEmail: "Vous devez fournir une adresse e-mail valide",
      missingEmail: "Vous devez fournir une adresse e-mail !"
    },
    getMyInvite: "Recevoir mon invitation",
    inviteSent: "Invitation envoyée ! Vérifiez vos e-mails.",
    join: "Rejoignez la communauté Slack",
    joinCommerceJs: "Rejoignez Commerce.js sur Slack",
    joinUs: "Rejoignez-nous sur Slack",
    openSlack: "Ouvrir Slack",
    registeredCount: "{count} utilisateurs sont enregistrés jusqu'à présent.",
    slack: "Slack"
  },
  spaces: {
    storefront: "Storefront"
  },
  status: {
    maintenance: "Service en cours de maintenance",
    major: "Panne partielle du système",
    minor: "Pannes de service mineures",
    none: "Tous les systèmes sont opérationnels"
  },
  validation: {
    enterEmail: "Please enter an email address",
    enterName: "Veuillez saisir un nom",
    enterOption: "Veuillez saisir un nom d'option",
    enterPrice: "Veuillez saisir un prix",
    enterQuantity: "Veuillez saisir une quantité",
    enterUrl: "Veuillez saisir une URL",
    enterValue: "Please enter an attribute value",
    generic: "Veuillez saisir une valeur pour {field}",
    number: "Veuillez entrer un nombre",
    positiveNumber: "Veuillez saisir un nombre positif",
    typeRequired: "Please enter a type",
    validBusinessName: "Please enter a business name",
    validCity: "Veuillez entrer une ville.",
    validCountry: "Veuillez sélectionner le pays",
    validEmail: "Please enter a valid email address",
    validNumber: "Veuillez saisir un numéro valide",
    validPostalZipCode: "Please enter a postal/zip code",
    validPrice: "Veuillez saisir un prix valide",
    validRegion: "Please select a province/state",
    validStreet: "Please enter a street name & number",
    validSupportEmail: "merci d'entrer ton e-mail",
    validUrl: "Veuillez saisir une URL valide"
  },
  versions: {
    current: "{version} - Actuel"
  },
  webhooks: {
    add: "Ajouter un webhook",
    added: "Webhook ajouté.",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer ce webhook ?",
    confirmRegenerateKey: "Êtes-vous sûr de vouloir régénérer la clé de signature ? Cela affectera tous les webhooks.",
    copiedKey: "Copié !",
    copyKey: "Copier la clé",
    created: "Créé",
    "delete": "Supprimer le webhook",
    deleteFailed: "Échec de la suppression du webhook !",
    deleted: "Webhook supprimé.",
    deliveryDetails: "Delivery details",
    deliveryHistory: "Historique des livraisons",
    edit: "Modifier le webhook",
    event: "Événement",
    eventName: "Event name",
    events: "Événements",
    generateKey: "Générer une nouvelle clé",
    introSplash: {
      description: "Webhooks configure callback URLs and subscribe them to events in your account. We’ll notify your callback URLs as these events happen, including the API response from the call that triggered the event. {more}.",
      more: "En savoir plus",
      title: "Add a webhook to extend the possibilities of your business."
    },
    regenerateKeyFailed: "Impossible de régénérer la clé de signature !",
    responseBody: "Response body",
    responseCode: "Code de réponse HTTP : {code}",
    save: "Enregistrer le webhook",
    saveFailed: "Échec de l'enregistrement du webhook ! Veuillez réessayer.",
    saved: "Modifications enregistrées.",
    sendTestRequest: "Envoyer la requête de test",
    sendingTestRequest: "Envoi d'une requête de test...",
    signingKey: "Clé de signature",
    testRequestCallbackFailed: "Requête de test envoyée mais la réponse du callback a échoué.",
    testRequestFailed: "Échec de l'envoi de la requête de test !",
    testRequestSuccess: "Requête de test envoyée avec succès.",
    view: "Voir le webhook",
    webhook: "Webhook",
    webhooks: "Webhooks"
  }
};