var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-field",class:{ 'dynamic-field--errored': Boolean(_vm.error) }},[_c('ChecFormField',{staticClass:"w-full",attrs:{"append-label":_vm.schema.description
      ? _vm.$t('general.whatIsThis')
      : null,"tooltip":_vm.schema.description}},[([
        'short_text',
        'long_text',
        'number' ].includes(_vm.schema.type))?_c('TextField',_vm._b({attrs:{"multiline":_vm.schema.type === 'long_text',"type":[
        'short_text',
        'long_text' ].includes(_vm.schema.type)
        ? 'text' : _vm.schema.type},on:{"input":_vm.handleChange}},'TextField',_vm.fieldProps,false)):_vm._e(),(_vm.schema.type === 'api_key')?_c('TextField',_vm._b({attrs:{"inner-input-class":"dynamic-field__password"},on:{"input":_vm.handleChange}},'TextField',_vm.fieldProps,false)):_vm._e(),(_vm.schema.type === 'password')?_c('PasswordField',_vm._b({attrs:{"hide-show-button":_vm.value === ''},on:{"input":_vm.handleChange}},'PasswordField',_vm.fieldProps,false)):_vm._e(),(_vm.schema.type === 'wysiwyg')?_c('ChecWysiwyg',_vm._b({attrs:{"size":"auto"},on:{"input":_vm.handleChange}},'ChecWysiwyg',_vm.fieldProps,false)):_vm._e(),(_vm.schema.type === 'boolean')?_c('ChecSwitch',{attrs:{"toggled":_vm.value,"name":"schema.key"},on:{"input":_vm.handleChange}},[_vm._v(" "+_vm._s(_vm.schema.label)+" ")]):_vm._e(),(_vm.schema.type === 'select')?_c('ChecDropdown',_vm._b({attrs:{"placeholder":_vm.$t('general.selectOption'),"options":_vm.schema.options,"multiselect":Boolean(_vm.schema.multiselect)},on:{"input":_vm.handleChange}},'ChecDropdown',_vm.fieldProps,false)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }