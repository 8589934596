<template>
  <div v-if="lastErrorId" class="error-code">
    <span>Error ID:</span>
    <div class="error-code__code">
      {{ lastErrorId }}
      <ChecButton
        v-if="canCopy"
        icon="copy"
        variant="small"
        @click="copyText(lastErrorId)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ChecButton } from '@chec/ui-library';
import copyText from '@/mixins/copyText';

export default {
  name: 'ErrorCode',
  components: { ChecButton },
  mixins: [copyText(
    'Error code has been copied to your clipboard.',
    'Failed to copy the error code. Please refresh the page and try again.',
  )],
  computed: {
    ...mapState(['errors']),
    lastErrorId() {
      const errors = [...this.errors];
      return errors.length > 0 && errors.sort().pop().id;
    },
  },
};
</script>

<style lang="scss">
.error-code {
  @apply mt-16 text-sm text-gray-500 font-mono;

  &__code {
    @apply text-gray-400 flex w-full justify-between items-center border rounded
      border-gray-300 p-1 pl-2 mt-2 bg-white;
  }
}
</style>
