export default [
  {
    path: 'account',
    component: () => import(/* webpackChunkName: "settings" */ './views/index.vue'),
    children: [
      {
        path: '',
        name: 'settings.account',
        component: () => import(/* webpackChunkName: "settings" */ './views/home.vue'),
        meta: {
          merchantless: true,
          title: (i18n) => i18n.t('account.account'),
        },
      },
    ],
  },
];
