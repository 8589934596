<template>
  <div>
    <ChecLoading />
    <router-view />
  </div>
</template>

<script>
import { ChecLoading } from '@chec/ui-library';

export default {
  name: 'LoadingLayout',
  components: {
    ChecLoading,
  },
};
</script>

<style lang="scss">

</style>
