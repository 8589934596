<template>
  <div class="template-tags">
    <template v-if="buttons">
      <ChecButton
        v-for="tag in tags"
        :key="tag"
        color="secondary"
        variant="small"
        class="template-tags__tag"
        @click="$emit('click-tag', tag)"
      >
        {{ tag }}
      </ChecButton>
    </template>
    <span
      v-for="tag in tags"
      v-else
      :key="tag"
      class="template-tags__tag"
    >
      {{ tag }}
    </span>
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';

export default {
  name: 'TemplateTags',
  components: {
    ChecButton,
  },
  props: {
    tags: Array,
    buttons: Boolean,
  },
};
</script>

<style lang="scss">
.template-tags {
  @apply flex pt-4;

  &__tag {
    &:not(:last-child) {
      @apply mr-3;
    }

    @apply shadow-sm text-gray-400 uppercase text-xxs; // Overriding default tags text style

    // todo use ChecTag when it doesn't have a hover state
    // see: https://github.com/chec/ui-library/issues/555
    @apply p-2 rounded bg-white;
  }
}
</style>
