export default {
  data() {
    return {
      externalId: undefined,
    };
  },
  methods: {
    async handleSaveConfiguration() {
      this.saving = true;
      try {
        const { id } = await this.create({
          template: this.template.id,
          config: this.templateConfig,
          external_id: this.externalId,
        });
        // If it's a "shortcut template", which doesn't require provisioning, send a success
        // message. Otherwise, send an info saying it is being set up.
        if (this.template.source_repo) {
          this.addNotification(this.$t('integration.savedProvisioning'), 'info');
        } else {
          this.addNotification(this.$t('integration.saved'));
        }
        this.$router.push({ name: 'integrations.edit', params: { id } });
      } catch (error) {
        this.addNotification(this.$t('integration.createError'), 'error');
      } finally {
        this.saving = false;
      }
    },
    setExternalId(id) {
      this.externalId = id;
    },
  },
};
