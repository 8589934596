<template>
  <ChecButton
    color="primary"
    :variant="variant"
    v-bind="props"
    v-on="events"
  >
    {{ label }}
  </ChecButton>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import createIntegration from '../mixins/create-integration';

export default {
  name: 'EnableTemplateButton',
  components: {
    ChecButton,
  },
  mixins: [
    addNotification,
    createIntegration,
    crud('integrations/integrations'),
  ],
  props: {
    /**
     * One of "round" or "small"
     */
    variant: String,
    /**
     * The template that this button will enable
     */
    template: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    events() {
      // Templates that don't skip configuration just use a route button so have no events
      if (!this.template.skip_initial_configuration) {
        return {};
      }

      return {
        click: async () => {
          this.$emit('loading');
          this.loading = true;
          await this.handleSaveConfiguration();
          this.loading = false;
          this.$emit('saved');
        },
      };
    },
    label() {
      return this.loading ? this.$t('general.loading') : this.$t('general.enable');
    },
    props() {
      const { id, skip_initial_configuration: skipConfig } = this.template;
      // Templates that don't skip configuration will take you to an edit screen
      const props = !skipConfig
        ? {
          tagType: 'route',
          to: { name: 'integrations.configure', params: { templateId: id } },
        }
        : {
          tagType: 'button',
        };

      if (this.loading) {
        return {
          ...props,
          disabled: true,
        };
      }

      return props;
    },
  },
};
</script>

<style lang="scss">

</style>
