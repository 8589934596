import actions from '@/store/actions';
import mutations from '@/store/mutations';
import { makeApiRequest } from '@/lib/api';

const {
  SET_WEBHOOK_TYPES,
  SET_WEBHOOK_SIGNING_KEY,
  SET_WEBHOOKS,
  SET_WEBHOOKS_HISTORY,
} = mutations;

const {
  FETCH_WEBHOOK_EVENTS,
  FETCH_WEBHOOK_SIGNING_KEY,
  FETCH_WEBHOOKS,
  APPEND_WEBHOOK,
  UPDATE_WEBHOOK,
  REMOVE_WEBHOOK,
  FETCH_WEBHOOKS_HISTORY,
  APPEND_WEBHOOK_HISTORY,
} = actions;

export default {
  namespaced: true,
  state: {
    isLoading: true,
    signingKey: null,
    types: [],
    // false indicates not loaded yet
    webhooks: false,
    items: undefined,
    history: {},
    historyLoaded: [],
  },
  mutations: {
    [SET_WEBHOOK_TYPES](state, { data }) {
      state.types = data;
    },
    // eslint-disable-next-line camelcase
    [SET_WEBHOOK_SIGNING_KEY](state, { data: { signing_key } }) {
      // eslint-disable-next-line camelcase
      state.signingKey = signing_key;
    },
    [SET_WEBHOOKS](state, payload) {
      state.webhooks = payload;
      state.isLoading = false;
      // Also set "items" to make compatible with pagination related processes that expect "items"
      state.items = payload;
    },
    [SET_WEBHOOKS_HISTORY](state, { webhookId, data }) {
      state.history = {
        ...state.history,
        [webhookId]: data,
      };
      state.historyLoaded.push(webhookId);
    },
  },
  actions: {
    [FETCH_WEBHOOK_EVENTS]({ commit }) {
      return makeApiRequest('get', '/v1/webhooks/events')
        .then(({ data }) => {
          commit(SET_WEBHOOK_TYPES, data);
        });
    },
    [FETCH_WEBHOOK_SIGNING_KEY]({ commit }, regenerate = false) {
      return makeApiRequest(regenerate ? 'PUT' : 'GET', '/v1/webhooks/signing_key')
        .then(({ data }) => commit(SET_WEBHOOK_SIGNING_KEY, data));
    },
    [FETCH_WEBHOOKS]({ commit }) {
      return makeApiRequest('get', '/v1/webhooks')
        .then(({ data: payload }) => {
          commit(SET_WEBHOOKS, payload.data);
        });
    },
    [APPEND_WEBHOOK]({ commit, state }, webhook) {
      commit(SET_WEBHOOKS, [...state.webhooks, webhook]);
    },
    [UPDATE_WEBHOOK]({ commit, state }, webhook) {
      // Replace the webhook in its existing position
      const newWebhooks = state.webhooks
        .map((entry) => (entry.id === webhook.id ? webhook : entry));
      commit(SET_WEBHOOKS, newWebhooks);
    },
    [REMOVE_WEBHOOK]({ commit, state }, webhookId) {
      return makeApiRequest('delete', `/v1/webhooks/${webhookId}`).then(() => {
        const webhooks = state.webhooks.filter((webhook) => webhook.id !== webhookId);
        commit(SET_WEBHOOKS, webhooks);
      });
    },
    [FETCH_WEBHOOKS_HISTORY]({ commit }, webhookId = 'all') {
      if (webhookId !== 'all') {
        // Fetch a specific webhook's history
        return makeApiRequest('get', `/v1/webhooks/${webhookId}`)
          .then(({ data }) => commit(SET_WEBHOOKS_HISTORY, {
            webhookId,
            data: data.data.history.data, // all the datas
          }));
      }
      // Fetch all webhook history
      return makeApiRequest('get', '/v1/webhooks/history')
        .then(({ data }) => commit(SET_WEBHOOKS_HISTORY, {
          webhookId: 'all',
          data: data.data,
        }));
    },
    [APPEND_WEBHOOK_HISTORY]({ commit, state }, { newRecord, webhookId }) {
      // Append to the specific webhook ID's history list
      const existingHistory = state.history[webhookId] || [];

      commit(SET_WEBHOOKS_HISTORY, {
        webhookId,
        data: [
          newRecord,
          ...existingHistory.slice(0, 29), // Remove one off the end of the list
        ],
      });

      // Append to the global history list as well
      commit(SET_WEBHOOKS_HISTORY, {
        webhookId: 'all',
        data: [newRecord, ...state.history.all],
      });
    },
  },
  getters: {
    isWebhookHistoryLoaded: (state) => (key) => {
      const historyKey = key || 'all';
      return !!state.historyLoaded.find((value) => value === historyKey);
    },
    getWebhook: (state) => (id) => (state.webhooks
      ? state.webhooks.filter((webhook) => webhook.id === id)[0]
      : null),
    webhookHistory: (state) => (key) => {
      const historyKey = key || 'all';
      return state.history[historyKey] || [];
    },
  },
};
