<template>
  <ChecCard
    inner-class="integrations__info-card"
    tailwind="p-4"
  >
    <ChecLoading v-if="loading" />
    <div v-else class="integrations__info-card-container">
      <div class="integrations__info-card-logo">
        <img
          v-if="template.icon_url"
          :src="template.icon_url"
          :alt="template.name"
          class="integrations__info-card-logo-image"
        >
        <h4 class="integrations__info-card-name">
          {{ template.name }}
        </h4>
      </div>
      <TemplateTags
        v-if="template.tags"
        :tags="template.tags"
      />
      <p class="integrations__info-card-description">
        {{ template.short_description }}
      </p>
      <ChecButton
        text-only
        variant="small"
        color="primary"
        @click="showInfoPanel = true"
      >
        {{ $t('general.learnMoreButton') }}
      </ChecButton>
    </div>
    <TemplateInfo
      v-if="showInfoPanel"
      :template="template"
      @close="handleClose"
    />
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecLoading,
} from '@chec/ui-library';
import TemplateInfo from './TemplateInfoPanel.vue';
import TemplateTags from './TemplateTags.vue';

export default {
  name: 'DescriptionCard',
  components: {
    ChecButton,
    ChecCard,
    ChecLoading,
    TemplateInfo,
    TemplateTags,
  },
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      showInfoPanel: false,
    };
  },
  methods: {
    handleClose() {
      this.showInfoPanel = false;
    },
  },
};
</script>

<style lang="scss">
.integrations {
  &__info-card {
    @apply relative shadow-sm rounded-md overflow-hidden flex flex-col;
  }

  &__info-card-logo {
    @apply flex flex-row items-center;
  }

  &__info-card-logo-image {
    @apply w-12 mr-2 rounded;
  }

  &__info-card-name {
    @apply text-xl font-bold text-gray-600;
  }

  &__info-card-description {
    @apply text-gray-600 py-4;
  }
}
</style>
