import { mapActions, mapGetters, mapState } from 'vuex';
import {
  actions,
  getters,
  mapPagination,
} from '@/lib/pagination';
import debouncedSearch from '@/mixins/debouncedSearch';

/**
 * Provide VueX state, getters, actions, and mutations to facilitate CUD operations. Adding "read"
 * functionality can also be enabled by passing `true` for the second parameter (includePagination)
 *
 * This is a factory function that generates a Vue mixin. Use within the mixins directive of your
 * Vue component:
 *
 * ```js
 * mixins: [
 *   crud('products/variants', true, 'context', true),
 * ],
 * ```
 *
 * ## `contextProp`
 *
 * The "contextProp" parameter will inform the crud mixin how to resolve the "context" for the
 * "pagination" helper. Refer to the pagination helper README for an explanation on context. This
 * parameter can be a string or a function. If given a string, it can either refer to a prop (or
 * computed/data attribute) where the context is provided, or it can refer to a specific attribute
 * that should be provided within the context object. For example, a usage involving variants may
 * choose to list `'productId'` as a "contextProp". The resolved context will be:
 *
 * ```js
 * {
 *   productId: $vm.productId
 * }
 * ```
 * If given a function as the "contextProp" parameter, the full context should be returned from the
 * function. This function will run bound to the Vue component, so `this.prop` (etc) can be used
 *
 * ## `namespace`
 *
 * You can also provide the `namespace` option to add the module name to methods and properties
 * defined by the mixin. This is helpful when using multiple crud mixins in one component.
 * The module name will be appended to the usual name of a property or method. For example,
 * `products/variants` as a state key, with namespacing on, will create method names like
 * `createVariants` and `deleteVariants`, and attribute names like `isLoadingVariants` and
 * `currentFiltersVariants`. Note that the plural on `variants` will remain even when using singular
 * sounding methods like `getVariants` which will get _one_ variant from state.
 *
 * @param {String} stateKey The state key for pagination module to map in (eg. products/variants)
 * @param {Boolean} includePagination Whether to include paginated data for reading
 * @param {Function|String} contextProp Indicate how pagination context is resolved (see above)
 * @param {Boolean} namespace Whether to add namespacing to bound props and methods
 */
export default (stateKey, includePagination = false, contextProp = null, namespace = false) => {
  // Create a copy of the (state) key, that excludes namespaces and has the first letter upper cased
  const parts = stateKey.split('/');
  const key = parts[parts.length - 1].charAt(0).toUpperCase() + parts[parts.length - 1].slice(1);

  // Create a function that will generate a suitable name for properties and methods, depending on
  // whether we're namespacing them (eg. when two crud mixins are used together)
  const propName = (name) => (namespace ? `${name}${key}` : name);

  // Create a function that will try to resolve context from props passed in, if there is any
  const resolveContext = ($vm) => {
    if (!contextProp) {
      return {};
    }

    // We allow context to be a prop name or a function that will resolve context like a method
    const context = typeof contextProp === 'function'
      ? contextProp.call($vm)
      : $vm[contextProp];

    // Allow context props that resolves to just a string, and then assume that it should be an
    // object with the propname. Eg. a prop of `zone` that gives just a zone ID will resolve to
    // a context object like `{ zone: 'zone_123' }`.
    if (typeof context !== 'object' && typeof contextProp === 'string') {
      return { [contextProp]: context };
    }

    if (typeof context !== 'object') {
      throw new Error('Context resolved by crud mixin is not an object!');
    }

    return context;
  };

  // NOTE: `propName` in the following methods changes the names of the methods when the
  // "namespaced" option is true - the names will be suffixed with the module name, eg.
  // "loadProducts".
  return {
    computed: {
      ...mapGetters(stateKey, {
        [propName('get')]: getters.GET_ITEM,
        [propName('isFullyLoaded')]: getters.IS_FULLY_LOADED,
      }),
      ...(includePagination ? mapPagination(stateKey) : {}),
      ...mapState(stateKey, {
        [propName('isLoading')]: 'isLoading',
        [propName('isSearching')]: 'isSearching',
        [propName('currentFilters')]: 'currentFilters',
        [propName('currentSearchTerm')]: 'currentSearchTerm',
        [propName('totalItemCount')]: 'totalCount',
      }),
    },
    mounted() {
      // Try to initialise context when crud components are mounted
      const context = resolveContext(this);
      this.$store.dispatch(`${stateKey}/${actions.SWITCH_CONTEXT}`, { context });
    },
    unmounted() {
      // Clear any search when unmounting a component that has searched
      this[propName('clearSearch')]();
    },
    methods: {
      ...mapActions(stateKey, {
        [propName('load')](dispatch, idOrIds, force = false) {
          return dispatch(actions.LOAD_ITEM, { id: idOrIds, force });
        },
        [propName('loadPage')](dispatch, page, isLoading = false, overrideContext = null) {
          const context = overrideContext || resolveContext(this);
          return dispatch(actions.SET_PAGE, { page, context, isLoading });
        },
        [propName('create')](
          dispatch,
          item,
          prepend = false,
          optimistic = true,
          expectedErrors = [],
        ) {
          return dispatch(actions.ADD_NEW_ITEM, {
            item,
            prepend,
            optimistic,
            expectedErrors,
          });
        },
        [propName('update')](
          dispatch,
          id,
          item,
          merge = false,
          optimistic = true,
          expectedErrors = [],
        ) {
          return dispatch(actions.UPDATE_ITEM, {
            id,
            item,
            merge,
            optimistic,
            expectedErrors,
          });
        },
        [propName('delete')](dispatch, id, optimistic = true) {
          return dispatch(actions.DELETE_ITEM, { id, optimistic });
        },
        [propName('search')]: debouncedSearch,
        [propName('setFilters')](dispatch, filters) {
          return dispatch(actions.SEARCH_ITEMS, { filters });
        },
        [propName('switchContext')](dispatch, context) {
          return dispatch(actions.SWITCH_CONTEXT, { context });
        },
        [propName('clearSearch')](dispatch) {
          return dispatch(actions.SEARCH_ITEMS, { term: '' });
        },
        [propName('resetItemsStore')]: actions.RESET,
      }),
    },
  };
};
